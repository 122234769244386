export default {
  // ------------------------- 颜色 --------------------------------------------
  getDividerBorderColor() { // 分隔线1
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return '#373941'
    } else if (themeType === 'tint') {
      return '#EEF0F5'
    }
  },
  getTabTitleActiveColor() { // tab选中态颜色
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return '#ffffff'
    } else if (themeType === 'tint') {
      return '#404044'
    }
  },
  getDividerBorderColo2() { // 分隔线2
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return '#373941'
    } else if (themeType === 'tint') {
      return '#E4E7ED'
    }
  },
  getProgressTrackColor() { // 进度条底色
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return '#29292D'
    } else if (themeType === 'tint') {
      return '#E6EAF4'
    }
  },
  getBackIconColor() { // 返回按钮颜色
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return '#ffffff'
    } else if (themeType === 'tint') {
      return '#404044'
    }
  },
  getMainBackgroundColor() { // 主背景色
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return '#1A1A1C'
    } else if (themeType === 'tint') {
      return '#EEF0F5'
    }
  },
  getTabsUnderlineColor() { // 主背景色
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return '#F73434'
    } else if (themeType === 'tint') {
      return '#F73434'
    }
  },
  // ------------------------- 图标 --------------------------------------------
  getVsImage() { // vs图标
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return require('@/assets/images/guessing/vs.png')
    } else if (themeType === 'tint') {
      return require('@/assets/images/guessing/vs_black.png')
    }
  },
  getHelpImage() { // 问号图标
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return require('@/assets/images/help.png')
    } else if (themeType === 'tint') {
      return require('@/assets/images/help_black.png')
    }
  },
  getRecordImage() { // 记录图标
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return require('@/assets/images/record.png')
    } else if (themeType === 'tint') {
      return require('@/assets/images/record_black.png')
    }
  },
  getEditImage() { // 编辑图标
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return require('@/assets/images/edit.png')
    } else if (themeType === 'tint') {
      return require('@/assets/images/edit_black.png')
    }
  },
  getDeleteImage() { // 删除图标
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return require('@/assets/images/delete.png')
    } else if (themeType === 'tint') {
      return require('@/assets/images/delete_black.png')
    }
  },
  getBatchImage() { // 批量图标
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return require('@/assets/images/batch.png')
    } else if (themeType === 'tint') {
      return require('@/assets/images/batch_black.png')
    }
  },
  getGradeAvatarBgImage() { // 批量图标
    const themeType = window.document.documentElement.getAttribute('data-theme')
    if (themeType === 'default') {
      return require('@/assets/images/grade/avatar_bg_black.png')
    } else if (themeType === 'tint') {
      return require('@/assets/images/grade/avatar_bg.png')
    }
  }

}
