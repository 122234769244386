import request from '@/api/httpRequest'

export function guessingPermissionStatus(params) {
  return request({
    url: '/app/guess/isOn',
    method: 'post',
    data: params
  })
}

export function kitPermissionStatus(params) {
  return request({
    url: '/app/kits/switch/isON',
    method: 'post',
    data: params
  })
}
