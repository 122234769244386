var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c(
        "van-list",
        {
          attrs: { finished: _vm.finished, "finished-text": _vm.finishedText },
          on: { load: _vm.getList },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            { key: index },
            [
              _c("span", { staticClass: "main-theme-font-color" }, [
                _vm._v(_vm._s(item.title)),
              ]),
              _vm._v(" "),
              _vm._l(item.list, function (subItem, subIndex) {
                return _c(
                  "div",
                  {
                    key: subIndex,
                    staticStyle: {
                      "margin-top": "10px",
                      "margin-bottom": "20px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card" },
                      [
                        _c(
                          "van-row",
                          {
                            attrs: {
                              type: "flex",
                              justify: "space-between",
                              align: "center",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "main-theme-font-color",
                                staticStyle: {
                                  "max-width": "90%",
                                  "line-height": "20px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " + _vm._s(subItem.eventName)
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: subItem.roundId !== "0",
                                        expression: "subItem.roundId !== '0'",
                                      },
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "第" + _vm._s(subItem.roundId) + "轮"
                                    ),
                                  ]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(subItem.matchTime) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: {
                                width: "16px",
                                height: "16px",
                                "margin-right": "10px",
                              },
                              attrs: {
                                src: require("@/assets/images/live-setting/delete_icon.png"),
                              },
                              on: {
                                click: function ($event) {
                                  ;(_vm.showAlert = true),
                                    (_vm.selectedItem = subItem)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "van-row",
                          {
                            staticStyle: { "margin-top": "20px" },
                            attrs: { type: "flex", align: "center" },
                          },
                          [
                            subItem.homeLogo && subItem.homeLogo !== ""
                              ? _c("img", {
                                  staticStyle: {
                                    width: "26px",
                                    height: "26px",
                                    "border-radius": "13px",
                                    border: "1px solid rgba(255,255,255,1)",
                                    "margin-right": "10px",
                                  },
                                  attrs: { src: subItem.homeLogo },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !subItem.homeLogo || subItem.homeLogo === ""
                              ? _c("img", {
                                  staticStyle: {
                                    width: "26px",
                                    height: "26px",
                                    "border-radius": "13px",
                                    border: "1px solid rgba(255,255,255,1)",
                                    "margin-right": "10px",
                                  },
                                  attrs: {
                                    src: require("@/assets/images/team_logo_default.png"),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "main-theme-font-color",
                                staticStyle: { "font-size": "12px" },
                              },
                              [_vm._v(_vm._s(subItem.homeName))]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: {
                                width: "22px",
                                height: "25px",
                                "margin-left": "20px",
                                "margin-right": "20px",
                              },
                              attrs: { src: _vm.$theme.getVsImage() },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "main-theme-font-color",
                                staticStyle: { "font-size": "12px" },
                              },
                              [_vm._v(_vm._s(subItem.awayName))]
                            ),
                            _vm._v(" "),
                            subItem.awayLogo && subItem.awayLogo !== ""
                              ? _c("img", {
                                  staticStyle: {
                                    width: "26px",
                                    height: "26px",
                                    "border-radius": "13px",
                                    border: "1px solid rgba(255,255,255,1)",
                                    "margin-left": "10px",
                                  },
                                  attrs: { src: subItem.awayLogo },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !subItem.awayLogo || subItem.awayLogo === ""
                              ? _c("img", {
                                  staticStyle: {
                                    width: "26px",
                                    height: "26px",
                                    "border-radius": "13px",
                                    border: "1px solid rgba(255,255,255,1)",
                                    "margin-left": "10px",
                                  },
                                  attrs: {
                                    src: require("@/assets/images/team_logo_default.png"),
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("alert", {
        attrs: {
          visible: _vm.showAlert,
          message: "确定取消预约吗?",
          "cancel-button-text": "取消",
          "confirm-button-text": "确认",
        },
        on: {
          "update:visible": function ($event) {
            _vm.showAlert = $event
          },
          confirm: function ($event) {
            return _vm.handleDelete()
          },
        },
      }),
      _vm._v(" "),
      !_vm.loading && _vm.list.length === 0
        ? _c(
            "van-empty",
            {
              staticClass: "empty-image",
              staticStyle: { "margin-top": "40%" },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { width: "100%", "text-align": "center" },
                  attrs: { slot: "image" },
                  slot: "image",
                },
                [
                  _c("img", {
                    staticStyle: { width: "257px", height: "84px" },
                    attrs: { src: require("@/assets/images/empty.png") },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { slot: "description" },
                  slot: "description",
                },
                [_c("div", [_vm._v(_vm._s(_vm.$t("nomatch")))])]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }