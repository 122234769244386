var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main-content" },
      [
        _c("img", {
          staticStyle: { width: "274px", height: "400px" },
          attrs: { src: require("@/assets/images/dowload/phone.png") },
        }),
        _vm._v(" "),
        _c(
          "van-button",
          {
            staticClass: "dowload-button",
            staticStyle: { "margin-top": "50px", "margin-bottom": "30px" },
          },
          [_vm._v("点击下载")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bg" }, [
      _c("img", {
        staticStyle: { width: "100%", height: "100%" },
        attrs: { src: require("@/assets/images/dowload/bg.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        staticStyle: { width: "50px", height: "69px" },
        attrs: { src: require("@/assets/images/dowload/icon.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }