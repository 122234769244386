var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c("van-sticky", { attrs: { "offset-top": 0 } }, [
        _c(
          "div",
          { staticClass: "main-bg", staticStyle: { "padding-top": "10px" } },
          [
            _c(
              "van-tabs",
              {
                attrs: {
                  swipeable: "",
                  type: "card",
                  color: "#63676B",
                  "title-inactive-color": "#9B9B9B",
                  sticky: "",
                },
                on: { change: _vm.tabChange },
                model: {
                  value: _vm.activeIndex,
                  callback: function ($$v) {
                    _vm.activeIndex = $$v
                  },
                  expression: "activeIndex",
                },
              },
              [
                _c("van-tab", { attrs: { title: _vm.$t("hotrace") } }),
                _vm._v(" "),
                _c("van-tab", { attrs: { title: _vm.$t("yiyuyue") } }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c(
            "van-radio-group",
            {
              model: {
                value: _vm.selectedGame,
                callback: function ($$v) {
                  _vm.selectedGame = $$v
                },
                expression: "selectedGame",
              },
            },
            [
              _c(
                "van-cell-group",
                _vm._l(_vm.selectedList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c(
                        "van-cell",
                        {
                          attrs: { clickable: "" },
                          on: {
                            click: function ($event) {
                              _vm.selectedGame = item
                            },
                          },
                        },
                        [
                          _vm.activeIndex === 0
                            ? _c(
                                "div",
                                { staticClass: "card" },
                                [
                                  _c(
                                    "van-row",
                                    {
                                      attrs: {
                                        type: "flex",
                                        justify: "space-between",
                                        align: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "main-theme-font-color",
                                          staticStyle: { "max-width": "90%" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.leagueName) +
                                              _vm._s(
                                                item.round === "0"
                                                  ? ""
                                                  : item.round
                                              ) +
                                              " " +
                                              _vm._s(item.startTime) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("van-radio", {
                                        attrs: { name: item },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "van-row",
                                    {
                                      staticStyle: { "margin-top": "20px" },
                                      attrs: { type: "flex", align: "center" },
                                    },
                                    [
                                      item.homeTeamLogo &&
                                      item.homeTeamLogo !== ""
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "26px",
                                              height: "26px",
                                              "border-radius": "13px",
                                              border: "1px solid #EEF0F5",
                                              "margin-right": "10px",
                                            },
                                            attrs: { src: item.homeTeamLogo },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !item.homeTeamLogo ||
                                      item.homeTeamLogo === ""
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "26px",
                                              height: "26px",
                                              "border-radius": "13px",
                                              border: "1px solid #EEF0F5",
                                              "margin-right": "10px",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/team_logo_default.png"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "main-theme-font-color",
                                          staticStyle: { "font-size": "12px" },
                                        },
                                        [_vm._v(_vm._s(item.homeTeam))]
                                      ),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticStyle: {
                                          width: "22px",
                                          height: "25px",
                                          "margin-left": "20px",
                                          "margin-right": "20px",
                                        },
                                        attrs: { src: _vm.$theme.getVsImage() },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "main-theme-font-color",
                                          staticStyle: { "font-size": "12px" },
                                        },
                                        [_vm._v(_vm._s(item.awayTeam))]
                                      ),
                                      _vm._v(" "),
                                      item.awayTeamLogo &&
                                      item.awayTeamLogo !== ""
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "26px",
                                              height: "26px",
                                              "border-radius": "13px",
                                              border: "1px solid #EEF0F5",
                                              "margin-left": "10px",
                                              "margin-right": "10px",
                                            },
                                            attrs: { src: item.awayTeamLogo },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !item.awayTeamLogo ||
                                      item.awayTeamLogo === ""
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "26px",
                                              height: "26px",
                                              "border-radius": "13px",
                                              border: "1px solid #EEF0F5",
                                              "margin-left": "10px",
                                              "margin-right": "10px",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/team_logo_default.png"),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "card" },
                                [
                                  _c(
                                    "van-row",
                                    {
                                      attrs: {
                                        type: "flex",
                                        justify: "space-between",
                                        align: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "main-theme-font-color",
                                          staticStyle: { "max-width": "90%" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.eventName) +
                                              _vm._s(
                                                item.roundId === "0"
                                                  ? ""
                                                  : "第" + item.roundId + "轮"
                                              ) +
                                              " " +
                                              _vm._s(item.matchTime) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("van-radio", {
                                        attrs: { name: item },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "van-row",
                                    {
                                      staticStyle: { "margin-top": "20px" },
                                      attrs: { type: "flex", align: "center" },
                                    },
                                    [
                                      item.homeLogo && item.homeLogo !== ""
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "26px",
                                              height: "26px",
                                              "border-radius": "13px",
                                              border: "1px solid #EEF0F5",
                                              "margin-right": "10px",
                                            },
                                            attrs: { src: item.homeLogo },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !item.homeLogo || item.homeLogo === ""
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "26px",
                                              height: "26px",
                                              "border-radius": "13px",
                                              border: "1px solid #EEF0F5",
                                              "margin-right": "10px",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/team_logo_default.png"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "main-theme-font-color",
                                          staticStyle: { "font-size": "12px" },
                                        },
                                        [_vm._v(_vm._s(item.homeName))]
                                      ),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticStyle: {
                                          width: "22px",
                                          height: "25px",
                                          "margin-left": "20px",
                                          "margin-right": "20px",
                                        },
                                        attrs: { src: _vm.$theme.getVsImage() },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "main-theme-font-color",
                                          staticStyle: { "font-size": "12px" },
                                        },
                                        [_vm._v(_vm._s(item.awayName))]
                                      ),
                                      _vm._v(" "),
                                      item.awayLogo && item.awayLogo !== ""
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "26px",
                                              height: "26px",
                                              "border-radius": "13px",
                                              border: "1px solid #EEF0F5",
                                              "margin-left": "10px",
                                              "margin-right": "10px",
                                            },
                                            attrs: { src: item.awayLogo },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !item.awayLogo || item.awayLogo === ""
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "26px",
                                              height: "26px",
                                              "border-radius": "13px",
                                              border: "1px solid #EEF0F5",
                                              "margin-left": "10px",
                                              "margin-right": "10px",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/team_logo_default.png"),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.selectedList.length === 0
            ? _c(
                "van-empty",
                {
                  staticClass: "empty-image",
                  staticStyle: { "margin-top": "40%" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { width: "100%", "text-align": "center" },
                      attrs: { slot: "image" },
                      slot: "image",
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "257px", height: "84px" },
                        attrs: { src: require("@/assets/images/empty.png") },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { slot: "description" },
                      slot: "description",
                    },
                    [_c("div", [_vm._v(_vm._s(_vm.$t("nomatch")))])]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bottom" }, [
        _c(
          "div",
          { staticClass: "row", staticStyle: { "padding-top": "10px" } },
          [
            _c(
              "van-button",
              {
                staticClass: "button-primary",
                staticStyle: { width: "80%", "border-radius": "6px" },
                attrs: { disabled: !_vm.selectedGame },
                on: { click: _vm.commit },
              },
              [_vm._v(_vm._s(_vm.$t("querenbtn")))]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }