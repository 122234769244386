var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-container" }, [
      _c("img", {
        staticStyle: {
          width: "132px",
          height: "20px",
          "margin-top": "20px",
          "margin-left": "20px",
        },
        attrs: {
          src: require("@/assets/images/guessing/contribution_icon.png"),
        },
      }),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "text-content", staticStyle: { "margin-top": "20px" } },
        [
          _vm._v(
            "\n    预言主题由主播发起，用户可在主播直播间点击预言入口按钮即可查看预言内容。\n    每个预言主题分别有2个可参与选项，可任意投注你认为会赢的选项参与，每个选项参与金额参与上限为5000；\n    可同时选择多个进行参与，最终将根据主播公布的结果统一结算。\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row",
          staticStyle: { width: "100%", "margin-top": "20px" },
        },
        [
          _c("img", {
            staticStyle: { width: "355px", height: "181px" },
            attrs: {
              src: require("@/assets/images/guessing/contribution_img_1.png"),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "title-wrapper", staticStyle: { "margin-top": "27px" } },
        [
          _c("img", {
            staticStyle: { width: "150px", height: "28px" },
            attrs: { src: require("@/assets/images/guessing/title_bg_1.png") },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "title-content" }, [
            _c("span", { staticClass: "title" }, [_vm._v("倍数及派奖规则")]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "text-content", staticStyle: { "margin-top": "20px" } },
        [
          _c("span", [_vm._v("1、倍数根据双方参与的数额实时计算；")]),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "2、选择你预测获胜的选项，点击选项，在弹出的参与面板中选择合适的数额参与；"
            ),
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", [_vm._v("3、如果获胜，最终返还数额=参与数额*倍数；")]),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "4、如果参与的主题只有其中1个结果有用户参与，主播公布结果后，此局不计输赢，将返还参与金额。"
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row",
          staticStyle: { width: "100%", "margin-top": "20px" },
        },
        [
          _c("img", {
            staticStyle: { width: "355px", height: "271px" },
            attrs: {
              src: require("@/assets/images/guessing/contribution_img_2.png"),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "title-wrapper", staticStyle: { "margin-top": "27px" } },
        [
          _c("img", {
            staticStyle: { width: "108px", height: "28px" },
            attrs: { src: require("@/assets/images/guessing/title_bg_2.png") },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "title-content" }, [
            _c("span", { staticClass: "title" }, [_vm._v("举例说明")]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "text-content", staticStyle: { "margin-top": "20px" } },
        [
          _c("span", [
            _vm._v(
              "参与【选项A】数额100，参与时的倍数为1.5，停止时倍数为2.0；"
            ),
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v("如预言结果正确，将返还你195（包含本金，且扣除抽成）"),
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", [_vm._v(" 如预言结果错误，将扣除你100本金；")]),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "主播全部结束预言后，将在直播间内弹窗显示预言结果，以及你的收益或亏损；"
            ),
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "收益将会充入你的账户中，请耐心等待5-10分钟；如未到账，请及时联系客服；"
            ),
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", [_vm._v(" 您也可以进入预言记录页面查看详细参与记录。")]),
          _c("br"),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row",
          staticStyle: { width: "100%", "margin-top": "20px" },
        },
        [
          _c("img", {
            staticStyle: { width: "355px", height: "256px" },
            attrs: {
              src: require("@/assets/images/guessing/contribution_img_3.png"),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "title-wrapper", staticStyle: { "margin-top": "27px" } },
        [
          _c("img", {
            staticStyle: { width: "178px", height: "28px" },
            attrs: { src: require("@/assets/images/guessing/title_bg_3.png") },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "title-content" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("异常问题及申诉反馈"),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "relative", "margin-top": "20px" } },
        [
          _c("div", {
            staticStyle: {
              position: "absolute",
              width: "4px",
              height: "16px",
              "background-color": "#4A90E2",
              top: "0px",
              left: "9px",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "text", staticStyle: { "padding-left": "20px" } },
            [_vm._v("1.主播结果公布错误怎么办？")]
          ),
          _c("br"),
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "text-content" }, [
        _c("span", [
          _vm._v(
            " 预言结果由主播个人提交，官方已提醒主播谨慎提交，若主播仍提交错误，请及时联系主播协商处理，若主播不受理，请联系客服，提供主播房间号及协商截图，官方核实后，将视情节对主播进行处罚；"
          ),
        ]),
        _c("br"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "relative", "margin-top": "20px" } },
        [
          _c("div", {
            staticStyle: {
              position: "absolute",
              width: "4px",
              height: "16px",
              "background-color": "#4A90E2",
              top: "0px",
              left: "9px",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "text", staticStyle: { "padding-left": "20px" } },
            [_vm._v(" 2.奖励未到账怎么办？ ")]
          ),
          _c("br"),
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "text-content" }, [
        _c("span", [
          _vm._v(
            "预言结束后，打开我的预言记录页面，查看参与记录，确认是否赢得了预言。余额如果未及时更新，你可以等待5-10分钟，或重新进入记录页面查看；如果确实未到账，请联系客服人员处理问题，核实后会返还你的奖励；"
          ),
        ]),
        _c("br"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "relative", "margin-top": "20px" } },
        [
          _c("div", {
            staticStyle: {
              position: "absolute",
              width: "4px",
              height: "16px",
              "background-color": "#4A90E2",
              top: "0px",
              left: "9px",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "text", staticStyle: { "padding-left": "20px" } },
            [_vm._v("3.什么情况下回流局处理？ ")]
          ),
          _c("br"),
        ]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "text-content",
          staticStyle: { "padding-bottom": "20px" },
        },
        [
          _c("span", [
            _vm._v(
              "主播在直播中，遇到特殊情况，如掉线、停电、忘记结算等无法公正功的公布预言结果时，将按照流局处理本局，流局后参与的金额将会返还给你。"
            ),
          ]),
          _c("br"),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }