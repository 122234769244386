var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.type === 1
      ? _c(
          "div",
          [
            _c(
              "van-row",
              [
                _c(
                  "van-col",
                  {
                    attrs: { span: "12" },
                    on: {
                      click: function ($event) {
                        return _vm.optionChange(0)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "rectangle left-radius right-border",
                        class:
                          _vm.selectedOption === 0 ? "option-active" : "option",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _vm.result === 0
                          ? _c("img", {
                              staticStyle: {
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                width: "30px",
                                height: "30px",
                              },
                              attrs: {
                                src: require("@/assets/images/kit/right_icon.png"),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class:
                              _vm.selectedOption === 0
                                ? "option-title-active"
                                : "option-title",
                          },
                          [
                            _vm.data
                              ? _c("div", [
                                  _c(
                                    "p",
                                    { staticStyle: { "margin-bottom": "4px" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("zhusheng")) +
                                          _vm._s(
                                            _vm.getZhuSheng(
                                              _vm.data.realOdds[1]
                                            )
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.data.realOdds[0])),
                                  ]),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "van-col",
                  {
                    attrs: { span: "12" },
                    on: {
                      click: function ($event) {
                        return _vm.optionChange(1)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "rectangle right-radius",
                        class:
                          _vm.selectedOption === 1 ? "option-active" : "option",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _vm.result === 1
                          ? _c("img", {
                              staticStyle: {
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                width: "30px",
                                height: "30px",
                              },
                              attrs: {
                                src: require("@/assets/images/kit/right_icon.png"),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class:
                              _vm.selectedOption === 1
                                ? "option-title-active"
                                : "option-title",
                          },
                          [
                            _vm.data
                              ? _c("div", [
                                  _c(
                                    "p",
                                    { staticStyle: { "margin-bottom": "4px" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("kesheng")) +
                                          _vm._s(
                                            _vm.getKeSheng(_vm.data.realOdds[1])
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.data.realOdds[2])),
                                  ]),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.type === 2
      ? _c(
          "div",
          [
            _c(
              "van-row",
              [
                _c(
                  "van-col",
                  {
                    attrs: { span: "12" },
                    on: {
                      click: function ($event) {
                        return _vm.optionChange(0)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "rectangle left-radius right-border",
                        class:
                          _vm.selectedOption === 0 ? "option-active" : "option",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _vm.result === 0
                          ? _c("img", {
                              staticStyle: {
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                width: "30px",
                                height: "30px",
                              },
                              attrs: {
                                src: require("@/assets/images/kit/right_icon.png"),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class:
                              _vm.selectedOption === 0
                                ? "option-title-active"
                                : "option-title",
                          },
                          [
                            _vm.data
                              ? _c("div", [
                                  _c(
                                    "p",
                                    { staticStyle: { "margin-bottom": "4px" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("bigqiu", {
                                              msg: _vm.data.realOdds[1],
                                            })
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.data.realOdds[0])),
                                  ]),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "van-col",
                  {
                    attrs: { span: "12" },
                    on: {
                      click: function ($event) {
                        return _vm.optionChange(1)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "rectangle right-radius",
                        class:
                          _vm.selectedOption === 1 ? "option-active" : "option",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _vm.result === 1
                          ? _c("img", {
                              staticStyle: {
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                width: "30px",
                                height: "30px",
                              },
                              attrs: {
                                src: require("@/assets/images/kit/right_icon.png"),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class:
                              _vm.selectedOption === 1
                                ? "option-title-active"
                                : "option-title",
                          },
                          [
                            _vm.data
                              ? _c("div", [
                                  _c(
                                    "p",
                                    { staticStyle: { "margin-bottom": "4px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("smallqiu", {
                                            msg: _vm.data.realOdds[1],
                                          })
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.data.realOdds[2])),
                                  ]),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.type === 3
      ? _c(
          "div",
          [
            _c(
              "van-row",
              [
                _c(
                  "van-col",
                  {
                    attrs: { span: "8" },
                    on: {
                      click: function ($event) {
                        return _vm.optionChange(0)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "rectangle left-radius right-border",
                        class:
                          _vm.selectedOption === 0 ? "option-active" : "option",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _vm.result === 0
                          ? _c("img", {
                              staticStyle: {
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                width: "30px",
                                height: "30px",
                              },
                              attrs: {
                                src: require("@/assets/images/kit/right_icon.png"),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class:
                              _vm.selectedOption === 0
                                ? "option-title-active"
                                : "option-title",
                          },
                          [
                            _vm.data
                              ? _c("div", [
                                  _c(
                                    "p",
                                    { staticStyle: { "margin-bottom": "4px" } },
                                    [_vm._v(_vm._s(_vm.$t("zhusheng")))]
                                  ),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.data.realOdds[0])),
                                  ]),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.gameType === 1
                  ? _c(
                      "van-col",
                      {
                        attrs: { span: "8" },
                        on: {
                          click: function ($event) {
                            return _vm.optionChange(1)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "rectangle right-border",
                            class:
                              _vm.selectedOption === 1
                                ? "option-active"
                                : "option",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _vm.result === 1
                              ? _c("img", {
                                  staticStyle: {
                                    position: "absolute",
                                    top: "0px",
                                    left: "0px",
                                    width: "30px",
                                    height: "30px",
                                  },
                                  attrs: {
                                    src: require("@/assets/images/kit/right_icon.png"),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                class:
                                  _vm.selectedOption === 1
                                    ? "option-title-active"
                                    : "option-title",
                              },
                              [
                                _vm.data
                                  ? _c("div", [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "4px",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("pingju")))]
                                      ),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(_vm._s(_vm.data.realOdds[1])),
                                      ]),
                                    ])
                                  : _c("span", [_vm._v("-")]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "van-col",
                  {
                    attrs: { span: "8" },
                    on: {
                      click: function ($event) {
                        return _vm.optionChange(2)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "rectangle right-radius",
                        class:
                          _vm.selectedOption === 2 ? "option-active" : "option",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _vm.result === 2
                          ? _c("img", {
                              staticStyle: {
                                position: "absolute",
                                top: "0px",
                                left: "0px",
                                width: "30px",
                                height: "30px",
                              },
                              attrs: {
                                src: require("@/assets/images/kit/right_icon.png"),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class:
                              _vm.selectedOption === 2
                                ? "option-title-active"
                                : "option-title",
                          },
                          [
                            _vm.data
                              ? _c("div", [
                                  _c(
                                    "p",
                                    { staticStyle: { "margin-bottom": "4px" } },
                                    [_vm._v(_vm._s(_vm.$t("kesheng")))]
                                  ),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.data.realOdds[2])),
                                  ]),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }