import Vue from 'vue'
import './cube-ui'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import '@/styles/index.scss' // global css
import '@/styles/theme.scss'

import App from './App'
import store from './store'
import router from './router'

import i18n from './locale' // internationalization
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import plugin from './plugin'
import * as filters from './filters' // global filters
import Vconsole from 'vconsole'

Vue.use(plugin)

/**
 * NODE_ENV为development时使用Vconsole
 */
if (process.env.NODE_ENV === 'development') {
  // const vConsole = new Vconsole()
  // Vue.use(vConsole)
}

/**
 * NODE_ENV为mock时使用本地模拟数据调试
 */
if (process.env.NODE_ENV === 'mock') {
  const { mockXHR } = require('../mock')
  mockXHR()
}

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
