<template>
  <div class="app-container">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="finishedText"
      @load="getList"
    >
      <div v-if="list.length > 0" class="main-content">
        <div v-for="(data, index) in list" :key="index">
          <div style="margin-top: 10px; margin-bottom: 10px; color: #9b9b9b">
            <span>{{ data.date | diyDateFormat }}</span>
          </div>
          <div
            v-for="(item, indexValue) in data.list"
            :key="indexValue"
            class="card"
            style="margin-bottom: 20px"
          >
            <van-row type="flex" justify="space-between" align="center">
              <span class="main-theme-font-color">{{ item.topic }}</span>
              <span v-if="item.status === 0" style="color: #f0cf2c">
                {{ $t('ongoing') }}
              </span>
              <span v-if="item.status != 0" class="main-theme-font-color">
                +{{ item.income }}
              </span>
            </van-row>
            <van-row
              type="flex"
              justify="space-between"
              align="center"
              style="margin-top: 20px"
            >
              <div class="row main-theme-font-color">
                <span>{{ item.optionDTO.forwardName }}</span>
                <span v-if="item.result === '流局'" style="color: #9b9b9b">
                  {{ $t('flow_bureau') }}
                </span>
                <img
                  v-if="item.optionDTO.forwardName === item.result"
                  style="width: 18px; height: 18px; margin-left: 8px"
                  src="@/assets/images/guessing/win.png"
                />
              </div>
              <div class="row-rigth">
                <span style="color: #9b9b9b; margin-right: 15px">
                  {{ item.optionDTO.forwardPersonNum }}
                  {{ $t('participation') }}
                </span>
                <span style="color: #9b9b9b">
                  {{ $t('money') }}{{ item.optionDTO.forwardNum }}
                </span>
              </div>
            </van-row>
            <van-divider
              :style="{
                borderColor: $theme.getDividerBorderColor(),
                marginTop: '12px',
                marginBottom: '0px'
              }"
            />
            <van-row
              type="flex"
              justify="space-between"
              align="center"
              style="margin-top: 20px"
            >
              <div class="row main-theme-font-color">
                <span>{{ item.optionDTO.backName }}</span>
                <span v-if="item.result === '流局'" style="color: #9b9b9b">
                  {{ $t('flow_bureau') }}
                </span>
                <img
                  v-if="item.optionDTO.backName === item.result"
                  style="width: 18px; height: 18px; margin-left: 8px"
                  src="@/assets/images/guessing/win.png"
                />
              </div>
              <div class="row-rigth">
                <span style="color: #9b9b9b; margin-right: 15px">
                  {{ item.optionDTO.backPersonNum }} {{ $t('participation') }}
                </span>
                <span style="color: #9b9b9b">
                  {{ $t('money') }}{{ item.optionDTO.backNum }}
                </span>
              </div>
            </van-row>
          </div>
        </div>
      </div>
    </van-list>
    <div v-if="list.length === 0">
      <van-empty class="empty-image" style="padding-top: 50%">
        <div slot="image" style="width: 100%; text-align: center">
          <img
            style="width: 257px; height: 84px"
            src="@/assets/images/empty.png"
          />
        </div>
        <div slot="description" style="text-align: center">
          <div>{{ $t('hint1') }}</div>
          <div style="margin-top: 5px">{{ $t('hint2') }}</div>
        </div>
      </van-empty>
      <div class="bottom">
        <router-link :to="{ path: '/guessing-selection' }">
          <van-button class="publishing-button">
            {{ $t('issuing_prophecy') }}
          </van-button>
        </router-link>
        <router-link :to="{ path: '/guessing-addition' }">
          <van-button class="addition-button">{{ $t('add_theme') }}</van-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import { history } from '@/api/guessing/index'
import { Row, Button, Divider, Empty, List } from 'vant'

export default {
  name: 'GuessingRecord',
  components: {
    [Button.name]: Button,
    [Row.name]: Row,
    [Divider.name]: Divider,
    [Empty.name]: Empty,
    [List.name]: List
  },
  data() {
    return {
      list: [],
      lastId: 0,
      loading: false,
      finished: false,
      finishedText: this.$t('prophetic_record')
    }
  },
  mounted() {
    window.load = this.load
  },
  created() {
    this.setTitle()
  },
  methods: {
    setTitle() {
      document.title = this.$t('nomore')
    },
    getList() {
      history({ lastId: this.lastId }).then(response => {
        this.list = this.list.concat(response.data)
        this.loading = false
        this.finished = true
        if (this.list.length < 20) {
          this.finishedText = ''
        } else {
          this.finishedText = this.$t('nomore')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/color.scss';
.main-content {
  width: 100%;
  overflow: scroll;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  @include color_main_bg();
}
.row-rigth {
  position: absolute;
  left: 170px;
}
.bottom {
  position: fixed;
  bottom: 35px;
  width: 100%;
  height: 140px;
  text-align: center;
  margin-bottom: 0px;
  @include color_main_bg();
  .publishing-button {
    width: 283px;
    height: 40px;
    background-color: #4a8fe2 !important;
    border-radius: 4px;
    border: 1px solid #4a8fe2 !important;
    color: #fff !important;
    font-size: 12px;
  }
  .addition-button {
    width: 283px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #4a8fe2 !important;
    font-size: 12px;
    margin-top: 30px;
    @include color_main_font();
    @include color_main_bg();
  }
}
/deep/.empty-image .van-empty__image {
  width: 257px;
  height: 84px;
}
</style>
