var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-container main-theme-font-color" }, [
    _c("div", { staticClass: "header" }, [
      _c("img", {
        staticStyle: {
          width: "80px",
          "max-width": "80px",
          height: "80px",
          "max-height": "80px",
          "object-fit": "fill",
        },
        attrs: {
          src:
            _vm.info.ios3X80X80LogoImageUrl +
            "?x-oss-process=image/resize,w_80,h_80,m_mfit",
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "main-content" }, [
      _c("span", {
        staticStyle: { "text-indent": "2em" },
        domProps: { innerHTML: _vm._s(_vm.info.aboutAgreement) },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("span", [_vm._v(_vm._s(_vm.info.brandName))]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", [_vm._v("联系方式：" + _vm._s(_vm.info.csCode))]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", [
        _vm._v("Copyright© " + _vm._s(_vm.info.brandName) + " All rights. "),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.info.icp))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }