import request from '@/api/httpRequest'

export function gameIndex(params) {
  return request({
    url: '/app/match/index/history',
    method: 'post',
    data: params
  })
}

export function fetchCompanyList(params) {
  return request({
    url: '/app/match/index/company',
    method: 'post',
    data: params
  })
}
