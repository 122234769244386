<template>
 <div class="icon-hit">
  <template v-if="lang === 'zh'">
        <img v-if="item.plan.guessHit === 1" style="width: 50px; height: 50px;" src="@/assets/images/kit/right.png">
        <img v-if="(item.plan.planBigType === 1 || item.plan.planBigType === 2) && parseLotteryResult(item.match.spf) === 1" style="width: 50px; height: 50px;" src="@/assets/images/kit/draw.png">
        <img v-if="item.plan.guessHit !== null && item.plan.guessHit === 0" style="width: 50px; height: 50px;" src="@/assets/images/kit/wrong.png">
  </template>
  <template v-else>
    <template v-if="item.plan.guessHit === 0 || item.plan.guessHit === 1 || item.plan.guessHit === 2">
      <img
        v-if="item.plan.guessHit === 1"
        class="icon-hit"
        :src="require('@/assets/images/plan/'+lang+'/icon_silk_correct.png')"
        width="50"
        height="50"
        alt
      />
      <template if="(item.plan.planBigType === 1 || item.plan.planBigType === 2) && parseLotteryResult(item.match.spf) === 1">
        <img
          class="icon-hit"
          :src="require('@/assets/images/plan/'+lang+'/icon_silk_miss.png')"
          width="50"
          height="50"
          alt
        />
        <img
          v-if="item.plan.guessHit !== null && item.plan.guessHit === 0"
          class="icon-hit"
          :src="require('@/assets/images/plan/'+lang+'/icon_silk_uncorrect.png')"
          width="50"
          height="50"
          alt
        />
      </template>
    </template>
  </template>
 </div>
</template>

<script>
export default {
  name:'planIcon',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  created(){
    // console.log(this.$i18n,1231)
  },
  computed: {
    lang() {
      return 'ko'
    }
  }

}
</script>

<style lang="scss" scoped>
      .icon-hit {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
      }
  
</style>