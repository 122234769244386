<template>
  <div class="app-container">
    <div class="main-content">
      <van-row type="flex" align="center">
        <span style="font-size: 14px; color: #6C6C6C">{{ $t('dangqianzhibopingdao') }}</span><span style="margin-left: 10px; font-size: 16px;" class="main-theme-font-color">{{ currentChannelName }}<span v-if="$store.getters.liveConfig.subChannelName">-{{ $store.getters.liveConfig.subChannelName }}</span></span>
      </van-row>
      <div style="margin-top: 29px;"><span class="main-theme-font-color">{{ $t('xuanzezhibo') }}</span></div>
      <div>
        <van-radio-group v-model="channel" direction="horizontal" @change="channelChange">
          <van-radio v-for="(item, index) in channelList" :key="index" :name="item" style="height: 60px;">
            <template #icon="props">
              <van-button :class=" props.checked ? 'channe-item-active' : 'channe-item-normal'">{{ item.channelName | omitString(4) }}</van-button>
            </template>
          </van-radio>
        </van-radio-group>
      </div>
      <div v-show="subChannelList.length > 0">
        <div style="margin-top: 29px;"><span class="main-theme-font-color">{{ $t('erjipingdao') }}</span></div>
        <div>
          <van-radio-group direction="horizontal">
            <van-radio v-for="(item, index) in subChannelList" :key="index" :name="item" style="height: 60px;">
              <template #icon>
                <van-button :class="subChannel === item ? 'channe-item-active' : 'channe-item-normal'" @click="subChannelChange(item)">{{ item.name | omitString(4) }}</van-button>
              </template>
            </van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="content">
        <van-button class="confirmation-button" :disabled="!channel" @click="confirm()">{{ $t('querenbtn') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Row, Divider, Button, RadioGroup, Radio } from 'vant'
import { channeList, setLiveConfig, getSubChannel } from '@/api/live-setting/index'

export default {
  components: {
    [Row.name]: Row,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio
  },
  data() {
    return {
      channelList: [],
      subChannelList: [],
      currentChannelName: undefined,
      channel: undefined,
      subChannel: undefined,
      checked: -1
    }
  },
  mounted() {
    window.load = this.load
  },
  created() {
    this.currentChannelName = this.$route.query.channelName
    this.setTitle()
    this.getList()
  },
  methods: {
    setTitle() {
      document.title = '直播频道'
    },
    getList() {
      channeList({}).then(response => {
        this.channelList = response.data
        this.setSelectChannel()
      })
    },
    setSelectChannel() {
      const self = this
      this.channelList.forEach(item => {
        if (item.id === self.$store.getters.liveConfig.channelId) {
          self.channel = item
        }
      })
    },
    setSelectSubChannel() {
      const self = this
      this.subChannelList.forEach(item => {
        if (item.id === self.$store.getters.liveConfig.subChannelId) {
          self.subChannel = item
        }
      })
    },
    channelChange(item) {
      this.subChannel = undefined
      getSubChannel({ channelIds: [item.id] }).then(response => {
        this.subChannelList = response.data.filter(item => {
          return item.name !== '全部'
        })
        this.setSelectSubChannel()
      })
    },
    subChannelChange(item) {
      if (item === this.subChannel) {
        this.subChannel = undefined
      } else {
        this.subChannel = item
      }
    },
    confirm() {
      setLiveConfig({ channelId: this.channel.id, subChannelId: this.subChannel ? this.subChannel.id : undefined, matchId: undefined, status: 0 }).then(response => {
        this.$router.go(-1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.channe-item-active {
  width: 73px;
  height: 40px;
  font-size: 13px;
  color: #fff;
  border-radius: 5px;
  background-color: #4A90E2;
  border: 1px solid #4A90E2;
}
.channe-item-normal {
  width: 73px;
  height: 40px;
  font-size: 13px;
  color: #000;
  border-radius: 5px;
  // background-color: #1A1A1C;
  // border: 1px solid #1A1A1C;
  @include checkbox_normal();
}
.van-cell {
  color: #fff;
  background-color: #29292D;
}
.cell-title {
    font-size: 14px;
}
.cell-value {
  font-size:14px;
  font-family:PingFangSC-Regular,PingFang SC;
  font-weight:400;
  color:rgba(74,144,226,1);
}

.main-content {
  padding-top: 24px;
  padding-left: 20px;
  color: #fff;
}
.van-button--normal {
  padding: 0px;
}
.bottom {
  position: fixed;
  width: 100%;
  height: 82px;
  color: #fff;
  @include color_main_bg();
  bottom: 35px;
  .content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .confirmation-button {
      width: 80%;
      height:40px;
      background-color: #4A8FE2!important;
      border-radius:4px;
      border:1px solid #4A8FE2!important;
      color: #fff!important;
      font-size: 12px;
    }
  }
}
</style>
