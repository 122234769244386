<template>
  <div class="app-container" :data-theme="$store.getters.themeType">
    <div v-if="userGradeInfo || anchorGradeInfo" class="main-content">
      <!-- <van-tabs v-if="isAnchor" swipeable type="card" color="#63676B">
        <van-tab title="主播等级">
          <grade-info :is-anchor="true" :grade-info="anchorGradeInfo" />
        </van-tab>
        <van-tab title="用户等级">
          <grade-info :is-anchor="false" :grade-info="userGradeInfo" />
        </van-tab>
      </van-tabs>
      <grade-info v-else :is-anchor="false" :grade-info="userGradeInfo" /> -->
      <grade-info :is-anchor="true" :grade-info="anchorGradeInfo" />
    </div>
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant'
import GradeInfo from './components/GradeInfo'
import { fetchGradeInfo } from '@/api/grade/index'

export default {
  name: 'Grade',
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    GradeInfo
  },
  data() {
    return {
      userGradeInfo: undefined,
      anchorGradeInfo: undefined,
      isAnchor: true
    }
  },
  created() {
    this.setTitle()
    this.fetchGradeInfo()
  },
  methods: {
    setTitle() {
      document.title = '主播等级'
    },
    fetchGradeInfo() {
      fetchGradeInfo({}).then(response => {
        this.userGradeInfo = response.data.userLevelDTO
        this.anchorGradeInfo = response.data.anchorLevelDTO
        this.isAnchor = response.data.type
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.main-content {
  padding-top: 16px;
  padding-bottom: 16px;
  @include color_main_bg();
}
/deep/.van-tabs__nav--card {
  @include van_tabs_card();
  margin: 0 10px;
  border-radius: 6px;
  padding: 1px;
}
/deep/.van-tab {
  @include van_tab();
  border-radius: 6px;
}
/deep/.van-tab--active {
  @include van_tab_active();
}
</style>
