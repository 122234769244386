export default {
loading: '正在刷新...',
searchplaceholder: '主播、頻道、遊戲',
dowload: '下載',
home: '首頁',
live: '直播',
match: '賽事',
plan: '錦囊',
mine: '我的',
hotgame: '熱門比賽',
news: '資訊',
mall: '商城',
video: '短視頻',
activity: '活動',
hotlive: '熱門直播',
hotanchor: '熱門主播',
highlights: '集錦',
more: '更多',
nolivetips: "相關主播還在熱身，請您稍等片刻...",
hit: "命中",
return: "回報",
wins: "連紅",
chat: '聊天',
speak: '請輸入聊天內容',
nospeak: '登錄後才可以發言哦~',
gologin: '去登錄',
seenewnews: '看新消息',
unlockvip: '免費解鎖VIP',
welcome: '歡迎',
enterroom: '進入直播間',
followed: '關注了主播',
announcement: "公告：",
newsinfo: '情報資訊',
fb: '足球',
bk: '籃球',
notstarted: "未開始",
checkmore: '查看詳情',
videolist: '集錦/錄影',
qianzan: '前瞻',
jinhua: '精華',
yulei: '娛樂',
recommendplan: '錦囊推薦',
winrate: '推薦準確率',
zuigaolianghong: '最高{msg}連紅',
lianghong: '連紅',
returnrate: '回報率',
buzhong: '不中返還',
xieyi: '協議相關',
personal: '隱私協議',
userxieyi: '使用者協定',
livexieyi: '直播協議',
chargexieyi: '充值協議',
anchorhelp: '主播幫助',
anchornew: '主播新手上路',
experts: '精選專家',
followus: '關注我們',
helpcenter: '幫助中心',
scancodedowload: '掃碼即可下載山貓APP',
smzb: '山貓直播',
noduty: '免責聲明：山貓直播所有足球直播和NBA直播信號均由使用者收集或從搜尋引擎搜索整理獲得，所有內容均來自互聯網，我們自身不提供任何直播信號和視頻內容，如有侵犯您的權益請通知我們，我們會第一時間處理。',
roomsearch: '請輸入關鍵字',
myfollow: '我的關注',
clearcache: '清除緩存',
kinds: '分類',
all: '全部',
others: '其他',
yingchao: '英超',
hot: '熱門',
esports: '電競',
exit: '退出',
downloadApp: '下載APP',
want: '去直播',
roomnum: '房間號',
wang: '萬',
feedback: '回饋',
funs: '粉絲',
follows: '+關注',
numberonlive: '位主播正在直播本場賽事',
anchorchat: '主播聊天',
contributerank: '貢獻榜',
othermatch: '其他比賽',
chatinput: '一起聊天一起high',
send: '發送',
livefail: '直播流獲取失敗',
tryagain: '點擊重試',
pushlist: '推單列表',
reward: '獎勵說明 ',
nopush: '暫無推單',
anchorpush: '主播推單',
maobipokect: '貓幣紅包',
sendsuccess: '發送成功可觸發全平臺飄屏廣播',
manexchange: '人可領',
threeminexchang: '3分鐘後可領',
exchangenow: '立即可領',
sendpokect: '發紅包',
pokecttips: '我在直播間發了個紅包喲～立即就可以領取啦！',
pokecttips1: '我在直播間發了個紅包喲～3分鐘後就可以領取啦！',
task: '任務',
litterhelper: '小助手',
litterhelper1: '優惠小助手',
enter: '點擊進入',
gift: '贈送',
high: '高清',
charge: '充值',
balance: '餘額',
words: '彈',
sendonepokect: '發了一個紅包',
exchange: '領取',
ofpokect: '的紅包',
nopokect: '手慢了，紅包派完了',
maobi: '貓幣',
savemyaccount: '已存入我的帳戶',
checkexchange: '查看領取詳情',
exchangelist: '紅包領取記錄',
pokecttips2: '的貓幣紅包裡搶到了x個貓幣！',
contribute: '日貢獻',
contribute1: '周貢獻',
contribute2: '貢獻總榜',
contributevalue: '貢獻值',
living: '直播中',
noliving: '暫無開播的主播',
matchstatus: '賽況',
zhishu: '指數',
livematchstatus: '即時賽況',
jinqiu: '進球',
zugong: '助攻',
shemeng: '射門',
dianqiu: '點球',
wulongqiu: '烏龍球',
huangpai: '黃牌',
nodianqiu: '點球未進',
huanxia: '換下',
nomatchevent: '暫無比賽事件',
onlyjinqiu: '只看進球',
analysis: '技術分析',
contrrate: '控球率',
shezheng: '射正',
jingong: '進攻',
dendrgjingong: '危險進攻',
renyiqiu: '任意球',
jiaoqiu: '角球',
hongpai: '紅牌',
login: '登錄',
followed: '已關注',
roommannage: '任命房管',
roomtips: '是否確定將 十五 任命為房管?',
roomtips1: '是否確定將 十五 解任房管?',
cancel: '取消',
confirm: '確定',
logout: '退出登錄',
uplevel: '距離升級：{msg}經驗值',
wallt: '錢包',
wantlive: '我要直播',
usercenter: '個人中心',
vipright: 'VIP特權介紹',
pwdlogin: '密碼登錄',
mobilelogin: '手機登錄',
doregister1: '沒有帳號',
doregister2: '立即註冊',
forgetpwd: '忘記密碼？',
logintips: '登錄即表示同意',
inputmobile: '請輸入手機號',
inputcode: '請輸入驗證碼',
mobiletips: '請輸入6-20位元數位密碼、字母密碼',
resetpwd: '重置密碼',
register: '註冊',
sendcode: '發送驗證碼',
registernow: '立即註冊',
appdownload: 'APP下載',
advice: '意見回饋',
backtop: '返回頂部',
intime: '即時',
anchor: '主播',
matchschude: '賽程',
matchresult: '賽果',
checkdata: '查看數據',
nomatch: '暫無賽事',
winlist: '命中榜',
returnlist: '回報榜',
lianghongrank: '連紅榜',
hotrace: '熱門賽事',
allrace: '全部賽事',
onrace: '比賽中',
onsale: '篇在售方案',
recommendmaster: '推薦專家',
mime: '本人',
recommendplan: '推薦方案',
buyedplan: '已購方案',
sorttype: '綜合排序',
returnfirst: '回報優先',
winfirst: '勝率優先',
lianghongfirst: '連紅優先',
nodata: '暫無數據',
detail: '詳情',
newslocal: '當前位置：首頁/資訊',
comments: '條評論',
nocomments: '暫無評論',
docomments: '評論',
typecomments: '說點什麼吧',
goods: '商品',
searchgoods: '搜索喜歡的商品',
mycarts: '我的購物車',
myorders: '我的訂單',
demens: '鑽石',
goodsinfo: '商品資訊',
kucun: '庫存',
limit: '限制兌換',
value: '單價',
num: '數量',
total: '小計',
choice: '已選擇',
jiesuan: '結算',
chooseall: '全選',
limitnum: '累計限購',
limitday: '日限購',
limitweek: '周限購',
totallimt: '累計兌換:',
totallimt1: '每日累計兌換:',
totallimt2: '每週累計兌換:',
jiage: '價格',
goodsdetail: '商品詳情',
buynow: '立即兌換',
addcart: '加入購物車',
recommendgoods: '為你推薦',
confirmbuy: '確認兌換',
mobile: '手機號',
reciptename: '收貨人名字',
address: '收貨地址',
remark: '備註',
inputreciptename: '請輸入收貨人名字',
inputaddress: '請輸入收貨位址',
orders: '單號',
time: '時間',
recipte: '收貨人',
telphone: '電話',
address1: '地址',
recovideo: '推薦視頻',
onplay: '正常播放...',
ongoing: '進行中',
result: '已結束',
reserve: '預約',
reserved: '我的預約',
myinfo: '我的資料',
anchorlevel: '主播等級',
userlevel: '使用者等級',
changeavter: '修改頭像',
changeinfo: '修改資料',
usertips: '(圖片大小不得超過1M，支持png，jpg格式)',
uploadimg: '上傳本地圖片',
myaccount: '我的帳戶',
anchorinfo: '主播相關',
tasks: '任務中心',
signin: '簽到有禮',
mywords: '我的預言',
downloadreword: '下載邀請獎勵',
nickname: '昵稱',
inputnickname: '請輸入昵稱',
infotips: `說明：
1. 新昵稱需符合註冊規範，可使用數位，字母，漢字，符號，最長長度不超過24個字元（中文占2個字元，英文占1個字元）。
2. 如遇伺服器更新或其它問題導致昵稱修改失敗，請聯繫客服。`,
introduce: '個人簡介',
region: '地區',
selectprovince: '請選擇省',
selectregion: '請選擇市',
sex: '性別',
savesecrt: '保密',
man: '男',
woman: '女',
bedate: '生日',
selectdate: '請選擇年份',
selectmonth: '請選擇月份',
select: '請選擇',
year: '年',
month: '月',
day: '日',
save: '保存',
accountvalue: '帳戶餘額',
billlist: '帳單記錄',
cast: '消費',
earnings: '收益',
casttype: '消費類型',
sendgift: '送禮',
prophecy: '預言',
redpokect: '紅包',
exchangegoods: '兌換禮品',
luckydraw: '付費抽獎',
querytime: '查詢時間',
starttime: '開始日期',
endtime: '結束日期',
query: '查詢',
reset: '重置',
castmaobi: '消費金額',
allprophecy: '全民預言',
prophecylist: '預言大廳',
planlist: '解球錦囊',
yuyuematch: '預約賽事',
earnlist: '收益記錄',
liveset: '直播設置',
roompush: '直播間推單',
roomset: '直播間字母設置',
// new
edit: '編輯',
beAnchor: '成為主播',
inviterUser: '邀請好友',
setting: '設置',
logining: '登錄中...',
loginerr: '登錄失敗,請稍後再試...',
loginsecucced: '登錄成功',
codesend: '驗證碼發送成功',
inputrightphone: '請輸入正確的手機號',
inputagain: '請再次輸入密碼',
pwdtips: '兩次輸入的密碼不一致',
pwdtips1: '密碼格式錯誤，請輸入6-20位元數位密碼、字母密碼',
registed: '註冊成功',
registerfail: "註冊失敗,請稍後再試...",
registing: '註冊中...',
findpwd: '找回密碼',
submit: '提交',
setingPwd: '密碼設置成功',
submitting: '提交中...',
nomore: '沒有更多了',
pullrefresh: '下拉刷新',
notstart: '該場未開始，您可觀看其他比賽：',
newsDe: '資訊詳情',
shouqi: '收起',
fabu: '發佈',
'eur': '歐賠',
"hk": '亞指',
"bm": '大小球',
'rq': '讓球勝平負',
'mzl': '命中率',
'mago': '分鐘前',
'sago': '秒前',
'hago': '小時前',
'mianfei': '免費',
'tacticde': '方案詳情',
'zhusheng': '主勝',
"pingju": '平局',
"kesheng": '客勝',
'pay': '立即支付',
'stop': '已停止售賣',
'reason': '推薦理由',
'afterpay': '支付後查看方案及解析',
'stoptime': '距離售賣截止時間：',
'tacticdetips': '注：觀點僅供參考,且不提供購彩業務，請您謹慎關注！',
'dago': '天前',
'wago': '周前',
'mouthago': '個月前',
'ganggang': '剛剛發表',
'bigqiu': '大於{msg}球',
'smallqiu': '小於{msg}球',
'buyplan': '購買專家方案',
'buyplantips': '購買此方案需要消耗{num}貓幣,確認夠購買嗎？',
'yesbuy': '確定購買',
'cancelfollow': '取消關注成功',
'followsucceed': '關注成功',
'buysucceed': '購買成功',
'buyfialed': '購買失敗',
'liangsai': '聯賽',
'team': '主隊vs客隊',
'playtype': '玩法',
'rate': '推薦(賠率)',
'chongzhijilu': '充值記錄',
'xiaofeijilu': '消費記錄',
'huodongshouyi': '活動收益',
'myinvitecode': '我的邀請碼',
'inputcodeinapp': '請在App登入時輸入',
'dianjifuzhi': '點擊複製邀請碼',
'jietu': '截圖傳給你的好友',
'shoukuang': '收款方式',
'alipay': '支付寶',
'wechat': '微信',
'shoukuangzhanghao': '收款帳號',
'invitegonglue': '邀請攻略',
'shareqrcode': '分享下載二維碼及專屬邀請碼',
'getjiangli': '好友首次登入成功您將獲得獎勵',
'invitemingxi': '邀請明細',
'zhanwuhaoyou': '暫無邀請好友，快去邀請好友吧！',
'nicheng': '昵稱',
'zucengshijian': '註冊時間',
'jiangli': '獎勵',
'islogout': '確定退出登錄？',
'dijilun': '第{num}輪',
'guangyuus': '關於山貓直播',
'zhuanjiajieshao': '專家介紹',
'recent10': '近10場回報率',
'zuijinzhuantai': '近期狀態',
'zhengzaituijian': '正在推薦',
'history': '歷史推薦',
'bisaishijian': '比賽事件',
'qiuduitongji': '球隊統計',
'qiudui': '球隊',
'zongfen': '總分',
'bisaikaishi': '比賽開始',
'fangui': '犯規',
'erfen': '二分',
'faqiu': '罰球',
'sanfen': '三分',
'paizhao': '拍照',
'xuanzezhaopian': '從相冊中選擇',
'jianyanzhi': '經驗值',
'yiwangcheng': '已完成',
'dangqian': '當前',
'shengjidao': '升級到',
'xuyao': '需要',
'cehngzhang': '成長特權',
'dengji': '等級圖示',
'kaiqi': '開啟',
'qidai': '敬請期待',
'mashanglai': '更多特權馬上來襲',
'qiandao': '每日簽到',
'qiandaochenggong': '簽到成功',
'qiandaotips': '溫馨提示：彩金和實物獎勵請聯繫小助手申請發放。',
'chakanqiandao': '查看獎勵',
'goxiaozhushou': '聯繫小助手',
'dijitian': '第{msg}天',
'zhubodasan': '主播打賞榜',
'riban': '日榜',
'yueban': '月榜',
'zongban': '總榜',
'gongxianzhi': '貢獻值',
'fananjieshu': '方案售賣已結束',
'bisaijieshu': '比賽結束後公佈解析詳情',
'yuebuzu': '餘額不足',
'qitajinnang': '該專家其它錦囊',
'qitatuijian': '其它推薦錦囊',
'dengluhou': '後才可以看到已購記錄哦',
'zhengzaibofng': '正在播放',
'jijiangbofang': '即將播放',
'touxiangshangchuan': '頭像上傳',
'shouyeleixing': '收益類型',
'lingquhongbao': '領取紅包',
'hongbaotuikuang': '紅包退還',
'jinnantutikuang': '錦囊退款',
'yuyanshouyi': '預言收益',
'yuyantuikuang': '預言退款',
'hongbaoyu': '紅包雨',
'jincaidati': '競猜答題',
'zhuanpan': '轉盤抽獎',
'jindan': '砸金蛋',
'zenfa': '系統增發',
'dengluhuodong': '登錄活動',
'guankan': '觀看直播',
'danmu': '發送彈幕',
'denglujianli': 'APP登陸獎勵',
'tuidan': '推單獎勵',
'zhuanpanchoujian': '新轉盤抽獎',
'zhuti': '主題',
'xuanxian': '選項',
'kejitouzhu': '累計投注',
'xuanxianbeishu': '選項倍數',
'touzhurenshu': '投注人數',
'jieguo': '結果',
'zhuantai': '狀態',
'daojishi': '倒計時',
'fabuplantips': '發佈解球錦囊命中後，賺取海量收益哦～',
'goumairenshu': '購買人數',
'zongji': '總計',
'fencheng': '分成前',
'liveshouyi': '直播可結算收益',
'jinnanshouyi': '錦囊可結算收益',
'jiesuanrules': '結算規則',
'tequan': '等級特權',
'kuaisushengji': '如何快速升級',
'wodedengji': '我的等級',
'dengjishuoming': '等級說明',
'zhubotequan': '主播特權',
'shejizhong': '主播特權正在設計中',
'moretequan': '更多特權馬上來襲',
'huodeliwu': '獲得禮物',
'qingfenzhibo': '勤奮直播',
'zensongliwu': '贈送禮物',
'yonghutequan': '用戶特權',
'tequansheji': '用戶特權正在設計中',
'fengzhong': '分鐘',
'jiyang': '經驗',
'quedingquxiao': '您確定要取消關注嗎？',
'zhengquetianxie': '請填寫正確資訊，未填寫將不會收到任何獎勵',
'qingshuruzhanghao': '請輸入帳號',
'yaoqingshuoming': '邀請說明',
'zaiciquanren': '請再次確認{msg}帳號：{msg1}',
'qingshuruzhanghao': '請輸入{msg}帳號',
'daishenhe': '待審核',
'yipaijiang': '已派獎',
'weitongguo': '未通過',
'leijizhong': '累計中',
'tishi': '提示',
'tijiaochengong': '提交成功！',
'zonghe': '綜合',
'yituichi': '已推遲',
'yiquxiao': '已取消',
'jitian': '今天',
'mintian': '明天',
'houtian': '後天',
'zuotian': '昨天',
'xiazaitixing': '預約成功',
'quxiaoyuyue': '取消預約成功',
'zhoutian': '周日',
'zhouyi': '週一',
'zhouer': '週二',
'zhousan': '週三',
'zhousi': '週四',
'zhouwu': '週五',
'zhouliu': '週六',
'jianshu': '件',
'meiri': '每日',
'meizhou': '每週',
'duihuanchenggong': '兌換成功',
'zuiduoduihuan': '最多兌換{msg}件',
'caozuo': '操作',
'zongjia': '總價',
'shanchu': '刪除',
'now': '剛剛',
'yituikuan': '已退款',
'goumai': '購買',
'xuanzezhibo': '選擇直播頻道',
'erjipingdao': '二級頻道',
'zhibobiaoti': '直播標題',
'shezhibiaoti': '請設置直播標題',
'guanliansaishi': '關聯賽事',
'xuanzhiguanlian': '請關聯賽事',
'xuanze': '選擇',
'yichu': '移除',
'zhibofenmian': '直播封面',
'yishenhe': '已審核',
'shenhezhong': '審核中',
'yijujue': '已拒絕',
'zhibijieshu': '結束直播',
'kaishizhibo': '開始直播',
'tuiliudizhi': '推流地址（伺服器地址）',
'fuzhi': '複製',
'tuiliumiyao': '推流碼（串流秘鑰)',
'laliudizhi': '拉流地址',
'zhiboshuoming': '直播說明',
'zhibotips1': `
1.設置基礎資訊：選擇頻道、設置標題、關聯賽事、直播封面，根據需要進行設定;<br />
2.點擊“開始直播”按鈕後；複製推流地址及推流碼；<br />
3.粘貼推流位址、推流碼至OBS軟體對應的“伺服器及串流秘鑰”，並進行開始直播；<br />`,
'zhibotips2': `
1.點擊開始直播後，請在1分鐘內複製推流位址及推流碼粘貼至OBS並進行推流，否則可能將作為異常系統將直播間下播；<br />
2.結束直播後，關閉OBS後，需點擊上方結束直播按鈕，將直播間下播。<br />`,
'yiyuyue': '已預約賽事',
'biaotitips': '標題不能為空！',
'yuliang': '預覽',
'zhuyishixian': '注意事項',
'xinjian': '新建推單',
'xuanzesaishi': '選擇賽事',
'xuanzesaiduan': '選擇賽段',
'xuanzewf': '選擇玩法',
'jieguotips': '請輸入結果,最多25字',
'tuidanlishi': '歷史推單',
'renmai': '{msg}人 跟單',
'renfanmai': '{msg}人 反買',
'qingxuanzesaishi': '請選擇賽事',
'qingsaiduan': '請選擇賽段',
'qingwf': '請選擇玩法',
'qingjieguo': '請輸入結果',
'tuidangtips': '本場推單提交後不可更改，確認提交嗎？',
'fabutuidan': '發佈推單',
'chuangjian': '創建成功',
'yijianfabu': '一鍵發佈',
'zimushezhi': '直播字幕設置',
'zhimutips': '非必填，開播後還可繼續編輯',
'dingbu': '頂部展示',
'shangshi': '展示字幕',
'zimutips': '請輸入直播介面字幕,最大50個字',
'xuanzepeise': '選擇配色：',
'dibu': '底部展示',
'shanchutips': '提示：如需要刪除，請點擊重置即可',
'yuliangzhuangshi': '預覽展示',
'shoujiduan': '手機端預覽',
'diannaoduan': '電腦端預覽',
'resettips': '確定要重置嗎？重置後前端已生效的字幕會自動刪除。',
'baocuntips': '確定要保存嗎？保存後會更新前端字幕並展示。',
'caozuochenggongg': '操作成功',
'dangqianjy': '當前經驗值',
'jinrihuode': '今日獲得',
'guizeshuo': '規則說明',
'dangqian': ' 當前LV.{msg}',
'shenjilv': '升級到LV.{msg}還需{msg1}經驗值',
'dachenglv': ' 達成V{msg}共需{msg1}經驗值',
'shenjidaolv': ' 升級到V{msg}共需{msg1}經驗值',
'xinshourenwu': '新手任務',
'meirirenwu': '每日任務',
'jieji': '階級',
'dengjit': '等級',
'tubiao': '圖示',
'gongxihuode': '恭喜獲得',
'weiwangcheng': '未完成',
'jianlilist': '獎勵明細',
'renwuleixin': '任務類型',
'renwujianli': '任務獎勵',
'paifazhuangt': '派發狀態',
'huodeshijian': '獲得時間',
'weipaifa': '未派發',
'yipaifa': '已派發',
'yuyanzhuti': '預言主題',
'cangyuxiang': '參與項',
'beishu': '倍數',
'touru': '投入',
'yingkui': '盈虧',
'yuyanshijian': '預言時間',
'zhubogonggao': '主播公告',
'zuiduiyuyue': '最多同時預約20場賽事',
'yituihuan': '已退還',
'qiuduimingcheng': '請輸入球隊名稱',
'sousuoqiudui': '搜索',
'chongxinxuanze': '重新選擇',
'fangfaleixing': '方法類型',
xuanzegongshi: '公司',
jinnangbiaoti: '方案標題',
jinnangbiaoti: '請輸入標題（4字以上）',
jinnangjiexibia: '請輸入解析（不得少於20字哦～）',
tuichutips: '退出後內容無法保存，確定要退出嗎？',
biaotizuishao: '標題最少4個字',
jiexizuishao: '解析最少20個字',
tianjiachenggong: '添加成功',
// 新增
"flow_bureau": '（流局)',
"participation": '人參與',
"money": '金額',
"hint1": '還沒發起預言',
"hint2": '發起預言給用戶帶來更多快樂',
"issuing_prophecy": '發佈預言',
"add_theme": '添加主題',
"prophetic_record": '預言記錄',
// 新增
"hint3": '您最多可同時發起5個主題',
"selected": '已選 ',
"Entries": '個 ',
"above_to_add": '請點擊上方“添加主題”添加 ',
"hint5": '還沒添加預言主題 ',
"hint4": '確定刪除此條預言主題嗎? ',
"hint6": '主題庫達到上限，請先刪除部分 ',
"select_theme": '選擇主題 ',
"have_deleted": '已刪除 ',
"hint7": '您還未開播，請先開啟直播',
"End_of_batch": '批量結束',
"submit_the_results": '提交結果',
"hint8": '說明：請謹慎提交結果，若用戶多次投訴，核實投訴屬實，將收回預言許可權及作出對應懲罰。',
"hint9": '如此局為流局，則此局無效，將返還全部使用者參與金額。',
"submit1": '提交',
"confirming_the_prophecy": '確認預言結果',
// 新增
"title_options": '標題及選項',
"hint11": '預言標題（限15個字）',
"hint12": '選項A（限5個字）',
"hint13": '選項B（限5個字）',
"stop_time": '停止時間',
"hint14": '（僅針對主播預言模式有效）',
"save1": '保存',
"5minute": '5分鐘',
"10minute": '10分鐘',
"30minute": '30分鐘',
"45minute": '45分鐘',
"110minute": '110分鐘',
"editing_subject": '編輯主題',
"add_subject": '添加主題',
// 新增
"surplus": '剩餘',
"Victory": '勝',
"Defeated": '敗',
"Flow": '流',
"Waiting_announcement": '等待公佈',
"Doubling": '倍',
"Stop": '停止',
"End": '結束',
"stopped": '已停止',
"hint15": '停止後將無法參與和預言，是否確定停止預言',
'xuanzepingdao':'請選擇頻道',
'xuanzesaishi':'請選擇賽事',
'jiamifanjianshezhi':'加密房設置',
'paomadeng':'跑馬燈設置',
'paomadengkaiguan':'跑馬燈開關',
'qingshurupaomadengneirong':'請輸入跑馬燈內容',
'dangqianzhibopingdao':'當前直播頻道',
'querenbtn':'確認',
'dingdaobianhao':'訂單編號',
'chongzhijine':'充值金額',
'qingxuanxuanzepingdao':'請先選擇頻道',
'kaisaishijian':'開賽時間需間隔1.5小時',
'fanjianmima':'房間密碼',
'jiamifanjianshuoming':'加密房說明：',
'jiami1':'1.密碼請設置四位元數位密碼',
'jiami2':'2.加密房的密碼設置成功後，將會在下一場直播開播後生效，開播後不能取消',
'jiami3':'3.加密房只對一場直播生效，下播後限制自動解除',
'chakanguize':'查看結算規則',
'kefuhao':'客服微信號',
'zhiboshouyi':'直播本月收益',
'jinnanshouyi':'錦囊本月收益'
}
