<template>
  <div class="page-main-content" :data-theme="$store.getters.themeType">
    <div class="main-content">
      <van-tabs v-model="activeTab" swipeable type="card" color="#63676B" sticky @change="tabChange">
        <van-tab :title="$t('chongzhijilu')">
          <div>
            <van-row align="center" style="color: #fff; margin-top: 20px; margin-bottom: 20px; margin-left: 20px; margin-right: 20px;">
              <van-col span="10" class="date-picker" @click="pickPaymentDate">
                <span class="font-14">{{ paymentDate | parseTime('{m}') }}月</span>
                <img style="width: 12px; height: 12px;" src="@/assets/images/live/arrow-down.png">
                <van-popup v-model="showPaymentDatePicker" :get-container="getContainer" :round="true" position="bottom" :style="{ height: '40%' }">
                  <van-datetime-picker
                    v-model="paymentPickerDate"
                    type="year-month"
                    :title="$t('select')"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @confirm="paymentDateConfirm()"
                    @cancel="showPaymentDatePicker = false"
                  />
                </van-popup>
              </van-col>
              <van-col span="7" style="text-align: center;">
                <span class="main-theme-font-color-2 font-14">{{ $t('dingdaobianhao') }}</span>
              </van-col>
              <van-col span="7" style="text-align: right">
                <span class="main-theme-font-color-2 font-14">{{ $t('chongzhijine') }}</span>
              </van-col>
            </van-row>
            <van-list
              v-if="paymentList.length > 0"
              v-model="paymentLoading"
              :finished="paymentFinished"
              :finished-text="paymentFinishedText"
              @load="loadPaymentList"
            >
              <div v-for="(item,index) in paymentList" :key="index" :title="item">
                <van-row type="flex" align="center" justify="space-between" class="main-theme-font-color" style="margin-left: 20px; margin-right: 20px;">
                  <van-col span="10">
                    <span class="font-13">{{ item.time }}</span>
                  </van-col>
                  <van-col span="7" style="text-align: center;">
                    <span class="font-13">{{ item.orderNo }}</span>
                  </van-col>
                  <van-col span="7" style="text-align: right;">
                    <span class="font-13">{{ item.coin }}</span>
                  </van-col>
                </van-row>
                <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
              </div>
            </van-list>
          </div>
          <van-empty
            v-show="activeTab === 0 && !paymentLoading && paymentList.length === 0"
            style="margin-top: 50%;"
            class="empty-image"
            :description="$t('nodata')"
          >
            <div slot="image" style="width: 100%; text-align: center">
              <img style="width: 257px; height: 84px;" src="@/assets/images/empty.png">
            </div>
          </van-empty>
        </van-tab>
        <van-tab title="消费记录">
          <div>
            <van-row type="flex" align="center" style="color: #fff; margin-top: 20px; margin-bottom: 20px; margin-left: 20px; margin-right: 20px;">
              <van-col span="10" class="date-picker" @click="pickConsumptionDate">
                <span class="font-14">{{ consumptionDate | parseTime('{m}') }}月</span>
                <img style="width: 12px; height: 12px;" src="@/assets/images/live/arrow-down.png">
                <van-popup v-model="showConsumptionDatePicker" :get-container="getContainer" :round="true" position="bottom" :style="{ height: '40%' }">
                  <van-datetime-picker
                    v-model="consumptionPickerDate"
                    type="year-month"
                    :title="$t('select')"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @confirm="consumptionDateConfirm()"
                    @cancel="showConsumptionDatePicker = false"
                  />
                </van-popup>
              </van-col>
              <van-col span="7" style="text-align: center;">
                <span class="main-theme-font-color-2 font-14">{{ $t('casttype') }}</span>
              </van-col>
              <van-col span="7" style="text-align: right">
                <span class="main-theme-font-color-2 font-14">{{ $t('castmaobi') }}</span>
              </van-col>
            </van-row>
            <van-list
              v-if="consumptionList.length > 0"
              v-model="consumptionLoading"
              :finished="consumptionFinished"
              :finished-text="consumptionFinishedText"
              @load="loadConsumptionList"
            >
              <div v-for="(item,index) in consumptionList" :key="index" :title="item">
                <van-row type="flex" align="center" justify="space-between" class="main-theme-font-color" style="margin-left: 20px; margin-right: 20px;">
                  <van-col span="10">
                    <span class="font-13">{{ item.time }}</span>
                  </van-col>
                  <van-col span="7" style="text-align: center;">
                    <span class="font-13">{{ item.typeName }}</span>
                  </van-col>
                  <van-col span="7" style="text-align: right">
                    <span class="font-13">{{ item.coin }}</span>
                  </van-col>
                </van-row>
                <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
              </div>
            </van-list>
          </div>
          <van-empty
            v-show="activeTab === 1 && !consumptionLoading && consumptionList.length === 0"
            style="margin-top: 50%;"
            class="empty-image"
             :description="$t('nodata')"
          >
            <div slot="image" style="width: 100%; text-align: center">
              <img style="width: 257px; height: 84px;" src="@/assets/images/empty.png">
            </div>
          </van-empty>
        </van-tab>
        <van-tab :title="$t('huodongshouyi')">
          <div>
            <van-row type="flex" align="center" style="color: #fff; margin-top: 20px; margin-bottom: 20px; margin-left: 20px; margin-right: 20px;">
              <van-col span="10" class="date-picker" @click="pickActivityDate">
                <span class="font-14">{{ activityDate | parseTime('{m}') }}月</span>
                <img style="width: 12px; height: 12px;" src="@/assets/images/live/arrow-down.png">
                <van-popup v-model="showActivityDatePicker" :get-container="getContainer" :round="true" position="bottom" :style="{ height: '40%' }">
                  <van-datetime-picker
                    v-model="activityPickerDate"
                    type="year-month"
                    :title="$t('select')"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :formatter="formatter"
                    @confirm="activityDateConfirm()"
                    @cancel="showActivityDatePicker = false"
                  />
                </van-popup>
              </van-col>
              <van-col span="7" style="text-align: center;">
                <span class="main-theme-font-color-2 font-14">{{ $t('shouyeleixing') }}</span>
              </van-col>
              <van-col span="7" style="text-align: right">
                <span class="main-theme-font-color-2 font-14">{{ $t('earnings') }}</span>
              </van-col>
            </van-row>
            <van-list
              v-if="activityList.length > 0"
              v-model="activityLoading"
              :finished="activityFinished"
              :finished-text="activityFinishedText"
              @load="loadActivityList"
            >
              <div v-for="(item,index) in activityList" :key="index" :title="item">
                <van-row type="flex" align="center" justify="space-between" class="main-theme-font-color" style="margin-left: 20px; margin-right: 20px;">
                  <van-col span="10">
                    <span class="font-13">{{ item.time }}</span>
                  </van-col>
                  <van-col span="7" style="text-align: center;">
                    <span class="font-13">{{ item.typeName }}</span>
                  </van-col>
                  <van-col span="7" style="text-align: right">
                    <span class="font-13">+{{ item.coin }}</span>
                  </van-col>
                </van-row>
                <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
              </div>
            </van-list>
          </div>
          <van-empty
            v-show="activeTab === 2 && !activityLoading && activityList.length === 0"
            style="margin-top: 50%;"
            class="empty-image"
             :description="$t('nodata')"
          >
            <div slot="image" style="width: 100%; text-align: center">
              <img style="width: 257px; height: 84px;" src="@/assets/images/empty.png">
            </div>
          </van-empty>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { CellGroup, Cell, Row, Col, Divider, List, DatetimePicker, Popup, Tab, Tabs, Empty } from 'vant'
import { formatDate } from '@/utils/tools'

import { consumptionRecord, paymentRecord, activityRecord } from '@/api/fund/fundRecord'

export default {
  name: 'FundRecord',
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [List.name]: List,
    [DatetimePicker.name]: DatetimePicker,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Empty.name]: Empty
  },
  data() {
    return {
      activeTab: 0,
      paymentList: [],
      paymentLastId: 0,
      paymentLoading: false,
      paymentFinished: false,
      showPaymentDatePicker: false,
      paymentDate: new Date(),
      paymentPickerDate: undefined,
      paymentFinishedText: '',
      consumptionList: [],
      consumptionLastId: 0,
      consumptionLoading: false,
      consumptionFinished: false,
      showConsumptionDatePicker: false,
      consumptionDate: new Date(),
      consumptionPickerDate: undefined,
      consumptionFinishedText: '',
      activityList: [],
      activityLastId: 0,
      activityLoading: false,
      activityFinished: false,
      showActivityDatePicker: false,
      activityDate: new Date(),
      activityPickerDate: undefined,
      activityFinishedText: '',
      minDate: new Date(2020, 1, 1)
    }
  },
  computed: {
    maxDate() {
      return new Date(formatDate(new Date(), 'yyyy'), formatDate(new Date(), 'MM') - 1, 1)
    }
  },
  mounted() {
    window.load = this.load
  },
  created() {
    this.setTitle()
    this.loadPaymentList()
  },
  methods: {
    getContainer() {
      return document.querySelector('.page-main-content')
    },
    setTitle() {
      document.title = '明细'
    },
    tabChange(name, title) {
      if (title === '充值记录') {
        this.paymentFinished = false
        this.paymentLastId = 0
        this.paymentList = []
        this.loadPaymentList()
      } else if (title === '消费记录') {
        this.consumptionFinished = false
        this.consumptionLastId = 0
        this.consumptionList = []
        this.loadConsumptionList()
      } else {
        this.activityFinished = false
        this.activityLastId = 0
        this.activityList = []
        this.loadActivityList()
      }
    },
    loadPaymentList() {
      const date = formatDate(this.paymentDate) + ' 00:00:00'
      this.paymentLoading = true
      paymentRecord({ dateMonth: date, lastId: this.paymentLastId }).then(response => {
        this.paymentList = this.paymentList.concat(response.data)
        this.paymentLoading = false
        if (response.data.length > 0) {
          this.paymentLastId = response.data[response.data.length - 1].recordId
        }
        if (response.data.length < 20) {
          this.paymentFinished = true
          this.paymentFinishedText = '没有更多了'
        }
        if (this.paymentList.length < 20) {
          this.paymentFinishedText = ''
        }
      })
    },
    loadConsumptionList() {
      const date = formatDate(this.consumptionDate) + ' 00:00:00'
      this.consumptionLoading = true
      consumptionRecord({ dateMonth: date, lastId: this.consumptionLastId }).then(response => {
        this.consumptionList = this.consumptionList.concat(response.data)
        this.consumptionLoading = false
        if (response.data.length > 0) {
          this.consumptionLastId = response.data[response.data.length - 1].recordId
        }
        if (response.data.length < 20) {
          this.consumptionFinished = true
          this.consumptionFinishedText = '没有更多了'
        }
        if (this.consumptionList.length < 20) {
          this.consumptionFinishedText = ''
        }
      })
    },
    loadActivityList() {
      const date = formatDate(this.activityDate) + ' 00:00:00'
      this.activityLoading = true
      activityRecord({ dateMonth: date, lastId: this.activityLastId }).then(response => {
        this.activityList = this.activityList.concat(response.data)
        this.activityLoading = false
        if (response.data.length > 0) {
          this.activityLastId = response.data[response.data.length - 1].recordId
        }
        if (response.data.length < 20) {
          this.activityFinished = true
          this.activityFinishedText = '没有更多了'
        }
        if (this.activityList.length < 20) {
          this.activityFinishedText = ''
        }
      })
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    },
    pickPaymentDate() {
      this.showPaymentDatePicker = true
    },
    consumptionDateConfirm() {
      this.consumptionDate = this.consumptionPickerDate
      this.showConsumptionDatePicker = false
      this.consumptionFinished = false
      this.consumptionLastId = 0
      this.consumptionList = []
      this.loadConsumptionList()
    },
    pickConsumptionDate() {
      this.showConsumptionDatePicker = true
    },
    paymentDateConfirm() {
      this.paymentDate = this.paymentPickerDate
      this.showPaymentDatePicker = false
      this.paymentFinished = false
      this.paymentLastId = 0
      this.paymentList = []
      this.loadPaymentList()
    },
    pickActivityDate() {
      this.showActivityDatePicker = true
    },
    activityDateConfirm() {
      this.activityDate = this.activityPickerDate
      this.showActivityDatePicker = false
      this.activityFinished = false
      this.activityLastId = 0
      this.activityList = []
      this.loadActivityList()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
#app {
  @include color_main_bg();
}
.page-main-content {
  height: 100%;
  @include color_main_bg();
}
/deep/.van-tabs__nav--card {
  @include van_tabs_card();
  margin: 0 10px;
  border-radius: 6px;
  padding: 1px;
}
/deep/.van-tab {
  @include van_tab();
  border-radius: 6px;
}
/deep/.van-tab--active {
  @include van_tab_active();
}
.van-list {
  @include color_main_bg();
}
.van-cell {
  @include color_main_font();
  @include color_main_bg();
}
.main-content {
  padding-top: 16px;
}
.feather {
  width: 24px;
  height: 24px;
}
.amount {
  width:51px;
  height:18px;
  font-size:18px;
  font-family:PingFangSC-Semibold,PingFang SC;
  font-weight:600;
  color:rgba(240,207,44,1);
  line-height:18px;
  margin-left: 4px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

/deep/.empty-image .van-empty__image {
  width: 257px;
  height: 84px;
}
/deep/.van-empty {
  @include color_main_bg();
}
.date-picker {
  color: #4A90E2
}
/deep/.van-picker__toolbar {
  @include color_main_card_bg();
  @include color_main_font();
}
/deep/.van-picker__confirm {
  color: #4A8FE2;
}
/deep/.van-picker__cancel {
  color: #9B9B9B;
}
/deep/.van-picker {
  @include color_main_card_bg();
}
/deep/.van-picker-column__item {
  @include color_main_font();
}
/deep/.van-picker__mask {
  background-image: none
}
/deep/.van-hairline-unset--top-bottom::after {
  border-top: 1px solid #373941!important;
  border-bottom: 1px solid #373941!important;
}
</style>
