<template>
  <div class="app-container" :data-theme="$store.getters.themeType">
    <div class="main-content">
      <van-row v-if="kitPermissionStatus === 1" type="flex" align="center" style="margin-left: 10px; margin-right: 10px;">
        <van-col span="12" style="margin-right: 11px;">
          <div class="card">
            <van-row type="flex" align="center" justify="center">
              <img v-show="incomeInfo.ios3X26X26CurrencyZhuboIconUrl" class="feather" :src="incomeInfo.ios3X26X26CurrencyZhuboIconUrl">
              <span class="amount">{{ incomeInfo.settlement }}</span>
            </van-row>
            <van-row style="text-align: center; margin-top: 12px;" class="main-theme-font-color"><span>({{ $t('zhiboshouyi')+incomeInfo.currencyZhuboName }})</span></van-row>
          </div>
        </van-col>
        <van-col span="12">
          <div class="card">
            <van-row type="flex" align="center" justify="center">
              <img v-show="incomeInfo.ios3X26X26CurrencySilkIconUrl" class="feather" :src="incomeInfo.ios3X26X26CurrencySilkIconUrl">
              <span class="amount">{{ incomeInfo.tacticCoin }}</span>
            </van-row>
            <van-row style="text-align: center; margin-top: 12px;" class="main-theme-font-color"><span>({{ $t('jinnanshouyi')+incomeInfo.currencySilkName }})</span></van-row>
          </div>
        </van-col>
      </van-row>
      <van-row v-else type="flex" align="center" style="margin-left: 10px; margin-right: 10px;">
        <van-col span="24">
          <div class="card">
            <van-row type="flex" align="center" justify="center">
              <img v-show="incomeInfo.ios3X26X26CurrencyZhuboIconUrl" class="feather" :src="incomeInfo.ios3X26X26CurrencyZhuboIconUrl">
              <span class="amount">{{ incomeInfo.settlement }}</span>
            </van-row>
            <van-row style="text-align: center; margin-top: 12px;" class="main-theme-font-color"><span>({{ $t('zhiboshouyi')+incomeInfo.currencyZhuboName }})</span></van-row>
          </div>
        </van-col>
      </van-row>
      <van-tabs v-if="kitPermissionStatus === 1" swipeable type="card" color="#63676B" style="margin-top: 15px;" sticky @change="tabChange">
        <van-tab :title="$t('live')">
          <live-income-record ref="liveIncome" :unit="incomeInfo.currencyZhuboName" />
        </van-tab>
        <van-tab :title="$t('plan')">
          <kit-income-record ref="kitIncome" :unit="incomeInfo.currencySilkName" />
        </van-tab>
      </van-tabs>
      <live-income-record v-else ref="liveIncome" :unit="incomeInfo.currencyZhuboName" />
    </div>
  </div>
</template>

<script>
import { Row, Col, Divider, Tab, Tabs, Empty } from 'vant'
import LiveIncomeRecord from './components/LiveIncomeRecord'
import KitIncomeRecord from './components/KitIncomeRecord'
import { income } from '@/api/live/income'
import { kitPermissionStatus } from '@/api/anchor'

export default {
  name: 'IncomeRecord',
  components: {
    LiveIncomeRecord,
    KitIncomeRecord,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [Empty.name]: Empty,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs
  },
  data() {
    return {
      incomeInfo: {},
      kitPermissionStatus: 0 // 0: 非专家账户 1: 专家账户
    }
  },
  mounted() {
    window.load = this.load
  },
  created() {
    this.setTitle()
    this.getKitPermissionStatus()
    this.getIncome()
  },
  methods: {
    tabChange(name, title) {
      if (title === '直播收益') {
        this.$refs.liveIncome.lastId = 0
        this.$refs.liveIncome.list = []
        this.$refs.liveIncome.getList()
      } else {
        if (this.$refs.kitIncome) {
          this.$refs.kitIncome.lastId = 0
          this.$refs.kitIncome.list = []
          this.$refs.kitIncome.getList()
        }
      }
    },
    setTitle() {
      document.title = '收益明细'
    },
    getKitPermissionStatus() {
      kitPermissionStatus({}).then(response => {
        this.kitPermissionStatus = response.data.status
      })
    },
    getIncome() {
      income({}).then(response => {
        this.incomeInfo = response.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
/deep/.van-row--flex {
  flex-wrap: nowrap;
}
.main-content {
  padding-top: 16px;
}
.feather {
  width: 24px;
  height: 24px;
}
.amount {
  height:18px;
  font-size:18px;
  font-family:PingFangSC-Semibold,PingFang SC;
  font-weight:600;
  color:rgba(240,207,44,1);
  line-height:18px;
  margin-left: 4px;
}
/deep/ .van-popup {
  @include color_main_bg_2();
}
/deep/.van-tabs__nav--card {
  @include van_tabs_card();
  margin: 0 10px;
  border-radius: 6px;
  padding: 1px;

}
/deep/.van-tab {
  @include van_tab();
  border-radius: 6px;
}
/deep/.van-tab--active {
  @include van_tab_active();
}
/deep/.empty-image .van-empty__image {
  width: 257px;
  height: 84px;
}

</style>
