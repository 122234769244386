import request from '@/api/httpRequest'

export function fetchList(params) {
  return request({
    url: '/app/guess/anchor/list',
    method: 'post',
    data: params
  })
}

export function addGuessing(params) {
  return request({
    url: '/app/guess/topic/add',
    method: 'post',
    data: params
  })
}

export function getTopicList(params) {
  return request({
    url: '/app/guess/topic/templates',
    method: 'post',
    data: params
  })
}

export function deleteTopic(params) {
  return request({
    url: '/app/guess/topic/del',
    method: 'post',
    data: params
  })
}

export function history(params) {
  return request({
    url: '/app/guess/list',
    method: 'post',
    data: params
  })
}

export function commitResult(params) {
  return request({
    url: '/app/guess/topic/result',
    method: 'post',
    data: params
  })
}

export function stopGuess(params) {
  return request({
    url: '/app/guess/stop',
    method: 'post',
    data: params
  })
}

export function publish(params) {
  return request({
    url: '/app/guess/publish',
    method: 'post',
    data: params
  })
}

export function topicDetail(params) {
  return request({
    url: '/app/guess/topic/detail',
    method: 'post',
    data: params
  })
}

export function editTopic(params) {
  return request({
    url: '/app/guess/topic/edit',
    method: 'post',
    data: params
  })
}

export function hasLiving(params) {
  return request({
    url: '/app/live/anchor/checkHasLiving',
    method: 'post',
    data: params
  })
}
