var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c("van-sticky", [
        _c(
          "div",
          {
            staticClass: "row main-bg",
            staticStyle: {
              width: "100%",
              "padding-top": "30px",
              "padding-bottom": "0px",
            },
          },
          [
            _c(
              "van-button",
              {
                staticClass: "button-default",
                staticStyle: {
                  width: "140px",
                  height: "30px",
                  "border-radius": "4px",
                },
                attrs: { icon: "plus" },
                on: { click: _vm.handleAdd },
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("add_theme")) + "\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _vm.list.length > 0
            ? _c(
                "van-checkbox-group",
                {
                  staticStyle: { "font-size": "14px", "margin-top": "30px" },
                  attrs: { max: 5, "icon-size": "16px" },
                  on: { change: _vm.change },
                  model: {
                    value: _vm.checkList,
                    callback: function ($$v) {
                      _vm.checkList = $$v
                    },
                    expression: "checkList",
                  },
                },
                _vm._l(_vm.list, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticStyle: { "margin-top": "10px", width: "100%" },
                    },
                    [
                      _c(
                        "van-row",
                        {
                          attrs: {
                            type: "flex",
                            justify: "space-between",
                            align: "center",
                          },
                        },
                        [
                          _c(
                            "van-col",
                            {
                              attrs: { span: "20" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCheck(item)
                                },
                              },
                            },
                            [
                              _c("van-checkbox", { attrs: { name: item } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "main-theme-font-color",
                                    staticStyle: {
                                      "font-size": "16px",
                                      "padding-left": "14px",
                                    },
                                    attrs: { slot: "default" },
                                    slot: "default",
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.topic) +
                                          "\n                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-top": "10px",
                                          "font-size": "14px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.forwardName) +
                                            " VS " +
                                            _vm._s(item.backName) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("van-col", { attrs: { span: "4" } }, [
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        path: "/guessing-editing",
                                        query: { id: item.id },
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "18px",
                                        height: "18px",
                                        "margin-right": "30px",
                                      },
                                      attrs: { src: _vm.$theme.getEditImage() },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("img", {
                                  staticStyle: {
                                    width: "18px",
                                    height: "18px",
                                  },
                                  attrs: { src: _vm.$theme.getDeleteImage() },
                                  on: {
                                    click: function ($event) {
                                      ;(_vm.showAlert = true),
                                        (_vm.selectedItem = item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("van-divider", {
                        style: {
                          borderColor: _vm.$theme.getDividerBorderColo2(),
                          marginTop: "12px",
                          marginBottom: "0px",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("alert", {
            attrs: {
              visible: _vm.showAlert,
              message: _vm.$t("hint4"),
              "cancel-button-text": _vm.$t("cancel"),
              "confirm-button-text": _vm.$t("confirm"),
            },
            on: {
              "update:visible": function ($event) {
                _vm.showAlert = $event
              },
              confirm: function ($event) {
                return _vm.handleDelete()
              },
            },
          }),
          _vm._v(" "),
          _vm.list.length === 0
            ? _c(
                "van-empty",
                {
                  staticClass: "empty-image",
                  staticStyle: { "margin-top": "40%" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { width: "100%", "text-align": "center" },
                      attrs: { slot: "image" },
                      slot: "image",
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "257px", height: "84px" },
                        attrs: { src: require("@/assets/images/empty.png") },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { slot: "description" },
                      slot: "description",
                    },
                    [
                      _c("div", [_vm._v(_vm._s(_vm.$t("hint5")))]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.$t("above_to_add")))]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bottom" }, [
        _c(
          "div",
          {
            staticStyle: {
              "text-align": "center",
              "margin-top": "11px",
              "margin-bottom": "14px",
            },
          },
          [
            _c(
              "span",
              { staticStyle: { color: "#9b9b9b", "font-size": "13px" } },
              [_vm._v("\n        " + _vm._s(_vm.$t("hint3")) + "\n      ")]
            ),
            _vm._v(" "),
            _c("span", { staticStyle: { "font-size": "13px" } }, [
              _vm._v(
                "\n        （" +
                  _vm._s(_vm.$t("selected")) +
                  " " +
                  _vm._s(_vm.checkList.length) +
                  _vm._s(_vm.$t("Entries")) +
                  "）\n      "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "van-button",
              {
                staticClass: "button-primary",
                staticStyle: {
                  width: "283px",
                  height: "40px",
                  "border-radius": "6px",
                },
                attrs: { disabled: _vm.checkList.length === 0 },
                on: { click: _vm.commit },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("issuing_prophecy")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }