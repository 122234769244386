import request from '@/api/httpRequest'

export function channeList(params) {
  return request({
    url: '/app/channel/live/list',
    method: 'post',
    data: params
  })
}

export function gameList(params) {
  return request({
    url: '/app/channel/matches',
    method: 'post',
    data: params
  })
}

export function fetchRecord(params) {
  return request({
    url: '/app/match/live/get',
    method: 'post',
    data: params
  })
}

export function setLiveConfig(params) {
  return request({
    url: '/app/match/live/add',
    method: 'post',
    data: params
  })
}

export function fetchAppointmentList(params) {
  return request({
    url: '/app/match/book/can',
    method: 'post',
    data: params
  })
}

export function fetchMyAppointmentList(params) {
  return request({
    url: '/app/user/anchor/book/list',
    method: 'post',
    data: params
  })
}

export function book(params) {
  return request({
    url: '/app/match/book',
    method: 'post',
    data: params
  })
}

export function checkBooking(params) {
  return request({
    url: '/app/user/anchor/book/check',
    method: 'post',
    data: params
  })
}

export function getSubChannel(params) {
  return request({
    url: '/app/channel/getSubChannel',
    method: 'post',
    data: params
  })
}

export function setRoomPassword(params) {
  return request({
    url: '/app/anchor/config/room/password/set',
    method: 'post',
    data: params
  })
}

export function getRoomPassword(params) {
  return request({
    url: '/app/anchor/config/room/password/get',
    method: 'post',
    data: params
  })
}

export function getMarquee(params) {
  return request({
    url: '/app/anchor/config/marquee/get',
    method: 'post',
    data: params
  })
}

export function setMarquee(params) {
  return request({
    url: '/app/anchor/config/marquee/set',
    method: 'post',
    data: params
  })
}
