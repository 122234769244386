import request from '@/api/httpRequest'

export function login(params) {
  return request({
    url: '/admin/user/login',
    method: 'post',
    data: params
  })
}

export function getInfo() {
  return request({
    url: '/admin/user/search',
    method: 'post',
    data: {}
  })
}

export function logout() {
  return request({
    url: '/admin/user/loginOut',
    method: 'post'
  })
}

export function fetchCommonConfig() {
  return request({
    url: '/app/commonConfig/ios',
    method: 'post'
  })
}
