var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("van-overlay", { attrs: { show: _vm.show } }, [
        _c(
          "div",
          { staticClass: "main-container" },
          [
            _c("transition", { attrs: { name: "bounce" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show,
                      expression: "show",
                    },
                  ],
                  staticClass: "wrapper",
                },
                [
                  _c(
                    "van-row",
                    {
                      attrs: {
                        type: "flex",
                        justify: "center",
                        align: "center",
                      },
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _vm._v(" "),
                  _c(
                    "van-row",
                    {
                      attrs: {
                        type: "flex",
                        justify: "center",
                        align: "center",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "font-size": "14px",
                            "line-height": "20px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.message))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "van-row",
                    {
                      staticStyle: { "margin-top": "40px" },
                      attrs: {
                        type: "flex",
                        justify: "center",
                        align: "center",
                      },
                    },
                    [
                      _c(
                        "van-button",
                        {
                          staticClass: "cancel-btn",
                          staticStyle: {
                            "border-radius": "4px",
                            "font-size": "13px",
                            width: "112px",
                            height: "40px",
                          },
                          attrs: {
                            size: "normal",
                            color: _vm.cancelButtonColor(),
                            plain: "",
                          },
                          on: { click: _vm.close },
                        },
                        [_vm._v(_vm._s(_vm.cancelButtonText))]
                      ),
                      _vm._v(" "),
                      _c(
                        "van-button",
                        {
                          staticStyle: {
                            "margin-left": "20px",
                            "border-radius": "4px",
                            "font-size": "13px",
                            width: "112px",
                            height: "40px",
                          },
                          attrs: { size: "normal", color: "#4A90E2" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("confirm")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.confirmButtonText))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }