var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card cell",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c(
        "van-row",
        { attrs: { type: "flex", align: "center", justify: "space-between" } },
        [
          _c("span", { staticClass: "main-theme-font-color" }, [
            _vm._v(_vm._s(_vm.data.topic)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "countdown" }, [
            _vm.data.status === 0
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "11px",
                        height: "13px",
                        "margin-right": "5px",
                      },
                      attrs: {
                        src: require("@/assets/images/guessing/clock.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "main-theme-font-color" }, [
                      _vm._v(_vm._s(_vm.$t("surplus"))),
                    ]),
                    _vm._v(" "),
                    _c("van-count-down", {
                      staticClass: "main-theme-font-color",
                      staticStyle: { width: "70px", "text-align": "right" },
                      attrs: {
                        time: _vm._f("parseRemainTime")(_vm.data.remainTime),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.data.status != 0
              ? _c("div", { staticClass: "row" }, [
                  _c("span", { staticStyle: { color: "#9b9b9b" } }, [
                    _vm._v(_vm._s(_vm._f("statusFilter")(_vm.data.status))),
                  ]),
                  _vm._v(" "),
                  _vm.data.isFlowBureau === 1
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("flow_bureau")))])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _vm.data.status != 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "10px",
                    top: "0px",
                  },
                },
                [
                  _vm.data.options[0].result === _vm.$t("Victory")
                    ? _c("img", {
                        staticStyle: {
                          width: "18px",
                          height: "20px",
                          "margin-right": "5px",
                        },
                        attrs: {
                          src: require("@/assets/images/guessing/win.png"),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.options[0].result === _vm.$t("Defeated")
                    ? _c("img", {
                        staticStyle: {
                          width: "18px",
                          height: "20px",
                          "margin-right": "5px",
                        },
                        attrs: {
                          src: require("@/assets/images/guessing/failure.png"),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.options[0].result === _vm.$t("Flow")
                    ? _c("img", {
                        staticStyle: {
                          width: "18px",
                          height: "20px",
                          "margin-right": "5px",
                        },
                        attrs: {
                          src: require("@/assets/images/guessing/draw.png"),
                        },
                      })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.data.status != 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    right: "5px",
                    top: "0px",
                  },
                },
                [
                  _vm.data.options[1].result === _vm.$t("Victory")
                    ? _c("img", {
                        staticStyle: {
                          width: "18px",
                          height: "20px",
                          "margin-right": "5px",
                        },
                        attrs: {
                          src: require("@/assets/images/guessing/win.png"),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.options[1].result === _vm.$t("Defeated")
                    ? _c("img", {
                        staticStyle: {
                          width: "18px",
                          height: "20px",
                          "margin-right": "5px",
                        },
                        attrs: {
                          src: require("@/assets/images/guessing/failure.png"),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.options[1].result === _vm.$t("Flow")
                    ? _c("img", {
                        staticStyle: {
                          width: "18px",
                          height: "20px",
                          "margin-right": "5px",
                        },
                        attrs: {
                          src: require("@/assets/images/guessing/draw.png"),
                        },
                      })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "van-row",
            {
              staticStyle: { "margin-top": "14px" },
              attrs: {
                type: "flex",
                align: "center",
                justify: "space-between",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card",
                  style:
                    _vm.data.status === 0 ? _vm.cellLeftActive : _vm.cellLeft,
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "main-theme-font-color",
                      staticStyle: { "font-size": "13px" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.data.options[0].optionItem) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.data.status === 1
                    ? _c(
                        "p",
                        {
                          staticStyle: {
                            "font-size": "13px",
                            color: "#9b9b9b",
                            "margin-top": "3px",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Waiting_announcement")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticStyle: {
                            color: "#f0cf2c",
                            "margin-top": "3px",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.data.options[0].odds) +
                              _vm._s(_vm.$t("Doubling")) +
                              "\n        "
                          ),
                        ]
                      ),
                ]
              ),
              _vm._v(" "),
              _c("img", {
                staticStyle: { width: "22px", height: "25px" },
                attrs: { src: _vm.$theme.getVsImage() },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "card",
                  style:
                    _vm.data.status === 0 ? _vm.cellRigthActive : _vm.cellRigth,
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "main-theme-font-color",
                      staticStyle: { "font-size": "13px" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.data.options[1].optionItem) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.data.status === 1
                    ? _c(
                        "p",
                        {
                          staticStyle: {
                            "font-size": "13px",
                            color: "#9b9b9b",
                            "margin-top": "3px",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Waiting_announcement")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticStyle: {
                            color: "#f0cf2c",
                            "margin-top": "3px",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.data.options[1].odds) +
                              " " +
                              _vm._s(_vm.$t("Doubling")) +
                              "\n        "
                          ),
                        ]
                      ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "relative", "margin-top": "10px" } },
        [
          _c("van-progress", {
            attrs: {
              percentage: _vm.percentage,
              "pivot-text": ".",
              "text-color": "#29292D",
              "track-color": "#FF2943",
              color: "#4A90E2",
              "stroke-width": "6",
            },
          }),
          _vm._v(" "),
          _vm.data.status !== 0
            ? _c("div", {
                staticStyle: {
                  position: "absolute",
                  width: "100%",
                  height: "6px",
                  "background-color": "rgba(26, 26, 28, 0.5)",
                  top: "0px",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "van-row",
        {
          staticStyle: { "margin-top": "14px", color: "#9b9b9b" },
          attrs: { type: "flex", align: "center", justify: "space-between" },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.data.options[0].betCoins))]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.data.options[1].betCoins))]),
        ]
      ),
      _vm._v(" "),
      _vm.data.status === 0
        ? _c(
            "van-row",
            {
              staticStyle: { "margin-top": "14px" },
              attrs: { type: "flex", align: "center", justify: "center" },
            },
            [
              _c(
                "van-button",
                {
                  staticClass: "button-normal",
                  staticStyle: { width: "45%", "margin-rigth": "20px" },
                  on: {
                    click: function ($event) {
                      _vm.showAlert = true
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("Stop")) + "\n    ")]
              ),
              _vm._v(" "),
              _c(
                "van-button",
                {
                  staticClass: "button-normal",
                  staticStyle: { width: "45%", "margin-left": "20px" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("terminate")
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("End")) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.status === 1
        ? _c(
            "van-row",
            {
              staticStyle: { "margin-top": "14px" },
              attrs: { type: "flex", align: "center", justify: "center" },
            },
            [
              _c(
                "van-button",
                {
                  staticClass: "button-normal",
                  staticStyle: { width: "100%" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("terminate")
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("End")) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("alert", {
        attrs: {
          visible: _vm.showAlert,
          message: _vm.$t("hint15"),
          "cancel-button-text": _vm.$t("cancel"),
          "confirm-button-text": _vm.$t("confirm"),
        },
        on: {
          "update:visible": function ($event) {
            _vm.showAlert = $event
          },
          confirm: _vm.confirm,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }