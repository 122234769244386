export default {
  loading: "cargando...",
  searchplaceholder: "Ancla, canal, juego.",
  dowload: "descargar",
  home: "Hogar",
  live: "Vivir",
  match: "Fósforo",
  plan: "Consejos",
  mine: "Mío",
  hotgame: "Competiciones populares",
  news: "Información",
  mall: "centro comercial",
  video: "video corto",
  activity: "actividad",
  hotlive: "Transmisión en vivo caliente",
  hotanchor: "Transmisión en vivo popular",
  highlights: "Reflejos",
  more: "Más",
  noliving: "Actualmente no hay ningún presentador al aire.",
  nolivetips: "El ancla aún se está calentando, espere un momento...",
  chat: "Charlar",
  speak: "Ingrese el contenido del chat",
  nospeak: "Puedes hablar solo después de iniciar sesión ~",
  gologin: "Acceso",
  seenewnews: "Nuevas noticias",
  unlockvip: "Desbloquea VIP gratis",
  announcement: "Anuncio:",
  welcome: "Bienvenido",
  enterroom: "ingresa a la sala de transmisión en vivo",
  followed: "ya seguido",
  newsinfo: "información",
  experts: "Expertos destacados",
  hit: "Golpear",
  return: "Devolver",
  wins: "gana",
  fb: "Fútbol americano",
  bk: "Baloncesto",
  notstarted: "no empezado",
  checkmore: "Ver detalles",
  videolist: "Destacados/vídeos",
  qianzan: "adelante",
  jinhua: "esencia",
  yulei: "entretenimiento",
  recommendplan: "Plan recomendado",
  buyedplan:'Plan comprado',
  winrate: "tasa de aciertos",
  zuigaolianghong: "{msg} el rojo consecutivo más alto",
  lianghong: "lianhong",
  returnrate: "tasa de retorno",
  buzhong: "reembolso si no ganas",
  xieyi: "acuerdo relacionado",
  personal: "Acuerdo de privacidad",
  userxieyi: "Acuerdo del Usuario",
  livexieyi: "Acuerdo de transmisión en vivo",
  chargexieyi: "Acuerdo de Recarga",
  anchorhelp: "Ayuda de anclaje",
  anchornew: "El nuevo ancla está en camino.",
  followus: "Síganos",
  helpcenter: "Centro de ayuda",
  scancodedowload: "Escanee el código para descargar la aplicación Bobcat",
  smzb: "gato vivo",
  noduty:
    "Descargo de responsabilidad: Todas las transmisiones en vivo de fútbol y las señales de transmisión en vivo de la NBA de Bobcat Live son recopiladas por los usuarios u obtenidas de los motores de búsqueda. Todo el contenido proviene de Internet. Nosotros mismos no proporcionamos señales de transmisión en vivo ni contenido de video. Por favor, notifíquenos si hay alguna infracción de sus derechos. , lo solucionaremos lo antes posible.",
  roomsearch: "Ingrese palabras claves",
  myfollow: "mi seguimiento",
  clearcache: "limpiar cache",
  kinds: "clasificación",
  all: "Todo",
  others: "otro",
  yingchao: "primera división",
  hot: "Caliente",
  esports: "deportes electrónicos",
  exit: "salida",
  downloadApp: "Descargar aplicación",
  want: "Ir a transmisión en vivo",
  roomnum: "número de habitación",
  wang: "pálido",
  feedback: "comentario",
  funs: "aficionados",
  follows: "Seguir",
  numberonlive: "Un presentador está transmitiendo actualmente este evento.",
  anchorchat: "charla ancla",
  contributerank: "lista de contribuciones",
  othermatch: "otros partidos",
  chatinput: "Chatea y drogate juntos",
  send: "enviar",
  livefail: "No se pudo obtener la transmisión en vivo",
  tryagain: "Haga clic para intentarlo de nuevo",
  pushlist: "Lista de pedidos push",
  reward: "descripción de la recompensa",
  nopush: "Aún no hay orden de envío",
  anchorpush: "Anchor empuja órdenes",
  maobipokect: "Sobre rojo moneda gato",
  sendsuccess:
    "El envío exitoso puede desencadenar una transmisión en pantalla flotante en todas las plataformas",
  manexchange: "La gente puede recibirlo.",
  threeminexhang: "Se puede recoger en 3 minutos.",
  exchangenow: "Se puede recoger inmediatamente",
  sendpokect: "enviar sobre rojo",
  pokecttips:
    "Envié un sobre rojo a la sala de transmisión en vivo ~ ¡Puedes recogerlo inmediatamente!",
  pokecttips1:
    "Envié un sobre rojo en la sala de transmisión en vivo ~ ¡Puedes recogerlo en 3 minutos!",
  task: "tarea",
  litterhelper: "Pequeño ayudante",
  litterhelper1: "Asistente de descuento",
  enter: "Haga clic para entrar",
  gift: "regalo",
  high: "HD",
  charge: "recargar",
  balance: "balance",
  words: "estallido",
  sendonepokect: "Envió un sobre rojo",
  exchange: "recibir",
  ofpokect: "sobre rojo",
  nopokect: "Soy demasiado lento, los sobres rojos han sido distribuidos.",
  maobi: "moneda de gato",
  savemyaccount: "ha sido depositado en mi cuenta",
  checkexchange: "Ver detalles del reclamo",
  exchangelist: "Registro de recogida de sobre rojo.",
  pokecttips2: "¡Agarré x monedas de gato en mi sobre rojo de monedas de gato!",
  contribute: "contribución diaria",
  contribute1: "Contribución semanal",
  contribute2: "Lista de contribuciones",
  contributevalue: "valor de la contribución",
  living: "transmisión en vivo",
  matchstatus: "Estado del partido",
  zhishu: "índice",
  livematchstatus: "estado del partido en tiempo real",
  jinqiu: "meta",
  zugong: "asistir",
  shemeng: "disparar",
  dianqiu: "Tiro penal",
  wulongqiu: "gol en propia puerta",
  huangpai: "tarjeta amarilla",
  nodianqiu: "El penalti no fue marcado.",
  huanxia: "reemplazar",
  nomatchevent: "Aún no hay evento de partido",
  onlyjinqiu: "Solo mira los goles",
  analysis: "análisis técnico",
  contrrate: "tasa de posesión",
  shezheng: "disparar al objetivo",
  jingong: "ataque",
  dendrgjingong: "ataque peligroso",
  renyiqiu: "Tiro libre",
  jiaoqiu: "saque de esquina",
  hongpai: "tarjeta roja",
  login: "acceso",
  roommannage: "nombrar gerente de sala",
  roomtips: "¿Estás seguro de nombrar a Quince como administrador de la sala?",
  roomtips1:
    "¿Estás seguro de que Quince será despedido como administrador de sala?",
  cancel: "Cancelar",
  confirm: "DE ACUERDO",
  logout: "Cerrar sesión",
  uplevel: "Mejora de distancia: valor de experiencia {msg}",
  wallt: "billetera",
  wantlive: "quiero transmision en vivo",
  usercenter: "centro personal",
  vipright: "Introducción al privilegio VIP",
  pwdlogin: "Contraseña de acceso",
  mobilelogin: "Inicio de sesión móvil",
  doregister1: "Sin cuenta",
  doregister2: "Regístrate ahora",
  forgetpwd: "¿Olvidaste tu contraseña?",
  logintips: "Iniciar sesión significa que estás de acuerdo",
  inputmobile: "Introduce el número de móvil",
  inputcode: "Introduzca el código de verificación",
  mobiletips:
    "Ingrese una contraseña de 6 a 20 dígitos o una contraseña de letras",
  resetpwd: "Restablecer la contraseña",
  register: "Registro",
  sendcode: "Obtener código",
  registernow: "Regístrate ahora",
  appdownload: "descarga de la aplicación",
  advice: "Comentario",
  backtop: "Atrás Arriba",
  intime: "Instante",
  anchor: "ancla",
  matchschude: "cronograma",
  matchresult: "resultado del partido",
  checkdata: "Ver datos",
  nomatch: "Aún no hay coincidencia",
  winlist: "lista de resultados",
  returnlist: "lista de retorno",
  lianghongrank: "Lista Roja Continuamente",
  hotrace: "carrera caliente",
  allrace: "todas las carreras",
  onrace: "en competición",
  onsale: "Plano en venta",
  recommendmaster: "experto recomendado",
  mime: "a mí",
  boughtplan: "Plan comprado",
  sorttype: "clasificación integral",
  returnfirst: "regresar primero",
  winfirst: "Tasa de ganancia primero",
  lianghongfirst: "Lianhong primero",
  nodata: "Aún no hay datos",
  detail: "detalles",
  newslocal: "Ubicación actual: Inicio/Noticias",
  comments: "comentarios",
  nocomments: "Sin comentarios aún",
  docomments: "Comentarios",
  typecomments: "Di algo",
  goods: "bienes",
  searchgoods: "Busca productos que te gusten",
  mycarts: "Mi carrito de compras",
  myorders: "Mis ordenes",
  demens: "diamante",
  goodsinfo: "información de mercancías",
  kucun: "inventario",
  limit: "límite de intercambio",
  value: "precio unitario",
  num: "cantidad",
  total: "Total parcial",
  choice: "seleccionado",
  jiesuan: "asentamiento",
  chooseall: "seleccionar todo",
  limitnum: "límite de compra acumulado",
  limitday: "límite de compra diaria",
  limitweek: "límite de compra semanal",
  totallimt: "Redención total:",
  totallimt1: "Redención acumulada diaria:",
  totallimt2: "Redención total semanal:",
  jiage: "precio",
  goodsdetail: "Detalles de producto",
  buynow: "Canjear ahora",
  addcart: "Añadir a la cesta",
  recommendgoods: "Recomendado para ti",
  confirmbuy: "confirmar el canje",
  mobile: "Número de teléfono móvil",
  recipename: "Nombre del consignatario",
  address: "dirección de entrega",
  remark: "observación",
  inputreciptename: "Introduzca el nombre del destinatario",
  inputaddress: "Introduce la dirección de entrega",
  orders: "número de orden",
  time: "tiempo",
  recipe: "consignatario",
  telphone: "teléfono",
  address1: "DIRECCIÓN",
  recovideo: "vídeo recomendado",
  onplay: "Juego normal....",
  ongoing: "en curso",
  result: "Terminado",
  reserve: "reserva",
  reserved: "reservado",
  myinfo: "mi informacion",
  anchorlevel: "Nivel de anclaje",
  userlevel: "nivel de usuario",
  changeavter: "Cambiar avatar",
  changeinfo: "Modificar información",
  usertips:
    "(El tamaño de la imagen no debe exceder 1M, admite formato png, jpg)",
  uploadimg: "Sube fotos locales",
  myaccount: "Mi cuenta",
  anchorinfo: "Relacionado con el ancla",
  tasks: "Centro de tareas",
  signin: "Inicia sesión con cortesía",
  mywords: "mi profecía",
  downloadreword: "Descargar recompensa de invitación",
  nickname: "apodo",
  inputnickname: "Ingresa tu alias",
  infotips:
    "Descripción:\n 1. El nuevo apodo debe cumplir con las especificaciones de registro y puede utilizar números, letras, caracteres chinos y símbolos. La longitud máxima no debe exceder los 24 caracteres (2 caracteres para chino y 1 carácter para inglés).\n 2. Si la modificación del apodo falla debido a actualizaciones del servidor u otros problemas, comuníquese con el servicio de atención al cliente.",
  introduce: "perfil personal",
  region: "región",
  selectprovince: "Por favor seleccione una provincia",
  selectregion: "Por favor seleccione una ciudad",
  sex: "género",
  savesecrt: "confidencial",
  man: "masculino",
  woman: "femenino",
  bedate: "cumpleaños",
  selectdate: "Por favor seleccione el año",
  selectmonth: "Por favor seleccione un mes",
  select: "Por favor seleccione",
  year: "año",
  month: "mes",
  day: "día",
  save: "ahorrar",
  accountvalue: "saldo de la cuenta",
  billlist: "registro de factura",
  cast: "consumo",
  earnings: "ganancias",
  casttype: "Tipo de consumo",
  sendgift: "regalo",
  prophecy: "profecía",
  redpokect: "sobre rojo",
  exchangegoods: "Intercambiar regalos",
  luckydraw: "sorteo pagado",
  querytime: "Tiempo de consulta",
  starttime: "fecha de inicio",
  endtime: "fecha final",
  query: "consulta",
  reset: "reiniciar",
  castmaobi: "Cantidad de consumo (monedas de gato)",
  allprophecy: "toda profecía",
  prophecylist: "Salón de la Profecía",
  planlist: "Consejos para resolver el problema.",
  yuyuematch: "Partido de reserva",
  earnlist: "récord de ganancias",
  liveset: "Configuración de transmisión en vivo",
  roompush: "Orden de inserción de sala de transmisión en vivo",
  roomset: "Configuración de letras de sala de transmisión en vivo",
  edit: "editar",
  beAnchor: "Conviértete en un ancla",
  inviterUser: "Invitar a amigos",
  setting: "configuración",
  logining: "Iniciando sesión...",
  loginerr: "No se pudo entrar Inténtelo más tarde...",
  loginsecucced: "Inicio de sesión correcto",
  codesend: "Código de verificación enviado exitosamente",
  inputrightphone: "Ingrese el número de teléfono móvil correcto",
  inputagain: "Ingrese su contraseña nuevamente",
  pwdtips: "Las contraseñas ingresadas dos veces son inconsistentes",
  pwdtips1:
    "El formato de la contraseña es incorrecto, ingrese una contraseña de 6 a 20 dígitos o una contraseña de letras",
  registed: "Registro exitoso",
  registerfail: "El registro falló, inténtalo de nuevo más tarde...",
  registing: "Registrando...",
  findpwd: "Recuperar Contraseña",
  submit: "entregar",
  settingPwd: "Contraseña establecida correctamente",
  submitting: "Sumisión...",
  "nomore":'no más',
  "pullrefresh":'Desplegar hacia abajo para actualizar',
  "notstart":'El juego no ha comenzado, puedes ver otros juegos:',
  newsDe:'detalles de la información',
   shouqi: 'guardar',
   fabu:'liberación',
   'eur':'Disco europeo',
    "hk":'Disco de Hong Kong',
    "bm":'Más/Menos',
    'rq':'Desventaja',
    'mzl':'tasa de aciertos',
   'mago':'hace minutos',
   'sago':'hace segundos',
   'hago':'hace horas',
   'mianfei':'gratis',
   'tacticde':'Detalles del plan',
   'zhusheng':'ganar',
   "pingju":'dibujar',
   "kesheng":'perder',
   'pay':'Pagar ahora',
   'stop':'Se ha detenido la venta',
   'reason':'Motivo de la recomendación',
   'afterpay':'Ver el plan y el análisis después del pago',
   'stoptime':'Fecha límite de ventas:',
   'tacticdetips':'Nota: Las opiniones son solo como referencia y no ofrecemos negocios de lotería, ¡preste atención con precaución! ',
   'dago':'hace un día',
   'wago':'hace una semana',
   'mouthago':'hace meses',
   'ganggang': 'justo ahora',
   'bigqiu':'Más{msg}balls',
   'smallqiu':'Menos{msg}balls',
   'buyplan':'Comprar plan experto',
    'buyplantips':'Comprar este plan requiere {num} monedas de gato. ¿Estás seguro de que tienes suficientes? ',
    'yesbuy':'Confirmar compra',
    'cancelfollow':'Cancelar seguimiento exitosamente',
    'followsucceed':'Seguir el éxito',
    'buysucceed':'Compra exitosa',
    'buyfialed':'Compra fallida',
    'liangsai':'liga',
    'team':'En casa vs. visitante',
    'playtype':'jugar',
    'rate': 'probabilidades',
    'chongzhijilu':'Registro de recarga',
    'xiaofeijilu': 'récord de consumo',
    'huodongshouyi': 'ingresos de actividad',
    'myinvitecode':'Mi código de invitación',
    'inputcodeinapp':'Ingrese al iniciar sesión en la aplicación',
    'dianjifuzhi':'Haga clic para copiar el código de invitación',
    'jietu':'Envía capturas de pantalla a tus amigos',
    'shoukuang':'Método de pago',
    'alipay':'Alipay',
    'wechat':'WeChat',
    'shoukuangzhanghao':'número de cuenta de pago',
    'invitegonglue':'Estrategia de invitación',
    'shareqrcode':'Compartir código QR de descarga y código de invitación exclusivo',
    'getjiangli':'Recibirás una recompensa si tu amigo inicia sesión exitosamente por primera vez',
    'invitemingxi':'Detalles de la invitación',
    'zhanwuhaoyou':'Aún no se ha invitado a ningún amigo. ¡Vaya e invite a amigos! ',
    'nicheng':'apodo',
    'zucengshijian':'Hora de registro',
    'jiangli':'recompensa',
    'islogout':'¿Está seguro de que desea cerrar sesión? ',
    'dijilun':'La ronda {num}',
    'guangyuus': 'sobre nosotros',
    'zhuanjiajieshao':'Introducción del experto',
    'recent10':'Tasa de retorno en los últimos 10 juegos',
    'zuijinzhuantai':'Estado reciente',
    'zhengzaituijian':'Recomendando',
    'historyrem':'Recomendación de historial',
    'bisaishijian':'evento del partido',
    'qiuduitongji': 'Estadísticas del equipo',
    'qiudui':'Equipo',
    'zongfen': 'puntuación total',
    'bisaikaishi':'El juego comienza',
    'fangui':'falta',
    'erfen':'dos puntos',
    'faqiu': 'tiro libre',
    'sanfen': 'tres puntos',
    'paizhao': 'tomar fotografías',
    'xuanzezhaopian':'Seleccionar del álbum',
    'jianyanzhi': 'valor de la experiencia',
    'yiwangcheng':'Completado',
    'dangqian':'actual',
    'shengjidao':'Actualizar a',
    'xuyao': 'necesidad',
    'cehngzhang':'Privilegio de crecimiento',
    'dengji':'icono de nivel',
    'kaiqi':'abierto',
    'qidai': 'Estén atentos',
    'mashanglai':'Pronto habrá más privilegios',
    'qiandao':'Registro diario',
    'qiandaochenggong':'Iniciar sesión correctamente',
    'qiandaotips':'Recordatorio: comuníquese con el asistente para solicitar bonificaciones y recompensas físicas. ',
    'chakanqiandao':'Ver recompensas',
    'goxiaozhushou':'Asistente de contacto',
    'dijitian':'{msg}día',
    'zhubodasan':'Lista de recompensas ancla',
    'riban':'lista diaria',
    'yueban':'lista mensual',
    'zongban':'Lista general',
    'gongxianzhi': 'valor de contribución',
    'fananjieshu':'La venta del plan ha finalizado',
    'bisaijieshu':'Los detalles del análisis se anunciarán después de la competencia',
    'yuebuzu':'Saldo insuficiente',
    'qitajinnang':'Otros consejos del experto',
    'qitatuijian':'Otras recomendaciones',
    'dengluhou':'Solo podrás ver los registros comprados más tarde',
    'zhengzaibofng':'Reproduciendo ahora',
    'jijiangbofang': 'Viniendo a jugar',
    'touxiangshangchuan': 'Carga de avatar',
    'shouyeleixing':'tipo de ingreso',
    'lingquhongbao':'Recibir sobre rojo',
    'hongbaotuikuang': 'devolución del sobre rojo',
    'jinnantutikuang':'Reembolso de propinas',
    'yuyanshouyi': 'beneficio de la profecía',
    'yuyantuikuang': 'reembolso de profecía',
    'hongbaoyu':'hongbaoyu',
    'jincaidati':'adivina y responde',
    'zhuanpan': 'lotería de ruleta',
    'jindan': 'rompiendo huevos de oro',
    'zenfa':'Emisión adicional del sistema',
    'dengluhuodong':'Actividad de inicio de sesión',
    'guankan':'Ver transmisión en vivo',
    'danmu':'Enviar bombardeo',
    'denglujianli':'recompensa por iniciar sesión en la APLICACIÓN',
    'tuidan':'Recompensa por orden push',
    'zhuanpanchoujian': 'Nueva lotería carrusel',
    'zhuti':'tema',
    'xuanxian':'opción',
    'kejitouzhu':'apuestas acumuladas',
    'xuanxianbeishu':'Opción múltiple',
    'touzhurenshu':'número de apostantes',
    'jieguo':'resultado',
    'zhuantai': 'estado',
    'daojishi':'Cuenta atrás',
    'fabuplantips':'Gana enormes cantidades de dinero después de publicar los consejos para resolver pelotas~',
    'goumairenshu':'número de compradores',
    'zongji':'Total',
    'fencheng':'antes de dividir en',
    'liveshouyi':'Ingresos liquidables por transmisión en vivo',
    'jinnanshouyi':'Las propinas se pueden liquidar como ingresos',
    'jiesuanrules':'Reglas de liquidación',
    'tequan':'nivel de privilegio',
    'kuaisushengji':'Cómo actualizar rápidamente',
    'wodedengji':'mi nivel',
    'dengjishuoming':'descripción del nivel',
    'zhubotequan':'privilegio de anclaje',
    'shejizhong':'Se están diseñando privilegios de anclaje',
    'moretequan':'Pronto habrá más privilegios',
    'huodeliwu':'Recibe un regalo',
    'qingfenzhibo': 'transmisión en vivo diligente',
    'zensongliwu':'dar un regalo',
    'yonghutequan':'Privilegios de usuario',
    'tequansheji':'Los privilegios de usuario están en diseño',
    'fengzhong': 'minuto',
    'jiyang':'experiencia',
    'quedingquxiao':'¿Estás seguro de que quieres dejar de seguir? ',
    'zhengquetianxie':'Por favor complete la información correcta, de lo contrario no recibirá ninguna recompensa',
    'qingshuruzhanghao':'Por favor ingrese su número de cuenta',
    'yaoqingshuoming':'Descripción de la invitación',
    'zaiciquanren':'Por favor, confirma la cuenta {msg} nuevamente: {msg1}',
    'qingshuruzhanghao':'Por favor ingrese {msg} número de cuenta',
    'daishenhe':'para ser revisado',
    'yipaijiang':'El premio ha sido distribuido',
    'weitongguo': 'fallido',
    'leijizhong':'acumulando',
    'tishi':'pista',
    'tijiaochengong':'¡Envío exitoso! ',
    'zonghe': 'integral',
   'yituichi':'pospuesto',
   'yiquxiao':'Cancelado',
   'jitian':'hoy',
   'mintian':'mañana',
   'houtian': 'pasado mañana',
   'zuotian':'ayer',
   'xiazaitixing':'La cita fue exitosa, descarga la APP para recibir el recordatorio de la cita',
   'quxiaoyuyue':'Reserva cancelada exitosamente',
   'zhoutian':'domingo',
   'zhouyi': 'lunes',
   'zhouer':'martes',
   'zhousan':'miércoles',
   'zhousi':'jueves',
   'zhouwu': 'viernes',
   'zhouliu': 'sábado',
   'jianshu':'pieza',
   'meiri': 'diario',
   'meizhou': 'semanal',
   'duihuanchenggong':'Canjear con éxito',
   'zuiduoduihuan':'Canje máximo de {msg} piezas',
   'caozuo':'operación',
   'zongjia': 'precio total',
   'shanchu':'eliminar',
   'now':'ahora sólo',
   'yituikuan':'reembolsado',
   'goumai':'comprar',
   'xuanzezhibo': 'Seleccionar canal de transmisión en vivo',
   'erjipingdao':'Canal de segundo nivel',
   'zhibobiaoti':'Título de transmisión en vivo',
   'shezhibiaoti':'Establezca el título de la transmisión en vivo',
   'guanliansaishi':'eventos relacionados',
   'xuanzhiguanlian':'Por favor asocie el evento',
   'xuanze':'seleccionar',
   'yichu':'eliminar',
   'zhibofenmian':'Portada de transmisión en vivo',
   'yishenhe':'revisado',
   'shenhezhong': 'bajo revisión',
   'yijujue':'Rechazado',
   'zhibijieshu':'Finalizar transmisión en vivo',
   'kaishizhibo':'Iniciar transmisión en vivo',
   'tuiliudizhi':'Dirección push (dirección del servidor)',
   'fuzhi':'copiar',
   'tuiliumiyao':'Código push (clave de transmisión)',
   'laliudizhi':'Laliudizhi',
   'zhiboshuoming':'Descripción de la transmisión en vivo',
   'zhibotips1':`
   1. Establecer información básica: seleccionar canal, establecer título, asociar eventos, cobertura de transmisión en vivo, configurar según sea necesario;<br />
   2. Después de hacer clic en el botón "Iniciar transmisión en vivo", copie la dirección y el código de inserción;<br />
   3. Pegue la dirección de inserción y el código de inserción en el "servidor y clave de transmisión" correspondiente al software OBS e inicie la transmisión en vivo;<br />`,
   'zhibotips2':`
   1. Después de hacer clic para iniciar la transmisión en vivo, copie y pegue la dirección de envío y el código de envío en OBS dentro de 1 minuto y envíe la transmisión. De lo contrario, la sala de transmisión en vivo puede degradarse como un sistema anormal;<br />
   2. Después de finalizar la transmisión en vivo y cerrar OBS, debe hacer clic en el botón finalizar transmisión en vivo que se encuentra arriba para descargar la sala de transmisión en vivo. <br />`,
   'yiyuyue':'El evento ha sido reservado',
   'biaotitips':'¡El título no puede estar vacío! ',
   'yuliang': 'vista previa',
   'zhuyishixian':'Notas',
   'xinjian':'Nueva orden push',
   'xuanzesaishi':'Seleccionar evento',
   'xuanzesaiduan':'Seleccionar etapa',
   'xuanzewf':'Elige cómo jugar',
   'jieguotips':'Por favor ingrese el resultado, hasta 25 palabras',
   'tuidanlishi':'Orden de inserción histórica',
   'renmai':'{msg}人 seguir órdenes',
   'renfanmai':'{msg}la gente compra en contra',
   'qingxuanzesaishi':'Por favor seleccione el evento',
   'qingsaiduan':'Por favor seleccione el escenario',
   'qingwf':'Por favor, elige cómo jugar',
   'qingjieguo':'Por favor ingrese el resultado',
   'tuidangtips':'Este pedido de promoción no se puede cambiar una vez enviado. ¿Confirma el envío? ',
   'fabutuidan':'Publicar una orden push',
   'chuangjian':'Creado exitosamente',
   'yijianfabu':'Publicación con un clic',
   'zimushezhi':'Configuración de subtítulos en vivo',
   'zhimutips':'No es necesario, puedes continuar editando después de que comience la transmisión',
   'dingbu':'pantalla superior',
   'shangshi':'Mostrar subtítulos',
   'zimutips':'Ingrese subtítulos para la interfaz de transmisión en vivo, hasta 50 caracteres',
   'xuanzepeise':'Seleccionar color:',
   'dibu':'pantalla inferior',
   'shanchutips':'Consejo: si necesita eliminar, haga clic en Restablecer',
   'yuliangzhuangshi':'Visualización previa',
   'shoujiduan':'Vista previa de la versión móvil',
   'diannaoduan':'vista previa de PC',
   'resettips':'¿Estás seguro de que quieres restablecer? Los subtítulos que hayan tenido efecto en la interfaz se eliminarán automáticamente después del reinicio. ',
   'baocuntips':'¿Estás seguro de que quieres guardar? Después de guardar, los subtítulos de la interfaz se actualizarán y mostrarán. ',
   'caozuochenggongg':'Operación exitosa',
   'dangqianjy':'Valor de la experiencia actual',
   'jinrihuode':'Consíguelo hoy',
   'guizeshuo':'Descripción de la regla',
   'dangqian':' LV actual.{msg}',
   'shenjilv':'Actualizar a LV.{msg} requiere {msg1} puntos de experiencia',
   'dachenglv':'Se requiere un total de {msg1}puntos de experiencia para alcanzar V{msg}',
   'shenjidaolv':' Actualizar a V{msg} requiere {msg1} puntos de experiencia',
   'xinshourenwu':'Tarea de novato',
   'meirirenwu':'tarea diaria',
   'jieji':'clase',
   'dengjit':'nivel',
   'tubiao':'icono',
   'gongxihuode':'Felicitaciones por conseguirlo',
   'weiwangcheng':'Sin terminar',
   'jianlilist':'Detalles de la recompensa',
   'renwuleixin':'tipo de tarea',
   'renwujianli':'Recompensa por tarea',
   'paifazhuangt':'Estado de distribución',
   'huodeshijian':'Conseguir tiempo',
   'weipaifa':'no distribuido',
   'yipaifa':'distribuido',
   'yuyanzhuti':'Tema de profecía',
   'cangyuxiang':'elemento de participación',
   'beishu': 'múltiple',
   'touru':'inversión',
   'yingkui': 'pérdidas y ganancias',
   'yuyanshijian': 'tiempo de profecía',
   'zhubogonggao': 'Anuncio de anclaje',
   'zuiduiyuyue':'Reservas máximas para 20 eventos al mismo tiempo',
   'yituihuan':'Regresado',
   'qiuduimingcheng':'Por favor ingrese el nombre del equipo',
   'sousuoqiudui':'buscar',
   'chongxinxuanze':'Volver a seleccionar',
   'fangfaleixing':'Tipo de método',
   xuanzegongshi:'Elija una empresa',
   jinnangbiaoti:'Título del proyecto',
   jinnangbiaoti:'Ingrese un título (más de 4 palabras)',
   jinnangjiexibia: 'Por favor ingrese el análisis (no menos de 20 palabras ~)',
   tuichutips:'El contenido no se puede guardar después de salir. ¿Está seguro de que desea salir? ',
   biaotizuishao:'El título debe tener al menos 4 palabras',
   jiexizuishao: 'analizar al menos 20 caracteres',
   tianjiachenggong: 'Agregado exitosamente'
};
