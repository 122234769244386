var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.kitInfo !== undefined
    ? _c(
        "div",
        {
          staticClass: "main-container",
          attrs: { "data-theme": _vm.$store.getters.themeType },
        },
        [
          _c("div", { staticClass: "main-theme-font-color" }, [
            _vm._v(_vm._s(_vm.kitInfo.plan.title)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                color: "#9B9B9B",
                "font-size": "12px",
                "margin-top": "8px",
                "margin-bottom": "15px",
              },
            },
            [
              _c("span", { staticStyle: { "margin-rigth": "4px" } }, [
                _vm._v(
                  _vm._s(
                    _vm._f("formatTime")(
                      _vm._f("stringToTime")(_vm.kitInfo.plan.createTime)
                    )
                  )
                ),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.$t("fabu")))]),
            ]
          ),
          _vm._v(" "),
          _c("van-row", { attrs: { type: "flex", align: "center" } }, [
            _c("img", {
              staticStyle: {
                width: "46px",
                height: "46px",
                "margin-right": "10px",
                border: "1px solid #EEF0F5",
                "border-radius": "23px",
              },
              attrs: { src: _vm.kitInfo.user.avatar },
            }),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "p",
                  {
                    staticClass: "main-theme-font-color",
                    staticStyle: { "font-size": "16px" },
                  },
                  [_vm._v(_vm._s(_vm.kitInfo.user.nickName))]
                ),
                _vm._v(" "),
                _c(
                  "van-row",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.kitInfo.expert.hitRecent &&
                          _vm.kitInfo.expert.hitSeries,
                        expression:
                          "kitInfo.expert.hitRecent && kitInfo.expert.hitSeries",
                      },
                    ],
                    staticStyle: { "margin-top": "10px" },
                    attrs: { type: "flex", align: "center" },
                  },
                  [
                    _vm.kitInfo.expert.hitRecent
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "10px",
                              padding: "3px 5px",
                              "background-color": "#5A4F21",
                              color: "#F1CB30",
                              "border-radius": "12px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.kitInfo.expert.hitRecent))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kitInfo.expert.hitSeriesClose &&
                    _vm.kitInfo.expert.hitSeriesClose > 2
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "10px",
                              padding: "3px 5px",
                              "margin-left": "3px",
                              "background-color": "#5A2627",
                              color: "#F04343",
                              "border-radius": "12px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.kitInfo.expert.hitSeriesClose) +
                                _vm._s(_vm.$t("lianghong"))
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kitInfo.expert.hitSeriesClose &&
                    _vm.kitInfo.expert.hitSeriesClose < 3 &&
                    _vm.kitInfo.expert.hitSeries &&
                    _vm.kitInfo.expert.hitSeries > 2
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "10px",
                              padding: "3px 5px",
                              "margin-left": "3px",
                              "background-color": "#5A2627",
                              color: "#F04343",
                              "border-radius": "12px",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("zuigaolianghong", {
                                    msg: _vm.kitInfo.expert.hitSeriesClose,
                                  })
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("van-divider", {
            style: { borderColor: _vm.$theme.getDividerBorderColor() },
          }),
          _vm._v(" "),
          _c(
            "van-row",
            {
              attrs: {
                type: "flex",
                justify: "space-between",
                align: "center",
              },
            },
            [
              _c("div", { staticClass: "main-theme-font-color" }, [
                _vm._v(_vm._s(_vm.$t("tacticde"))),
              ]),
              _vm._v(" "),
              _vm.lang === "zh"
                ? [
                    _vm.kitInfo.plan.guessHit !== null
                      ? _c("div", [
                          _vm.kitInfo.plan.guessHit === 1
                            ? _c("img", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: {
                                  src: require("@/assets/images/kit/right.png"),
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          (_vm.kitInfo.plan.planBigType === 1 ||
                            _vm.kitInfo.plan.planBigType === 2) &&
                          _vm.parseLotteryResult(_vm.kitInfo.match.spf) === 1
                            ? _c("img", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: {
                                  src: require("@/assets/images/kit/draw.png"),
                                },
                              })
                            : _vm.kitInfo.plan.guessHit !== null &&
                              _vm.kitInfo.plan.guessHit === 0
                            ? _c("img", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: {
                                  src: require("@/assets/images/kit/wrong.png"),
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                : [
                    _vm.kitInfo.plan.guessHit !== null
                      ? _c("div", [
                          _vm.kitInfo.plan.guessHit === 1
                            ? _c("img", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: {
                                  src: require("@/assets/plan/" +
                                    _vm.lang +
                                    "/icon_silk_correct.png"),
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          (_vm.kitInfo.plan.planBigType === 1 ||
                            _vm.kitInfo.plan.planBigType === 2) &&
                          _vm.parseLotteryResult(_vm.kitInfo.match.spf) === 1
                            ? _c("img", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: {
                                  src: require("@/assets/plan/" +
                                    _vm.lang +
                                    "/icon_silk_miss.png"),
                                },
                              })
                            : _vm.kitInfo.plan.guessHit !== null &&
                              _vm.kitInfo.plan.guessHit === 0
                            ? _c("img", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: {
                                  src: require("@/assets/plan/" +
                                    _vm.lang +
                                    "/icon_silk_uncorrect.png"),
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "van-row",
            {
              staticStyle: {
                color: "#9B9B9B",
                "font-size": "14px",
                "margin-top": "15px",
                "margin-bottom": "10px",
              },
              attrs: { type: "flex", justify: "center", align: "center" },
            },
            [
              _c("span", { staticClass: "plan-big-type" }, [
                _vm._v(
                  "[" +
                    _vm._s(
                      _vm.planBigTypeFilter(_vm.kitInfo.plan.planBigType)
                    ) +
                    "]"
                ),
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.kitInfo.match.matchTime.substr(0, 4)) +
                    "/" +
                    _vm._s(_vm.kitInfo.match.matchTime.substr(5, 2)) +
                    "/" +
                    _vm._s(_vm.kitInfo.match.matchTime.substr(8, 2)) +
                    " " +
                    _vm._s(_vm.kitInfo.match.matchTime.substr(11, 5)) +
                    " " +
                    _vm._s(_vm.kitInfo.match.eventName)
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "van-row",
            {
              staticStyle: { "margin-bottom": "15px" },
              attrs: { type: "flex", align: "center" },
            },
            [
              _c(
                "van-col",
                {
                  staticStyle: { "font-size": "14px", "text-align": "center" },
                  attrs: { span: "10" },
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "46px",
                      height: "46px",
                      "margin-bottom": "10px",
                      border: "1px solid #EEF0F5",
                      "border-radius": "23px",
                    },
                    attrs: { src: _vm.kitInfo.match.homeLogo },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "main-theme-font-color" }, [
                    _vm._v(_vm._s(_vm.kitInfo.match.homeName)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("van-col", { staticClass: "vs", attrs: { span: "4" } }, [
                _vm.kitInfo.match.score
                  ? _c("span", [_vm._v(_vm._s(_vm.kitInfo.match.score))])
                  : _c("span", [_vm._v("VS")]),
              ]),
              _vm._v(" "),
              _c(
                "van-col",
                {
                  staticStyle: { "font-size": "14px", "text-align": "center" },
                  attrs: { span: "10" },
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "46px",
                      height: "46px",
                      "margin-bottom": "10px",
                      border: "1px solid #EEF0F5",
                      "border-radius": "23px",
                    },
                    attrs: { src: _vm.kitInfo.match.awayLogo },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "main-theme-font-color" }, [
                    _vm._v(_vm._s(_vm.kitInfo.match.awayName)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.kitInfo.plan.planBigType === 0
            ? _c(
                "van-row",
                {
                  attrs: {
                    type: "flex",
                    justify: "space-between",
                    align: "center",
                  },
                },
                [
                  _c("van-col", { attrs: { span: "6" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "rectangle-play",
                        staticStyle: {
                          "text-align": "center",
                          color: "#9B9B9B",
                          "font-size": "12px",
                        },
                      },
                      [
                        _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
                          _vm._v(_vm._s(_vm.$t("rq"))),
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.addBall))]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "van-col",
                    { attrs: { span: "17", offset: "1" } },
                    [
                      _c(
                        "van-row",
                        [
                          _c("van-col", { attrs: { span: "8" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "rectangle left-radius right-border",
                                class:
                                  _vm.recommend === 0
                                    ? "option-active"
                                    : "option",
                                staticStyle: { position: "relative" },
                              },
                              [
                                _vm.result === 3
                                  ? _c("img", {
                                      staticStyle: {
                                        position: "absolute",
                                        top: "0px",
                                        left: "0px",
                                        width: "30px",
                                        height: "30px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/kit/right_icon.png"),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    class:
                                      _vm.recommend === 0
                                        ? "option-title-active"
                                        : "option-title",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("zhusheng")))]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.addBall === 0
                                            ? _vm.kitInfo.match.spf.realOdds[0]
                                            : _vm.kitInfo.match.rqspf
                                                .realOdds[0]
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("van-col", { attrs: { span: "8" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "rectangle right-border",
                                class:
                                  _vm.recommend === 1
                                    ? "option-active"
                                    : "option",
                                staticStyle: { position: "relative" },
                              },
                              [
                                _vm.result === 1
                                  ? _c("img", {
                                      staticStyle: {
                                        position: "absolute",
                                        top: "0px",
                                        left: "0px",
                                        width: "30px",
                                        height: "30px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/kit/right_icon.png"),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    class:
                                      _vm.recommend === 1
                                        ? "option-title-active"
                                        : "option-title",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("pingju")))]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.addBall === 0
                                            ? _vm.kitInfo.match.spf.realOdds[1]
                                            : _vm.kitInfo.match.rqspf
                                                .realOdds[1]
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("van-col", { attrs: { span: "8" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "rectangle right-radius",
                                class:
                                  _vm.recommend === 2
                                    ? "option-active"
                                    : "option",
                                staticStyle: { position: "relative" },
                              },
                              [
                                _vm.result === 0
                                  ? _c("img", {
                                      staticStyle: {
                                        position: "absolute",
                                        top: "0px",
                                        left: "0px",
                                        width: "30px",
                                        height: "30px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/kit/right_icon.png"),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    class:
                                      _vm.recommend === 2
                                        ? "option-title-active"
                                        : "option-title",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("kesheng")))]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.addBall === 0
                                            ? _vm.kitInfo.match.spf.realOdds[2]
                                            : _vm.kitInfo.match.rqspf
                                                .realOdds[2]
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.kitInfo.plan.planBigType !== 0
            ? _c(
                "div",
                [
                  _c("recommend-segmentation", {
                    attrs: {
                      type: _vm.kitInfo.plan.planBigType,
                      data: _vm.kitInfo.match.spf,
                      "plan-info": _vm.kitInfo.plan,
                      "is-detail": true,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "main-theme-font-color",
              staticStyle: {
                "font-size": "16px",
                "margin-top": "20px",
                "margin-bottom": "10px",
              },
            },
            [_vm._v(_vm._s(_vm.$t("reason")))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.kitInfo.plan.analysisText &&
                    _vm.kitInfo.plan.analysisText !== "",
                  expression:
                    "kitInfo.plan.analysisText && kitInfo.plan.analysisText !== ''",
                },
              ],
              staticClass: "card reson",
            },
            [
              _c("span", { staticStyle: { width: "100%" } }, [
                _vm._v(_vm._s(_vm.kitInfo.plan.analysisText)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "main-bg",
              staticStyle: {
                position: "fixed",
                width: "100%",
                bottom: "0px",
                "padding-bottom": "10px",
              },
            },
            [
              _c(
                "van-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { type: "flex", align: "center" },
                },
                [
                  _c("van-icon", {
                    attrs: { name: "info-o", color: "#F04343" },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        color: "#9B9B9B",
                        "margin-left": "7px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("tacticdetips")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("div", {
        staticClass: "main-bg",
        staticStyle: { width: "100%", height: "100%" },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }