<template>
  <div class="app-container">
    <div class="main-content">
      <input ref="passwordInput" v-model="passwordText" type="text" maxlength="4" class="password-input" @input="changeInput()">
      <div class="card password-warpper">
        <p class="password-warpper-text">{{ $t('fanjianmima') }}</p>
        <div class="password-warpper-content" @click="inputFocus">
          <div class="password-warpper-content-item" :class="password.length === 0 ? 'password-warpper-content-item-active' : ''">{{ password.length > 0 ? password.substr(0,1) : '' }}</div>
          <div class="password-warpper-content-item margin-left-15" :class="password.length === 1 ? 'password-warpper-content-item-active' : ''">{{ password.length > 1 ? password.substr(1,1) : '' }}</div>
          <div class="password-warpper-content-item margin-left-15" :class="password.length === 2 ? 'password-warpper-content-item-active' : ''">{{ password.length > 2 ? password.substr(2,1) : '' }}</div>
          <div class="password-warpper-content-item margin-left-15" :class="password.length === 3 ? 'password-warpper-content-item-active' : ''">{{ password.length > 3 ? password.substr(3,1) : '' }}</div>
        </div>
      </div>
      <div class="prompts">
        <p class="margin-top-20 prompts-text">{{ $t('jiamifanjianshuoming') }}</p>
        <p class="margin-top-20 prompts-text">{{ $t('jiami1') }} </p>
        <p class="margin-top-20 prompts-text">{{ $t('jiami2') }}</p>
        <p class="margin-top-20 prompts-text">{{ $t('jiami3') }} </p>
      </div>
      <div class="bottom">
        <div class="content">
          <van-button class="confirmation-button" :disabled="password.length < 4" @click="confirm()">{{ $t('querenbtn') }}</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Row, Button, Toast } from 'vant'
import { setRoomPassword, getRoomPassword } from '@/api/live-setting/index'

export default {
  components: {
    [Row.name]: Row,
    [Button.name]: Button
  },
  data() {
    return {
      passwordText: '',
      password: ''
    }
  },
  mounted() {
  },
  created() {
    this.setTitle()
    this.getPassword()
  },
  methods: {
    setTitle() {
      document.title = '加密房设置'
    },
    inputFocus() {
      this.$refs.passwordInput.focus()
    },
    confirm() {
      setRoomPassword({ password: this.password }).then(response => {
        Toast('添加成功')
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      })
    },
    getPassword() {
      getRoomPassword({}).then(response => {
        this.passwordText = response.data.password || ''
        this.password = response.data.password || ''
      })
    },
    changeInput() {
      if (/^\d+$/.test(this.passwordText) || this.passwordText === '') {
        this.password = this.passwordText
      } else {
        this.passwordText = this.password
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";

.main-content {
  height: 100%;
  width: 100%;
  padding-top: 24px;
  padding: 20px 20px;
  color: #fff;
  .password-input {
    position: absolute;
    top: -200px;
    background-color: transparent;
    color:transparent;
    caret-color: transparent;
  }
  .margin-left-15 {
    margin-left: 15px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .password-warpper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-text {
      margin-top: 20px;
      height: 18px;
      line-height: 18px;
      font-size: 18px;
    }
    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0px 30px 0px;
      &-item {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        font-family: Helvetica;
        text-align: center;
        border-radius: 2px;
        border: 1px solid #373941;
        @include color_main_font();
      }
      &-item-active {
        border: 1px solid #4A90E2!important;
      }
    }
  }
  .prompts {
    &-text {
      font-size: 14px;
      line-height: 20px;
      color: #C0C0C0;
    }
  }
  .bottom {
    position: absolute;
    width: 100%;
    height: 82px;
    bottom: 35px;
    left: 0px;
    color: #fff;
    @include color_main_bg();
    .content {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      .confirmation-button {
        width: 80%;
        height:40px;
        background-color: #4A8FE2!important;
        border-radius:4px;
        border:1px solid #4A8FE2!important;
        color: #fff!important;
        font-size: 12px;
      }
    }
  }
}
</style>
