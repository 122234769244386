var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "main-content" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.passwordText,
            expression: "passwordText",
          },
        ],
        ref: "passwordInput",
        staticClass: "password-input",
        attrs: { type: "text", maxlength: "4" },
        domProps: { value: _vm.passwordText },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.passwordText = $event.target.value
            },
            function ($event) {
              return _vm.changeInput()
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card password-warpper" }, [
        _c("p", { staticClass: "password-warpper-text" }, [
          _vm._v(_vm._s(_vm.$t("fanjianmima"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "password-warpper-content",
            on: { click: _vm.inputFocus },
          },
          [
            _c(
              "div",
              {
                staticClass: "password-warpper-content-item",
                class:
                  _vm.password.length === 0
                    ? "password-warpper-content-item-active"
                    : "",
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.password.length > 0 ? _vm.password.substr(0, 1) : ""
                  )
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "password-warpper-content-item margin-left-15",
                class:
                  _vm.password.length === 1
                    ? "password-warpper-content-item-active"
                    : "",
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.password.length > 1 ? _vm.password.substr(1, 1) : ""
                  )
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "password-warpper-content-item margin-left-15",
                class:
                  _vm.password.length === 2
                    ? "password-warpper-content-item-active"
                    : "",
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.password.length > 2 ? _vm.password.substr(2, 1) : ""
                  )
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "password-warpper-content-item margin-left-15",
                class:
                  _vm.password.length === 3
                    ? "password-warpper-content-item-active"
                    : "",
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.password.length > 3 ? _vm.password.substr(3, 1) : ""
                  )
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "prompts" }, [
        _c("p", { staticClass: "margin-top-20 prompts-text" }, [
          _vm._v(_vm._s(_vm.$t("jiamifanjianshuoming"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "margin-top-20 prompts-text" }, [
          _vm._v(_vm._s(_vm.$t("jiami1")) + " "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "margin-top-20 prompts-text" }, [
          _vm._v(_vm._s(_vm.$t("jiami2"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "margin-top-20 prompts-text" }, [
          _vm._v(_vm._s(_vm.$t("jiami3")) + " "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bottom" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "van-button",
              {
                staticClass: "confirmation-button",
                attrs: { disabled: _vm.password.length < 4 },
                on: {
                  click: function ($event) {
                    return _vm.confirm()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("querenbtn")))]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }