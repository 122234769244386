<template>
  <div class="main-container">
    <div class="content" v-html="content" />
  </div>
</template>
<script>
import { getProtocalDetail } from '@/api/protocol'

export default {
  name: 'PrivacyAgreement',
  components: {},
  data() {
    return {
      content: '',
      title: ''
    }
  },
  created() {
    this.getProtocal()
  },
  methods: {
    setTitle() {
      document.title = this.title
    },
    getProtocal() {
      getProtocalDetail({ agreementNo: 10001 }).then(res => {
        this.content = res.data.agreementContent
        this.title = res.data.agreementName
        this.setTitle()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.main-container {
  padding: 15px;
  @include color_main_bg();
}
.content {
  line-height: 22px;
  @include color_main_font();
}
</style>
