<template>
  <div v-if="kitInfo !== undefined" class="main-container" :data-theme="$store.getters.themeType">
    <div class="main-theme-font-color">{{ kitInfo.plan.title }}</div>
    <div style="color: #9B9B9B; font-size: 12px; margin-top: 8px; margin-bottom: 15px;">
      <span style="margin-rigth: 4px;">{{ kitInfo.plan.createTime | stringToTime | formatTime }}</span><span>{{ $t('fabu') }}</span>
    </div>
    <van-row type="flex" align="center">
      <img style="width: 46px; height: 46px; margin-right: 10px; border:1px solid #EEF0F5; border-radius: 23px;" :src="kitInfo.user.avatar">
      <div>
        <p style="font-size: 16px;" class="main-theme-font-color">{{ kitInfo.user.nickName }}</p>
        <van-row v-show="kitInfo.expert.hitRecent && kitInfo.expert.hitSeries" type="flex" align="center" style="margin-top: 10px;">
          <span v-if="kitInfo.expert.hitRecent" style="font-size: 10px; padding: 3px 5px; background-color: #5A4F21; color: #F1CB30; border-radius:12px">{{ kitInfo.expert.hitRecent }}</span>
          <span v-if="kitInfo.expert.hitSeriesClose && kitInfo.expert.hitSeriesClose > 2 " style="font-size: 10px; padding: 3px 5px; margin-left: 3px; background-color: #5A2627; color: #F04343; border-radius:12px">{{ kitInfo.expert.hitSeriesClose }}{{ $t('lianghong') }}</span>
          <span v-if="kitInfo.expert.hitSeriesClose && kitInfo.expert.hitSeriesClose < 3 && kitInfo.expert.hitSeries && kitInfo.expert.hitSeries > 2 " style="font-size: 10px; padding: 3px 5px; margin-left: 3px; background-color: #5A2627; color: #F04343; border-radius:12px"> {{ $t('zuigaolianghong',{msg:kitInfo.expert.hitSeriesClose}) }} </span>
        </van-row>
      </div>
    </van-row>
    <van-divider :style="{borderColor: $theme.getDividerBorderColor()}" />
    <van-row type="flex" justify="space-between" align="center">
      <div class="main-theme-font-color">{{ $t('tacticde') }}</div>
      <template v-if="lang === 'zh'">
        <div v-if="kitInfo.plan.guessHit !== null">
          <img v-if="kitInfo.plan.guessHit === 1" style="width: 50px; height: 50px;" src="@/assets/images/kit/right.png">
          <img v-if="(kitInfo.plan.planBigType === 1 || kitInfo.plan.planBigType === 2) && parseLotteryResult(kitInfo.match.spf) === 1" style="width: 50px; height: 50px;" src="@/assets/images/kit/draw.png">
          <img v-else-if="kitInfo.plan.guessHit !== null && kitInfo.plan.guessHit === 0" style="width: 50px; height: 50px;" src="@/assets/images/kit/wrong.png">
        </div>
      </template>
      <template v-else>
        <div v-if="kitInfo.plan.guessHit !== null">
          <img v-if="kitInfo.plan.guessHit === 1" style="width: 50px; height: 50px;" :src="require('@/assets/plan/'+lang+'/icon_silk_correct.png')">
          <img v-if="(kitInfo.plan.planBigType === 1 || kitInfo.plan.planBigType === 2) && parseLotteryResult(kitInfo.match.spf) === 1" style="width: 50px; height: 50px;" :src="require('@/assets/plan/'+lang+'/icon_silk_miss.png')">
          <img v-else-if="kitInfo.plan.guessHit !== null && kitInfo.plan.guessHit === 0" style="width: 50px; height: 50px;" :src="require('@/assets/plan/'+lang+'/icon_silk_uncorrect.png')">
        </div>
      </template>

    </van-row>
    <van-row type="flex" justify="center" align="center" style="color: #9B9B9B; font-size: 14px; margin-top: 15px; margin-bottom: 10px;">
      <span class="plan-big-type">[{{ planBigTypeFilter(kitInfo.plan.planBigType) }}]</span><span>{{ kitInfo.match.matchTime.substr(0,4) }}/{{ kitInfo.match.matchTime.substr(5,2) }}/{{ kitInfo.match.matchTime.substr(8,2) }} {{ kitInfo.match.matchTime.substr(11,5) }} {{ kitInfo.match.eventName }}</span>
    </van-row>
    <van-row type="flex" align="center" style="margin-bottom: 15px;">
      <van-col span="10" style="font-size: 14px; text-align: center;">
        <img style="width: 46px; height: 46px;  margin-bottom: 10px; border:1px solid #EEF0F5; border-radius: 23px;" :src="kitInfo.match.homeLogo">
        <p class="main-theme-font-color">{{ kitInfo.match.homeName }}</p>
      </van-col>
      <van-col span="4" class="vs">
        <span v-if="kitInfo.match.score">{{ kitInfo.match.score }}</span>
        <span v-else>VS</span>
      </van-col>
      <van-col span="10" style="font-size: 14px; text-align: center;">
        <img style="width: 46px; height: 46px;  margin-bottom: 10px; border:1px solid #EEF0F5; border-radius: 23px;" :src="kitInfo.match.awayLogo">
        <p class="main-theme-font-color">{{ kitInfo.match.awayName }}</p>
      </van-col>
    </van-row>
    <van-row v-if="kitInfo.plan.planBigType === 0" type="flex" justify="space-between" align="center">
      <van-col span="6">
        <div class="rectangle-play" style="text-align: center; color: #9B9B9B; font-size: 12px;">
          <p style="margin-bottom: 10px;">{{ $t('rq') }}</p>
          <span>{{ addBall }}</span>
        </div>
      </van-col>
      <van-col span="17" offset="1">
        <van-row>
          <van-col span="8">
            <div class="rectangle left-radius right-border" style="position: relative;" :class="recommend === 0 ? 'option-active' : 'option'">
              <img v-if="result === 3" style="position: absolute; top: 0px; left: 0px; width: 30px; height: 30px;" src="@/assets/images/kit/right_icon.png">
              <div :class="recommend === 0 ? 'option-title-active' : 'option-title'">
                <p style="margin-bottom: 10px;">{{ $t('zhusheng') }}</p>
                <span>{{ addBall === 0 ? kitInfo.match.spf.realOdds[0] : kitInfo.match.rqspf.realOdds[0] }}</span>
              </div>
            </div>
          </van-col>
          <van-col span="8">
            <div class="rectangle right-border" :class="recommend === 1 ? 'option-active' : 'option'" style="position: relative;">
              <img v-if="result === 1" style="position: absolute; top: 0px; left: 0px; width: 30px; height: 30px;" src="@/assets/images/kit/right_icon.png">
              <div :class="recommend === 1 ? 'option-title-active' : 'option-title'">
                <p style="margin-bottom: 10px;">{{ $t('pingju') }}</p>
                <span>{{ addBall === 0 ? kitInfo.match.spf.realOdds[1] : kitInfo.match.rqspf.realOdds[1] }}</span>
              </div>
            </div>
          </van-col>
          <van-col span="8">
            <div class="rectangle right-radius" :class="recommend === 2 ? 'option-active' : 'option'" style="position: relative;">
              <img v-if="result === 0" style="position: absolute; top: 0px; left: 0px; width: 30px; height: 30px;" src="@/assets/images/kit/right_icon.png">
              <div :class="recommend === 2 ? 'option-title-active' : 'option-title'">
                <p style="margin-bottom: 10px;">{{ $t('kesheng') }}</p>
                <span>{{ addBall === 0 ? kitInfo.match.spf.realOdds[2] : kitInfo.match.rqspf.realOdds[2] }}</span>
              </div>
            </div>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
    <div v-if="kitInfo.plan.planBigType !== 0">
      <recommend-segmentation :type="kitInfo.plan.planBigType" :data="kitInfo.match.spf" :plan-info="kitInfo.plan" :is-detail="true" />
    </div>
    <div style="font-size: 16px; margin-top: 20px; margin-bottom: 10px;" class="main-theme-font-color">{{ $t('reason') }}</div>
    <div v-show="kitInfo.plan.analysisText && kitInfo.plan.analysisText !== ''" class="card reson">
      <span style="width: 100%">{{ kitInfo.plan.analysisText }}</span>
    </div>
    <div class="main-bg" style="position: fixed; width: 100%; bottom: 0px; padding-bottom: 10px;">
      <van-row type="flex" align="center" style="margin-top: 10px;">
        <van-icon name="info-o" color="#F04343" />
        <span style="font-size:12px; color: #9B9B9B; margin-left: 7px;">{{ $t('tacticdetips') }}</span>
      </van-row>
    </div>
  </div>
  <div v-else style="width: 100%; height: 100%;" class="main-bg" />
</template>
<script>

import { Row, Col, Divider, Icon } from 'vant'
import { kitDetail } from '@/api/answer-kit/answerKit'
import RecommendSegmentation from './components/RecommendSegmentation'
export default {
  name: 'KitDetail',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [Icon.name]: Icon,
    RecommendSegmentation
  },
  filters: {

  },
  data() {
    return {
      kitInfo: undefined,
      selectedOption: 1,
      loading: false
    }
  },
  computed: {
    lang(){
      return localStorage.getItem('language')
    },
    // 让球数
    addBall() {
      return parseInt(this.kitInfo.plan.recommend.split(',')[0])
    },
    // 推荐选项 3: 主胜   1: 平局    0: 客胜
    result() {
      const recommend = parseInt(this.kitInfo.plan.recommend.split(',')[0])
      return recommend !== 0 ? parseInt(this.kitInfo.match.rqspf.lotteryResult) : parseInt(this.kitInfo.match.spf.lotteryResult)
    },
    // 预测结果 0为非让球，不是0为让球
    recommend() {
      return parseInt(this.kitInfo.plan.recommend.split(',')[1])
    }
  },
  created() {
    this.setTitle()
    const id = this.$route.query.id
    if (id) {
      this.getKitDetail(id)
    }
  },
  methods: {
    planBigTypeFilter(type) {
      const map = {
        0: this.$t('rq'),
        1: this.$t('hk'),
        2: this.$t('bm'),
        3: this.$t('eur')
      }
      return map[type]
    },
    setTitle() {
      document.title = this.$t('tacticde')
    },
    parseLotteryResult(spf) {
      if (!spf || spf.lotteryResult === null || spf.lotteryResult === undefined) {
        return -1000
      }
      return parseInt(spf.lotteryResult)
    },
    getKitDetail(id) {
      this.loading = true
      kitDetail({ id }).then(response => {
        this.loading = false
        this.kitInfo = response.data
      }).catch(() => {
        this.loading = false
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.option-active {
  text-align: center;
  font-size: 12px;
  background-color: #4A90E2;
}
.option {
  text-align: center;
  font-size: 12px;
  @include color_main_card_bg_2();
}
.option-title {
  color: #9B9B9B;
  font-size: 12px;
}
.option-title-active {
  color: #fff;
  font-size: 12px;
}
.main-container {
  width: 100%;
  min-height: 100%;
  color: #fff;
  padding: 15px 15px 80px 15px;
  @include color_main_bg_2();
}
.plan-big-type {
  color: #1989fa;
  margin-right: 2px;
}
.main-bg {
  @include color_main_bg_2();
}
.vs {
  text-align: center;
  font-size: 30px;
  font-family: DINCondensed-Bold, DINCondensed;
  font-weight: bold;
  line-height: 30px;
  @include color_main_font();
}
.card {
  padding: 10px 10px;
  border-radius: 6px;
  @include color_main_font();
  @include color_main_card_bg_2();
}
.reson {
  word-wrap: break-word;
  font-size: 14px;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: rgba(255,255,255,1);
  line-height: 20px;
}

.rectangle {
  padding: 10px 10px;
  @include color_main_font();
}

.right-border {
  @include color_main_right_border();
}

.rectangle-play {
  padding: 10px 10px;
  border-radius: 10px;
  @include color_main_font();
  @include color_main_card_bg_2();
}

.left-radius {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;;
}

.right-radius {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.right-border {
  @include color_main_right_border();
}
</style>
