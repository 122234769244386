import request from '@/api/httpRequest'

export function gameList(params) {
  return request({
    url: '/app/tactic/streamer/matches',
    method: 'post',
    data: params
  })
}

export function createAnswerKit(params) {
  return request({
    url: '/app/tactic/streamer/add',
    method: 'post',
    data: params
  })
}

export function kitList(params) {
  return request({
    url: '/app/tactic/streamer/list',
    method: 'post',
    data: params
  })
}

export function kitDetail(params) {
  return request({
    url: '/app/tactic/streamer/get',
    method: 'post',
    data: params
  })
}

export function getMatchList(params) {
  return request({
    url: '/admin/tactic/getMatchList',
    method: 'post',
    data: params
  })
}

export function getSwitch(params) {
  return request({
    url: '/app/tactic/getSwitch',
    method: 'post',
    data: params
  })
}

export function getMoneyList(params) {
  return request({
    url: '/app/tactic/getMoneyList',
    method: 'post',
    data: params
  })
}
