<template>
  <div class="app-container">
    <div class="main-content">
      <van-row v-if="kitPermissionStatus === 1" type="flex" align="center">
        <van-col span="12" style="margin-right: 11px;">
          <div class="card">
            <van-row type="flex" align="center" justify="center">
              <img v-show="incomeInfo.ios3X26X26CurrencyZhuboIconUrl" class="feather" :src="incomeInfo.ios3X26X26CurrencyZhuboIconUrl">
              <span class="amount">{{ incomeInfo.settlement }}</span>
            </van-row>
            <van-row style="text-align: center; margin-top: 12px;" class="main-theme-font-color"><span>({{ $t('liveshouyi')+incomeInfo.currencyZhuboName }})</span></van-row>
          </div>
        </van-col>
        <van-col span="12">
          <div class="card">
            <van-row type="flex" align="center" justify="center">
              <img v-show="incomeInfo.ios3X26X26CurrencySilkIconUrl" class="feather" :src="incomeInfo.ios3X26X26CurrencySilkIconUrl">
              <span class="amount">{{ incomeInfo.tacticCoin }}</span>
            </van-row>
            <van-row style="text-align: center; margin-top: 12px;" class="main-theme-font-color"><span>({{ $t('jinnanshouyi')+incomeInfo.currencySilkName }})</span></van-row>
          </div>
        </van-col>
      </van-row>
      <van-row v-else type="flex" align="center">
        <van-col span="24">
          <div class="card">
            <van-row style="text-align: center; margin-bottom: 12px;" class="main-theme-font-color"><span>({{ $t('liveshouyi')+incomeInfo.currencyZhuboName }})</span></van-row>
            <van-row type="flex" align="center" justify="center">
              <img v-show="incomeInfo.ios3X26X26CurrencyZhuboIconUrl" class="feather" :src="incomeInfo.ios3X26X26CurrencyZhuboIconUrl">
              <span class="amount">{{ incomeInfo.settlement }}</span>
            </van-row>
          </div>
        </van-col>
      </van-row>
      <div class="card" style="margin-top: 15px;">
        <router-link :to="{ path: '/income-record'}">
          <van-cell :border="false" title-class="cell-title" :title="$t('earnlist')" is-link />
        </router-link>
        <van-divider :style="{ borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px' }" />
        <router-link :to="{ path: '/settlement-introduction'}">
          <van-cell :border="false" title-class="cell-title" :title="$t('chakanguize')" value-class="cell-value" :value="$t('chakanguize')" is-link />
        </router-link>
        <van-divider :style="{ borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px' }" />
        <van-cell :title="$t('kefuhao')" title-class="cell-title" value-class="weixin" :value="incomeInfo.csCode" />
      </div>
    </div>
  </div>
</template>

<script>
import { CellGroup, Cell, Row, Col, Divider } from 'vant'
import { income } from '@/api/live/income'
import { kitPermissionStatus } from '@/api/anchor'

export default {
  name: 'Income',
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider
  },
  data() {
    return {
      kitPermissionStatus: 0, // 0: 非专家账户 1: 专家账户
      incomeInfo: {}
    }
  },
  mounted() {
    window.load = this.load
  },
  created() {
    this.setTitle()
    this.getKitPermissionStatus()
    this.getIncome()
  },
  methods: {
    setTitle() {
      document.title = '收益记录'
    },
    getKitPermissionStatus() {
      kitPermissionStatus({}).then(response => {
        this.kitPermissionStatus = response.data.status
      })
    },
    getIncome() {
      income({}).then(response => {
        this.incomeInfo = response.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
/deep/.van-row--flex {
  flex-wrap: nowrap;
}
.van-cell--clickable:active {
  background-color: #29292D
}
.van-cell {
  @include color_main_font();
  @include color_main_card_bg();
}
.cell-title {
    font-size: 14px;
}
.cell-value {
  font-size:14px;
  font-family:PingFangSC-Regular,PingFang SC;
  font-weight:400;
  color:rgba(74,144,226,1);
}
.weixin {
  font-size: 14px;
  @include color_main_font();
}
.main-content {
  padding-top: 16px;
  margin-left: 15px;
  margin-right: 15px;
}

.feather {
  width: 24px;
  height: 24px;
}
.amount {
  height:18px;
  font-size:18px;
  font-family:PingFangSC-Semibold,PingFang SC;
  font-weight:600;
  color:rgba(240,207,44,1);
  line-height:18px;
  margin-left: 4px;
}

</style>
