import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../layout/index'

Vue.use(Router)

export const constantRouterMap = [
  {
    path: '/',
    component: Layout,
    redirect: '/about',
    children: [
      {
        path: 'about',
        component: () => import('@/views/about'),
        name: 'About'
      }
    ]
  },
  {
    path: '/privacy-agreement',
    component: () => import('@/views/protocol/privacy-agreement'),
    name: 'PrivacyAgreement',
    meta: { keepAlive: false }
  },
  {
    path: '/user-agreement',
    component: () => import('@/views/protocol/user-agreement'),
    name: 'UserAgreement',
    meta: { keepAlive: false }
  },
  {
    path: '/cooperation-agreement',
    component: () => import('@/views/protocol/cooperation-agreement'),
    name: 'CooperationAgreement',
    meta: { keepAlive: false }
  },
  {
    path: '/recharge-agreement',
    component: () => import('@/views/protocol/recharge-agreement'),
    name: 'RechargeAgreement',
    meta: { keepAlive: false }
  },
  {
    path: '/grade',
    component: () => import('@/views/grade'),
    name: 'Grade',
    meta: { keepAlive: false }
  },
  {
    path: '/income',
    component: () => import('@/views/live/income'),
    name: 'Income',
    meta: { keepAlive: false }
  },
  {
    path: '/income-record',
    component: () => import('@/views/live/income-record'),
    name: 'IncomeRecord',
    meta: { keepAlive: false }
  },
  {
    path: '/fund-record',
    component: () => import('@/views/fund/fund-record'),
    name: 'FundRecord',
    meta: { keepAlive: false }
  },
  {
    path: '/guessing',
    component: () => import('@/views/guessing/guessing'),
    name: 'Guessing',
    meta: {
      keepAlive: false,
      deepth: 1
    }
  },
  {
    path: '/guessing-selection',
    name: 'GuessingSelection',
    component: () => import('@/views/guessing/guessing-selection'),
    meta: {
      keepAlive: true,
      deepth: 2
    }
  },
  {
    path: '/guessing-record',
    component: () => import('@/views/guessing/guessing-record'),
    name: 'GuessingRecord',
    meta: { keepAlive: false }
  },
  {
    path: '/guessing-contribution',
    component: () => import('@/views/guessing/guessing-contribution'),
    name: 'GuessingContribution',
    meta: { keepAlive: false }
  },
  {
    path: '/guessing-addition',
    component: () => import('@/views/guessing/guessing-addition'),
    name: 'GuessingAddition',
    meta: {
      keepAlive: false,
      deepth: 3
    }
  },
  {
    path: '/guessing-editing',
    component: () => import('@/views/guessing/guessing-editing'),
    name: 'GuessingEditing',
    meta: {
      keepAlive: false,
      deepth: 3
    }
  },
  {
    path: '/app-dowload',
    component: () => import('@/views/dowload/app-dowload'),
    name: 'AppDowload',
    meta: { keepAlive: false }
  },
  {
    path: '/anchor',
    component: () => import('@/views/anchor'),
    name: 'Anchor',
    meta: { keepAlive: false }
  },
  {
    path: '/live-setting',
    component: () => import('@/views/live-setting')
  },
  {
    path: '/channel-selection',
    component: () => import('@/views/live-setting/channel-selection')
  },
  {
    path: '/room-password-setting',
    component: () => import('@/views/live-setting/room-password-setting')
  },
  {
    path: '/marquee-setting',
    component: () => import('@/views/live-setting/marquee-setting')
  },
  {
    path: '/game-list',
    component: () => import('@/views/live-setting/game-list')
  },
  {
    path: '/appointment-game-list',
    component: () => import('@/views/live-setting/appointment-game-list')
  },
  {
    path: '/appointment-game-search',
    component: () => import('@/views/live-setting/appointment-game-search')
  },
  {
    path: '/game-index',
    component: () => import('@/views/game/game-index')
  },
  {
    path: '/kit-list',
    component: () => import('@/views/answer-kit/kit-list'),
    name: 'KitList',
    meta: {
      keepAlive: true,
      deepth: 1
    }
  },
  {
    path: '/kit-detail',
    component: () => import('@/views/answer-kit/kit-detail')
  },
  {
    name: 'KitAddition',
    path: '/kit-addition',
    component: () => import('@/views/answer-kit/kit-addition')
  },
  {
    path: '/game-selection',
    component: () => import('@/views/answer-kit/game-selection'),
    name: 'GameSelection',
    meta: {
      keepAlive: true,
      deepth: 1
    }
  },
  {
    path: '/settlement-introduction',
    component: () => import('@/views/guide/settlement-introduction'),
    name: 'SettlementIntroduction',
    meta: { keepAlive: false }
  },
  {
    path: '/cover-introduction',
    component: () => import('@/views/guide/cover-introduction'),
    name: 'CoverIntroduction',
    meta: { keepAlive: false }
  },
  {
    path: '/virtual-currency-agreement',
    component: () => import('@/views/protocol/virtual-currency-agreement'),
    name: 'VirtualCurrencyAgreement',
    meta: { keepAlive: false }
  }

]

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
