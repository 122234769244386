var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c("div", { staticClass: "main-content" }, [
        _c(
          "div",
          { staticClass: "card content-wrapper" },
          [
            _c("van-field", {
              attrs: {
                rows: "9",
                autosize: "",
                type: "textarea",
                maxlength: "200",
                placeholder: _vm.$t("qingshurupaomadengneirong"),
                "show-word-limit": "",
              },
              model: {
                value: _vm.content,
                callback: function ($$v) {
                  _vm.content = $$v
                },
                expression: "content",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bottom" },
              [
                _c("span", [_vm._v(_vm._s(_vm.$t("paomadengkaiguan")))]),
                _vm._v(" "),
                _c("van-switch", {
                  staticStyle: { "margin-right": "2px" },
                  attrs: {
                    size: "20px",
                    "active-color": "#4CD964",
                    "inactive-color": "#9A9B9C",
                  },
                  on: { change: _vm.switchChange },
                  model: {
                    value: _vm.isOn,
                    callback: function ($$v) {
                      _vm.isOn = $$v
                    },
                    expression: "isOn",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }