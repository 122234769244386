var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c(
        "van-sticky",
        [
          _c(
            "van-row",
            {
              staticClass: "default-background",
              staticStyle: {
                "padding-top": "15px",
                "padding-bottom": "15px",
                "padding-right": "15px",
              },
              attrs: { type: "flex", justify: "end" },
            },
            [
              _c(
                "router-link",
                { attrs: { to: { path: "/guessing-record" } } },
                [
                  _c("img", {
                    staticStyle: {
                      width: "18px",
                      height: "18px",
                      "margin-right": "15px",
                    },
                    attrs: { src: _vm.$theme.getRecordImage() },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { path: "/guessing-contribution" } } },
                [
                  _c("img", {
                    staticStyle: { width: "18px", height: "18px" },
                    attrs: { src: _vm.$theme.getHelpImage() },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.list.length > 0
        ? _c(
            "div",
            { staticClass: "main-content" },
            [
              _vm._l(_vm.list, function (item) {
                return _c("guessing-cell", {
                  key: item.topic,
                  attrs: { data: item },
                  on: {
                    terminate: function ($event) {
                      return _vm.terminate(item)
                    },
                    stop: function ($event) {
                      return _vm.stop(item)
                    },
                  },
                })
              }),
              _vm._v(" "),
              _c("div", { staticClass: "bottom" }, [
                _c("div", { staticClass: "content" }, [
                  _c("img", {
                    staticStyle: { width: "17px", height: "15px" },
                    attrs: { src: _vm.$theme.getBatchImage() },
                  }),
                  _vm._v(" "),
                  _c("span", { on: { click: _vm.handleBatch } }, [
                    _vm._v(_vm._s(_vm.$t("End_of_batch"))),
                  ]),
                ]),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.list.length === 0 && !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "addition-content" },
            [
              _c(
                "van-empty",
                {
                  staticClass: "empty-image",
                  staticStyle: { "padding-top": "50%" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { width: "100%", "text-align": "center" },
                      attrs: { slot: "image" },
                      slot: "image",
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "257px", height: "84px" },
                        attrs: { src: require("@/assets/images/empty.png") },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { slot: "description" },
                      slot: "description",
                    },
                    [
                      _c("div", [_vm._v(_vm._s(_vm.$t("hint1")))]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(_vm.$t("hint2")))]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "bottom" },
                [
                  _c(
                    "van-button",
                    {
                      staticClass: "publishing-button",
                      on: { click: _vm.handlePublish },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("issuing_prophecy")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: { path: "/guessing-addition" } } },
                    [
                      _c("van-button", { staticClass: "addition-button" }, [
                        _vm._v(_vm._s(_vm.$t("add_theme"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "van-popup",
        {
          ref: "container",
          staticClass: "main-bg-2",
          style: { maxHeight: "80%" },
          attrs: { round: true, position: "bottom" },
          on: { close: _vm.popupClose },
          model: {
            value: _vm.showConfirmingPopup,
            callback: function ($$v) {
              _vm.showConfirmingPopup = $$v
            },
            expression: "showConfirmingPopup",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.confirmingSteps === 1,
                  expression: "confirmingSteps === 1",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "20px",
                    color: "#fff",
                    "font-size": "14px",
                  },
                },
                [
                  _c(
                    "van-row",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: {
                        type: "flex",
                        justify: "center",
                        align: "center",
                      },
                    },
                    [
                      _c("span", { staticClass: "main-theme-font-color" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("submit_the_results")) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticStyle: {
                          position: "absolute",
                          right: "20px",
                          width: "24px",
                          height: "24px",
                        },
                        attrs: { src: require("@/assets/images/close.png") },
                        on: {
                          click: function ($event) {
                            _vm.showConfirmingPopup = false
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "description" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("hint8")) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "description" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("hint9")) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.answerList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.topicId,
                        staticStyle: { "margin-top": "20px" },
                      },
                      [
                        _c("p", [_vm._v(_vm._s(item.topic))]),
                        _vm._v(" "),
                        _c("cube-checker", {
                          staticStyle: {
                            "font-size": "16px",
                            "margin-top": "30px",
                          },
                          attrs: {
                            options: item.options,
                            type: "radio",
                            max: 1,
                          },
                          model: {
                            value: item.optionItem,
                            callback: function ($$v) {
                              _vm.$set(item, "optionItem", $$v)
                            },
                            expression: "item.optionItem",
                          },
                        }),
                        _vm._v(" "),
                        _c("van-divider", {
                          style: {
                            borderColor: _vm.$theme.getDividerBorderColor(),
                            marginTop: "20px",
                            marginBottom: "0px",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "main-bg-2",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center",
                    "padding-top": "20px",
                    "padding-bottom": "20px",
                  },
                },
                [
                  _c(
                    "van-button",
                    {
                      staticClass: "button-primary",
                      staticStyle: { width: "283px" },
                      attrs: {
                        disabled:
                          _vm.selectedAnswerCount !== _vm.answerList.length,
                      },
                      on: { click: _vm.nextStep },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("submit1")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.confirmingSteps === 2,
                  expression: "confirmingSteps === 2",
                },
              ],
            },
            [
              _c(
                "div",
                { staticStyle: { padding: "20px", "font-size": "14px" } },
                [
                  _c(
                    "van-row",
                    {
                      staticStyle: { "margin-bottom": "20px" },
                      attrs: {
                        type: "flex",
                        justify: "center",
                        align: "center",
                      },
                    },
                    [
                      _c("van-icon", {
                        staticStyle: { position: "absolute", left: "20px" },
                        attrs: {
                          name: "arrow-left",
                          color: _vm.$theme.getBackIconColor(),
                          size: "22",
                        },
                        on: {
                          click: function ($event) {
                            _vm.confirmingSteps = 1
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "main-theme-font-color" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("confirming_the_prophecy")) +
                            "\n          "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.answerList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.topic,
                        staticStyle: { "margin-top": "20px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "card border",
                            staticStyle: {
                              padding: "30px 20px",
                              "border-radius": "8px",
                            },
                          },
                          [
                            _c(
                              "van-row",
                              {
                                attrs: {
                                  type: "flex",
                                  justify: "space-between",
                                  align: "center",
                                },
                              },
                              [
                                _c("span", [_vm._v(_vm._s(item.topic))]),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(item.optionItem))]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "main-bg-2",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "align-items": "center",
                    "padding-top": "20px",
                    "padding-bottom": "20px",
                  },
                },
                [
                  _c(
                    "van-button",
                    {
                      staticClass: "button-primary",
                      staticStyle: { width: "283px" },
                      attrs: { disabled: _vm.answerList.length === 0 },
                      on: { click: _vm.handleCommit },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("submit1")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }