<template>
  <div>
    <div v-if="type === 1">
      <van-row>
        <van-col span="12" @click="optionChange(0)">
          <div style="position: relative;" class="rectangle left-radius right-border" :class="selectedOption === 0 ? 'option-active' : 'option'">
            <img v-if="result === 0" style="position: absolute; top: 0px; left: 0px; width: 30px; height: 30px;" src="@/assets/images/kit/right_icon.png">
            <div :class="selectedOption === 0 ? 'option-title-active' : 'option-title'">
              <div v-if="data">
                <p style="margin-bottom: 4px;">{{$t('zhusheng')}}{{ getZhuSheng(data.realOdds[1]) }}</p>
                <p>{{ data.realOdds[0] }}</p>
              </div>
              <span v-else>-</span>
            </div>
          </div>
        </van-col>
        <van-col span="12" @click="optionChange(1)">
          <div style="position: relative;" class="rectangle right-radius" :class="selectedOption === 1 ? 'option-active' : 'option'">
            <img v-if="result === 1" style="position: absolute; top: 0px; left: 0px; width: 30px; height: 30px;" src="@/assets/images/kit/right_icon.png">
            <div :class="selectedOption === 1 ? 'option-title-active' : 'option-title'">
              <div v-if="data">
                <p style="margin-bottom: 4px;">{{$t('kesheng')}}{{ getKeSheng(data.realOdds[1]) }}</p>
                <p>{{ data.realOdds[2] }}</p>
              </div>
              <span v-else>-</span>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
    <div v-if="type === 2">
      <van-row>
        <van-col span="12" @click="optionChange(0)">
          <div style="position: relative;" class="rectangle left-radius right-border" :class="selectedOption === 0 ? 'option-active' : 'option'">
            <img v-if="result === 0" style="position: absolute; top: 0px; left: 0px; width: 30px; height: 30px;" src="@/assets/images/kit/right_icon.png">
            <div :class="selectedOption === 0 ? 'option-title-active' : 'option-title'">
              <div v-if="data">
                <p style="margin-bottom: 4px;"> {{ $t('bigqiu', {msg:data.realOdds[1]}) }}</p>
                <p>{{ data.realOdds[0] }}</p>
              </div>
              <span v-else>-</span>
            </div>
          </div>
        </van-col>
        <van-col span="12" @click="optionChange(1)">
          <div style="position: relative;" class="rectangle right-radius" :class="selectedOption === 1 ? 'option-active' : 'option'">
            <img v-if="result === 1" style="position: absolute; top: 0px; left: 0px; width: 30px; height: 30px;" src="@/assets/images/kit/right_icon.png">
            <div :class="selectedOption === 1 ? 'option-title-active' : 'option-title'">
              <div v-if="data">
                <p style="margin-bottom: 4px;">{{ $t('smallqiu', {msg:data.realOdds[1]}) }}</p>
                <p>{{ data.realOdds[2] }}</p>
              </div>
              <span v-else>-</span>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
    <div v-if="type === 3">
      <van-row>
        <van-col span="8" @click="optionChange(0)">
          <div style="position: relative;" class="rectangle left-radius right-border" :class="selectedOption === 0 ? 'option-active' : 'option'">
            <img v-if="result === 0" style="position: absolute; top: 0px; left: 0px; width: 30px; height: 30px;" src="@/assets/images/kit/right_icon.png">
            <div :class="selectedOption === 0 ? 'option-title-active' : 'option-title'">
              <div v-if="data">
                <p style="margin-bottom: 4px;">{{$t('zhusheng')}}</p>
                <p>{{ data.realOdds[0] }}</p>
              </div>
              <span v-else>-</span>
            </div>
          </div>
        </van-col>
        <van-col span="8" @click="optionChange(1)" v-if="gameType===1">
          <div style="position: relative;" class="rectangle right-border" :class="selectedOption === 1 ? 'option-active' : 'option'">
            <img v-if="result === 1" style="position: absolute; top: 0px; left: 0px; width: 30px; height: 30px;" src="@/assets/images/kit/right_icon.png">
            <div :class="selectedOption === 1 ? 'option-title-active' : 'option-title'">
              <div v-if="data">
                <p style="margin-bottom: 4px;">{{ $t('pingju') }}</p>
                <p>{{ data.realOdds[1] }}</p>
              </div>
              <span v-else>-</span>
            </div>
          </div>
        </van-col>
        <van-col span="8" @click="optionChange(2)">
          <div style="position: relative;" class="rectangle right-radius" :class="selectedOption === 2 ? 'option-active' : 'option'">
            <img v-if="result === 2" style="position: absolute; top: 0px; left: 0px; width: 30px; height: 30px;" src="@/assets/images/kit/right_icon.png">
            <div :class="selectedOption === 2 ? 'option-title-active' : 'option-title'">
              <div v-if="data">
                <p style="margin-bottom: 4px;">{{$t('kesheng')}}</p>
                <p>{{ data.realOdds[2] }}</p>
              </div>
              <span v-else>-</span>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import { Row, Col } from 'vant'

export default {
  name: 'MethodSegmentation',
  components: {
    [Row.name]: Row,
    [Col.name]: Col
  },
  props: {
    type: {
      type: Number,
      default: -1
    },
    gameType: {
      type: Number,
      default: 1
    },
    data: {
      type: Object,
      default: undefined
    },
    planInfo: {
      type: Object,
      default: undefined
    },
    matchInfo: {
      type: Object,
      default: undefined
    },
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOption: -1,
      result: -1
    }
  },
  watch: {
    planInfo: {
      handler(val, oldVal) {
        if (this.isDetail) {
          const list = val.recommend.split(',')
          this.selectedOption = list.length > 0 ? parseInt(list[list.length - 1]) : -1
        } else {
          this.selectedOption = -1
        }
      },
      immediate: true
    },
    matchInfo: {
      handler(val, oldVal) {
        if (this.isDetail) {
          this.result = parseInt(this.data.lotteryResult)
          if (this.type === 1 || this.type === 2) {
            const lotteryResult = parseInt(this.data.lotteryResult)
            if (lotteryResult === 2) {
              this.result = 1
            } else if (lotteryResult === 1) {
              this.result = -1
            }
          }
        }
      },
      immediate: true
    },
    data(val) {
      // this.selectedOption = 0
      this.selectedOption = -1
    }
  },
  methods: {
    getZhuSheng(value) {
      if ((value + '') === '-0' || (value + '') === '-0.0') return '+0'
      if ((value + '') === '0' || (value + '') === '0.0') return '-0'
      return -parseFloat(value) > 0 ? '+' + (-parseFloat(value)) : -parseFloat(value)
    },
    getKeSheng(value) {
      if ((value + '') === '-0' || (value + '') === '-0.0') return '-0'
      if ((value + '') === '0' || (value + '') === '0.0') return '+0'
      return parseFloat(value) > 0 ? '+' + value : value
    },
    optionChange(index) {
      if (!this.data || this.isDetail) return
      this.selectedOption = index
      this.$emit('change', index)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.option-active {
  text-align: center;
  font-size: 12px;
  background-color: #4A90E2;
}
.option {
  text-align: center;
  font-size: 12px;
  @include color_main_card_bg_2();
}
.option-title {
  color: #9B9B9B;
  font-size: 12px;
}
.option-title-active {
  color: #fff;
  font-size: 12px;
}
.rectangle {
  padding: 10px 10px;
  @include color_main_font();
}
.right-border {
  @include color_main_right_border();
}
.left-radius {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;;
}
.right-radius {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>
