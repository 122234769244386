var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c("van-sticky", { attrs: { "offset-top": 0 } }, [
        _c(
          "div",
          { staticClass: "sticky", staticStyle: { "padding-top": "10px" } },
          [
            _c(
              "van-tabs",
              {
                attrs: {
                  swipeable: "",
                  type: "card",
                  color: "#63676B",
                  "title-inactive-color": "#9B9B9B",
                  sticky: "",
                },
                model: {
                  value: _vm.activeIndex,
                  callback: function ($$v) {
                    _vm.activeIndex = $$v
                  },
                  expression: "activeIndex",
                },
              },
              [
                _c("van-tab", { attrs: { title: _vm.$t("fb") } }),
                _vm._v(" "),
                _c("van-tab", { attrs: { title: _vm.$t("bk") } }),
                _vm._v(" "),
                _c("van-tab", { attrs: { title: _vm.$t("reserved") } }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "van-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTabTitle !== _vm.$t("reserved"),
                    expression: "activeTabTitle !== $t('reserved')",
                  },
                ],
                staticStyle: {
                  "margin-top": "0px",
                  "margin-right": "10px",
                  flex: "none",
                },
                attrs: { type: "flex", align: "center" },
              },
              [
                _c("img", {
                  staticStyle: {
                    float: "left",
                    width: "16px",
                    height: "16px",
                    "margin-left": "20px",
                  },
                  attrs: {
                    src: require("@/assets/images/live-setting/search_icon.png"),
                  },
                  on: { click: _vm.search },
                }),
                _vm._v(" "),
                _c(
                  "van-tabs",
                  {
                    staticStyle: { display: "block" },
                    attrs: {
                      color: _vm.$theme.getTabsUnderlineColor(),
                      "title-inactive-color": "#9B9B9B",
                      "title-active-color": "#fff",
                      "line-width": "22px",
                    },
                    model: {
                      value: _vm.dateActiveIndex,
                      callback: function ($$v) {
                        _vm.dateActiveIndex = $$v
                      },
                      expression: "dateActiveIndex",
                    },
                  },
                  _vm._l(_vm.tabs, function (item, index) {
                    return _c("van-tab", {
                      key: index,
                      attrs: { title: item.name },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeTabTitle !== _vm.$t("reserved"),
              expression: "activeTabTitle !== $t('reserved')",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "main-content" },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": _vm.finishedText,
                  },
                  on: { load: _vm.getList },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                [
                  _c(
                    "van-checkbox-group",
                    {
                      model: {
                        value: _vm.checkedList,
                        callback: function ($$v) {
                          _vm.checkedList = $$v
                        },
                        expression: "checkedList",
                      },
                    },
                    [
                      _c(
                        "van-cell-group",
                        _vm._l(_vm.list, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticStyle: { "margin-bottom": "10px" },
                            },
                            [
                              _c(
                                "van-cell",
                                {
                                  attrs: { clickable: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cellClicked(item, index)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "card" },
                                    [
                                      _c(
                                        "van-row",
                                        {
                                          attrs: {
                                            type: "flex",
                                            justify: "space-between",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "main-theme-font-color",
                                              staticStyle: {
                                                "max-width": "80%",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(item.eventName) +
                                                  "\n                      "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        item.roundId !== "0",
                                                      expression:
                                                        "item.roundId !== '0'",
                                                    },
                                                  ],
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t("dijilun", {
                                                          num: item.roundId,
                                                        })
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(item.matchTime) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("van-checkbox", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.isBook !== 1,
                                                expression: "item.isBook !== 1",
                                              },
                                            ],
                                            ref: "checkboxes",
                                            refInFor: true,
                                            attrs: { name: item },
                                          }),
                                          _vm._v(" "),
                                          _c("van-icon", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.isBook === 1,
                                                expression: "item.isBook === 1",
                                              },
                                            ],
                                            attrs: {
                                              name: "checked",
                                              size: "22px",
                                              color: "#3D679A",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "van-row",
                                        {
                                          staticStyle: { "margin-top": "20px" },
                                          attrs: {
                                            type: "flex",
                                            align: "center",
                                          },
                                        },
                                        [
                                          item.homeLogo && item.homeLogo !== ""
                                            ? _c("img", {
                                                staticStyle: {
                                                  width: "26px",
                                                  height: "26px",
                                                  "border-radius": "13px",
                                                  border:
                                                    "1px solid rgba(255, 255, 255, 1)",
                                                  "margin-right": "10px",
                                                },
                                                attrs: { src: item.homeLogo },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !item.homeLogo || item.homeLogo === ""
                                            ? _c("img", {
                                                staticStyle: {
                                                  width: "26px",
                                                  height: "26px",
                                                  "border-radius": "13px",
                                                  border:
                                                    "1px solid rgba(255, 255, 255, 1)",
                                                  "margin-right": "10px",
                                                },
                                                attrs: {
                                                  src: require("@/assets/images/team_logo_default.png"),
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "main-theme-font-color",
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(item.homeName) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("img", {
                                            staticStyle: {
                                              width: "22px",
                                              height: "25px",
                                              "margin-left": "20px",
                                              "margin-right": "20px",
                                            },
                                            attrs: {
                                              src: _vm.$theme.getVsImage(),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "main-theme-font-color",
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(item.awayName) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          item.awayLogo && item.awayLogo !== ""
                                            ? _c("img", {
                                                staticStyle: {
                                                  width: "26px",
                                                  height: "26px",
                                                  "border-radius": "13px",
                                                  border:
                                                    "1px solid rgba(255, 255, 255, 1)",
                                                  "margin-left": "10px",
                                                  "margin-right": "10px",
                                                },
                                                attrs: { src: item.awayLogo },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !item.awayLogo || item.awayLogo === ""
                                            ? _c("img", {
                                                staticStyle: {
                                                  width: "26px",
                                                  height: "26px",
                                                  "border-radius": "13px",
                                                  border:
                                                    "1px solid rgba(255, 255, 255, 1)",
                                                  "margin-left": "10px",
                                                  "margin-right": "10px",
                                                },
                                                attrs: {
                                                  src: require("@/assets/images/team_logo_default.png"),
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.loading && _vm.list.length === 0
                ? _c(
                    "van-empty",
                    {
                      staticClass: "empty-image",
                      staticStyle: { "margin-top": "40%" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            "text-align": "center",
                          },
                          attrs: { slot: "image" },
                          slot: "image",
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "257px", height: "84px" },
                            attrs: {
                              src: require("@/assets/images/empty.png"),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { slot: "description" },
                          slot: "description",
                        },
                        [_c("div", [_vm._v(_vm._s(_vm.$t("nomatch")))])]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "bottom" }, [
            _c(
              "div",
              { staticClass: "row", staticStyle: { "padding-top": "10px" } },
              [
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("zuiduiyuyue")) +
                      "（" +
                      _vm._s(
                        // $t('choice') + checkedList.length + bookCount
                        _vm.$t("choice") + _vm.checkedList.length
                      ) +
                      ")\n        "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row", staticStyle: { "padding-top": "10px" } },
              [
                _c(
                  "van-button",
                  {
                    staticClass: "button-primary",
                    staticStyle: { width: "80%", "border-radius": "6px" },
                    attrs: { disabled: _vm.checkedList.length === 0 },
                    on: { click: _vm.commit },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("confirm")) + "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("my-appointment-list", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeTabTitle === _vm.$t("reserved"),
            expression: "activeTabTitle === $t('reserved')",
          },
        ],
        ref: "myAppointment",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }