<template>
  <div class="app-container" :data-theme="$store.getters.themeType">
    <van-sticky :offset-top="0">
      <div style="padding-top: 10px;" class="main-bg">
        <van-tabs v-model="activeIndex" swipeable type="card" color="#63676B" title-inactive-color="#9B9B9B" sticky @change="tabChange">
          <van-tab :title="$t('hotrace')" />
          <van-tab :title="$t('yiyuyue')" />
        </van-tabs>
      </div>
    </van-sticky>
    <div class="main-content">
      <van-radio-group v-model="selectedGame">
        <van-cell-group>
          <div v-for="(item,index) in selectedList" :key="index" style="margin-bottom: 20px;">
            <van-cell clickable @click="selectedGame = item">
              <div v-if="activeIndex === 0" class="card">
                <van-row type="flex" justify="space-between" align="center">
                  <div style="max-width: 90%;" class="main-theme-font-color">
                    {{ item.leagueName }}{{ item.round === '0' ? '' : item.round }} {{ item.startTime }}
                  </div>
                  <van-radio :name="item" />
                </van-row>
                <van-row type="flex" align="center" style="margin-top: 20px;">
                  <img v-if="item.homeTeamLogo && item.homeTeamLogo !== ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid #EEF0F5; margin-right: 10px;" :src="item.homeTeamLogo">
                  <img v-if="!item.homeTeamLogo || item.homeTeamLogo === ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid #EEF0F5; margin-right: 10px;" src="@/assets/images/team_logo_default.png">
                  <span style="font-size: 12px;" class="main-theme-font-color">{{ item.homeTeam }}</span>
                  <img style="width: 22px; height: 25px; margin-left: 20px; margin-right: 20px;" :src="$theme.getVsImage()">
                  <span style="font-size: 12px;" class="main-theme-font-color">{{ item.awayTeam }}</span>
                  <img v-if="item.awayTeamLogo && item.awayTeamLogo !== ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid #EEF0F5; margin-left: 10px; margin-right: 10px;" :src="item.awayTeamLogo">
                  <img v-if="!item.awayTeamLogo || item.awayTeamLogo === ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid #EEF0F5; margin-left: 10px; margin-right: 10px;" src="@/assets/images/team_logo_default.png">
                </van-row>
              </div>
              <div v-else class="card">
                <van-row type="flex" justify="space-between" align="center">
                  <div style="max-width: 90%;" class="main-theme-font-color">
                    {{ item.eventName }}{{ item.roundId === '0' ? '' : '第' + item.roundId + '轮' }} {{ item.matchTime }}
                  </div>
                  <van-radio :name="item" />
                </van-row>
                <van-row type="flex" align="center" style="margin-top: 20px;">
                  <img v-if="item.homeLogo && item.homeLogo !== ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid #EEF0F5; margin-right: 10px;" :src="item.homeLogo">
                  <img v-if="!item.homeLogo || item.homeLogo === ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid #EEF0F5; margin-right: 10px;" src="@/assets/images/team_logo_default.png">
                  <span style="font-size: 12px;" class="main-theme-font-color">{{ item.homeName }}</span>
                  <img style="width: 22px; height: 25px; margin-left: 20px; margin-right: 20px;" :src="$theme.getVsImage()">
                  <span style="font-size: 12px;" class="main-theme-font-color">{{ item.awayName }}</span>
                  <img v-if="item.awayLogo && item.awayLogo !== ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid #EEF0F5; margin-left: 10px; margin-right: 10px;" :src="item.awayLogo">
                  <img v-if="!item.awayLogo || item.awayLogo === ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid #EEF0F5; margin-left: 10px; margin-right: 10px;" src="@/assets/images/team_logo_default.png">
                </van-row>
              </div>
            </van-cell>
          </div>
        </van-cell-group>
      </van-radio-group>
      <van-empty
        v-if="selectedList.length === 0"
        class="empty-image"
        style="margin-top: 40%;"
      >
        <div slot="image" style="width: 100%; text-align: center">
          <img style="width: 257px; height: 84px;" src="@/assets/images/empty.png">
        </div>
        <div slot="description" style="text-align: center;">
          <div>{{ $t('nomatch') }}</div>
        </div>
      </van-empty>
    </div>
    <div class="bottom">
      <div class="row" style="padding-top: 10px;">
        <van-button class="button-primary" style="width: 80%; border-radius:6px;" :disabled="!selectedGame" @click="commit">{{ $t('querenbtn') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>

import { Sticky, Row, Button, Divider, RadioGroup, Radio, Empty, Toast, CellGroup, Cell, Tab, Tabs } from 'vant'
import { gameList, setLiveConfig, fetchMyAppointmentList } from '@/api/live-setting/index'

export default {
  name: 'GuessingSelection',
  components: {
    [Button.name]: Button,
    [Row.name]: Row,
    [Divider.name]: Divider,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Empty.name]: Empty,
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast
  },
  data() {
    return {
      list: [],
      myAppointmentList: [],
      selectedList: [],
      activeIndex: 0,
      selectedGame: undefined
    }
  },
  created() {
    this.setTitle()
    this.getList()
    this.getMyAppointmentList()
  },
  methods: {
    setTitle() {
      document.title = ''
    },
    tabChange(index, title) {
      if (this.activeIndex === 0) {
        this.selectedList = this.list
      } else {
        this.selectedList = this.myAppointmentList
      }
    },
    getList() {
      gameList({ channelId: this.$store.getters.liveConfig.channelId, subChannelId: this.$store.getters.liveConfig.subChannelId }).then(response => {
        this.list = response.data
        if (this.activeIndex === 0) this.selectedList = this.list
      })
    },
    getMyAppointmentList() {
      fetchMyAppointmentList({ channelId: this.$store.getters.liveConfig.channelId, subChannelId: this.$store.getters.liveConfig.subChannelId, matchIsLiving: true }).then(response => {
        this.myAppointmentList = response.data[0]['list']
        if (this.activeIndex === 1) this.selectedList = this.myAppointmentList
      })
    },
    commit() {
      const matchId = this.activeIndex === 0 ? this.selectedGame.matchId : this.selectedGame.id
      setLiveConfig({ channelId: this.$store.getters.liveConfig.channelId, subChannelId: this.$store.getters.liveConfig.subChannelId, matchId: matchId, status: 1 }).then(response => {
        this.$router.go(-1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.main-content {
  @include color_main_bg();
  width: 100%;
  min-height: 90vh;
  overflow: scroll;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 140px;
}
.van-cell {
  padding: 0px 0px;
  @include color_main_bg();
}
.van-cell-group {
  padding: 0px 0px;
  @include color_main_bg();
}
.van-hairline--top-bottom::after {
    border-width: 0px 0;
}
.card {
  padding: 20px 10px;
  border-radius: 6px;
  color: #fff;
  @include color_main_card_bg();
}
.bottom {
  position: fixed;
  width: 100%;
  height: 80px;
  color: #fff;
  bottom: 35px;
  @include color_main_bg();
}
/deep/.empty-image .van-empty__image {
  width: 257px;
  height: 84px;
}
/deep/.van-tabs__nav--card {
  @include van_tabs_card();
  margin: 0 10px;
  border-radius: 6px;
  padding: 1px;

}
/deep/.van-tab {
  @include van_tab();
  border-radius: 6px;
}
/deep/.van-tab--active {
  @include van_tab_active();
}
</style>
