var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("guessing-detail", {
        ref: "detail",
        attrs: { "is-edit": false },
        on: { change: _vm.change },
      }),
      _vm._v(" "),
      _c("alert", {
        attrs: {
          visible: _vm.showAlert,
          message: "确定退出预言主题添加吗?",
          "cancel-button-text": "取消",
          "confirm-button-text": "确认",
        },
        on: {
          "update:visible": function ($event) {
            _vm.showAlert = $event
          },
          confirm: _vm.confirm,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }