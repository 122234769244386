var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _vm.userGradeInfo || _vm.anchorGradeInfo
        ? _c(
            "div",
            { staticClass: "main-content" },
            [
              _c("grade-info", {
                attrs: { "is-anchor": true, "grade-info": _vm.anchorGradeInfo },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }