import Vue from 'vue'

// By default we import all the components.
// Only reserve the components on demand and remove the rest.
// Style is always required.
import {
  /* eslint-disable no-unused-vars */
  Style,
  // form
  Checker,
  Input
} from 'cube-ui'

Vue.use(Checker)
Vue.use(Input)
