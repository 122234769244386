exports = module.exports = require("../../node_modules/_css-loader@1.0.1@css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#ccc",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#1F2124",
	"menuHover": "#5D85F4",
	"subMenuBg": "#000",
	"subMenuHover": "#5D85F4",
	"sideBarWidth": "210px",
	"theme": "#1A1A1C"
};