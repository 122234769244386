<template>
  <div class="app-container" :data-theme="$store.getters.themeType">
    <van-sticky>
      <van-row
        type="flex"
        justify="end"
        class="default-background"
        style="padding-top: 15px; padding-bottom: 15px; padding-right: 15px"
      >
        <router-link :to="{ path: '/guessing-record' }">
          <img
            style="width: 18px; height: 18px; margin-right: 15px"
            :src="$theme.getRecordImage()"
          />
        </router-link>
        <router-link :to="{ path: '/guessing-contribution' }">
          <img style="width: 18px; height: 18px" :src="$theme.getHelpImage()" />
        </router-link>
      </van-row>
    </van-sticky>
    <div v-if="list.length > 0" class="main-content">
      <guessing-cell
        v-for="item in list"
        :key="item.topic"
        :data="item"
        @terminate="terminate(item)"
        @stop="stop(item)"
      />
      <div class="bottom">
        <div class="content">
          <img
            style="width: 17px; height: 15px"
            :src="$theme.getBatchImage()"
          />
          <span @click="handleBatch">{{ $t('End_of_batch') }}</span>
        </div>
      </div>
    </div>
    <div v-if="list.length === 0 && !isLoading" class="addition-content">
      <van-empty class="empty-image" style="padding-top: 50%">
        <div slot="image" style="width: 100%; text-align: center">
          <img
            style="width: 257px; height: 84px"
            src="@/assets/images/empty.png"
          />
        </div>
        <div slot="description" style="text-align: center">
          <div>{{ $t('hint1') }}</div>
          <div>{{ $t('hint2') }}</div>
        </div>
      </van-empty>
      <div class="bottom">
        <van-button class="publishing-button" @click="handlePublish">
          {{ $t('issuing_prophecy') }}
        </van-button>
        <router-link :to="{ path: '/guessing-addition' }">
          <van-button class="addition-button">{{ $t('add_theme') }}</van-button>
        </router-link>
      </div>
    </div>
    <van-popup
      ref="container"
      v-model="showConfirmingPopup"
      :round="true"
      position="bottom"
      :style="{ maxHeight: '80%' }"
      class="main-bg-2"
      @close="popupClose"
    >
      <div v-show="confirmingSteps === 1">
        <div style="padding: 20px; color: #fff; font-size: 14px">
          <van-row
            type="flex"
            justify="center"
            align="center"
            style="margin-bottom: 20px"
          >
            <span class="main-theme-font-color">
              {{ $t('submit_the_results') }}
            </span>
            <img
              style="position: absolute; right: 20px; width: 24px; height: 24px"
              src="@/assets/images/close.png"
              @click="showConfirmingPopup = false"
            />
          </van-row>
          <p class="description">
            {{ $t('hint8') }}
          </p>
          <p class="description">
            {{ $t('hint9') }}
          </p>
          <div
            v-for="item in answerList"
            :key="item.topicId"
            style="margin-top: 20px"
          >
            <p>{{ item.topic }}</p>
            <cube-checker
              v-model="item.optionItem"
              :options="item.options"
              type="radio"
              :max="1"
              style="font-size: 16px; margin-top: 30px"
            />
            <van-divider
              :style="{
                borderColor: $theme.getDividerBorderColor(),
                marginTop: '20px',
                marginBottom: '0px'
              }"
            />
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
          "
          class="main-bg-2"
        >
          <van-button
            :disabled="selectedAnswerCount !== answerList.length"
            class="button-primary"
            style="width: 283px"
            @click="nextStep"
          >
            {{ $t('submit1') }}
          </van-button>
        </div>
      </div>
      <div v-show="confirmingSteps === 2">
        <div style="padding: 20px; font-size: 14px">
          <van-row
            type="flex"
            justify="center"
            align="center"
            style="margin-bottom: 20px"
          >
            <van-icon
              name="arrow-left"
              :color="$theme.getBackIconColor()"
              size="22"
              style="position: absolute; left: 20px"
              @click="confirmingSteps = 1"
            />
            <span class="main-theme-font-color">
              {{ $t('confirming_the_prophecy') }}
            </span>
          </van-row>
          <div
            v-for="item in answerList"
            :key="item.topic"
            style="margin-top: 20px"
          >
            <div
              class="card border"
              style="padding: 30px 20px; border-radius: 8px"
            >
              <van-row type="flex" justify="space-between" align="center">
                <span>{{ item.topic }}</span>
                <span>{{ item.optionItem }}</span>
              </van-row>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
          "
          class="main-bg-2"
        >
          <van-button
            :disabled="answerList.length === 0"
            class="button-primary"
            style="width: 283px"
            @click="handleCommit"
          >
            {{ $t('submit1') }}
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>

import { fetchList, commitResult, stopGuess } from '@/api/guessing/index'
import GuessingCell from './components/GuessingCell'
import { Row, Button, Empty, Popup, Divider, Icon, Sticky } from 'vant'

export default {
  name: 'Guessing',
  components: {
    GuessingCell,
    [Button.name]: Button,
    [Row.name]: Row,
    [Empty.name]: Empty,
    [Popup.name]: Popup,
    [Sticky.name]: Sticky,
    [Divider.name]: Divider,
    [Icon.name]: Icon
  },
  data() {
    return {
      list: [],
      answerList: [],
      isLoading: true,
      showConfirmingPopup: false,
      confirmingSteps: 1,
      container: null
    }
  },
  computed: {
    selectedAnswerCount() {
      let count = 0
      for (let i = 0; i < this.answerList.length; i++) {
        const item = this.answerList[i]
        if (item.optionItem !== undefined) count++
      }
      return count
    }
  },
  mounted() {
    this.container = this.$refs.container
    window.load = this.load
  },
  created() {

  },
  mounted() {
    this.setTitle()
    this.getList()
  },
  methods: {
    setTitle() {
      document.title = '预言'
    },
    getList() {
      fetchList({}).then(response => {
        this.list = response.data
        this.isLoading = false
      })
    },
    handlePublish() {
      this.$router.push({ path: 'guessing-selection' })
    },
    configureAnswerList(list) {
      const answerList = []
      for (var i = 0; i < list.length; i++) {
        const item = Object.assign({}, list[i])
        const options = item.options.map((obj, index) => {
          var rObj = {}
          rObj['value'] = obj.optionItem
          rObj['text'] = obj.optionItem
          return rObj
        })
        if (item.status !== 2) {
          answerList.push(
            {
              topic: item.topic,
              topicItemId: item.topItemId,
              optionItem: undefined,
              options: options
            }
          )
        }
      }
      this.answerList = answerList
    },
    handleBatch() {
      this.configureAnswerList(this.list)
      this.showConfirmingPopup = true
    },
    stop(data) {
      stopGuess({ topicItemIds: [data.topItemId] }).then(response => {
        this.getList()
      })
    },
    terminate(data) {
      const list = []
      list.push(data)
      this.configureAnswerList(list)
      this.showConfirmingPopup = true
    },
    nextStep() {
      this.confirmingSteps = 2
    },
    handleCommit() {
      const list = this.answerList.map(obj => {
        var rObj = {}
        rObj['topicItemId'] = obj.topicItemId
        rObj['resultKey'] = obj.optionItem
        return rObj
      })
      commitResult({ list }).then(response => {
        this.showConfirmingPopup = false
        this.getList()
      })
    },
    popupClose() {
      this.answerList = []
      this.confirmingSteps = 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/color.scss';
.main-content {
  width: 100%;
  overflow: scroll;
  padding-bottom: 80px;
  margin-bottom: 43px;
  @include color_main_bg();
}
.card {
  padding: 20px 10px;
  border-radius: 10px;
  @include color_main_font();
  @include color_main_card_bg();
}
.border {
  @include color_border_2();
}
.cell {
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.cell-left {
  border: 1px solid #4a8fe2;
  text-align: center;
  padding: 10px 40px;
}
.cell-right {
  border: 1px solid #ff2a45;
  text-align: center;
  padding: 10px 40px;
}
.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-primary {
  height: 40px;
  border-radius: 6px;
  border: 1px solid #fff !important;
  color: #fff !important;
  font-size: 12px;
  background-color: #29292d !important;
}
.bottom {
  position: fixed;
  width: 100%;
  height: 45px;
  bottom: 35px;
  @include color_main_font();
  @include color_main_bg();
  .content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
.addition-content {
  display: flex;
  width: 100%;
  justify-content: center;
  @include color_main_bg();
  .bottom {
    position: absolute;
    bottom: 35px;
    width: 100%;
    height: 100px;
    text-align: center;
    margin-bottom: 70px;
    @include color_main_bg();
    .publishing-button {
      width: 283px;
      height: 40px;
      background-color: #4a8fe2 !important;
      border-radius: 6px;
      border: 1px solid #4a8fe2 !important;
      font-size: 12px;
      color: #ffffff;
    }
    .addition-button {
      width: 283px;
      height: 40px;
      border-radius: 6px;
      border: 1px solid #4a8fe2 !important;
      font-size: 12px;
      margin-top: 30px;
      @include color_main_font();
      @include color_main_bg();
    }
  }
}
.description {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(155, 155, 155, 1);
  line-height: 22px;
}
/deep/.cube-checker-item::after {
  border-width: 2px;
  // border-color: #373941;
  @include color_border_3();
}
/deep/.cube-checker-item_active::after {
  border-color: #1989fa !important;
}
/deep/.van-progress__pivot {
  top: 50%;
  background-size: cover !important;
  background-image: url('https://s1.ax1x.com/2020/06/09/t5Lkj0.png') !important;
  font-size: 6px !important;
  line-height: 6px !important;
  height: 6px !important;
  border-radius: 0px !important;
}
/deep/.van-progress__portion {
  position: absolute;
  left: 0;
  height: 100%;
  background: #1989fa;
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
/deep/.empty-image .van-empty__image {
  width: 257px;
  height: 84px;
}
/deep/.button-primary {
  color: #fff !important;
  background-color: #4a8fe2 !important;
  border: 1px solid #4a8fe2 !important;
}

/deep/.cube-checker-item {
  width: 90px;
  border-radius: 8px;
  font-size: 13px;
  @include color_main_font();
  @include color_main_bg_2();
}
</style>
