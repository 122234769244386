<template>
  <div class="app-container" :data-theme="$store.getters.themeType">
    <div class="main-content">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="getList"
      >
        <div v-for="(item,index) in list" :key="index" style="position: relative; margin-top: 10px; width: 100%">
          <router-link :to="{ path: '/kit-detail',query: { id: item.plan.id }}">
            <!-- <span class="title">{{ item.plan.title }}</span> -->
            <span class="title">{{' '}}</span>
            <van-cell style="margin-top: 10px;" :border="false" is-link>
              <template #title>
                <span class="plan-big-type">{{ planBigTypeFilter(item.plan.planBigType)  }}</span><span>{{ item.match.matchTime.substr(5,2) }}/{{ item.match.matchTime.substr(8,2) }}</span><span style="margin-left: 4px; margin-right: 4px;">{{ item.match.matchTime.substr(11,5) }}</span><span>{{ item.match.eventName }}</span><span style="margin-left: 4px; margin-right: 4px;">|</span><span>{{ item.match.homeName }}VS{{ item.match.awayName }}</span>
              </template>
            </van-cell>
            <div v-if="item.plan.price > 0">
              <van-row type="flex" justify="space-between" align="center" style="margin-top: 10px;">
                <span class="main-theme-font-color">{{ $t('goumairenshu')}}：{{ item.plan.countSell }}</span>
                <span>
                  <span style="font-size: 12px; color: #9B9B9B;">（ {{ $t('fencheng')}} ）</span>
                  <span v-if="(item.plan.settlementFinish === 1 && item.plan.guessHit === 0) || (item.isRefund && item.isRefund === 1)" style="font-size: 14px; color: #9B9B9B; margin-left: 5px; text-decoration: line-through;">{{ $t('zongji')}}：{{ item.plan.profitTotal }}{{ commonConfig.currencyMoneyName }}</span>
                  <span v-else style="font-size: 14px; color: #F1CB30; margin-left: 5px;">{{ $t('zongji')}}：{{ item.plan.profitTotal }}{{ commonConfig.currencyMoneyName }}</span>
                </span>
              </van-row>
              <van-row style="margin-top: 10px;" type="flex" justify="space-between" align="center">
                <div>
                  <span style="font-size: 12px; color: #9B9B9B">{{ item.plan.createTime  }}<span style="margin-left: 4px;">{{ $t('fabu') }}</span></span>
                </div>
                <span v-if="(item.plan.settlementFinish === 1 && item.plan.guessHit === 0) || (item.isRefund && item.isRefund === 1)" style="font-size: 12px; color: #F1CB30;">{{ $t('yituihuan') }}</span>
              </van-row>
            </div>
            <van-row v-else type="flex" justify="space-between" align="center" style="margin-top: 10px;">
              <div>
                <span style="font-size: 12px; color: #9B9B9B">{{ item.plan.createTime  }}<span style="margin-left: 4px;">{{}}</span></span>
              </div>
              <span style="font-size: 12px; color: #F1CB30;">{{ $t('mianfei') }}</span>
            </van-row>
            <van-divider :style="{borderColor: $theme.getDividerBorderColor()}" />
          </router-link>
          <!-- <div v-if="item.plan.guessHit !== null" style="position: absolute; top: 0px; right: 0px;">
            <img v-if="item.plan.guessHit === 1" style="width: 50px; height: 50px;" src="@/assets/images/kit/right.png">
            <img v-if="(item.plan.planBigType === 1 || item.plan.planBigType === 2) && parseLotteryResult(item.match.spf) === 1" style="width: 50px; height: 50px;" src="@/assets/images/kit/draw.png">
            <img v-if="item.plan.guessHit !== null && item.plan.guessHit === 0" style="width: 50px; height: 50px;" src="@/assets/images/kit/wrong.png">
            <Plan-icon :item="item"/>
          </div> -->
          <template v-if="lang === 'zh'">
            <div v-if="item.plan.guessHit !== null" style="position: absolute; top: 0px; right: 0px;">
              <img v-if="item.plan.guessHit === 1" style="width: 50px; height: 50px;" src="@/assets/images/kit/right.png">
              <img v-if="(item.plan.planBigType === 1 || item.plan.planBigType === 2) && parseLotteryResult(item.match.spf) === 1" style="width: 50px; height: 50px;" src="@/assets/images/kit/draw.png">
              <img v-else-if="item.plan.guessHit !== null && item.plan.guessHit === 0" style="width: 50px; height: 50px;" src="@/assets/images/kit/wrong.png">
            </div>
          </template>
          <template v-else>
            <div v-if="item.plan.guessHit !== null" style="position: absolute; top: 0px; right: 0px;">
              <img v-if="item.plan.guessHit === 1" style="width: 50px; height: 50px;" :src="require('@/assets/plan/'+lang+'/icon_silk_correct.png')">
              <img v-if="(item.plan.planBigType === 1 || item.plan.planBigType === 2) && parseLotteryResult(item.match.spf) === 1" style="width: 50px; height: 50px;" :src="require('@/assets/plan/'+lang+'/icon_silk_miss.png')">
              <img v-else-if="item.plan.guessHit !== null && item.plan.guessHit === 0" style="width: 50px; height: 50px;" :src="require('@/assets/plan/'+lang+'/icon_silk_uncorrect.png')">
            </div>
          </template>
        </div>
      </van-list>
      <van-empty
        v-if="!loading && list.length === 0"
        class="empty-image"
        style="margin-top: 40%;"
      >
        <div slot="image" style="width: 100%; text-align: center">
          <img style="width: 257px; height: 84px;" src="@/assets/images/empty.png">
        </div>
        <div slot="description" style="text-align: center;">
          {{ $t('fabuplantips') }}
        </div>
      </van-empty>
    </div>
    <div class="bottom">
      <div class="row" style="padding-top: 10px;">
        <router-link :to="{ path: '/game-selection'}">
          <van-button class="button-primary" style="width: 260px; border-radius:6px;">{{ $t('fabu') + $t('planlist') }}</van-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import { Row, Button, Divider, List, Empty, Toast, Cell } from 'vant'
import { kitList } from '@/api/answer-kit/answerKit'
import { fetchCommonConfig } from '@/api/user'
import planIcon from './planIcon/index.vue'
export default {
  name: 'KitList',
  components: {
    [List.name]: List,
    [Button.name]: Button,
    [Row.name]: Row,
    [Cell.name]: Cell,
    [Divider.name]: Divider,
    [Empty.name]: Empty,
    [Toast.name]: Toast,
    'Plan-icon':planIcon
  },
  computed: {
    lang(){
      return localStorage.getItem('language')
    },
  },
  data() {
    return {
      commonConfig: {},
      list: [],
      pageNum: 1,
      pageSize: 20,
      loading: false,
      finished: false,
      finishedText: '没有更多了'
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.path === '/kit-addition') {
        vm.refresh()
      }
    })
  },
  created() {
    this.setTitle()
    this.getCommonConfig()
    this.getList()
  },
  methods: {
    planBigTypeFilter(type) {
      const map = {
        0: this.$t('rq'),
        1: this.$t('hk'),
        2: this.$t('bm'),
        3: this.$t('eur')
      }
      return map[type]
    },
    setTitle() {
      document.title = '解球锦囊'
    },
    refresh() {
      this.list = []
      this.pageNum = 1
      this.getList()
    },
    getCommonConfig() {
      fetchCommonConfig({}).then(response => {
        this.commonConfig = response.data
      })
    },
    parseLotteryResult(spf) {
      if (!spf || spf.lotteryResult === null || spf.lotteryResult === undefined) {
        return -1000
      }
      return parseInt(spf.lotteryResult)
    },
    getList() {
      this.loading = true
      kitList({ pageSize: this.pageSize, pageNum: this.pageNum }).then(response => {
        this.loading = false
        this.pageNum = response.data.nextPage
        this.list = this.list.concat(response.data.list)
        if (!response.data.hasNextPage) {
          this.finished = true
          this.finishedText = this.$t('nomore')
        }
        if (this.list.length < 20) {
          this.finishedText = ''
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.title {
  font-size: 16px;
  word-break:normal;
  width:auto;
  display:block;
  white-space:pre-wrap;
  word-wrap : break-word;
  overflow: hidden ;
  @include color_main_font();
}
.van-cell {
  border-radius: 10px;
  color: #9B9B9B;
  @include color_main_card_bg_2();
}
.main-content {
  width: 100%;
  min-height: 100%;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 140px;
  @include color_main_bg_2();
}
.bottom {
  @include color_main_bg_2();
  position: fixed;
  width: 100%;
  height: 80px;
  color: #fff;
  bottom: 35px;
  z-index: 999;
}
.plan-big-type {
  color: #1989fa;
  padding: 1px;
  margin-right: 2px;
  border: 1px solid #1989fa!important;
  border-width: 1px;
  border-radius: 2px;
}
/deep/.empty-image .van-empty__image {
  width: 257px;
  height: 84px;
}
</style>
