import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './lang/en'
import zh from './lang/zh'
import ko from './lang/ko'
import br from './lang/br'
import th from './lang/th'
import vi from './lang/vi'
import zht from './lang/zht'
import { getQueryVariable } from '@/utils'
// 组件
import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';
import thTH from 'vant/es/locale/lang/th-TH';
import zhTW from 'vant/es/locale/lang/zh-TW';
Vue.use(VueI18n)

const messages = {
  en, zh, ko, th, vi, br,zht
}
export function getLanguage() {
  const lang = getQueryVariable('language')
  const chooseLanguage = lang || localStorage.getItem('language')
  localStorage.setItem('language', chooseLanguage)
  // 组件语言
  if (lang === 'en') {
    Locale.use('en-US', enUS)
  } else if (lang === 'zh') {
    Locale.use('zh-CN', zhCN);
  } else if (lang === 'th') {
    Locale.use('th-TH', thTH);
  } else if (lang === 'zht') {
    Locale.use('zh-TW', zhTW);
  }
  return lang
}
const i18n = new VueI18n({
  // set locale
  // options: en | zh
  locale: getLanguage(),
  // set locale messages
  messages
})

export default i18n
