<template>
  <div class="app-container" :data-theme="$store.getters.themeType">
    <div class="main-content">
      <div class="card content-wrapper">
        <van-field
          v-model="content"
          rows="9"
          autosize
          type="textarea"
          maxlength="200"
          :placeholder="$t('qingshurupaomadengneirong')"
          show-word-limit
        />
        <div class="bottom">
          <span>{{ $t('paomadengkaiguan') }}</span>
          <van-switch
            v-model="isOn"
            size="20px"
            active-color="#4CD964"
            inactive-color="#9A9B9C"
            style="margin-right: 2px;"
            @change="switchChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Row, Toast, Field, Switch } from 'vant'
import { setMarquee, getMarquee } from '@/api/live-setting/index'

export default {
  components: {
    [Row.name]: Row,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Switch.name]: Switch
  },
  data() {
    return {
      content: '',
      isOn: false
    }
  },
  mounted() {
  },
  created() {
    this.setTitle()
    this.getMarquee()
  },
  beforeRouteLeave(to, from, next) {
    this.switchChange()
    next()
  },
  methods: {
    setTitle() {
      document.title = '跑马灯设置'
    },
    switchChange() {
      this.content = this.content.replace(/[\r\n]/g, '')
      setMarquee({ isOn: this.isOn ? 1 : 0, content: this.content }).then(response => {

      })
    },
    getMarquee() {
      getMarquee({}).then(response => {
        this.isOn = response.data.isOn === 1
        this.content = response.data.content || ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";

.main-content {
  height: 100%;
  width: 100%;
  padding-top: 24px;
  padding: 20px 20px;
  color: #fff;
  .content-wrapper {
    padding: 15px;
    .van-cell {
      position: initial;
      background-color: transparent;
      border-radius: 6px;
      @include color_border_2()
    }
    .bottom {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include color_main_font();
    }
  }
}

/deep/ .van-field__control {
  @include color_main_font();
}

</style>
