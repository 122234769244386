<template>
  <div class="app-container">
    <guessing-detail ref="detail" :is-edit="false" @change="change" />
    <alert :visible.sync="showAlert" message="确定退出预言主题添加吗?" cancel-button-text="取消" confirm-button-text="确认" @confirm="confirm" />
  </div>
</template>

<script>
import GuessingDetail from './components/GuessingDetail'
import Alert from '@/components/Alert'

export default {
  name: 'GuessingAddition',
  components: { GuessingDetail, Alert },
  data() {
    return {
      saved: false,
      showAlert: false,
      isChecked: false
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.saved) {
      to.meta.keepAlive = false
    }
    if (this.isChecked) {
      next()
      return
    }
    const inputted = this.$refs.detail.checkInput()
    if (inputted && !this.saved) {
      this.showAlert = true
      next(false)
      return
    }
    next()
  },
  methods: {
    change(val) {
      this.saved = val
    },
    confirm() {
      this.isChecked = true
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/.cube-checker-item::after {
    border-color: #373941;
  }
  /deep/.cube-checker-item_active::after {
    border-color: #1989fa!important;
  }
</style>
