var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "main-content" },
      [
        _vm.kitPermissionStatus === 1
          ? _c(
              "van-row",
              { attrs: { type: "flex", align: "center" } },
              [
                _c(
                  "van-col",
                  {
                    staticStyle: { "margin-right": "11px" },
                    attrs: { span: "12" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card" },
                      [
                        _c(
                          "van-row",
                          {
                            attrs: {
                              type: "flex",
                              align: "center",
                              justify: "center",
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.incomeInfo
                                      .ios3X26X26CurrencyZhuboIconUrl,
                                  expression:
                                    "incomeInfo.ios3X26X26CurrencyZhuboIconUrl",
                                },
                              ],
                              staticClass: "feather",
                              attrs: {
                                src: _vm.incomeInfo
                                  .ios3X26X26CurrencyZhuboIconUrl,
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "amount" }, [
                              _vm._v(_vm._s(_vm.incomeInfo.settlement)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "van-row",
                          {
                            staticClass: "main-theme-font-color",
                            staticStyle: {
                              "text-align": "center",
                              "margin-top": "12px",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm.$t("liveshouyi") +
                                      _vm.incomeInfo.currencyZhuboName
                                  ) +
                                  ")"
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("van-col", { attrs: { span: "12" } }, [
                  _c(
                    "div",
                    { staticClass: "card" },
                    [
                      _c(
                        "van-row",
                        {
                          attrs: {
                            type: "flex",
                            align: "center",
                            justify: "center",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.incomeInfo.ios3X26X26CurrencySilkIconUrl,
                                expression:
                                  "incomeInfo.ios3X26X26CurrencySilkIconUrl",
                              },
                            ],
                            staticClass: "feather",
                            attrs: {
                              src: _vm.incomeInfo.ios3X26X26CurrencySilkIconUrl,
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "amount" }, [
                            _vm._v(_vm._s(_vm.incomeInfo.tacticCoin)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "van-row",
                        {
                          staticClass: "main-theme-font-color",
                          staticStyle: {
                            "text-align": "center",
                            "margin-top": "12px",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "(" +
                                _vm._s(
                                  _vm.$t("jinnanshouyi") +
                                    _vm.incomeInfo.currencySilkName
                                ) +
                                ")"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          : _c(
              "van-row",
              { attrs: { type: "flex", align: "center" } },
              [
                _c("van-col", { attrs: { span: "24" } }, [
                  _c(
                    "div",
                    { staticClass: "card" },
                    [
                      _c(
                        "van-row",
                        {
                          staticClass: "main-theme-font-color",
                          staticStyle: {
                            "text-align": "center",
                            "margin-bottom": "12px",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "(" +
                                _vm._s(
                                  _vm.$t("liveshouyi") +
                                    _vm.incomeInfo.currencyZhuboName
                                ) +
                                ")"
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "van-row",
                        {
                          attrs: {
                            type: "flex",
                            align: "center",
                            justify: "center",
                          },
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.incomeInfo.ios3X26X26CurrencyZhuboIconUrl,
                                expression:
                                  "incomeInfo.ios3X26X26CurrencyZhuboIconUrl",
                              },
                            ],
                            staticClass: "feather",
                            attrs: {
                              src: _vm.incomeInfo
                                .ios3X26X26CurrencyZhuboIconUrl,
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "amount" }, [
                            _vm._v(_vm._s(_vm.incomeInfo.settlement)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card", staticStyle: { "margin-top": "15px" } },
          [
            _c(
              "router-link",
              { attrs: { to: { path: "/income-record" } } },
              [
                _c("van-cell", {
                  attrs: {
                    border: false,
                    "title-class": "cell-title",
                    title: _vm.$t("earnlist"),
                    "is-link": "",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("van-divider", {
              style: {
                borderColor: _vm.$theme.getDividerBorderColor(),
                marginLeft: "16px",
                marginRight: "19px",
              },
            }),
            _vm._v(" "),
            _c(
              "router-link",
              { attrs: { to: { path: "/settlement-introduction" } } },
              [
                _c("van-cell", {
                  attrs: {
                    border: false,
                    "title-class": "cell-title",
                    title: _vm.$t("chakanguize"),
                    "value-class": "cell-value",
                    value: _vm.$t("chakanguize"),
                    "is-link": "",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("van-divider", {
              style: {
                borderColor: _vm.$theme.getDividerBorderColor(),
                marginLeft: "16px",
                marginRight: "19px",
              },
            }),
            _vm._v(" "),
            _c("van-cell", {
              attrs: {
                title: _vm.$t("kefuhao"),
                "title-class": "cell-title",
                "value-class": "weixin",
                value: _vm.incomeInfo.csCode,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }