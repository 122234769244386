<template>
  <div class="main-content">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="finishedText"
      @load="getList"
    >
      <div v-for="(item, index) in list" :key="index">
        <span class="main-theme-font-color">{{ item.title }}</span>
        <div v-for="(subItem,subIndex) in item.list" :key="subIndex" style="margin-top: 10px; margin-bottom: 20px;">
          <div class="card">
            <van-row type="flex" justify="space-between" align="center">
              <div style="max-width: 90%; line-height: 20px;" class="main-theme-font-color">
                {{ subItem.eventName }}<span v-show="subItem.roundId !== '0'">第{{ subItem.roundId }}轮</span> {{ subItem.matchTime }}
              </div>
              <img style="width: 16px; height: 16px; margin-right: 10px;" src="@/assets/images/live-setting/delete_icon.png" @click="showAlert = true, selectedItem = subItem">
            </van-row>
            <van-row type="flex" align="center" style="margin-top: 20px;">
              <img v-if="subItem.homeLogo && subItem.homeLogo !== ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid rgba(255,255,255,1); margin-right: 10px;" :src="subItem.homeLogo">
              <img v-if="!subItem.homeLogo || subItem.homeLogo === ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid rgba(255,255,255,1); margin-right: 10px;" src="@/assets/images/team_logo_default.png">
              <span style="font-size: 12px;" class="main-theme-font-color">{{ subItem.homeName }}</span>
              <img style="width: 22px; height: 25px; margin-left: 20px; margin-right: 20px;" :src="$theme.getVsImage()">
              <span style="font-size: 12px;" class="main-theme-font-color">{{ subItem.awayName }}</span>
              <img v-if="subItem.awayLogo && subItem.awayLogo !== ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid rgba(255,255,255,1); margin-left: 10px;" :src="subItem.awayLogo">
              <img v-if="!subItem.awayLogo || subItem.awayLogo === ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid rgba(255,255,255,1); margin-left: 10px;" src="@/assets/images/team_logo_default.png">
            </van-row>
          </div>
        </div>
      </div>
    </van-list>
    <alert :visible.sync="showAlert" message="确定取消预约吗?" cancel-button-text="取消" confirm-button-text="确认" @confirm="handleDelete()" />
    <van-empty
      v-if="!loading && list.length === 0"
      class="empty-image"
      style="margin-top: 40%;"
    >
      <div slot="image" style="width: 100%; text-align: center">
        <img style="width: 257px; height: 84px;" src="@/assets/images/empty.png">
      </div>
      <div slot="description" style="text-align: center;">
        <div>{{ $t('nomatch') }}</div>
      </div>
    </van-empty>
  </div>
</template>

<script>
import { Row, Button, Col, Divider, Empty, List, Toast } from 'vant'
import Alert from '@/components/Alert'
import { fetchMyAppointmentList, book } from '@/api/live-setting'

export default {
  name: 'MyAppointmentList',
  components: {
    [List.name]: List,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [Empty.name]: Empty,
    [Toast.name]: Toast,
    Alert
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      finishedText: '没有更多了',
      pageNum: 1,
      selectedItem: undefined,
      showAlert: false
    }
  },
  created() {

  },
  methods: {
    getList() {
      this.loading = true
      fetchMyAppointmentList({ matchIsLiving: true }).then(response => {
        this.loading = false
        this.list = this.list.concat(response.data)
        if (response.data.length < 20) {
          this.finished = true
          this.finishedText = '没有更多了'
        } else {
          this.pageNum += 1
        }
        if (this.list.length < 20) {
          this.finishedText = ''
        }
      })
    },
    handleDelete() {
      this.showAlert = false
      book({ source: 1, matchId: [this.selectedItem.id], sportsType: this.selectedItem.sportsType, status: 1 }).then(response => {
        Toast('已删除')
        // const index = this.list.indexOf(this.selectedItem)
        // this.list.splice(index, 1)
        this.pageNum = 1
        this.list = []
        this.getList()
        this.selectedItem = undefined
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.main-content {
  padding-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  @include color_main_bg();
}

/deep/.van-hairline-unset--top-bottom::after {
  border-top: 1px solid #373941!important;
  border-bottom: 1px solid #373941!important;
}
/deep/.empty-image .van-empty__image {
  width: 257px;
  height: 84px;
}

/deep/.van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
  border-width: 0px 0;
}
</style>
