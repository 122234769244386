<template>
  <div class="container" :data-theme="$store.getters.themeType">
    <div class="user-info-avatar">
      <!-- <img class="avatar-bg" :src="$theme.getGradeAvatarBgImage()"> -->
      <img class="avatar" :src="gradeInfo.avatar + '?x-oss-process=image/resize,w_100/auto-orient,1'">
      <div class="riband-container">
        <div style="position: relative;">
          <img class="riband" src="@/assets/images/grade/riband.png">
          <div class="grade">
            <van-row type="flex" justify="center" align="bottom">
              <span style="font-size: 8px; padding-bottom: 1px;">LV.</span><span style="font-size: 12px;">{{ gradeInfo.level }}</span>
            </van-row>
          </div>
        </div>
      </div>
    </div>
    <div class="name">{{ gradeInfo.nickname }}</div>
    <van-row style="margin: 20px 20px 35px 20px;">
      <van-progress color="#F6BA3D" :track-color="$theme.getProgressTrackColor()" :percentage="(gradeInfo.exp / gradeInfo.nextExp * 100) < 1 ? 0 : (gradeInfo.exp / gradeInfo.nextExp * 100)" :pivot-text="gradeInfo.exp + ''" stroke-width="8" />
    </van-row>
    <div class="experience-info main-theme-font-color">
      <div> {{ $t('jianyanzhi') }} </div>
      <div> {{ $t('uplevel',{msg:gradeInfo.nextExp - gradeInfo.exp})  }} </div>
    </div>
    <div class="card" style="margin-top: 30px; margin-bottom: 10px; margin-left: 15px; margin-right: 15px;">
      <van-row style="text-align: center;">{{ $t('tequan') }}</van-row>
      <div>
        <van-row type="flex" align="center">
          <img class="left-icon" src="@/assets/images/grade/waiting.png">
          <span class="main-theme-font-color">{{ $t('qidai') }}</span>
        </van-row>
        <van-row class="description">{{ $t('mashanglai') }}</van-row>
      </div>
    </div>
    <div class="card" style="margin-top: 30px; margin-bottom: 10px; margin-left: 15px; margin-right: 15px;">
      <van-row style="text-align: center;">{{ $t('kuaisushengji') }}</van-row>
      <van-row type="flex" align="center">
        <img class="left-icon" src="@/assets/images/grade/gift.png">
        <span class="main-theme-font-color">{{ isAnchor ? $t('huodeliwu') : $t('zensongliwu') }}</span>
      </van-row>
      <van-row class="description">1{{ commonConfig.currencyMoneyName +'=1' +$t('jiyang')}}</van-row>
      <div v-if="isAnchor">
        <van-divider :style="{ borderColor: $theme.getDividerBorderColor() }" />
        <van-row type="flex" align="center">
          <img class="left-icon" src="@/assets/images/grade/live.png">
          <span class="main-theme-font-color">{{ $t('qingfenzhibo') }}</span>
        </van-row>
        <van-row class="description">{{30+$t('fengzhong')+'=100'+$t('jiyang')+',60'+$t('fengzhong')+'=300'+$t('jiyang')}} </van-row>
        <van-row class="description">{{120+$t('fengzhong')+'=600'+$t('jiyang')+',180'+$t('fengzhong')+'=1000'+$t('jiyang')}} </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import { Image as VanImage, Row, Col, Progress, Cell, Icon, Divider } from 'vant'
import { fetchCommonConfig } from '@/api/user'

export default {
  components: {
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Progress.name]: Progress,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [Divider.name]: Divider
  },
  props: {
    isAnchor: {
      type: Boolean,
      default: false
    },
    gradeInfo: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      commonConfig: {}
    }
  },
  computed: {
    percentage() {
      let percentage = this.gradeInfo.exp / this.gradeInfo.nextExp * 100
      if (percentage > 0 && percentage < 3) {
        percentage = 3
      }
      return percentage
    }
  },
  created() {
    this.getCommonConfig()
  },
  methods: {
    getCommonConfig() {
      fetchCommonConfig({}).then(response => {
        this.commonConfig = response.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.container {
  position: relative;
  @include color_main_bg();
}
.user-info-avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar-bg {
    position: absolute;
    z-index: 0;
    width: 375px;
    height: 126px;
  }
  .avatar {
    width: 70px;
    height: 70px;
    background-color: #fff;
    border-radius: 35px;
    margin-top: 25px;
    border: 2px solid #fff;
  }
  .riband-container {
    position: absolute;
    z-index: 10;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    .riband {
      width: 80px;
      height: 24px;
    }
  }
}
.grade {
  width: 100%;
  position: absolute;
  z-index: 20;
  color: #fff;
  top: 8px;
}
.name {
  width:100%;
  height:15px;
  font-size:14px;
  font-family:PingFangSC-Medium,PingFang SC;
  font-weight:500;
  line-height:15px;
  text-shadow:0px 2px 4px rgba(0,0,0,0.5);
  text-align: center;
  margin-top: 20px;
  @include color_main_font_3();
}
.experience-info {
  display: flex;
  justify-content: space-between;
  color: #fff;
  margin-left: 20px;
  margin-right: 20px;
}
.left-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.description {
  font-size:13px;
  color:rgba(155,155,155,1);
  margin-top:10px;
  margin-left: 34px;
}
/deep/.van-progress__portion {
  border-bottom-left-radius: 4px!important;
  border-top-left-radius: 4px!important;
  border-bottom-right-radius: 0px!important;
  border-top-right-radius: 0px!important;
}
/deep/ .van-progress__pivot {
  @include color_main_font_3();
}
</style>
