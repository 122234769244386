<template>
  <div class="main-container">
    <p>
      <span style="color: #ffffff;">亲爱的主播们：</span>
    </p>
    <p>
      <span
        style="color: #ffffff;"
      >感谢大家选择我们直播平台，也感谢各位主播的信任与支持，为了规范平台运营，出台《封面规范说明》，请主播认真阅读封面规范说明，以求达到更好的直播效果</span>
    </p>
    <p>
      <span style="color: #ffffff;">
        <strong>具体封面规范如下：</strong>
      </span>
    </p>
    <p>
      <span style="color: #ffffff;">一、主播人物封面图要求</span>
    </p>
    <ol>
      <li>
        <span style="color: #ffffff;">封面必须使用本人图片；</span>
      </li>
      <li>
        <span style="color: #ffffff;">需要背景干净、光线明亮、人像高清、半身或全身的艺术照；</span>
      </li>
      <li>
        <span style="color: #ffffff;">图片不能太暗或者曝光过度、不要用自拍像、不要用大头照；</span>
      </li>
      <li>
        <span style="color: #ffffff;">禁止使用任何直播截图；</span>
      </li>
      <li>
        <span style="color: #ffffff;">禁止使用风景、动物、卡通人物、公众人物等图片；&nbsp;&nbsp;</span>
      </li>
    </ol>
    <p>
      <span style="color: #ffffff;">二、封面图尺寸要求</span>
    </p>
    <p>
      <span style="color: #ffffff;">348 *1 96的等比例长方形图片；</span>
    </p>
    <p>
      <span style="color: #ffffff;">三、封面图具体原则</span>
    </p>
    <p>
      <span style="color: #ffffff;">（一）主体构图</span>
    </p>
    <ol>
      <li>
        <span style="color: #ffffff;">图片主体突出，清晰完整；</span>
      </li>
      <li>
        <span style="color: #ffffff;">不可出现无关的文字、水印、logo等；</span>
      </li>
      <li>
        <span style="color: #ffffff;">不可拼接；</span>
      </li>
    </ol>
    <p>
      <span style="color: #ffffff;">（二）其他</span>
    </p>
    <ol>
      <li>
        <span style="color: #ffffff;">封面内不得出现其他主播，有版权的除外；</span>
      </li>
      <li>
        <span style="color: #ffffff;">侧躺、平躺、露臀线、露胸、抽烟等封面视为低俗违规，请勿上传；</span>
      </li>
      <li>
        <span style="color: #ffffff;">封面模糊、扭曲、边框等美观度差；</span>
      </li>
      <li>
        <span style="color: #ffffff;">人像不居中、侧脸、低头、只有背影、远景导致看不到全五官特征的图片；</span>
      </li>
      <li>
        <span style="color: #ffffff;">使用风景、动物、卡通人物、明星、影视角色等非本人照片的图片；</span>
      </li>
      <li>
        <span style="color: #ffffff;">戴口罩、大面积遮挡面部的图片；</span>
      </li>
    </ol>
  </div>
</template>
<script>
export default {
  name: 'CoverIntroduction',
  components: {},
  data() {
    return {
      content: '',
      title: '封面规范说明'
    }
  },
  created() {},
  methods: {
    setTitle() {
      document.title = this.title
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  line-height: 22px;
  margin-top: 10px;
}
.main-container {
  min-height: 100%;
  background-color: #282828;
  padding: 15px;
  line-height: 22px;
}
</style>
