<template>
  <div class="card cell" :data-theme="$store.getters.themeType">
    <van-row type="flex" align="center" justify="space-between">
      <span class="main-theme-font-color">{{ data.topic }}</span>
      <div class="countdown">
        <div v-if="data.status === 0" class="row">
          <img
            style="width: 11px; height: 13px; margin-right: 5px"
            src="@/assets/images/guessing/clock.png"
          />
          <span class="main-theme-font-color">{{ $t('surplus') }}</span>
          <van-count-down
            :time="data.remainTime | parseRemainTime"
            class="main-theme-font-color"
            style="width: 70px; text-align: right"
          />
        </div>
        <div v-if="data.status != 0" class="row">
          <span style="color: #9b9b9b">{{ data.status | statusFilter }}</span>
          <span v-if="data.isFlowBureau === 1">{{ $t('flow_bureau') }}</span>
        </div>
      </div>
    </van-row>
    <div style="position: relative">
      <div
        v-if="data.status != 0"
        style="position: absolute; left: 10px; top: 0px"
      >
        <img
          v-if="data.options[0].result === $t('Victory')"
          style="width: 18px; height: 20px; margin-right: 5px"
          src="@/assets/images/guessing/win.png"
        />
        <img
          v-if="data.options[0].result === $t('Defeated')"
          style="width: 18px; height: 20px; margin-right: 5px"
          src="@/assets/images/guessing/failure.png"
        />
        <img
          v-if="data.options[0].result === $t('Flow')"
          style="width: 18px; height: 20px; margin-right: 5px"
          src="@/assets/images/guessing/draw.png"
        />
      </div>
      <div
        v-if="data.status != 0"
        style="position: absolute; right: 5px; top: 0px"
      >
        <img
          v-if="data.options[1].result === $t('Victory')"
          style="width: 18px; height: 20px; margin-right: 5px"
          src="@/assets/images/guessing/win.png"
        />
        <img
          v-if="data.options[1].result === $t('Defeated')"
          style="width: 18px; height: 20px; margin-right: 5px"
          src="@/assets/images/guessing/failure.png"
        />
        <img
          v-if="data.options[1].result === $t('Flow')"
          style="width: 18px; height: 20px; margin-right: 5px"
          src="@/assets/images/guessing/draw.png"
        />
      </div>
      <van-row
        type="flex"
        align="center"
        justify="space-between"
        style="margin-top: 14px"
      >
        <div
          class="card"
          :style="data.status === 0 ? cellLeftActive : cellLeft"
        >
          <p style="font-size: 13px" class="main-theme-font-color">
            {{ data.options[0].optionItem }}
          </p>
          <p
            v-if="data.status === 1"
            style="font-size: 13px; color: #9b9b9b; margin-top: 3px"
          >
            {{ $t('Waiting_announcement') }}
          </p>
          <p v-else style="color: #f0cf2c; margin-top: 3px">
            {{ data.options[0].odds }}{{ $t('Doubling') }}
          </p>
        </div>
        <img style="width: 22px; height: 25px" :src="$theme.getVsImage()" />
        <div
          class="card"
          :style="data.status === 0 ? cellRigthActive : cellRigth"
        >
          <p style="font-size: 13px" class="main-theme-font-color">
            {{ data.options[1].optionItem }}
          </p>
          <p
            v-if="data.status === 1"
            style="font-size: 13px; color: #9b9b9b; margin-top: 3px"
          >
            {{ $t('Waiting_announcement') }}
          </p>
          <p v-else style="color: #f0cf2c; margin-top: 3px">
            {{ data.options[1].odds }} {{ $t('Doubling') }}
          </p>
        </div>
      </van-row>
    </div>
    <div style="position: relative; margin-top: 10px">
      <van-progress
        :percentage="percentage"
        pivot-text="."
        text-color="#29292D"
        track-color="#FF2943"
        color="#4A90E2"
        stroke-width="6"
      />
      <div
        v-if="data.status !== 0"
        style="
          position: absolute;
          width: 100%;
          height: 6px;
          background-color: rgba(26, 26, 28, 0.5);
          top: 0px;
        "
      />
    </div>
    <van-row
      type="flex"
      align="center"
      justify="space-between"
      style="margin-top: 14px; color: #9b9b9b"
    >
      <span>{{ data.options[0].betCoins }}</span>
      <span>{{ data.options[1].betCoins }}</span>
    </van-row>
    <van-row
      v-if="data.status === 0"
      type="flex"
      align="center"
      justify="center"
      style="margin-top: 14px"
    >
      <van-button
        class="button-normal"
        style="width: 45%; margin-rigth: 20px"
        @click="showAlert = true"
      >
        {{ $t('Stop') }}
      </van-button>
      <van-button
        class="button-normal"
        style="width: 45%; margin-left: 20px"
        @click="$emit('terminate')"
      >
        {{ $t('End') }}
      </van-button>
    </van-row>
    <van-row
      v-if="data.status === 1"
      type="flex"
      align="center"
      justify="center"
      style="margin-top: 14px"
    >
      <van-button
        class="button-normal"
        style="width: 100%"
        @click="$emit('terminate')"
      >
        {{ $t('End') }}
      </van-button>
    </van-row>
    <alert
      :visible.sync="showAlert"
      :message="$t('hint15')"
      :cancel-button-text="$t('cancel')"
      :confirm-button-text="$t('confirm')"
      @confirm="confirm"
    />
  </div>
</template>

<script>

import { Button, Row, Progress, CountDown } from 'vant'
import Alert from '@/components/Alert'

import { getLanguage } from '@/locale/index'
import i18n from '@/locale'
let languageInfo = i18n.messages[getLanguage()]

export default {
  name: 'GuessingCell',
  components: {
    [Row.name]: Row,
    [Button.name]: Button,
    [Progress.name]: Progress,
    [CountDown.name]: CountDown,
    Alert
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        // 0: '进行中',
        // 1: '已停止',
        // 2: '已结束'
        0: languageInfo.ongoing,
        1: languageInfo.stopped,
        2: languageInfo.result
      }
      return statusMap[status]
    }
  },
  props: {
    going: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      showAlert: false,
      alertMessage: '',
      cellLeftActive: {
        width: '137px',
        height: '52px',
        backgroundColor: '#30303A',
        border: '1px solid #4A8FE2',
        textAlign: 'center',
        padding: '10px 10px'
      },
      cellLeft: {
        width: '137px',
        height: '52px',
        backgroundColor: '#30303A',
        textAlign: 'center',
        padding: '10px 10px'
      },
      cellRigthActive: {
        width: '137px',
        height: '52px',
        backgroundColor: '#30303A',
        border: '1px solid #FF2A45',
        textAlign: 'center',
        padding: '10px 10px'
      },
      cellRigth: {
        width: '137px',
        height: '52px',
        backgroundColor: '#30303A',
        textAlign: 'center',
        padding: '10px 10px'
      }
    }
  },
  computed: {
    percentage() {
      const leftItem = this.data.options[0]
      const rigthItem = this.data.options[1]
      if (parseInt(leftItem.betCoins) === 0 && parseInt(rigthItem.betCoins) === 0) return 50
      let percentage = parseInt(leftItem.betCoins) / (parseInt(leftItem.betCoins) + parseInt(rigthItem.betCoins)) * 100
      if (!percentage || percentage < 1) percentage = 1
      return percentage
    }
  },
  created() {
  },
  methods: {
    confirm() {
      this.showAlert = false
      this.$emit('stop')
    }

  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/color.scss';
.card {
  padding: 10px 10px;
  border-radius: 10px;
  color: #fff;
  background-color: #29292d;
}
.cell {
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.cell-left-active {
  border: 1px solid #4a8fe2;
  text-align: center;
  padding: 10px 40px;
}
.cell-left {
  text-align: center;
  padding: 10px 40px;
}
.cell-right-active {
  border: 1px solid #ff2a45;
  text-align: center;
  padding: 10px 40px;
}
.cell-right {
  text-align: center;
  padding: 10px 40px;
}
.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-normal {
  height: 30px;
  border-radius: 4px;
  font-size: 12px;
  @include color_border_2();
  @include color_main_font();
  @include color_main_card_bg();
}
/deep/.van-progress__pivot {
  top: 50%;
  background-size: cover !important;
  background-image: url('https://s1.ax1x.com/2020/06/09/t5Lkj0.png') !important;
  font-size: 6px !important;
  line-height: 6px !important;
  height: 6px !important;
  border-radius: 0px !important;
}
/deep/.van-progress__portion {
  position: absolute;
  left: 0;
  height: 100%;
  background: #1989fa;
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
/deep/.cube-checker-item {
  font-size: 13px;
}
</style>
