import axios from 'axios'
import router from '@/router'
import store from '@/store'
import platform from '@/plugin/platform'
import { Toast } from 'vant'
import { getQueryVariable } from '@/utils'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  // baseURL: '/api',
  // baseURL: 'https://gateway-test.xxx',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 9000 // request timeout
})

const noNeedTokenApi=['/app/agreement/list','/app/commonConfig/h5']

// request拦截器
service.interceptors.request.use(
  config => {
    // console.log(config, process.env)
    if (getQueryVariable('token')) {
      // const token = (!store.getters.token || store.getters.token === '') ? getQueryVariable('token') : store.getters.token
      const token = getQueryVariable('token')
      localStorage.setItem('tenantToken', token)
      const platform = getQueryVariable('platform')
      const appId = getQueryVariable('appId')
      const merchantId = getQueryVariable('merchantId')
      const clientVersion = getQueryVariable('clientVersion')
      store.dispatch('configureHeaders', { token: token, platform: platform, merchantId: merchantId, appId: appId, clientVersion: clientVersion }).then(() => { })
    } else if (getQueryVariable('appId')) {
      // const token = ''
      const token = getQueryVariable('token') || ''
      const platform = getQueryVariable('platform')
      const appId = getQueryVariable('appId')
      const merchantId = getQueryVariable('merchantId')
      const clientVersion = getQueryVariable('clientVersion')
      store.dispatch('configureHeaders', { token: token, platform: platform, merchantId: merchantId, appId: appId, clientVersion: clientVersion }).then(() => { })
    }
    if (getQueryVariable('theme')) { // 设置皮肤
      const theme = getQueryVariable('theme')
      if (theme === 'Night') {
        window.document.documentElement.setAttribute('data-theme', 'default')
        store.dispatch('setThemeType', 'default')
      } else if (theme === 'Whitey') {
        window.document.documentElement.setAttribute('data-theme', 'tint')
        store.dispatch('setThemeType', 'tint')
      }
    }
    // console.log(config.url,111,noNeedTokenApi.every(m=> config.url.indexOf(m) === -1))
    if (store.getters.token && store.getters.token !== ''&& noNeedTokenApi.every(m=> config.url.indexOf(m) === -1)) {
      config.headers['X-ACCESS-TOKEN'] = localStorage.getItem('tenantToken') || store.getters.token
    }
    config.headers['platform'] = store.getters.platform
    config.headers['appId'] = store.getters.appId
    config.headers['merchantId'] = store.getters.merchantId
    config.headers['clientVersion'] = store.getters.clientVersion
    config.headers['Content-Type'] = 'application/json',
      // 'Accept-Language':getLang()
      // config.headers['X-ACCESS-TOKEN'] = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIzNzIxMzkiLCJyb29tTm8iOiI5ODY3MjkiLCJtZXJjaGFudElkIjoiMTAwMDAiLCJyYW5kb21WYWx1ZSI6ODI1MywiaXNzIjoibGl2ZS10cnVtcCIsImV4cCI6MTYxNDQwNjE3MywiaWF0IjoxNjEzODAxMzczfQ.qLf7id1S25vxIkytV07I4a06NR3SXJryK3dR8xxHmig'
      // config.headers['platform'] = 'ios'
      // config.headers['appId'] = 'APP1594644083423'
      config.headers['Accept-Language'] = localStorage.getItem('language')
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const status = response.status
    const code = response.data.code
    if (code === 1301 && !store.getters.logining) {
      store.dispatch('updateLogining', true)
      platform.toAPPlogin(store.getters.platform)
      return Promise.reject(response)
    }
    if (status < 200 || status > 300) {
      Toast(response.message)
      return Promise.reject(response)
    }
    if (code < 200 || code > 300) {
      Toast(response.data.msg)
      return Promise.reject(response)
    }
    return response.data
  },
  error => {
    let status = 0
    try {
      status = error.response.data.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        return Promise.reject(error)
      }
    }
    if (status) {
      if (status === 401) {
        store.dispatch('LogOut').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        })
      } else if (status === 403) {
        router.push({ path: '/401' })
      } else {
        const errorMsg = error.response.data.message
        console.log(errorMsg)
        Toast(errorMsg)
      }
    } else {
      Toast('接口请求失败')
      console.log('接口请求失败')
    }
    return Promise.reject(error)
  }
)
export default service
