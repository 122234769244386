<template>
  <div class="main-container">
    <div class="bg">
      <img style="width: 100%; height: 100%;" src="@/assets/images/dowload/bg.png">
    </div>
    <div class="icon">
      <img style="width: 50px; height: 69px;" src="@/assets/images/dowload/icon.png">
    </div>
    <div class="main-content">
      <img style="width: 274px; height: 400px;" src="@/assets/images/dowload/phone.png">
      <van-button class="dowload-button" style="margin-top: 50px; margin-bottom: 30px;">点击下载</van-button>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'

export default {
  name: 'AppDowload',
  components: {
    [Button.name]: Button
  },
  data() {
    return {}
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.main-container {
  width: 100%;
  height: 100%;
  background-color: #282828;
  .bg {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .icon {
    position: relative;
    z-index: 9;
    padding-top: 20px;
    padding-left: 20px;
  }
  .main-content {
    position: relative;
    width: 100%;
    z-index: 10;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    .dowload-button {
      width: 80%;
      color: #fff!important;
      background-color: #4A8FE2!important;
      border:1px solid #4A8FE2!important;
      border-radius: 6px;
    }
  }
}
</style>
