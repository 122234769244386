<template>
  <van-row>
    <van-col span="8" @click="optionChange(0)">
      <div class="rectangle left-radius right-border" :class="selectedOption === 0 ? 'option-active' : 'option'">
        <span :class="selectedOption === 0 ? 'option-title-active' : 'option-title'">{{ $t('hk') }}</span>
      </div>
    </van-col>
    <van-col span="8" @click="optionChange(1)">
      <div class="rectangle right-border" :class="selectedOption === 1 ? 'option-active' : 'option'">
        <span :class="selectedOption === 1 ? 'option-title-active' : 'option-title'">{{ $t('bm') }}</span>
      </div>
    </van-col>
    <van-col span="8" @click="optionChange(2)">
      <div class="rectangle right-radius" :class="selectedOption === 2 ? 'option-active' : 'option'">
        <span :class="selectedOption === 2 ? 'option-title-active' : 'option-title'">{{ $t('eur') }}</span>
      </div>
    </van-col>
  </van-row>
</template>

<script>
import { Row, Col } from 'vant'

export default {
  name: 'MethodSegmentation',
  components: {
    [Row.name]: Row,
    [Col.name]: Col
  },
  data() {
    return {
      selectedOption: 0
    }
  },
  methods: {
    optionChange(index) {
      this.selectedOption = index
      this.$emit('change', index + 1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.option-active {
  text-align: center;
  font-size: 12px;
  background-color: #4A90E2;
}
.option {
  text-align: center;
  font-size: 12px;
  @include color_main_card_bg_2();
}
.option-title {
  margin-bottom: 10px;
  color: #9B9B9B;
  font-size: 12px;
}
.option-title-active {
  margin-bottom: 10px;
  color: #fff;
  font-size: 12px;
}
.rectangle {
  padding: 10px 10px;
  @include color_main_font();
}
.right-border {
  @include color_main_right_border();
}
.left-radius {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;;
}
.right-radius {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>
