var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icon-hit" },
    [
      _vm.lang === "zh"
        ? [
            _vm.item.plan.guessHit === 1
              ? _c("img", {
                  staticStyle: { width: "50px", height: "50px" },
                  attrs: { src: require("@/assets/images/kit/right.png") },
                })
              : _vm._e(),
            _vm._v(" "),
            (_vm.item.plan.planBigType === 1 ||
              _vm.item.plan.planBigType === 2) &&
            _vm.parseLotteryResult(_vm.item.match.spf) === 1
              ? _c("img", {
                  staticStyle: { width: "50px", height: "50px" },
                  attrs: { src: require("@/assets/images/kit/draw.png") },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.item.plan.guessHit !== null && _vm.item.plan.guessHit === 0
              ? _c("img", {
                  staticStyle: { width: "50px", height: "50px" },
                  attrs: { src: require("@/assets/images/kit/wrong.png") },
                })
              : _vm._e(),
          ]
        : [
            _vm.item.plan.guessHit === 0 ||
            _vm.item.plan.guessHit === 1 ||
            _vm.item.plan.guessHit === 2
              ? [
                  _vm.item.plan.guessHit === 1
                    ? _c("img", {
                        staticClass: "icon-hit",
                        attrs: {
                          src: require("@/assets/images/plan/" +
                            _vm.lang +
                            "/icon_silk_correct.png"),
                          width: "50",
                          height: "50",
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  [
                    _c("img", {
                      staticClass: "icon-hit",
                      attrs: {
                        src: require("@/assets/images/plan/" +
                          _vm.lang +
                          "/icon_silk_miss.png"),
                        width: "50",
                        height: "50",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _vm.item.plan.guessHit !== null &&
                    _vm.item.plan.guessHit === 0
                      ? _c("img", {
                          staticClass: "icon-hit",
                          attrs: {
                            src: require("@/assets/images/plan/" +
                              _vm.lang +
                              "/icon_silk_uncorrect.png"),
                            width: "50",
                            height: "50",
                            alt: "",
                          },
                        })
                      : _vm._e(),
                  ],
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }