var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "van-list",
        {
          attrs: { finished: _vm.finished, "finished-text": _vm.finishedText },
          on: { load: _vm.getList },
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        [
          _vm.list.length > 0
            ? _c(
                "div",
                { staticClass: "main-content" },
                _vm._l(_vm.list, function (data, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            "margin-bottom": "10px",
                            color: "#9b9b9b",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("diyDateFormat")(data.date))),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(data.list, function (item, indexValue) {
                        return _c(
                          "div",
                          {
                            key: indexValue,
                            staticClass: "card",
                            staticStyle: { "margin-bottom": "20px" },
                          },
                          [
                            _c(
                              "van-row",
                              {
                                attrs: {
                                  type: "flex",
                                  justify: "space-between",
                                  align: "center",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "main-theme-font-color" },
                                  [_vm._v(_vm._s(item.topic))]
                                ),
                                _vm._v(" "),
                                item.status === 0
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#f0cf2c" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$t("ongoing")) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.status != 0
                                  ? _c(
                                      "span",
                                      { staticClass: "main-theme-font-color" },
                                      [
                                        _vm._v(
                                          "\n              +" +
                                            _vm._s(item.income) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "van-row",
                              {
                                staticStyle: { "margin-top": "20px" },
                                attrs: {
                                  type: "flex",
                                  justify: "space-between",
                                  align: "center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row main-theme-font-color" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(item.optionDTO.forwardName)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    item.result === "流局"
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#9b9b9b" } },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("flow_bureau")) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.optionDTO.forwardName === item.result
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "18px",
                                            height: "18px",
                                            "margin-left": "8px",
                                          },
                                          attrs: {
                                            src: require("@/assets/images/guessing/win.png"),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "row-rigth" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#9b9b9b",
                                        "margin-right": "15px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            item.optionDTO.forwardPersonNum
                                          ) +
                                          "\n                " +
                                          _vm._s(_vm.$t("participation")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#9b9b9b" } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("money")) +
                                          _vm._s(item.optionDTO.forwardNum) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("van-divider", {
                              style: {
                                borderColor: _vm.$theme.getDividerBorderColor(),
                                marginTop: "12px",
                                marginBottom: "0px",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "van-row",
                              {
                                staticStyle: { "margin-top": "20px" },
                                attrs: {
                                  type: "flex",
                                  justify: "space-between",
                                  align: "center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row main-theme-font-color" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.optionDTO.backName)),
                                    ]),
                                    _vm._v(" "),
                                    item.result === "流局"
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#9b9b9b" } },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("flow_bureau")) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.optionDTO.backName === item.result
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "18px",
                                            height: "18px",
                                            "margin-left": "8px",
                                          },
                                          attrs: {
                                            src: require("@/assets/images/guessing/win.png"),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "row-rigth" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#9b9b9b",
                                        "margin-right": "15px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.optionDTO.backPersonNum) +
                                          " " +
                                          _vm._s(_vm.$t("participation")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#9b9b9b" } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("money")) +
                                          _vm._s(item.optionDTO.backNum) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.list.length === 0
        ? _c(
            "div",
            [
              _c(
                "van-empty",
                {
                  staticClass: "empty-image",
                  staticStyle: { "padding-top": "50%" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { width: "100%", "text-align": "center" },
                      attrs: { slot: "image" },
                      slot: "image",
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "257px", height: "84px" },
                        attrs: { src: require("@/assets/images/empty.png") },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { slot: "description" },
                      slot: "description",
                    },
                    [
                      _c("div", [_vm._v(_vm._s(_vm.$t("hint1")))]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { "margin-top": "5px" } }, [
                        _vm._v(_vm._s(_vm.$t("hint2"))),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "bottom" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { path: "/guessing-selection" } } },
                    [
                      _c("van-button", { staticClass: "publishing-button" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("issuing_prophecy")) +
                            "\n        "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: { path: "/guessing-addition" } } },
                    [
                      _c("van-button", { staticClass: "addition-button" }, [
                        _vm._v(_vm._s(_vm.$t("add_theme"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }