var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c("van-sticky", { attrs: { "offset-top": 0 } }, [
        _c(
          "div",
          {
            staticClass: "default-background",
            staticStyle: { "padding-top": "10px" },
          },
          [
            _c(
              "van-row",
              { attrs: { type: "flex", align: "center" } },
              [
                _c(
                  "van-col",
                  {
                    staticStyle: { "padding-left": "10px" },
                    attrs: { span: "20" },
                  },
                  [
                    _c("cube-input", {
                      staticClass: "search-input",
                      attrs: { clearable: true, placeholder: "请输入球队名称" },
                      scopedSlots: _vm._u([
                        {
                          key: "prepend",
                          fn: function () {
                            return [
                              _c("img", {
                                staticStyle: {
                                  width: "14px",
                                  height: "14px",
                                  "margin-left": "10px",
                                },
                                attrs: {
                                  src: require("@/assets/images/live-setting/search_icon.png"),
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.keywords,
                        callback: function ($$v) {
                          _vm.keywords = $$v
                        },
                        expression: "keywords",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "van-col",
                  {
                    staticStyle: { "text-align": "center" },
                    attrs: { span: "4" },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "main-theme-font-color",
                        on: { click: _vm.handleSearch },
                      },
                      [_vm._v("搜索")]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "van-tabs",
              {
                staticStyle: { width: "100px" },
                attrs: {
                  color: _vm.$theme.getTabsUnderlineColor(),
                  "title-inactive-color": "#9B9B9B",
                  "title-active-color": _vm.$theme.getTabTitleActiveColor(),
                  "line-width": "22px",
                },
                model: {
                  value: _vm.activeIndex,
                  callback: function ($$v) {
                    _vm.activeIndex = $$v
                  },
                  expression: "activeIndex",
                },
              },
              [
                _c("van-tab", { attrs: { title: "足球" } }),
                _vm._v(" "),
                _c("van-tab", { attrs: { title: "篮球" } }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text": _vm.finishedText,
              },
              on: { load: _vm.getList },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _c(
                "van-checkbox-group",
                {
                  model: {
                    value: _vm.checkedList,
                    callback: function ($$v) {
                      _vm.checkedList = $$v
                    },
                    expression: "checkedList",
                  },
                },
                [
                  _c(
                    "van-cell-group",
                    _vm._l(_vm.list, function (item, index) {
                      return _c(
                        "div",
                        { key: item.title },
                        [
                          _c("span", { staticClass: "main-theme-font-color" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _vm._v(" "),
                          _vm._l(item.list, function (subItem, subIndex) {
                            return _c(
                              "div",
                              {
                                key: subItem.id,
                                staticStyle: {
                                  "margin-top": "10px",
                                  "margin-bottom": "10px",
                                },
                              },
                              [
                                _c(
                                  "van-cell",
                                  {
                                    attrs: { clickable: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.cellClicked(
                                          subItem,
                                          index,
                                          subIndex
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "card" },
                                      [
                                        _c(
                                          "van-row",
                                          {
                                            attrs: {
                                              type: "flex",
                                              justify: "space-between",
                                              align: "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "main-theme-font-color",
                                                staticStyle: {
                                                  "max-width": "80%",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(subItem.eventName)
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          subItem.roundId !==
                                                          "0",
                                                        expression:
                                                          "subItem.roundId !== '0'",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      "第" +
                                                        _vm._s(
                                                          subItem.roundId
                                                        ) +
                                                        "轮"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(subItem.matchTime) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("van-checkbox", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: subItem.isBook !== 1,
                                                  expression:
                                                    "subItem.isBook !== 1",
                                                },
                                              ],
                                              ref: "checkboxes",
                                              refInFor: true,
                                              attrs: { name: subItem },
                                            }),
                                            _vm._v(" "),
                                            _c("van-icon", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: subItem.isBook === 1,
                                                  expression:
                                                    "subItem.isBook === 1",
                                                },
                                              ],
                                              attrs: {
                                                name: "checked",
                                                size: "22px",
                                                color: "#3D679A",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "van-row",
                                          {
                                            staticStyle: {
                                              "margin-top": "20px",
                                            },
                                            attrs: {
                                              type: "flex",
                                              align: "center",
                                            },
                                          },
                                          [
                                            subItem.homeLogo &&
                                            subItem.homeLogo !== ""
                                              ? _c("img", {
                                                  staticStyle: {
                                                    width: "26px",
                                                    height: "26px",
                                                    "border-radius": "13px",
                                                    border:
                                                      "1px solid rgba(255,255,255,1)",
                                                    "margin-right": "10px",
                                                  },
                                                  attrs: {
                                                    src: subItem.homeLogo,
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !subItem.homeLogo ||
                                            subItem.homeLogo === ""
                                              ? _c("img", {
                                                  staticStyle: {
                                                    width: "26px",
                                                    height: "26px",
                                                    "border-radius": "13px",
                                                    border:
                                                      "1px solid rgba(255,255,255,1)",
                                                    "margin-right": "10px",
                                                  },
                                                  attrs: {
                                                    src: require("@/assets/images/team_logo_default.png"),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "main-theme-font-color",
                                                staticStyle: {
                                                  "font-size": "12px",
                                                },
                                              },
                                              [_vm._v(_vm._s(subItem.homeName))]
                                            ),
                                            _vm._v(" "),
                                            _c("img", {
                                              staticStyle: {
                                                width: "22px",
                                                height: "25px",
                                                "margin-left": "20px",
                                                "margin-right": "20px",
                                              },
                                              attrs: {
                                                src: _vm.$theme.getVsImage(),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "main-theme-font-color",
                                                staticStyle: {
                                                  "font-size": "12px",
                                                },
                                              },
                                              [_vm._v(_vm._s(subItem.awayName))]
                                            ),
                                            _vm._v(" "),
                                            subItem.awayLogo &&
                                            subItem.awayLogo !== ""
                                              ? _c("img", {
                                                  staticStyle: {
                                                    width: "26px",
                                                    height: "26px",
                                                    "border-radius": "13px",
                                                    border:
                                                      "1px solid rgba(255,255,255,1)",
                                                    "margin-left": "10px",
                                                  },
                                                  attrs: {
                                                    src: subItem.awayLogo,
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !subItem.awayLogo ||
                                            subItem.awayLogo === ""
                                              ? _c("img", {
                                                  staticStyle: {
                                                    width: "26px",
                                                    height: "26px",
                                                    "border-radius": "13px",
                                                    border:
                                                      "1px solid rgba(255,255,255,1)",
                                                    "margin-left": "10px",
                                                  },
                                                  attrs: {
                                                    src: require("@/assets/images/team_logo_default.png"),
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.loading && _vm.list.length === 0
            ? _c(
                "van-empty",
                {
                  staticClass: "empty-image",
                  staticStyle: { "margin-top": "40%" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { width: "100%", "text-align": "center" },
                      attrs: { slot: "image" },
                      slot: "image",
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "257px", height: "84px" },
                        attrs: { src: require("@/assets/images/empty.png") },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { slot: "description" },
                      slot: "description",
                    },
                    [_c("div", [_vm._v(_vm._s(_vm.$t("nomatch")))])]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bottom" }, [
        _c(
          "div",
          { staticClass: "row", staticStyle: { "padding-top": "10px" } },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("zuiduiyuyue") +
                    "(" +
                    _vm.$t("selected") +
                    _vm.checkedList.length +
                    _vm.bookCount
                ) + ")"
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row", staticStyle: { "padding-top": "10px" } },
          [
            _c(
              "van-button",
              {
                staticClass: "button-primary",
                staticStyle: { width: "80%", "border-radius": "6px" },
                attrs: { disabled: _vm.checkedList.length === 0 },
                on: { click: _vm.commit },
              },
              [_vm._v("确认")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }