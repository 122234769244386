<template>
  <div class="page-main-content" :data-theme="$store.getters.themeType">
    <van-row type="flex" align="center" justify="space-between" style="color: #fff; margin-bottom: 20px; margin-left: 15px; margin-right: 15px; margin-top: 15px;">
      <div class="date-picker" @click="pickDate">
        <span>{{ currentDate | parseTime('{m}') }}{{ $t('month') }}</span>
        <img style="width: 12px; height: 12px; margin-left: 4px;" src="@/assets/images/live/arrow-down.png">
        <van-popup v-model="showDatePicker" :get-container="getContainer" :round="true" position="bottom" :style="{ height: '40%' }">
          <van-datetime-picker
            v-model="date"
            type="year-month"
            :title="$t('select')"
            :min-date="minDate"
            :max-date="maxDate"
            :formatter="formatter"
            @confirm="confirm()"
            @cancel="closeDatePicker()"
          />
        </van-popup>
      </div>
      <div class="main-theme-font-color-2">({{ $t('earnings')+unit }})</div>
    </van-row>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="finishedText"
      @load="getList"
    >
      <div v-for="(item,index) in list" :key="index" :title="item" class="list-content">
        <van-row type="flex" align="center" justify="space-between" style="margin-left: 20px; margin-right: 20px;">
          <span>{{ item.time }}</span>
          <span>{{ item.income }}</span>
        </van-row>
        <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
      </div>
    </van-list>
    <van-empty
      v-if="!loading && list.length === 0"
      class="empty-image"
      style="padding-top: 30%;"
    >
      <div slot="image" style="width: 100%; text-align: center">
        <img style="width: 257px; height: 84px;" src="@/assets/images/empty.png">
      </div>
      <div slot="description" style="text-align: center;">
        <div>{{ $t('nodata') }}</div>
      </div>
    </van-empty>
  </div>
</template>

<script>
import { CellGroup, Cell, Row, Col, Divider, List, DatetimePicker, Popup, Empty } from 'vant'
import { incomeList } from '@/api/live/income'
import { formatDate } from '@/utils/tools'

export default {
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [List.name]: List,
    [Empty.name]: Empty,
    [DatetimePicker.name]: DatetimePicker,
    [Popup.name]: Popup
  },
  props: {
    unit: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      list: [],
      lastId: 0,
      income: 0,
      loading: false,
      finished: false,
      finishedText: '没有更多了',
      showDatePicker: false,
      minDate: new Date(2020, 1, 1),
      currentDate: new Date(),
      date: undefined
    }
  },
  computed: {
    maxDate() {
      return new Date(formatDate(new Date(), 'yyyy'), formatDate(new Date(), 'MM') - 1, 1)
    }
  },
  methods: {
    getContainer() {
      return document.querySelector('.page-main-content')
    },
    getList() {
      const date = formatDate(this.currentDate) + ' 00:00:00'
      this.loading = true
      incomeList({ dateMonth: date, lastId: this.lastId }).then(response => {
        this.loading = false
        this.income = response.data.totalIncome
        this.list = this.list.concat(response.data.list)
        if (response.data.list.length > 0) {
          this.lastId = response.data.list[response.data.list.length - 1].id
        }
        if (response.data.list.length < 20) {
          this.finished = true
          this.finishedText = '没有更多了'
        }
        if (this.list.length < 20) {
          this.finishedText = ''
        }
      })
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    },
    pickDate() {
      this.showDatePicker = true
    },
    confirm() {
      this.currentDate = this.date
      this.showDatePicker = false
      this.finished = false
      this.lastId = 0
      this.list = []
      this.getList()
    },
    closeDatePicker() {
      this.showDatePicker = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.page-main-content {
  @include color_main_bg();
}
.list-content {
  @include color_main_font();
  @include color_main_bg();
}
.van-list {
  @include color_main_bg();
}
.van-cell {
  @include color_main_font();
  @include color_main_bg();
}
.main-content {
  padding-top: 16px;
}
.feather {
  width: 24px;
  height: 24px;
}
.amount {
  height:18px;
  font-size:18px;
  font-family:PingFangSC-Semibold,PingFang SC;
  font-weight:600;
  color:rgba(240,207,44,1);
  line-height:18px;
  margin-left: 4px;
}
.date-picker {
  color: #4A90E2
}
/deep/.van-picker__toolbar {
  @include color_main_card_bg();
  @include color_main_font();
}
/deep/.van-picker__confirm {
  color: #4A8FE2;
}
/deep/.van-picker__cancel {
  color: #9B9B9B;
}
/deep/.van-picker {
  @include color_main_card_bg();
}
/deep/.van-picker-column__item {
  @include color_main_font();
}
/deep/.van-picker__mask {
  background-image: none
}
/deep/.van-hairline-unset--top-bottom::after {
  border-top: 1px solid #373941!important;
  border-bottom: 1px solid #373941!important;
}
/deep/.empty-image .van-empty__image {
  width: 257px;
  height: 84px;
}
</style>
