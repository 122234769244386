<template>
  <div class="app-container" :data-theme="$store.getters.themeType">
    <van-sticky>
      <div
        class="row main-bg"
        style="width: 100%; padding-top: 30px; padding-bottom: 0px"
      >
        <van-button
          icon="plus"
          class="button-default"
          style="width: 140px; height: 30px; border-radius: 4px"
          @click="handleAdd"
        >
          {{ $t('add_theme') }}
        </van-button>
      </div>
    </van-sticky>
    <div class="main-content">
      <van-checkbox-group
        v-if="list.length > 0"
        v-model="checkList"
        :max="5"
        style="font-size: 14px; margin-top: 30px"
        icon-size="16px"
        @change="change"
      >
        <div
          v-for="item in list"
          :key="item.id"
          style="margin-top: 10px; width: 100%"
        >
          <van-row type="flex" justify="space-between" align="center">
            <van-col span="20" @click="handleCheck(item)">
              <van-checkbox :name="item">
                <div
                  slot="default"
                  class="main-theme-font-color"
                  style="font-size: 16px; padding-left: 14px"
                >
                  <div>
                    {{ item.topic }}
                  </div>
                  <div style="margin-top: 10px; font-size: 14px">
                    {{ item.forwardName }} VS {{ item.backName }}
                  </div>
                </div>
              </van-checkbox>
            </van-col>
            <van-col span="4">
              <div class="row">
                <router-link
                  :to="{ path: '/guessing-editing', query: { id: item.id } }"
                >
                  <img
                    style="width: 18px; height: 18px; margin-right: 30px"
                    :src="$theme.getEditImage()"
                  />
                </router-link>
                <img
                  style="width: 18px; height: 18px"
                  :src="$theme.getDeleteImage()"
                  @click=";(showAlert = true), (selectedItem = item)"
                />
              </div>
            </van-col>
          </van-row>
          <van-divider
            :style="{
              borderColor: $theme.getDividerBorderColo2(),
              marginTop: '12px',
              marginBottom: '0px'
            }"
          />
        </div>
      </van-checkbox-group>
      <alert
        :visible.sync="showAlert"
        :message="$t('hint4')"
        :cancel-button-text="$t('cancel')"
        :confirm-button-text="$t('confirm')"
        @confirm="handleDelete()"
      />
      <van-empty
        v-if="list.length === 0"
        class="empty-image"
        style="margin-top: 40%"
      >
        <div slot="image" style="width: 100%; text-align: center">
          <img
            style="width: 257px; height: 84px"
            src="@/assets/images/empty.png"
          />
        </div>
        <div slot="description" style="text-align: center">
          <div>{{ $t('hint5') }}</div>
          <div>{{ $t('above_to_add') }}</div>
        </div>
      </van-empty>
    </div>
    <div class="bottom">
      <div style="text-align: center; margin-top: 11px; margin-bottom: 14px">
        <span style="color: #9b9b9b; font-size: 13px">
          {{ $t('hint3') }}
        </span>
        <span style="font-size: 13px">
          （{{ $t('selected') }} {{ checkList.length }}{{ $t('Entries') }}）
        </span>
      </div>
      <div class="row">
        <van-button
          class="button-primary"
          style="width: 283px; height: 40px; border-radius: 6px"
          :disabled="checkList.length === 0"
          @click="commit"
        >
          {{ $t('issuing_prophecy') }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>

import { Row, Col, Button, Divider, CheckboxGroup, Checkbox, Empty, Toast, Sticky } from 'vant'
import { getTopicList, deleteTopic, publish, hasLiving } from '@/api/guessing/index'
import Alert from '@/components/Alert'

export default {
  name: 'GuessingSelection',
  components: {
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Empty.name]: Empty,
    [Sticky.name]: Sticky,
    [Toast.name]: Toast,
    Alert
  },
  data() {
    return {
      list: [],
      livingStatus: 0,
      checkList: [],
      showAlert: false,
      selectedItem: undefined
    }
  },
  beforeRouteLeave(to, from, next) {
    if ((to.name === 'GuessingEditing' || to.name === 'GuessingAddition') && this.checkList.length !== 0) {
      this.$forceUpdate()
      from.meta.keepAlive = true
      to.meta.deepth = 3
    } else {
      from.meta.keepAlive = false
      to.meta.deepth = 1
    }
    next()
  },
  created() {
    this.setTitle()
    this.getList()
  },
  methods: {
    setTitle() {
      document.title = this.$t('select_theme')
    },
    getList() {
      getTopicList({}).then(response => {
        this.list = response.data
        this.checkList = []
      })
    },
    change() {
      this.$forceUpdate()
    },
    handleCheck(item) {
      if (this.checkList.length >= 5 && !this.checkList.includes(item)) {
        Toast(this.$t('hint3'))
      }
    },
    handleAdd() {
      if (this.list.length >= 20) {
        Toast(this.$t('hint6'))
      } else {
        this.$router.push({
          path: '/guessing-addition'
        })
      }
    },
    handleDelete() {
      this.showAlert = false
      const id = this.selectedItem.id
      deleteTopic({ id }).then(response => {
        Toast(this.$t('have_deleted'))
        this.selectedItem = undefined
        this.getList()
      })
    },
    commit() {
      hasLiving({}).then(response => {
        this.livingStatus = response.data.hasLiving
        if (this.livingStatus === 0) {
          Toast(this.$t('hint7'))
        } else {
          const list = this.checkList.map(obj => {
            return obj.id
          })
          publish({ templateIds: list }).then(response => {
            this.$router.push({ path: 'guessing' })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/color.scss';
.main-content {
  width: 100%;
  overflow: scroll;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 140px;
  @include color_main_bg();
}
.button-default {
  @include color_main_bg();
  @include color_border();
}
.bottom {
  position: fixed;
  width: 100%;
  height: 100px;
  bottom: 35px;
  overflow: scroll;
  @include color_main_font();
  @include color_main_bg();
  .content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
/deep/.empty-image .van-empty__image {
  width: 257px;
  height: 84px;
}
</style>
