export default {
  loading: "로드 중...",
  searchplaceholder: "앵커, 채널, 게임",
  dowload: "다운로드",
  home: "집",
  live: "살다",
  match: "성냥",
  plan: "팁",
  mine: "내 거",
  hotgame: "인기 대회",
  news: "정보",
  mall: "쇼핑 센터",
  video: "짧은 영상",
  activity: "활동",
  hotlive: "뜨거운 라이브 방송",
  hotanchor: "인기 라이브 방송",
  highlights: "하이라이트",
  more: "더",
  noliving: "현재 방송 중인 앵커는 없습니다.",
  nolivetips: "앵커가 아직 예열 중입니다. 잠시만 기다려 주세요...",
  chat: "채팅",
  speak: "채팅 내용 입력",
  nospeak: "로그인을 하셔야 통화가 가능합니다~",
  gologin: "로그인",
  seenewnews: "새로운 뉴스",
  unlockvip: "무료로 VIP 잠금 해제",
  announcement: "발표:",
  welcome: "환영",
  enterroom: "생방송실에 입장하다",
  followed: "이미 팔로우했습니다",
  newsinfo: "정보",
  experts: "주요 전문가",
  hit: "때리다",
  return: "반품",
  wins: "승리",
  fb: "축구",
  bk: "농구",
  notstarted: "시작되지 않음",
  checkmore: "세부 정보보기",
  videolist: "하이라이트/비디오",
  qianzan: "앞으로",
  jinhua: "본질",
  yulei: "오락",
  recommendplan: "추천 플랜",
  buyedplan:'구매한 요금제',
  winrate: "타율",
  zuigaolianghong: "{msg} 가장 높은 연속 빨간색",
  lianghong: "연홍",
  returnrate: "반품률",
  buzhong: "당첨되지 않으면 환불",
  xieyi: "계약 관련",
  personal: "개인 정보 보호 계약",
  userxieyi: "사용자 계약",
  livexieyi: "생방송 계약",
  chargexieyi: "재충전 계약",
  anchorhelp: "앵커 도움말",
  anchornew: "새로운 앵커가 도로에 있습니다",
  followus: "우리를 따르라",
  helpcenter: "지원 센터",
  scancodedowload: "코드를 스캔하여 Bobcat 앱을 다운로드하세요",
  smzb: "고양이 라이브",
  noduty:
    "고지 사항: Bobcat Live의 모든 축구 생방송 및 NBA 생방송 신호는 사용자가 수집하거나 검색 엔진에서 얻습니다. 모든 콘텐츠는 인터넷에서 나옵니다. 우리는 라이브 방송 신호 및 비디오 콘텐츠를 자체적으로 제공하지 않습니다. 귀하의 권리가 침해된 경우 당사에 알려주시기 바랍니다. , 최대한 빨리 처리해드리겠습니다.",
  roomsearch: "키워드를 입력하세요",
  myfollow: "내 팔로우",
  clearcache: "캐시 지우기",
  kinds: "분류",
  all: "모두",
  others: "다른",
  yingchao: "프리미어리그",
  hot: "더운",
  esports: "e스포츠",
  exit: "출구",
  downloadApp: "앱 다운로드",
  want: "생방송으로 이동",
  roomnum: "방 번호",
  wang: "핏기 없는",
  feedback: "피드백",
  funs: "팬",
  follows: "따르다",
  numberonlive: "현재 앵커가 이 이벤트를 방송 중입니다.",
  anchorchat: "앵커 채팅",
  contributerank: "기부 목록",
  othermatch: "다른 경기",
  chatinput: "함께 채팅하고 취해보세요",
  send: "보내다",
  livefail: "라이브 스트림을 가져오지 못했습니다.",
  tryagain: "다시 시도하려면 클릭하세요.",
  pushlist: "푸시 주문 목록",
  reward: "보상 설명",
  nopush: "아직 푸시 주문이 없습니다.",
  anchorpush: "앵커가 명령을 내립니다.",
  maobipokect: "고양이 동전 빨간 봉투",
  sendsuccess:
    "성공적으로 전송하면 모든 플랫폼에서 플로팅 스크린 브로드캐스트가 실행될 수 있습니다.",
  manexchange: "사람이 받을 수 있어요",
  threeminexhang: "3분 안에 수집 가능",
  exchangenow: "즉시수집 가능",
  sendpokect: "빨간 봉투 보내기",
  pokecttips: "생방송실에서 빨간 봉투를 보냈어요~ 바로 수령하실 수 있어요!",
  pokecttips1: "생방송실에서 빨간봉투를 보냈어요~ 3분안에 받아보실 수 있어요!",
  task: "일",
  litterhelper: "작은 도우미",
  litterhelper1: "할인 도우미",
  enter: "입력하려면 클릭하세요.",
  gift: "선물",
  high: "HD",
  charge: "재충전",
  balance: "균형",
  words: "쾅",
  sendonepokect: "빨간 봉투를 보냈어요",
  exchange: "받다",
  ofpokect: "빨간 봉투",
  nopokect: "너무 느려 빨간 봉투가 흩어졌어",
  maobi: "고양이 동전",
  savemyaccount: "내 계좌에 입금됐어",
  checkexchange: "청구 세부정보 보기",
  exchangelist: "빨간 봉투 수집 기록",
  pokecttips2: "고양이코인 빨간봉투에 고양이코인 x개를 집어들었어요!",
  contribute: "일일 기여",
  contribute1: "주간 기여",
  contribute2: "기여 목록",
  contributevalue: "기여 가치",
  living: "생방송",
  matchstatus: "경기 상태",
  zhishu: "색인",
  livematchstatus: "실시간 경기 상황",
  jinqiu: "목표",
  zugong: "돕다",
  shemeng: "사격",
  dianqiu: "페널티 킥",
  wulongqiu: "나만의 목표",
  huangpai: "경고 카드",
  nodianqiu: "페널티킥이 득점되지 않았습니다.",
  huanxia: "바꾸다",
  nomatchevent: "아직 경기 이벤트가 없습니다",
  onlyjinqiu: "목표만 지켜보세요",
  analysis: "기술적 분석",
  contrrate: "보유율",
  shezheng: "목표물에 쏘다",
  jingong: "공격",
  dendrgjingong: "위험한 공격",
  renyiqiu: "프리킥",
  jiaoqiu: "코너킥",
  hongpai: "레드 카드",
  login: "로그인",
  roommannage: "방 관리자를 임명하다",
  roomtips: "Fifteen을 객실 관리자로 임명하시겠습니까?",
  roomtips1: "피프틴이 룸 매니저로 해고될 것이 확실합니까?",
  cancel: "취소",
  confirm: "좋아요",
  logout: "로그 아웃",
  uplevel: "거리 업그레이드: {msg} 경험치",
  wallt: "지갑",
  wantlive: "실시간 방송을 원해요",
  usercenter: "개인 센터",
  vipright: "VIP 특전 소개",
  pwdlogin: "비밀번호 로그인",
  mobilelogin: "모바일 로그인",
  doregister1: "계정 없음",
  doregister2: "지금 등록하세요",
  forgetpwd: "비밀번호를 잊어 버렸습니까?",
  logintips: "로그인은 귀하가 동의한다는 것을 의미합니다",
  inputmobile: "휴대폰 번호를 입력하세요",
  inputcode: "승인 번호를 입력하십시오",
  mobiletips: "6~20자리 비밀번호 또는 문자 비밀번호를 입력하세요.",
  resetpwd: "암호를 재설정",
  register: "등록하다",
  sendcode: "코드 받기",
  registernow: "지금 등록하세요",
  appdownload: "앱 다운로드",
  advice: "피드백",
  backtop: "뒤로 가기",
  intime: "즉각적인",
  anchor: "닻",
  matchschude: "일정",
  matchresult: "경기 결과",
  checkdata: "데이터 보기",
  nomatch: "아직 일치하는 항목이 없습니다.",
  winlist: "히트리스트",
  returnlist: "반품 목록",
  lianghongrank: "지속적으로 레드리스트",
  hotrace: "뜨거운 경주",
  allrace: "모든 인종",
  onrace: "경쟁 중",
  onsale: "판매 예정",
  recommendmaster: "전문가 추천",
  mime: "나",
  boughtplan: "구매한 요금제",
  sorttype: "포괄적인 정렬",
  returnfirst: "먼저 돌아오다",
  winfirst: "승률 우선",
  lianghongfirst: "연홍 먼저",
  nodata: "아직 데이터가 없습니다",
  detail: "세부",
  newslocal: "현재 위치: 홈/뉴스",
  comments: "코멘트",
  nocomments: "아직 댓글이 없습니다",
  docomments: "코멘트",
  typecomments: "뭐라고 말해보세요",
  goods: "상품",
  searchgoods: "마음에 드는 제품을 검색해보세요",
  mycarts: "내 장바구니",
  myorders: "내 주문",
  demens: "다이아몬드",
  goodsinfo: "상품정보",
  kucun: "목록",
  limit: "한도 교환",
  value: "단가",
  num: "수량",
  total: "소계",
  choice: "선택된",
  jiesuan: "합의",
  chooseall: "모두 선택",
  limitnum: "누적 구매 한도",
  limitday: "일일 구매 한도",
  limitweek: "주간 구매 한도",
  totallimt: "총 상환액:",
  totallimt1: "일일 누적 상환액:",
  totallimt2: "주간 총 상환액:",
  jiage: "가격",
  goodsdetail: "제품 세부 정보",
  buynow: "지금 사용하세요",
  addcart: "장바구니에 추가",
  recommendgoods: "당신을 위해 추천 된",
  confirmbuy: "상환 확인",
  mobile: "휴대 전화 번호",
  recipename: "수취인 이름",
  address: "배달 주소",
  remark: "주목",
  inputreciptename: "수취인의 이름을 입력하세요",
  inputaddress: "배송주소를 입력하세요",
  orders: "주문 번호",
  time: "시간",
  recipe: "수취인",
  telphone: "전화",
  address1: "주소",
  recovideo: "추천 영상",
  onplay: "정상적인 플레이...",
  ongoing: "진행 중",
  result: "종료됨",
  reserve: "예약",
  reserved: "예약된",
  myinfo: "내 정보",
  anchorlevel: "앵커 레벨",
  userlevel: "사용자 수준",
  changeavter: "아바타 변경",
  changeinfo: "정보 수정",
  usertips: "(사진 크기는 1M를 초과할 수 없으며, png, jpg 형식을 지원합니다)",
  uploadimg: "현지 사진 업로드",
  myaccount: "내 계정",
  anchorinfo: "앵커 관련",
  tasks: "태스크 센터",
  signin: "친절하게 로그인하세요",
  mywords: "나의 예언",
  downloadreword: "초대 보상 다운로드",
  nickname: "별명",
  inputnickname: "닉네임을 입력하세요",
  infotips:
    "설명:\n 1. 새로운 닉네임은 등록기준을 준수하여야 하며, 숫자, 문자, 한자, 기호 등을 사용할 수 있습니다. 최대 길이는 24자(중국어는 2자, 영어는 1자)를 초과할 수 없습니다.\n 2. 서버 업데이트나 기타 문제로 인해 닉네임 수정이 실패할 경우, 고객센터로 문의해주세요.",
  introduce: "개인 프로필",
  region: "지역",
  selectprovince: "지역을 선택해주세요.",
  selectregion: "도시를 선택하세요.",
  sex: "성별",
  savesecrt: "비밀의",
  man: "남성",
  woman: "여성",
  bedate: "생일",
  selectdate: "연도를 선택하세요.",
  selectmonth: "월을 선택해주세요.",
  select: "선택 해주세요",
  year: "년도",
  month: "월",
  day: "낮",
  save: "구하다",
  accountvalue: "계정 잔액",
  billlist: "청구서 기록",
  cast: "소비",
  earnings: "수입",
  casttype: "소비 유형",
  sendgift: "선물",
  prophecy: "예언",
  redpokect: "빨간 봉투",
  exchangegoods: "선물 교환",
  luckydraw: "유료 추첨",
  querytime: "쿼리 시간",
  starttime: "시작일",
  endtime: "종료일",
  query: "질문",
  reset: "초기화",
  castmaobi: "소비량(고양이코인)",
  allprophecy: "모든 예언",
  prophecylist: "예언의 전당",
  planlist: "문제 해결을 위한 팁",
  yuyuematch: "예약 일치",
  earnlist: "수익 기록",
  liveset: "라이브 방송 설정",
  roompush: "생방송방 푸시주문",
  roomset: "생방송 방 문자 설정",
  edit: "편집하다",
  beAnchor: "앵커가 되어 보세요",
  inviterUser: "친구를 초대",
  setting: "환경",
  logining: "로그인 중...",
  loginerr: "로그인에 실패했습니다. 나중에 다시 시도해 주세요...",
  loginsecucced: "성공적 로그인",
  codesend: "인증코드가 성공적으로 전송되었습니다",
  inputrightphone: "정확한 휴대폰 번호를 입력하세요",
  inputagain: "비밀번호를 다시 입력하세요.",
  pwdtips: "두 번 입력한 비밀번호가 일치하지 않습니다.",
  pwdtips1:
    "비밀번호 형식이 잘못되었습니다. 비밀번호 6~20자리 또는 문자 비밀번호를 입력하세요.",
  registed: "등록 성공",
  registerfail: "등록에 실패했습니다. 나중에 다시 시도해 주세요...",
  registing: "등록 중...",
  findpwd: "비밀번호 검색",
  submit: "제출하다",
  settingPwd: "비밀번호가 성공적으로 설정되었습니다",
  submitting: "제출 중...",
  "nomore": '더 이상',
  "pullrefresh": '새로고침하려면 아래로 당기세요',
  "notstart": '게임이 시작되지 않았습니다. 다른 게임을 볼 수 있습니다:',
  'newsDe':'정보 세부정보',
  'shouqi':'치워두세요',
  'fabu':'해제',
  'eur':'유럽 디스크',
    "hk": '홍콩 디스크',
    "bm":'오버/언더',
    'rq':'핸디캡',
    'mzl':'적중률',
   'mago':'분 전',
   'sago':'초 전',
   'hago':'몇 시간 전',
   'mianfei':'무료',
   'tacticde':'계획 세부정보',
   'zhusheng':'이기다',
   "pingju":'그리다',
   "kesheng":'잃다',
   'pay':'지금 지불하세요',
   'stop': '판매가 중지되었습니다',
   'reason':'추천 이유',
   'afterpay': '결제 후 계획 및 분석 보기',
   'stoptime':'판매 마감일:',
   'tacticdetips':'참고: 의견은 참고용일 뿐이며 복권 사업을 제공하지 않으므로 주의하시기 바랍니다! ',
   'dago':'하루 전',
    'wago':'일주일 전',
    'mouthago':'개월 전',
    'ganggang':'지금 막',
    'bigqiu':'오버{msg}balls',
    'smallqiu':'언더{msg}balls',
    'buyplan': '전문가 계획 구매',
    'buyplantips':'이 플랜을 구매하려면 {num}개의 고양이 코인이 필요합니다. 충분합니까? ',
    'yesbuy':'구매 확인',
    'cancelfollow':'팔로우가 성공적으로 취소되었습니다',
    'followsucceed': '성공 따르기',
    'buysucceed': '구매 성공',
    'buyfialed':'구매 실패',
    'liangsai':'리그',
    'team':'홈 대 원정',
    'playtype':'플레이',
    'rate':'확률',
    'chongzhijilu': '충전 기록',
    'xiaofeijilu':'소비 기록',
    'huodongshouyi':'활동 소득',
    'myinvitecode':'내 초대 코드',
    'inputcodeinapp':'앱에 로그인할 때 입력하세요',
    'dianjifuzhi':'초대 코드를 복사하려면 클릭하세요',
    'jietu': '친구에게 스크린샷 보내기',
    'shoukuang': '결제 방법',
    'alipay':'알리페이',
    'wechat':'위챗',
    'shoukuangzhanghao':'결제 계좌 번호',
    'invitegonglue': '초대 전략',
    'shareqrcode':'다운로드 QR 코드 및 독점 초대 코드 공유',
    'getjiangli': '친구가 처음으로 로그인에 성공하면 보상을 받게 됩니다.',
    'invitemingxi': '초대 세부정보',
    'zhanwuhaoyou':'아직 초대된 친구가 없습니다. 가서 친구를 초대하세요! ',
    'nicheng':'닉네임',
    'zucengshijian': '등록 시간',
    'jiangli':'보상',
    'islogout':'정말로 로그아웃하시겠습니까? ',
    'dijilun':'{num}라운드',
    'guangyuus':'회사 소개',
    'zhuanjiajieshao':'전문가 소개',
    'recent10': '지난 10경기 수익률',
    'zuijinzhuantai': '최근 상태',
    'zhengzaituijian': '추천',
    'historyrem':'역사 추천',
    '비사이시지안':'경기 이벤트',
    'qiuduitongji':'팀 통계',
    'qiudui':'팀',
    'zongfen':'총점',
    'bisaikaishi': '게임이 시작됩니다',
    'fangui':'파울',
    'erfen':'두 점',
    'faqiu':'자유투',
    'sanfen':'세 점',
    'paizhao':'사진을 찍다',
    'xuanzezhaopian': '앨범에서 선택',
    'jianyanzhi':'경험 가치',
    'yiwangcheng':'완료',
    'dangqian':'현재',
    'shengjidao':'다음으로 업그레이드',
    'xuyao':'필요',
    'cehngzhang':'성장 특권',
    'dengji':'레벨 아이콘',
    'kaiqi':'열기',
    'qidai':'계속 지켜봐주세요',
    'mashanglai': '더 많은 권한이 곧 제공될 예정입니다.',
    'qiandao':'매일 체크인',
    'qiandaochenggong': '성공적으로 로그인했습니다',
    'qiandaotips':'따뜻한 알림: 보너스와 실물 보상을 신청하려면 어시스턴트에게 문의하세요. ',
    'chakanqiandao': '보상 보기',
    'goxiaozhushou': '연락처 보조',
    'dijitian':'{msg}일',
    'zhubodasan':'앵커 보상 목록',
    'riban':'일일 목록',
    'yueban':'월별 목록',
    'zongban':'일반 목록',
    'gongxianzhi':'공헌 가치',
    'fananjieshu': '기획 판매가 종료되었습니다',
    'bisaijieshu': '분석 세부 사항은 대회 후 발표될 예정입니다',
    'yuebuzu': '잔액 부족',
    'qitajinnang': '전문가의 다른 팁',
    'qitatuijian':'기타 권장사항',
    'dengluhou': '구매한 기록은 나중에만 볼 수 있습니다',
    'zhengzaibofng':'지금 재생 중',
    'jijiangbofang': '놀러 온다',
    'touxiangshangchuan': '아바타 업로드',
    'shouyeleixing':'소득 유형',
    'lingquhongbao': '빨간 봉투를 받으세요',
    'hongbaotuikuang':'빨간 봉투 반환',
    'jinnantutikuang': '팁 환불',
    'yuyanshouyi':'예언 이익',
    'yuyantuikuang':'예언 환불',
    'hongbaoyu':'홍바오유',
    'jincaidati': '추측 및 답변',
    'zhuanpan':'룰렛 복권',
    'jindan': '황금알을 깨뜨리는 것',
    'zenfa':'시스템 추가 발급',
    'dengluhuodong':'로그인 활동',
    'guankan':'생방송 시청하기',
    'danmu': '포격을 보내다',
    'denglujianli':'APP 로그인 보상',
    'tuidan': '푸시 주문 보상',
    'zhuanpanchoujian': '새로운 회전목마 복권',
    'zhuti':'테마',
    'xuanxian':'옵션',
    'kejitouzhu':'누적된 베팅',
    'xuanxianbeishu':'다중 옵션',
    'touzhurenshu': '베터 수',
    'jieguo':'결과',
    'zhuantai':'상태',
    'daojishi':'카운트다운',
    'fabuplantips':'공 풀기 팁 공개하고 엄청난 돈을 벌어보세요~',
    'goumairenshu': '구매자 수',
    'zongji':'합계',
    'fencheng':'분할되기 전',
    'liveshouyi': '생방송 정산가능소득',
    'jinnanshouyi': '팁은 수입으로 정산 가능',
    'jiesuanrules': '정산 규칙',
    'tequan':'레벨 권한',
    'kuaisushengji': '빠른 업그레이드 방법',
    'wodedengji':'내 수준',
    'dengjishuoming':'레벨 설명',
    'zhubotekan':'앵커 특권',
    'shejizhong': '앵커 권한이 설계되고 있습니다',
    'moretequan': '더 많은 권한이 곧 제공될 예정입니다.',
    'huodeliwu': '선물을 받으세요',
    'qingfenzhibo': '부지런한 생방송',
    'zensonliwu': '선물을 주다',
    'yonghutequan':'사용자 권한',
    'tequansheji': '사용자 권한은 설계 중입니다.',
    'fengzhong':'분',
    'jiyang':'경험',
    'quedingquxiao': '정말 팔로우를 취소하시겠습니까? ',
    'zhengquetianxie': '정확한 정보를 입력하세요. 그렇지 않으면 보상을 받을 수 없습니다.',
    'qingshuruzhanghao': '계좌번호를 입력하세요',
    'yaoqingshuoming': '초대 설명',
    'zaiciquanren':'{msg} 계정을 다시 확인하세요: {msg1}',
    'qingshuruzhanghao':'{msg} 계좌번호를 입력하세요',
    'daishenhe': '검토 예정',
    'yipaijiang':'상이 배포되었습니다',
    'weitongguo':'실패',
    'leijizhong':'축적',
    'tishi':'힌트',
    'tijiaochengong': '제출 성공!',
    'zonghe':'포괄적',
   'yituichi':'연기',
   'yiquxiao':'취소됨',
   'jitian':'오늘',
   'mintian':'내일',
   'houtian':'내일 모레',
   'zuotian':'어제',
   'xiazaitixing': '약속이 성공적으로 완료되었습니다. 약속 알림을 받으려면 앱을 다운로드하세요.',
   'quxiaoyuyue': '예약이 성공적으로 취소되었습니다',
   'zhoutian':'일요일',
   'zhouyi':'월요일',
   'zhouer':'화요일',
   'zhousan':'수요일',
   'zhousi':'목요일',
   'zhouwu':'금요일',
   'zhouliu':'토요일',
   'jianshu':'조각',
   'meiri':'매일',
   'meizhou':'매주',
   'duihuanchenggong': '성공적으로 상환',
   'zuiduoduihuan': '{msg} 조각의 최대 상환',
   'caozuo':'작업',
   'zongjia':'총 가격',
   'shanchu':'삭제',
   'now':'그냥',
   'yituikuan':'환불됨',
   'goumai':'구매',
   'xuanzezhibo': '생방송 채널 선택',
   'erjipingdao':'2차 채널',
   'zhibobiaoti': '생방송 제목',
   'shezhibiaoti': '생방송 제목을 설정해주세요',
   'guanliansaishi':'관련 이벤트',
   'xuanzhiguanlian': '이벤트를 연결해 주세요',
   'xuanze':'선택',
   'yichu':'제거',
   'zhibofenmian': '생방송 커버',
   'yishenhe':'검토됨',
   'shenhezhong':'검토 중',
   'yijujue':'거부됨',
   'zhibijieshu':'생방송 종료',
   'kaishizhibo': '생방송 시작',
   'tuiliudizhi':'푸시 주소(서버 주소)',
   'fuzhi':'복사',
   'tuiliumiyao':'푸시 코드(스트리밍 키)',
   'laliudizhi':'랄리우디지',
   'zhiboshuoming': '생방송 설명',
   'zhibotips1':`
   1. 기본 정보 설정: 채널 선택, 제목 설정, 이벤트 연결, 라이브 방송 커버, 필요에 따라 설정<br />
   2. '라이브 방송 시작' 버튼을 클릭한 후 푸시 주소와 푸시 코드를 복사하세요.<br />
   3. 푸시 주소와 푸시 코드를 OBS 소프트웨어에 해당하는 '서버 및 스트리밍 키'에 붙여넣고 라이브 방송을 시작하세요.<br />`,
   'zhibotips2':`
   1. 생방송 시작을 클릭하신 후, 1분 이내에 푸시주소와 푸시코드를 복사하여 OBS에 붙여넣고 스트리밍을 푸시해 주세요. 그렇지 않으면 생방송방이 비정상적인 시스템으로 다운그레이드될 수 있습니다.<br />
   2. 생방송 종료 후 OBS 종료 후, 위 생방송 종료 버튼을 눌러 생방송방을 다운로드 하셔야 합니다. <br />`,
   'yiyuyue': '이벤트가 예약되었습니다',
   'biaotitips': '제목은 비워둘 수 없습니다! ',
   'yuliang':'미리보기',
   'zhuyishixian':'메모',
   'xinjian':'새 푸시 명령',
   'xuanzesaishi':'이벤트 선택',
   'xuanzesaiduan': '무대 선택',
   'xuanzewf': '플레이 방법 선택',
   'jieguotips': '최대 25단어까지 결과를 입력하세요',
   'tuidanlishi': '역사적 푸시 명령',
   'renmai':'{msg}人 명령을 따르세요',
   'renfanmai':'{msg}사람들이 반대 구매',
   'qingxuanzesaishi': '이벤트를 선택하세요',
   'qingsaidian':'무대를 선택해주세요',
   'qingwf': '플레이 방법을 선택하세요',
   'qingjieguo': '결과를 입력하세요',
   'tuidangtips': '이 프로모션 주문은 제출된 후에는 변경할 수 없습니다. 제출을 확인하시겠습니까? ',
   'fabutuidan': '푸시 주문 게시',
   'chuangjian':'성공적으로 생성되었습니다',
   'yijianfabu': '원클릭 게시',
   'zimushezhi':'라이브 자막 설정',
   'zhimutips': '필요하지 않습니다. 방송이 시작된 후에도 계속 편집할 수 있습니다.',
   'dingbu':'상단 디스플레이',
   'shangshi':'자막 표시',
   'zimutips':'라이브 방송 인터페이스의 자막을 최대 50자까지 입력하세요',
   'xuanzepeise':'색상 선택:',
   'dibu':'하단 디스플레이',
   'shanchutips':'팁: 삭제해야 하는 경우 재설정을 클릭하세요.',
   'yuliangzhuangshi':'미리보기 표시',
   'shoujiduan':'모바일 버전 미리보기',
   'diannaoduan':'PC 미리보기',
   'resetps': '정말로 재설정하시겠습니까? 프런트 엔드에 적용된 자막은 재설정 후 자동으로 삭제됩니다. ',
   'baocuntips': '정말로 저장하시겠습니까? 저장 후 프런트엔드 자막이 업데이트되어 표시됩니다. ',
   'caozuochenggongg':'작업 성공',
   'dangqianjy': '현재 경험치',
   'jinrihuode':'오늘 받으세요',
   'guizeshuo':'규칙 설명',
   'dangqian':' 현재 LV.{msg}',
   'shenjilv':'LV.{msg}로 업그레이드하려면 <span>{msg1}</span> 경험치가 필요합니다.',
   'dachenglv':'V{msg}를 달성하려면 총 <span>{msg1}</span>의 경험치가 필요합니다.',
   'shenjidaolv':' V{msg}로 업그레이드하려면 <span>{msg1}</span> 경험치가 필요합니다.',
   'xinshourenwu':'초보자 작업',
   'meirirenwu':'일상 작업',
   'jieji':'클래스',
   'dengjit':'수준',
   'tubiao':'아이콘',
   'gongxihuode': '얻은 것을 축하합니다',
   'weiwangcheng': '미완성',
   'jianlilist':'보상 세부정보',
   'renwuleixin':'작업 유형',
   'renwujianli': '과제 보상',
   'paifazhuangt':'배포 상태',
   'huodeshijian':'시간을 가져라',
   'weipaifa':'배포되지 않음',
   'yipaifa':'분산',
   'yuyanzhuti': '예언 테마',
   'cangyuxiang':'참여 아이템',
   'beishu':'여러',
   'touru':'투자',
   'yingkui':'손익',
   'yuyanshijian':'예언 시간',
   'zhubogonggao': '앵커 발표',
   'zuiduiyuyue': '동시에 최대 20개 이벤트 예약 가능',
   'yituihuan':'반환',
   'qiuduimingcheng': '팀 이름을 입력하세요',
   'sousuoqiudui':'검색',
   'chongxinxuanze':'재선택',
   'fangfaleixing':'메서드 유형',
   xuanzegongshi:'회사를 선택하세요',
   jinnangbiaoti:'프로젝트 제목',
   jinnangbiaoti:'제목을 입력하세요(4단어 이상)',
   jinnangjiexibia: '분석을 입력해주세요(20단어 이상~)',
   tuichutips:'종료 후 콘텐츠를 저장할 수 없습니다. 종료하시겠습니까? ',
   biaotizuishao:'제목은 최소 4단어 이상이어야 합니다',
   jiexizuishao:'최소 20자 이상 구문 분석',
   tianjiachenggong:'성공적으로 추가되었습니다'
};
