<template>
  <guessing-detail :is-edit="true" @change="change" />
</template>

<script>
import GuessingDetail from './components/GuessingDetail'

export default {
  name: 'GuessingEditing',
  components: { GuessingDetail },
  data() {
    return {
      saved: false
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.saved) {
      to.meta.keepAlive = false
    }
    next()
  },
  methods: {
    change(val) {
      this.saved = val
    }
  }
}
</script>
