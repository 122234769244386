import request from '@/api/httpRequest'

export function paymentRecord(params) {
  return request({
    url: '/app/user/charge/records',
    method: 'post',
    data: params
  })
}

export function consumptionRecord(params) {
  return request({
    url: '/app/user/consume/records',
    method: 'post',
    data: params
  })
}

export function activityRecord(params) {
  return request({
    url: '/app/user/income/list',
    method: 'post',
    data: params
  })
}
