var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c("div", { staticClass: "user-info-avatar" }, [
        _c("img", {
          staticClass: "avatar",
          attrs: {
            src:
              _vm.gradeInfo.avatar +
              "?x-oss-process=image/resize,w_100/auto-orient,1",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "riband-container" }, [
          _c("div", { staticStyle: { position: "relative" } }, [
            _c("img", {
              staticClass: "riband",
              attrs: { src: require("@/assets/images/grade/riband.png") },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "grade" },
              [
                _c(
                  "van-row",
                  {
                    attrs: { type: "flex", justify: "center", align: "bottom" },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "8px",
                          "padding-bottom": "1px",
                        },
                      },
                      [_vm._v("LV.")]
                    ),
                    _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v(_vm._s(_vm.gradeInfo.level)),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "name" }, [
        _vm._v(_vm._s(_vm.gradeInfo.nickname)),
      ]),
      _vm._v(" "),
      _c(
        "van-row",
        { staticStyle: { margin: "20px 20px 35px 20px" } },
        [
          _c("van-progress", {
            attrs: {
              color: "#F6BA3D",
              "track-color": _vm.$theme.getProgressTrackColor(),
              percentage:
                (_vm.gradeInfo.exp / _vm.gradeInfo.nextExp) * 100 < 1
                  ? 0
                  : (_vm.gradeInfo.exp / _vm.gradeInfo.nextExp) * 100,
              "pivot-text": _vm.gradeInfo.exp + "",
              "stroke-width": "8",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "experience-info main-theme-font-color" }, [
        _c("div", [_vm._v(" " + _vm._s(_vm.$t("jianyanzhi")) + " ")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("uplevel", {
                  msg: _vm.gradeInfo.nextExp - _vm.gradeInfo.exp,
                })
              ) +
              " "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            "margin-top": "30px",
            "margin-bottom": "10px",
            "margin-left": "15px",
            "margin-right": "15px",
          },
        },
        [
          _c("van-row", { staticStyle: { "text-align": "center" } }, [
            _vm._v(_vm._s(_vm.$t("tequan"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("van-row", { attrs: { type: "flex", align: "center" } }, [
                _c("img", {
                  staticClass: "left-icon",
                  attrs: { src: require("@/assets/images/grade/waiting.png") },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "main-theme-font-color" }, [
                  _vm._v(_vm._s(_vm.$t("qidai"))),
                ]),
              ]),
              _vm._v(" "),
              _c("van-row", { staticClass: "description" }, [
                _vm._v(_vm._s(_vm.$t("mashanglai"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "card",
          staticStyle: {
            "margin-top": "30px",
            "margin-bottom": "10px",
            "margin-left": "15px",
            "margin-right": "15px",
          },
        },
        [
          _c("van-row", { staticStyle: { "text-align": "center" } }, [
            _vm._v(_vm._s(_vm.$t("kuaisushengji"))),
          ]),
          _vm._v(" "),
          _c("van-row", { attrs: { type: "flex", align: "center" } }, [
            _c("img", {
              staticClass: "left-icon",
              attrs: { src: require("@/assets/images/grade/gift.png") },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "main-theme-font-color" }, [
              _vm._v(
                _vm._s(
                  _vm.isAnchor ? _vm.$t("huodeliwu") : _vm.$t("zensongliwu")
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("van-row", { staticClass: "description" }, [
            _vm._v(
              "1" +
                _vm._s(
                  _vm.commonConfig.currencyMoneyName + "=1" + _vm.$t("jiyang")
                )
            ),
          ]),
          _vm._v(" "),
          _vm.isAnchor
            ? _c(
                "div",
                [
                  _c("van-divider", {
                    style: { borderColor: _vm.$theme.getDividerBorderColor() },
                  }),
                  _vm._v(" "),
                  _c("van-row", { attrs: { type: "flex", align: "center" } }, [
                    _c("img", {
                      staticClass: "left-icon",
                      attrs: { src: require("@/assets/images/grade/live.png") },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "main-theme-font-color" }, [
                      _vm._v(_vm._s(_vm.$t("qingfenzhibo"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("van-row", { staticClass: "description" }, [
                    _vm._v(
                      _vm._s(
                        30 +
                          _vm.$t("fengzhong") +
                          "=100" +
                          _vm.$t("jiyang") +
                          ",60" +
                          _vm.$t("fengzhong") +
                          "=300" +
                          _vm.$t("jiyang")
                      ) + " "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("van-row", { staticClass: "description" }, [
                    _vm._v(
                      _vm._s(
                        120 +
                          _vm.$t("fengzhong") +
                          "=600" +
                          _vm.$t("jiyang") +
                          ",180" +
                          _vm.$t("fengzhong") +
                          "=1000" +
                          _vm.$t("jiyang")
                      ) + " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }