<template>
  <div class="app-container" :data-theme="$store.getters.themeType">
    <van-sticky :offset-top="0">
      <div class="default-background" style="padding-top: 10px;">
        <van-row type="flex" align="center">
          <van-col span="20" style="padding-left: 10px;">
            <cube-input v-model="keywords" :clearable="true" placeholder="请输入球队名称" class="search-input">
              <template #prepend>
                <img style="width: 14px; height: 14px; margin-left: 10px;" src="@/assets/images/live-setting/search_icon.png">
              </template>
            </cube-input>
          </van-col>
          <van-col span="4" style="text-align: center;">
            <span class="main-theme-font-color" @click="handleSearch">搜索</span>
          </van-col>
        </van-row>
        <van-tabs v-model="activeIndex" :color="$theme.getTabsUnderlineColor()" title-inactive-color="#9B9B9B" :title-active-color="$theme.getTabTitleActiveColor()" line-width="22px" style="width: 100px;">
          <van-tab title="足球" />
          <van-tab title="篮球" />
        </van-tabs>
      </div>
    </van-sticky>
    <div class="main-content">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="getList"
      >
        <van-checkbox-group v-model="checkedList">
          <van-cell-group>
            <div v-for="(item,index) in list" :key="item.title">
              <span class="main-theme-font-color">{{ item.title }}</span>
              <div v-for="(subItem,subIndex) in item.list" :key="subItem.id" style="margin-top: 10px; margin-bottom: 10px;">
                <van-cell clickable @click="cellClicked(subItem,index,subIndex)">
                  <div class="card">
                    <van-row type="flex" justify="space-between" align="center">
                      <div class="main-theme-font-color" style="max-width: 80%;">
                        {{ subItem.eventName }}<span v-show="subItem.roundId !== '0'">第{{ subItem.roundId }}轮</span> {{ subItem.matchTime }}
                      </div>
                      <van-checkbox v-show="subItem.isBook !== 1" ref="checkboxes" :name="subItem" />
                      <van-icon v-show="subItem.isBook === 1" name="checked" size="22px" color="#3D679A" />
                    </van-row>
                    <van-row type="flex" align="center" style="margin-top: 20px;">
                      <img v-if="subItem.homeLogo && subItem.homeLogo !== ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid rgba(255,255,255,1); margin-right: 10px;" :src="subItem.homeLogo">
                      <img v-if="!subItem.homeLogo || subItem.homeLogo === ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid rgba(255,255,255,1); margin-right: 10px;" src="@/assets/images/team_logo_default.png">
                      <span class="main-theme-font-color" style="font-size: 12px;">{{ subItem.homeName }}</span>
                      <img style="width: 22px; height: 25px; margin-left: 20px; margin-right: 20px;" :src="$theme.getVsImage()">
                      <span class="main-theme-font-color" style="font-size: 12px;">{{ subItem.awayName }}</span>
                      <img v-if="subItem.awayLogo && subItem.awayLogo !== ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid rgba(255,255,255,1); margin-left: 10px;" :src="subItem.awayLogo">
                      <img v-if="!subItem.awayLogo || subItem.awayLogo === ''" style="width: 26px; height: 26px; border-radius: 13px; border: 1px solid rgba(255,255,255,1); margin-left: 10px;" src="@/assets/images/team_logo_default.png">
                    </van-row>
                  </div>
                </van-cell>
              </div>
            </div>
          </van-cell-group>
        </van-checkbox-group>
      </van-list>
      <van-empty
        v-if="!loading && list.length === 0"
        class="empty-image"
        style="margin-top: 40%;"
      >
        <div slot="image" style="width: 100%; text-align: center">
          <img style="width: 257px; height: 84px;" src="@/assets/images/empty.png">
        </div>
        <div slot="description" style="text-align: center;">
          <div>{{ $t('nomatch') }}</div>
        </div>
      </van-empty>
    </div>
    <div class="bottom">
      <div class="row" style="padding-top: 10px;">
        <span>{{$t('zuiduiyuyue')+'('+$t('selected') + checkedList.length + bookCount }})</span>
      </div>
      <div class="row" style="padding-top: 10px;">
        <van-button class="button-primary" style="width: 80%; border-radius:6px;" :disabled="checkedList.length === 0" @click="commit">确认</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Sticky, Row, Button, Col, Checkbox, CheckboxGroup, CellGroup, List, Divider, Tab, Tabs, Empty, Cell, Toast, Icon } from 'vant'
import { fetchAppointmentList, book, fetchMyAppointmentList, checkBooking } from '@/api/live-setting'
import { deepClone } from '@/utils'

export default {
  name: 'AppointmentGameList',
  components: {
    [Sticky.name]: Sticky,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [Empty.name]: Empty,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [Icon.name]: Icon
  },
  data() {
    return {
      dataType: 1, // 1：足球 2：篮球 99：其他
      pageNum: 1,
      list: [],
      checkedList: [],
      keywords: '',
      loading: false,
      finished: false,
      finishedText: '没有更多了',
      bookCount: 0,
      myAppointmentList: [],
      checkedAndBookedList: [],
      activeIndex: 0
    }
  },
  computed: {
    maxBookCount() {
      return 20 - this.bookCount
    }
  },
  watch: {
    checkedList(val, oldVal) {
      this.checkedAndBookedList = this.myAppointmentList.concat(val)
    },
    activeIndex(val, oldVal) {
      if (val === 0) {
        this.dataType = 1
        this.pageNum = 1
        this.list = []
        this.checkedList = []
        this.getList()
      } else if (val === 1) {
        this.dataType = 2
        this.pageNum = 1
        this.list = []
        this.checkedList = []
        this.getList()
      } else if (val === 2) {
        this.dataType = 99
        this.pageNum = 1
        this.list = []
        this.checkedList = []
        this.getList()
      }
    }
  },
  created() {
    this.setTitle()
    this.getMyAppointmentList()
  },
  methods: {
    setTitle() {
      document.title = '搜索'
    },
    getMyAppointmentList() {
      fetchMyAppointmentList({ matchIsLiving: true }).then(response => {
        this.myAppointmentList = response.data.length > 0 ? response.data[0].list : []
      })
    },
    cellClicked(item, index, subIndex) {
      if (item.isBook === 1) return
      let checkbox = null
      let selectedIndex = 0
      for (let i = 0; i < this.list.length; i++) {
        for (let j = 0; j < this.list[i].list.length; j++) {
          selectedIndex += 1
          if (i === index && j === subIndex) {
            checkbox = this.$refs.checkboxes[selectedIndex - 1]
          }
        }
      }
      if (this.checkedList.length >= this.maxBookCount && !this.checkedList.includes(item)) {
        checkbox.toggle(false)
        Toast(this.$t('zuiduiyuyue'))
        return
      }
      const checked = checkbox.checked
      if (checked) {
        checkbox.toggle(false)
      } else {
        const idList = this.checkedList.map(obj => {
          return obj.id
        })
        idList.push(item.id)
        checkBooking({ source: 1, matchIds: idList, sportsType: item.sportsType }).then(response => {
          if (response.data) {
            checkbox.toggle(true)
          } else {
            checkbox.toggle(false)
            Toast(this.$t('kaisaishijian'))
          }
        })
      }
    },
    tabChange(index, title) {
      if (title === this.$t('fb')) {
        this.dataType = 1
        this.pageNum = 1
        this.list = []
        this.checkedList = []
        this.getList()
      } else if (title === this.$t('bk')) {
        this.dataType = 2
        this.pageNum = 1
        this.list = []
        this.checkedList = []
        this.getList()
      }
    },
    handleSearch() {
      this.pageNum = 1
      this.list = []
      this.checkedList = []
      this.getList()
    },
    getList() {
      this.loading = true
      fetchAppointmentList({ pageNum: this.pageNum, sportsType: this.dataType, keywords: this.keywords }).then(response => {
        this.bookCount = response.data.bookCount
        this.loading = false
        this.list = this.concat(this.list, response.data.list)
        if (response.data.currentSize < 20) {
          this.finished = true
          this.finishedText = this.$t('nomore')
        } else {
          this.pageNum += 1
        }
        if (this.list.length < 20) {
          this.finishedText = ''
        }
      })
    },
    concat(list, newList) {
      let dataList = []
      if (!newList || newList.length === 0) {
        return list
      }
      if (list.length === 0) {
        return newList
      }
      const lastItem = list[list.length - 1]
      const firstItem = newList[0]
      if (lastItem.title === firstItem.title) {
        const tempList = deepClone(firstItem.list)
        lastItem.list = lastItem.list.concat(tempList)
        newList.shift()
        dataList = list.concat(newList)
      } else {
        dataList = list.concat(newList)
      }
      return dataList
    },
    commit() {
      const matchIds = this.checkedList.map(obj => {
        return obj.id
      })
      // status 1: 取消预约 2: 预约
      book({ source: 1, matchId: matchIds, sportsType: this.dataType, status: 2 }).then(response => {
        Toast(this.$t('xiazaitixing'))
        this.updateStatus(matchIds)
      })
    },
    updateStatus(matchIds) {
      for (let i = 0; i < this.list.length; i++) {
        const subList = this.list[i].list
        for (let j = 0; j < subList.length; j++) {
          const item = subList[j]
          if (matchIds.includes(item.id)) item.isBook = 1
        }
      }
      this.checkedList = []
      this.bookCount += matchIds.length
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.main-content {
  padding-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 100px;
  @include color_main_bg();
}
.search-input {
  border-radius: 6px!important;
  @include color_main_card_bg();
}
/deep/input::-webkit-input-placeholder{
  color:#C0C0C0;
}
/deep/input::-moz-placeholder{   /* Mozilla Firefox 19+ */
  color:#C0C0C0;
}
/deep/input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
  color:#C0C0C0;
}
/deep/input:-ms-input-placeholder{  /* Internet Explorer 10-11 */
  color:#C0C0C0;
}
/deep/.cube-input-field {
  @include color_main_font();
  border-radius: 6px!important;
}
/deep/.cube-input::after {
  border: 0px solid #ebebeb;
}
.bottom {
  position: fixed;
  width: 100%;
  height: 90px;
  bottom: 35px;
  @include color_main_font();
  @include color_main_bg();
}
/deep/.van-cell {
  padding: 0px 0px;
  @include color_main_bg();
}
/deep/.van-cell-group {
  padding: 0px 0px;
  @include color_main_bg();
}
/deep/.van-tabs__nav--card .van-tab {
  border-right: 0px solid #63676B;
}
/deep/.van-tabs__nav--card {
  background-color: #29292D;
  margin: 0 10px;
  border-radius: 6px;
  border: 1px solid #29292D!important;
  padding: 1px;
}
/deep/.van-tab {
  border-radius: 6px;
}
/deep/.van-hairline-unset--top-bottom::after {
  border-top: 1px solid #373941!important;
  border-bottom: 1px solid #373941!important;
}
/deep/.empty-image .van-empty__image {
  width: 257px;
  height: 84px;
}

/deep/.van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
  border-width: 0px 0;
}
/deep/.van-tabs__nav--line {
    box-sizing: content-box;
    height: 100%;
    padding-bottom: 15px;
    @include color_main_bg();
}
</style>
