export default {
  loading: "đang tải...",
  searchplaceholder: "Neo, kênh, trò chơi",
  dowload: "Tải xuống",
  home: "Trang chủ",
  live: "Sống",
  match: "Cuộc thi đấu",
  plan: "Lời khuyên",
  mine: "Của tôi",
  hotgame: "Các cuộc thi phổ biến",
  news: "Thông tin",
  mall: "trung tâm mua sắm",
  video: "đoạn video ngắn",
  activity: "hoạt động ",
  hotlive: "Truyền hình trực tiếp hấp dẫn",
  hotanchor: "Hot",
  highlights: "Điểm nổi bật",
  more: "Hơn",
  noliving: "Hiện tại không có mỏ neo nào đang phát sóng",
  nolivetips: "Mỏ neo vẫn đang khởi động, vui lòng đợi một lát...",
  chat: "Trò chuyện",
  speak: "Nhập nội dung trò chuyện",
  nospeak: "Bạn chỉ có thể nói sau khi đăng nhập~",
  gologin: "Đăng nhập",
  seenewnews: "Tin tức mới",
  unlockvip: "Mở khóa VIP miễn phí",
  announcement: "Thông báo:",
  welcome: "Chào mừng",
  enterroom: "vào phòng phát sóng trực tiếp",
  followed: "đã theo dõi",
  newsinfo: "thông tin",
  experts: "Chuyên gia nổi bật",
  hit: "Đánh",
  return: "Trở lại",
  wins: "Thắng",
  fb: "Bóng đá",
  bk: "Bóng rổ",
  notstarted: "chưa bắt đầu",
  checkmore: "Xem chi tiết",
  videolist: "Video nổi bật/Video",
  qianzan: "phía trước",
  jinhua: "nước hoa",
  yulei: "sự giải trí",
  recommendplan: "Kế hoạch đề xuất",
  buyedplan:'Gói đã mua',
  winrate: "tỷ lệ trúng",
  zuigaolianghong: "{msg} màu đỏ liên tiếp cao nhất",
  lianghong: "liên hồng",
  returnrate: "tỷ lệ hoàn vốn",
  buzhong: "hoàn tiền nếu bạn không thắng",
  xieyi: "thỏa thuận liên quan",
  personal: "Thỏa thuận quyền riêng tư",
  userxieyi: "sự thỏa thuận của người dùng",
  livexieyi: "Thỏa thuận phát sóng trực tiếp",
  chargexieyi: "Thỏa thuận nạp tiền",
  anchorhelp: "Trợ giúp neo",
  anchornew: "Mỏ neo mới đang trên đường",
  followus: "Theo chúng tôi",
  helpcenter: "Trung tâm trợ giúp",
  scancodedowload: "Quét mã để tải xuống ỨNG DỤNG Bobcat",
  smzb: "mèo sống",
  noduty:
    "Tuyên bố miễn trừ trách nhiệm: Tất cả các chương trình phát sóng trực tiếp bóng đá và tín hiệu phát sóng trực tiếp NBA của Bobcat Live đều do người dùng thu thập hoặc lấy từ các công cụ tìm kiếm. Tất cả nội dung đều đến từ Internet. Chúng tôi không tự cung cấp bất kỳ tín hiệu phát sóng trực tiếp và nội dung video nào. Vui lòng thông báo cho chúng tôi nếu có bất kỳ hành vi xâm phạm quyền của bạn. , chúng tôi sẽ giải quyết nó càng sớm càng tốt.",
  roomsearch: "Nhập từ khóa",
  myfollow: "sự theo dõi của tôi",
  clearcache: "xóa bộ nhớ đệm",
  kinds: "phân loại",
  all: "Tất cả",
  others: "khác",
  yingchao: "Premier League",
  hot: "Nóng",
  esports: "thể thao điện tử",
  exit: "lối ra",
  downloadApp: "Tải ứng dụng",
  want: "Đi tới phát sóng trực tiếp",
  roomnum: "số phòng",
  wang: "wan",
  feedback: "nhận xét",
  funs: "người hâm mộ",
  follows: "Theo",
  numberonlive: "Một người dẫn chương trình hiện đang phát sóng sự kiện này",
  anchorchat: "trò chuyện neo",
  contributerank: "danh sách đóng góp",
  othermatch: "các trận đấu khác",
  chatinput: "Trò chuyện và cùng nhau hưng phấn",
  send: "gửi",
  livefail: "Không thể phát trực tiếp",
  tryagain: "Bấm để thử lại",
  pushlist: "Danh sách đặt hàng đẩy",
  reward: "mô tả phần thưởng",
  nopush: "Chưa có lệnh đẩy nào",
  anchorpush: "Mỏ neo đẩy lệnh",
  maobipokect: "Phong bì màu đỏ đồng xu mèo",
  sendsuccess:
    "Gửi thành công có thể kích hoạt phát màn hình nổi trên tất cả các nền tảng",
  manexchange: "Người ta có thể nhận nó",
  threeminexhang: "Có thể được thu thập trong 3 phút",
  exchangenow: "Có thể thu thập ngay",
  sendpokect: "Gửi phong bì màu đỏ",
  pokecttips:
    "Tôi đã gửi một phong bì màu đỏ trong phòng phát sóng trực tiếp ~ Bạn có thể nhận nó ngay lập tức!",
  pokecttips1:
    "Tôi đã gửi một phong bì màu đỏ trong phòng phát sóng trực tiếp ~ Bạn có thể nhận nó sau 3 phút!",
  task: "nhiệm vụ",
  litterhelper: "Người giúp việc nhỏ",
  litterhelper1: "Trợ lý giảm giá",
  enter: "Bấm để vào",
  gift: "quà ",
  high: "HD",
  charge: "nạp điện",
  balance: "THĂNG BẰNG",
  words: "bang",
  sendonepokect: "Đã gửi một phong bì màu đỏ",
  exchange: "nhận được",
  ofpokect: "phong bì màu đỏ",
  nopokect: "Tôi chậm quá, phong bì đỏ đã được phát rồi",
  maobi: "đồng xu mèo",
  savemyaccount: "đã được gửi vào tài khoản của tôi",
  checkexchange: "Xem chi tiết yêu cầu",
  exchangelist: "Kỷ lục sưu tập phong bì đỏ",
  pokecttips2:
    "Tôi đã lấy x đồng xu mèo trong phong bì màu đỏ đồng xu mèo của tôi!",
  contribute: "đóng góp hàng ngày",
  contribute1: "Đóng góp hàng tuần",
  contribute2: "Danh sách đóng góp",
  contributevalue: "giá trị đóng góp",
  living: "phát sóng trực tiếp",
  matchstatus: "Trạng thái trận đấu",
  zhishu: "mục lục",
  livematchstatus: "trạng thái trận đấu thời gian thực",
  jinqiu: "mục tiêu",
  zugong: "hỗ trợ",
  shemeng: "bắn",
  dianqiu: "đá phạt",
  wulongqiu: "mục tiêu riêng",
  huangpai: "thẻ vàng",
  nodianqiu: "Quả đá phạt đền không được ghi bàn",
  huanxia: "thay thế",
  nomatchevent: "Chưa có sự kiện trận đấu nào",
  onlyjinqiu: "Chỉ xem bàn thắng",
  analysis: "phân tích kỹ thuật",
  contrrate: "tỷ lệ sở hữu",
  shezheng: "bắn trúng mục tiêu",
  jingong: "tấn công",
  dendrgjingong: "cuộc tấn công nguy hiểm",
  renyiqiu: "phạt trực tiếp",
  jiaoqiu: "phạt góc",
  hongpai: "thẻ đỏ",
  login: "đăng nhập",
  roommannage: "bổ nhiệm quản lý phòng",
  roomtips: "Bạn có chắc chắn bổ nhiệm Mười Lăm làm quản lý phòng không?",
  roomtips1:
    "Bạn có chắc chắn rằng Mười Lăm sẽ bị sa thải khỏi vị trí quản lý phòng không?",
  cancel: "Hủy bỏ",
  confirm: "ĐƯỢC RỒI",
  logout: "Đăng xuất",
  uplevel: "Nâng cấp khoảng cách: giá trị kinh nghiệm {msg}",
  wallt: "cái ví",
  wantlive: "Tôi muốn phát sóng trực tiếp",
  usercenter: "trung tâm cá nhân",
  vipright: "Giới thiệu đặc quyền VIP",
  pwdlogin: "Mật khẩu đăng nhập",
  mobilelogin: "Đăng nhập di động",
  doregister1: "Không tài khoản",
  doregister2: "Đăng ký ngay bây giờ",
  forgetpwd: "Quên mật khẩu?",
  logintips: "Đăng nhập có nghĩa là bạn đồng ý",
  inputmobile: "Nhập số điện thoại di động",
  inputcode: "Nhập mã xác nhận",
  mobiletips: "Nhập mật khẩu gồm 6-20 chữ số hoặc mật khẩu chữ cái",
  resetpwd: "Đặt lại mật khẩu",
  register: "Đăng ký",
  sendcode: "Nhận được mã",
  registernow: "Đăng ký ngay bây giờ",
  appdownload: "Tải xuống ứng dụng",
  advice: "Nhận xét",
  backtop: "Quay lại đầu trang",
  intime: "Lập tức",
  anchor: "mỏ neo",
  matchschude: "lịch trình",
  matchresult: "kết quả trận đấu",
  checkdata: "Xem dữ liệu",
  nomatch: "Chưa có trận đấu nào",
  winlist: "danh sách hit",
  returnlist: "danh sách trả lại",
  lianghongrank: "Danh sách đỏ liên tục",
  hotrace: "cuộc đua nóng bỏng",
  allrace: "tất cả các chủng tộc",
  onrace: "trong cạnh tranh",
  onsale: "Kế hoạch bán hàng",
  recommendmaster: "chuyên gia đề nghị",
  mime: "Tôi",
  boughtplan: "Gói đã mua",
  sorttype: "sắp xếp toàn diện",
  returnfirst: "trở về trước",
  winfirst: "Tỷ lệ thắng đầu tiên",
  lianghongfirst: "Liên Hồng đầu tiên",
  nodata: "Chưa có dữ liệu",
  detail: "chi tiết",
  newslocal: "Vị trí hiện tại: Trang chủ/Tin tức",
  comments: "bình luận",
  nocomments: "Chưa có bình luận nào",
  docomments: "Bình luận",
  typecomments: "Nói gì đó đi",
  goods: "Các mặt hàng",
  searchgoods: "Tìm kiếm sản phẩm bạn thích",
  mycarts: "Giỏ hàng của tôi",
  myorders: "Đơn đặt hàng của tôi",
  demens: "kim cương",
  goodsinfo: "thông tin hàng hóa",
  kucun: "hàng tồn kho",
  limit: "giới hạn trao đổi",
  value: "đơn giá",
  num: "Số lượng",
  total: "Tổng phụ",
  choice: "đã chọn",
  jiesuan: "giải quyết",
  chooseall: "chọn tất cả",
  limitnum: "giới hạn mua tích lũy",
  limitday: "giới hạn mua hàng ngày",
  limitweek: "giới hạn mua hàng tuần",
  totallimt: "Tổng số tiền chuộc:",
  totallimt1: "Quy đổi tích lũy hàng ngày:",
  totallimt2: "Tổng số tiền quy đổi hàng tuần:",
  jiage: "giá ",
  goodsdetail: "Thông tin chi tiết sản phẩm",
  buynow: "Đổi ngay bây giờ",
  addcart: "Thêm vào giỏ hàng",
  recommendgoods: "được đề xuất cho bạn",
  confirmbuy: "xác nhận đổi thưởng",
  mobile: "số điện thoại",
  recipename: "Tên của người nhận hàng",
  address: "địa chỉ giao hàng",
  remark: "nhận xét",
  inputreciptename: "Nhập tên người nhận hàng",
  inputaddress: "Nhập địa chỉ giao hàng",
  orders: "số thứ tự",
  time: "thời gian",
  recipe: "người nhận hàng",
  telphone: "Điện thoại",
  address1: "Địa chỉ",
  recovideo: "video được đề xuất",
  onplay: "Chơi bình thường...",
  ongoing: "trong tiến trình",
  result: "Đã kết thúc",
  reserve: "sự đặt chỗ",
  reserved: "kín đáo",
  myinfo: "thông tin của tôi",
  anchorlevel: "Mức neo",
  userlevel: "cấp độ người dùng",
  changeavter: "Thay đổi hình đại diện",
  changeinfo: "Sửa đổi thông tin",
  usertips: "(Kích thước ảnh không quá 1M, hỗ trợ định dạng png, jpg)",
  uploadimg: "Tải lên hình ảnh địa phương",
  myaccount: "Tài khoản của tôi",
  anchorinfo: "Neo liên quan",
  tasks: "Trung tâm tác vụ",
  signin: "Đăng nhập lịch sự",
  mywords: "lời tiên tri của tôi",
  downloadreword: "Tải xuống phần thưởng lời mời",
  nickname: "tên nick",
  inputnickname: "Nhập biệt hiệu của bạn",
  infotips:
    "Sự miêu tả:\n 1. Biệt hiệu mới phải tuân thủ các quy định đăng ký và có thể sử dụng số, chữ cái, ký tự tiếng Trung và ký hiệu. Độ dài tối đa không được vượt quá 24 ký tự (2 ký tự cho tiếng Trung và 1 ký tự cho tiếng Anh).\n 2. Nếu việc sửa đổi biệt hiệu không thành công do cập nhật máy chủ hoặc các sự cố khác, vui lòng liên hệ với bộ phận dịch vụ khách hàng.",
  introduce: "thông tin cá nhân",
  region: "vùng đất",
  selectprovince: "Vui lòng chọn tỉnh",
  selectregion: "Vui lòng chọn một thành phố",
  sex: "giới tính",
  savesecrt: "bảo mật",
  man: "nam giới",
  woman: "nữ giới",
  bedate: "sinh nhật",
  selectdate: "Hãy chọn năm",
  selectmonth: "Vui lòng chọn một tháng",
  select: "Vui lòng chọn",
  year: "năm",
  month: "tháng",
  day: "ngày",
  save: "cứu",
  accountvalue: "số dư tài khoản",
  billlist: "Hồ sơ hóa đơn",
  cast: "sự tiêu thụ",
  earnings: "thu nhập",
  casttype: "Loại tiêu thụ",
  sendgift: "quà ",
  prophecy: "lời tiên tri",
  redpokect: "phong bì màu đỏ",
  exchangegoods: "Trao đổi quà",
  luckydraw: "rút thăm trả phí",
  querytime: "thời gian truy vấn",
  starttime: "ngày bắt đầu",
  endtime: "ngày cuối",
  query: "truy vấn",
  reset: "cài lại",
  castmaobi: "Lượng tiêu thụ (tiền mèo)",
  allprophecy: "Tất cả lời tiên tri",
  prophecylist: "Phòng tiên tri",
  planlist: "Lời khuyên để giải quyết vấn đề",
  yuyuematch: "trận đấu đặt chỗ",
  earnlist: "kỷ lục thu nhập",
  liveset: "Cài đặt phát sóng trực tiếp",
  roompush: "Lệnh đẩy phòng phát sóng trực tiếp",
  roomset: "Cài đặt thư phòng phát sóng trực tiếp",
  edit: "biên tập",
  beAnchor: "Trở thành một mỏ neo",
  inviterUser: "Mời bạn bè",
  setting: "cài đặt",
  logining: "Đăng nhập...",
  loginerr: "Đăng nhập không thành công, vui lòng thử lại sau...",
  loginsecucced: "Đăng nhập thành công",
  codesend: "Mã xác minh đã được gửi thành công",
  inputrightphone: "Nhập đúng số điện thoại di động",
  inputagain: "Nhập lại mật khẩu của bạn",
  pwdtips: "Mật khẩu nhập hai lần không nhất quán",
  pwdtips1:
    "Định dạng mật khẩu sai, vui lòng nhập mật khẩu 6-20 chữ số hoặc mật khẩu chữ cái",
  registed: "Đăng ký thành công",
  registerfail: "Đăng ký không thành công, vui lòng thử lại sau...",
  registing: "Đang đăng ký...",
  findpwd: "Lấy lại mật khẩu",
  submit: "nộp",
  settingPwd: "Đặt mật khẩu thành công",
  submitting: "Đang gửi...",
  "nomore":'Không còn nữa',
    "pullrefresh":'Kéo xuống để làm mới',
    "notstart":'Trò chơi chưa bắt đầu, bạn có thể xem các trò chơi khác:',
    newsDe:'thông tin chi tiết',
   shouqi:'bỏ đi',
   fabu:'phát hành',
   'eur':'Đĩa châu Âu',
    "hk":'Đĩa Hồng Kông',
    "bm":'Trên/Dưới',
    'rq':'Khuyết tật',
    'mzl':'tỷ lệ trúng',
    'mago':'phút trước',
    'sago':'giây trước',
    'hago':'giờ trước',
    'mianfei':'miễn phí',
    'tacticde':'Chi tiết kế hoạch',
   'zhusheng':'thắng',
   "pingju":'vẽ tranh',
   "kesheng":'thua',
   'pay':'Trả tiền ngay',
    'stop':'Việc bán đã dừng lại',
    'reason':'Lý do đề xuất',
    'afterpay':'Xem kế hoạch và phân tích sau khi thanh toán',
    'stoptime':'Thời hạn bán hàng:',
    'tacticdetips':'Lưu ý: Các ý kiến ​​chỉ mang tính tham khảo và chúng tôi không cung cấp dịch vụ kinh doanh xổ số, vui lòng chú ý thận trọng! ',
    'dago':'ngày trước',
    'wago':'tuần trước',
    'mouthago':'vài tháng trước',
    'ganggang':'vừa rồi',
    'bigqiu':'Trên{msg}balls',
    'smallqiu':'Dưới{msg}balls',
    'buyplan':'Mua gói chuyên gia',
    'buyplantips':'Mua gói này cần có {num} xu mèo. Bạn có chắc là mình có đủ không? ',
    'yesbuy':'Xác nhận mua hàng',
    'cancelfollow':'Hủy theo dõi thành công',
    'followsucceed':'Theo đuổi thành công',
    'buysucceed':'Mua hàng thành công',
    'buyfialed':'Mua hàng không thành công',
    'liangsai':'giải đấu',
    'team':'Sân nhà vs sân khách',
    'playtype':'chơi',
    'rate':'tỷ lệ cược',
    'chongzhijilu':'Bản ghi nạp tiền',
    'xiaofeijilu':'kỷ lục tiêu thụ',
    'huodongshouyi':'thu nhập hoạt động',
    'myinvitecode':'Mã mời của tôi',
    'inputcodeinapp':'Vui lòng nhập khi đăng nhập vào Ứng dụng',
    'dianjifuzhi':'Click để sao chép mã mời',
    'jietu':'Gửi ảnh chụp màn hình cho bạn bè của bạn',
    'shoukuang':'Phương thức thanh toán',
    'alipay':'Alipay',
    'wechat':'WeChat',
    'shoukuangzhanghao':'số tài khoản thanh toán',
    'invitegonglue':'Mời chiến lược',
    'shareqrcode':'Chia sẻ mã QR tải xuống và mã mời độc quyền',
    'getjiangli':'Bạn sẽ nhận được phần thưởng nếu bạn của bạn đăng nhập thành công lần đầu tiên',
    'invitemingxi':'Chi tiết lời mời',
    'zhanwuhaoyou':'Chưa có bạn bè nào được mời. Hãy đi và mời bạn bè! ',
    'ngách':'biệt danh',
    'zucengshijian':'Thời gian đăng ký',
    'jiangli':'phần thưởng',
    'islogout':'Bạn có chắc chắn muốn đăng xuất không? ',
    'dijilun':'Vòng {num}',
    'guangyuus':'về chúng tôi',
    'zhuanjiajieshao':'Giới thiệu chuyên gia',
    'recent10':'Tỷ lệ về trong 10 trận gần nhất',
    'zuijinzhuantai':'Trạng thái gần đây',
    'zhengzaituijian':'Đề xuất',
    'historyrem':'Đề xuất lịch sử',
    'bisaishijian':'sự kiện trận đấu',
    'qiuduitongji':'Số liệu thống kê của đội',
    'qiudui':'Đội',
    'zongfen':'tổng số điểm',
    'bisaikaishi':'Trò chơi bắt đầu',
    'fangui':'phạm lỗi',
    'erfen':'hai điểm',
    'faqiu':'ném phạt',
    'sanfen':'ba điểm',
    'pazhao':'chụp ảnh',
    'xuanzezhaopian':'Chọn từ album',
    'jianyanzhi':'giá trị kinh nghiệm',
    'yiwang Cheng':'Đã hoàn thành',
    'dangqian':'hiện tại',
    'shengjidao':'Nâng cấp lên',
    'xuyao':'cần',
    'cehngzhang':'Đặc quyền tăng trưởng',
    'dengji':'biểu tượng cấp độ',
    'kaiqi':'mở',
    'qidai':'Hãy theo dõi',
    'mashanglai':'Sắp có thêm nhiều đặc quyền',
    'qiandao':'Nhận phòng hàng ngày',
    'qiandaochengong':'Đăng nhập thành công',
    'qiandaotips':'Nhắc nhở nồng nhiệt: Vui lòng liên hệ với trợ lý để đăng ký nhận tiền thưởng và phần thưởng vật chất. ',
    'chakanqiandao':'Xem phần thưởng',
    'goxiaozhushou':'Liên hệ trợ lý',
    'dijitian':'{msg}ngày',
    'zhubodasan':'Danh sách phần thưởng neo',
    'riban':'danh sách hàng ngày',
    'yueban':'danh sách hàng tháng',
    'zongban':'Danh sách chung',
    'gongxianzhi':'giá trị đóng góp',
    'fananjieshu':'Việc bán gói đã kết thúc',
    'bisaijieshu':'Chi tiết phân tích sẽ được công bố sau cuộc thi',
    'yuebuzu':'Không đủ số dư',
    'qitajinnang':'Các lời khuyên khác của chuyên gia',
    'qitatuijian':'Các đề xuất khác',
    'dengluhou':'Sau này bạn chỉ có thể xem các bản ghi đã mua',
    'zhengzaibofng':'Đang phát',
    'jijiangbofang':'Đến chơi',
    'touxiangshangchuan':'Tải lên hình đại diện',
    'shouyeleixing':'loại thu nhập',
    'lingquhongbao':'Nhận phong bì màu đỏ',
    'hongbaotuikuang':'trả lại phong bì màu đỏ',
    'jinnantutikuang':'Hoàn tiền tip',
    'yuyanshouyi':'lợi nhuận tiên tri',
    'yuyantuikuang':'hoàn trả lời tiên tri',
    'hongbaoyu':'hongbaoyu',
    'jincaidati':'đoán và trả lời',
    'zhuanpan':'Xổ số Roulette',
    'jindan':'đập trứng vàng',
    'zenfa':'Hệ thống phát hành bổ sung',
    'dengluhuodong':'Hoạt động đăng nhập',
    'guankan':'Xem truyền hình trực tiếp',
    'danmu':'Gửi đập',
    'denglujianli':'Phần thưởng đăng nhập APP',
    'tuidan':'Phần thưởng cho đơn hàng đẩy',
    'zhuanpanchoujian':'Xổ số băng chuyền mới',
    'zhuti':'chủ đề',
    'xuanxian':'tùy chọn',
    'kejitouzhu':'cá cược tích lũy',
    'xuanxianbeishu':'Nhiều tùy chọn',
    'touzhurenshu':'số người đặt cược',
    'jieguo':'kết quả',
    'zhuantai':'trạng thái',
    'daojishi':'Đếm ngược',
    'fabuplantips':'Kiếm được số tiền khổng lồ sau khi xuất bản các mẹo giải bóng~',
    'goumairenshu':'số lượng người mua',
    'zongji':'Tổng cộng',
    'fenchen':'trước khi chia thành',
    'liveshouyi':'Phát sóng trực tiếp thu nhập ổn định',
    'jinnanshouyi':'Tiền boa có thể được thanh toán dưới dạng thu nhập',
    'jiesuanrules':'Quy tắc giải quyết',
    'tequan':'đặc quyền cấp độ',
    'kuaisshengji':'Cách nâng cấp nhanh chóng',
    'wodedengji':'trình độ của tôi',
    'dengjishuoming':'mô tả cấp độ',
    'zhubotequan':'Đặc quyền neo',
    'shejizhong':'Đặc quyền của neo đang được thiết kế',
    'moretequan':'Sắp có thêm nhiều đặc quyền',
    'huodeliwu':'Nhận quà',
    'qingfenzhibo':'phát sóng trực tiếp siêng năng',
    'zensongliwu':'tặng một món quà',
    'yonghutequan':'Đặc quyền của người dùng',
    'tequansheji':'Đặc quyền của người dùng đang được thiết kế',
    'fengzhong':'phút',
    'jiyang':'kinh nghiệm',
    'quedingquxiao':'Bạn có chắc muốn hủy theo dõi không? ',
    'zhengquetianxie':'Vui lòng điền thông tin chính xác, nếu không bạn sẽ không nhận được bất kỳ phần thưởng nào',
    'qingshuruzhanghao':'Vui lòng nhập số tài khoản của bạn',
    'yaoqingshuoming':'Mô tả lời mời',
    'zaiciquanren':'Vui lòng xác nhận lại tài khoản {msg}: {msg1}',
    'qingshuruzhanghao':'Vui lòng nhập {msg} số tài khoản',
    'daishenhe':'đang được xem xét',
    'yipaijiang':'Giải thưởng đã được phân phối',
    'weitongguo':'thất bại',
    'leijizhong':'tích lũy',
    'tishi':'gợi ý',
    'tijiaochenong':'Gửi thành công! ',
    'zonghe':'toàn diện',
   'yituichi':'bị trì hoãn',
   'yiquxiao':'Đã hủy',
   'jitian':'hôm nay',
   'mintian':'ngày mai',
   'houtian':'ngày kia',
   'zuotian':'ngày hôm qua',
   'xiazaiitixing':'Cuộc hẹn thành công, hãy tải xuống APP để nhận lời nhắc cuộc hẹn',
   'quxiaoyuyue':'Hủy đặt chỗ thành công',
   'zhoutian':'Chủ nhật',
   'zhouyi':'Thứ Hai',
   'zhouer':'Thứ ba',
   'zhousan':'Thứ Tư',
   'zhousi':'Thứ năm',
   'zhouwu':'Thứ sáu',
   'zhouliu':'Thứ bảy',
   'jianshu':'mảnh',
   'meiri':'hàng ngày',
   'meizhou':'hàng tuần',
   'duihuan Chenggong':'Đổi thành công',
   'zuiduoduihuan':'Quy đổi tối đa {tin nhắn} mảnh',
   'caozuo':'hoạt động',
   'zongjia':'tổng giá',
   'shanchu':'xóa',
   'now':'chỉ',
   'yituikuan':'được hoàn tiền',
   'goumai':'mua',
   'xuanzezhibo':'Chọn kênh phát sóng trực tiếp',
   'erjipingdao':'Kênh cấp hai',
   'zhibobiaoti':'Tiêu đề phát sóng trực tiếp',
   'shezhibiaoti':'Vui lòng đặt tiêu đề phát sóng trực tiếp',
   'guanliansaishi':'các sự kiện liên quan',
   'xuanzhiguanlian':'Hãy liên kết sự kiện này',
   'xuanze':'chọn',
   'yichu':'xóa',
   'zhibofenmian':'Ảnh bìa phát sóng trực tiếp',
   'yishenhe':'đã xem xét',
   'shenhezhong':'đang được xem xét',
   'yijujue':'Bị từ chối',
   'zhibijieshu':'Kết thúc phát sóng trực tiếp',
   'kaishizhibo':'Bắt đầu phát sóng trực tiếp',
   'tuiliudizhi':'Địa chỉ đẩy (địa chỉ máy chủ)',
   'fuzhi':'sao chép',
   'tuiliumiyao':'Mã đẩy (khóa phát trực tuyến)',
   'laliudizhi':'Laliudizhi',
   'zhiboshuoming':'Mô tả chương trình phát sóng trực tiếp',
   'zhibotips1':`
   1. Đặt thông tin cơ bản: chọn kênh, đặt tiêu đề, liên kết sự kiện, bìa phát sóng trực tiếp, đặt khi cần;<br />
   2. Sau khi nhấp vào nút "Bắt đầu phát sóng trực tiếp", sao chép địa chỉ đẩy và mã đẩy;<br />
   3. Dán địa chỉ đẩy và mã đẩy vào "máy chủ và khóa phát trực tuyến" tương ứng với phần mềm OBS và bắt đầu phát sóng trực tiếp;<br />`,
   'zhibotips2':`
   1. Sau khi nhấp để bắt đầu phát sóng trực tiếp, vui lòng sao chép và dán địa chỉ đẩy và mã đẩy vào OBS trong vòng 1 phút và đẩy luồng. Nếu không, phòng phát sóng trực tiếp có thể bị hạ cấp do hệ thống bất thường;<br />
   2. Sau khi kết thúc phát sóng trực tiếp và đóng OBS, bạn cần nhấn vào nút kết thúc phát sóng trực tiếp bên trên để tải phòng phát sóng trực tiếp. <br />`,
   'yiyuyue':'Sự kiện đã được đặt trước',
   'biaotitips':'Tiêu đề không được để trống! ',
   'yuliang':'xem trước',
   'zhuyishixian':'Ghi chú',
   'xinjian':'Lệnh đẩy mới',
   'xuanzesaishi':'Chọn sự kiện',
   'xuanzesaiduan':'Chọn giai đoạn',
   'xuanzewf':'Chọn cách chơi',
   'jieguotips':'Vui lòng nhập kết quả, tối đa 25 từ',
   'tuidanlishi':'Lệnh đẩy lịch sử',
   'renmai':'{msg}人 tuân theo mệnh lệnh',
   'renfanmai':'{msg}mọi người mua chống lại',
   'qingxuanzesaishi':'Vui lòng chọn sự kiện',
   'qingsaiduan':'Xin vui lòng chọn sân khấu',
   'qingwf':'Hãy chọn cách chơi',
   'qingjieguo':'Xin vui lòng nhập kết quả',
   'tuidangtips':'Không thể thay đổi thứ tự khuyến mãi này sau khi đã gửi. Bạn có xác nhận việc gửi không? ',
   'fabutuidan':'Xuất bản lệnh đẩy',
   'chuangjian':'Tạo thành công',
   'yijianfabu':'Xuất bản bằng một cú nhấp chuột',
   'zimushezhi':'Cài đặt phụ đề trực tiếp',
   'zhimutips':'Không bắt buộc, bạn có thể tiếp tục chỉnh sửa sau khi chương trình phát sóng bắt đầu',
   'dingbu':'màn hình trên cùng',
   'shangshi':'Hiển thị phụ đề',
   'zimutips':'Vui lòng nhập phụ đề cho giao diện phát sóng trực tiếp, tối đa 50 ký tự',
   'xuanzepeise':'Chọn màu:',
   'dibu':'màn hình phía dưới',
   'shanchutips':'Mẹo: Nếu bạn cần xóa, vui lòng nhấp vào Đặt lại',
   'yuliangzhuangshi':'Hiển thị xem trước',
   'shoujiduan':'Xem trước phiên bản di động',
   'diannaoduan':'xem trước trên PC',
   'resettips':'Bạn có chắc chắn muốn đặt lại không? Phụ đề đã có hiệu lực ở giao diện người dùng sẽ tự động bị xóa sau khi thiết lập lại. ',
   'baocuntips':'Bạn có chắc chắn muốn lưu không? Sau khi lưu, phụ đề giao diện người dùng sẽ được cập nhật và hiển thị. ',
   'caozuochengongg':'Thao tác thành công',
   'dangqianjy':'Giá trị kinh nghiệm hiện tại',
   'jinrihuode':'Nhận ngay hôm nay',
   'guizeshuo':'Mô tả quy tắc',
   'dangqian':' LV hiện tại.{msg}',
   'shenjilv':'Nâng cấp lên LV.{msg} yêu cầu {msg1} điểm kinh nghiệm',
   'dachenlv':'Cần có tổng cộng {msg1}điểm kinh nghiệm để đạt được V{msg}',
   'shenjidaolv':' Nâng cấp lên V{msg} yêu cầu {msg1} điểm kinh nghiệm',
   'xinshourenwu':'Nhiệm vụ dành cho người mới',
   'meirirenwu':'công việc hàng ngày',
   'jieji':'đẳng cấp',
   'dengjit':'cấp độ',
   'tubiao':'biểu tượng',
   'gongxihuode':'Chúc mừng bạn đã nhận được',
   'weiwang Cheng':'Chưa hoàn thành',
   'jianlilist':'Chi tiết phần thưởng',
   'renwuleixin':'loại nhiệm vụ',
   'renwujianli':'Phần thưởng nhiệm vụ',
   'paifazhuangt':'Trạng thái phân phối',
   'huodeshijian':'Hãy dành thời gian',
   'weipaifa':'không được phân phối',
   'yipaifa':'phân phối',
   'yuyanzhuti':'Chủ đề tiên tri',
   'cangyuxiang':'vật phẩm tham gia',
   'beishu':'nhiều',
   'touru':'đầu tư',
   'yingkui':'lãi và lỗ',
   'yuyanshijian':'thời gian tiên tri',
   'zhubogonggao':'Thông báo neo',
   'zuiduiyuyue':'Đặt chỗ tối đa cho 20 sự kiện cùng lúc',
   'yituihuan':'Đã trở lại',
   'qiuduimingchen':'Xin vui lòng nhập tên đội',
   'sousuoqiudui':'tìm kiếm',
   'chongxinxuanze':'Chọn lại',
   'fangfaleixing':'Loại phương thức',
   xuanzegongshi:'Chọn công ty',
   jinnangbiaoti:'Tên dự án',
   jinnangbiaoti:'Vui lòng nhập tiêu đề (hơn 4 từ)',
   jinnangjiexibia: 'Vui lòng nhập phân tích (không ít hơn 20 từ~)',
   tuchutips:'Nội dung không thể lưu sau khi thoát. Bạn có chắc chắn muốn thoát không? ',
   biaotizuishao:'Tiêu đề phải có ít nhất 4 từ',
   jiexizuishao:'phân tích ít nhất 20 ký tự',
   tianjiachengong:'Thêm thành công'
};
