export const formatDate = (value, format) => {
  if (value) {
    if (typeof (value) === 'string') {
      value = value.replace(/-/g, '/')
    }
    value = new Date(value)
    const o = {
      'M+': value.getMonth() + 1, // month
      'd+': value.getDate(), // day
      'h+': value.getHours(), // hour
      'm+': value.getMinutes(), // minute
      's+': value.getSeconds(), // second
      'q+': Math.floor((value.getMonth() + 3) / 3), // quarter
      'S': value.getMilliseconds() // millisecond
    }
    if (!format) format = 'yyyy-MM-dd'
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1,
        (value.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
      }
    }
    return format
  } else {
    return ''
  }
}
