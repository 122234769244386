<template>
  <div class="main-content" :data-theme="$store.getters.themeType">
    <van-row type="flex" align="center" style="margin-top: 0px;">
      <van-col span="20" style="padding-left: 10px;">
        <cube-input v-model="keywords" :clearable="true" :placeholder="$t('qiuduimingcheng')" class="search-input">
          <template #prepend>
            <img style="width: 14px; height: 14px; margin-left: 10px;" src="@/assets/images/live-setting/search_icon.png">
          </template>
        </cube-input>
      </van-col>
      <van-col span="4" style="text-align: center;">
        <span class="main-theme-font-color" @click="handleSearch">{{ $t('sousuoqiudui') }}</span>
      </van-col>
    </van-row>
    <div style="margin-top: 15px;" class="_main-content-list">
      <van-list
        v-if="list.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="loadList"
      >
        <div v-for="(item,index) in list" :key="index" :title="item">
          <van-row type="flex" align="center" justify="space-between" class="main-theme-font-color" style="margin-left: 20px; margin-right: 20px;" @click="rowClicked(item)">
            <span style="line-height: 22px; font-size: 13px;">{{ item.eventName }} | {{ item.matchTime.substr(5,2) }}/{{ item.matchTime.substr(8,2) }} {{ item.matchTime.substr(10,6) }} {{ item.homeName }}VS{{ item.awayName }}</span>
          </van-row>
          <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
        </div>
      </van-list>
      <van-empty
        v-show="!loading && list.length === 0"
        style="margin-top: 50%;"
        class="empty-image"
        :description="$t('nodata')"
      >
        <div slot="image" style="width: 100%; text-align: center">
          <img style="width: 257px; height: 84px;" src="@/assets/images/empty.png">
        </div>
      </van-empty>
    </div>
  </div>
</template>

<script>
import { CellGroup, Cell, Row, Col, Divider, List, Popup, Tab, Tabs, Empty } from 'vant'
import { gameList } from '@/api/answer-kit/answerKit'
import { deepClone } from '@/utils'

export default {
  name: 'GameSelection',
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [List.name]: List,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Empty.name]: Empty
  },
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      keywords: '',
      list: [],
      lastId: 0,
      loading: false,
      finished: false,
      finishedText: '没有更多了'
    }
  },
  created() {
    this.loadList()
  },
  methods: {
    handleSearch() {
      this.lastId = 0
      this.list = []
      this.loadList()
    },
    loadList() {
      this.loading = true
      gameList({ type: this.type, lastId: this.lastId, keywords: this.keywords }).then(response => {
        this.loading = false
        this.list = this.list.concat(response.data)
        if (response.data.length > 0) {
          this.lastId = response.data[response.data.length - 1].id
        }
        // if (response.data.length < 20) {
        this.finished = true
        this.finishedText = '没有更多了'
        // }
        if (this.list.length < 20) {
          this.finishedText = ''
        }
      })
    },
    rowClicked(row) {
      row['gameType'] = this.type
      this.$router.push({
        name: 'KitAddition',
        params: deepClone(row)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.main-content {
  padding-top: 15px;
  height: 100%;
  @include color_main_bg_2();
  ._main-content-list{
    height: calc(100vh - 115px);
    overflow: auto;
  }
}
.van-list {
  @include color_main_bg_2();
}
.search-input {
  border-radius: 6px!important;
  @include color_search_input();
}
/deep/input::-webkit-input-placeholder{
  color:#C0C0C0;
}
/deep/input::-moz-placeholder{   /* Mozilla Firefox 19+ */
  color:#C0C0C0;
}
/deep/input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
  color:#C0C0C0;
}
/deep/input:-ms-input-placeholder{  /* Internet Explorer 10-11 */
  color:#C0C0C0;
}
/deep/.cube-input-field {
  @include color_main_font();
  border-radius: 6px!important;
}
/deep/.cube-input::after {
  border: 0px solid #ebebeb;
}
/deep/.van-tabs__nav--card {
  @include van_tabs_card();
  margin: 0 10px;
  border-radius: 6px;
  padding: 1px;
}
/deep/.van-tab {
  @include van_tab();
  border-radius: 6px;
}
/deep/.van-tab--active {
  @include van_tab_active();
}
/deep/.empty-image .van-empty__image {
  width: 257px;
  height: 84px;
}
</style>
