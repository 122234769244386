<template>
  <div class="main-container" :data-theme="$store.getters.themeType">
    <div style="height: 100px;">
      <van-tabs
        v-model="activeTab"
        :background="getTabBackgroundColor()"
        :color="$theme.getTabsUnderlineColor()"
        title-inactive-color="#808080"
        :title-active-color="getTabTitleActiveColor()"
        line-width="14"
        :border="false"
        @click="handleTabChange"
      >
        <van-tab :title="sportType === '2' ? '让分' : $t('rq')">
          <van-row style="color: #9B9B9B; margin-top: 10px; font-size: 14px; padding-right: 15px;">
            <van-col span="5" class="text-center">{{ $t('xuanzegongshi') }}</van-col>
            <van-col span="5" class="text-center pl-14">{{ sportType === '2' ? '客队' : '胜指数' }}</van-col>
            <van-col span="4" class="text-center">{{ sportType === '2' ? '盘口' : '让球' }}</van-col>
            <van-col span="4" class="text-right">{{ sportType === '2' ? '主队' : '负指数' }}</van-col>
            <van-col span="6" class="text-right">{{ sportType === '2' ? '变盘时间' : '更新时间' }}</van-col>
          </van-row>
        </van-tab>
        <van-tab title="欧指">
          <van-row style="color: #9B9B9B; margin-top: 10px; font-size: 14px; padding-right: 15px;">
            <van-col span="5" class="text-center">{{ $t('xuanzegongshi') }}</van-col>
            <van-col span="5" class="text-center pl-14">{{ sportType === '2' ? '客胜' : '胜' }}</van-col>
            <van-col span="4" class="text-center">{{ sportType === '2' ? '主胜' : '平' }}</van-col>
            <van-col span="4" class="text-center pl-14">{{ sportType === '2' ? '返还率' : '负' }}</van-col>
            <van-col span="6" class="text-right">{{ sportType === '2' ? '变盘时间' : '更新时间' }}</van-col>
          </van-row>
        </van-tab>
        <van-tab :title="sportType === '2' ? '总分' : '大小'">
          <van-row style="color: #9B9B9B; margin-top: 10px; font-size: 14px; padding-right: 15px;">
            <van-col span="5" class="text-center">{{ $t('xuanzegongshi') }}</van-col>
            <van-col span="5" class="text-center pl-14">{{ sportType === '2' ? '大球' : '大' }}</van-col>
            <van-col span="4" class="text-center">{{ sportType === '2' ? '盘口' : '总进球' }}</van-col>
            <van-col span="4" class="text-center pl-14">{{ sportType === '2' ? '小球' : '小' }}</van-col>
            <van-col span="6" class="text-right">{{ sportType === '2' ? '变盘时间' : '更新时间' }}</van-col>
          </van-row>
        </van-tab>
      </van-tabs>
    </div>
    <div style="position: absolute; width: 100%; top: 85px; bottom: 0px; left: 0px;">
      <van-tree-select
        height="100%"
        :items="leftNavItems"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        @click-nav="clickNav"
      >
        <template #content>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="getIndex"
          >
            <van-row v-for="(item,index) in list" :key="index" :title="item" type="flex" align="center" style="padding: 19px 15px 14px 0px; font-size: 13px;" class="main-theme-font-color">
              <van-col span="7">
                <div style="position: relative; width: 100%; text-align: center;">
                  <span v-if="item.tag && item.tag !== ''" style="position: absolute; color: #fff; left: 8px; top:50%; transform: translateY(-50%); background-color: #F04343; width: 15px; height: 15px; line-height: 15px; font-size: 13px;">{{ item.tag }}</span>
                  <span>{{ item.value1 }}</span>
                </div>
              </van-col>
              <van-col span="5" class="text-center pr-20">
                {{ item.value2 }}
              </van-col>
              <van-col span="5" class="text-center">
                {{ item.value3 }}
              </van-col>
              <van-col span="7" class="text-right">
                {{ item.updateAt.substr(5,11) }}
              </van-col>
            </van-row>
          </van-list>
          <van-empty
            v-if="list.length === 0 && !loading"
            class="empty-image"
            style="padding-top: 70%;"
          >
            <div slot="image" style="width: 100%; text-align: center">
              <img style="width: 257px; height: 84px;" src="@/assets/images/empty.png">
            </div>
            <div slot="description" style="width: 100%; margin-top: 20px; text-align: center; color: #9B9B9B; font-size: 13px;">
              <div>{{ $t('nodata') }}</div>
            </div>
          </van-empty>
        </template>
      </van-tree-select>
    </div>
  </div>
</template>
<script>

import { CellGroup, Cell, Row, Col, Divider, List, DatetimePicker, Popup, Tab, Tabs, Empty, TreeSelect } from 'vant'
import { gameIndex, fetchCompanyList } from '@/api/game/gameIndex'

export default {
  name: 'GameIndex',
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [List.name]: List,
    [DatetimePicker.name]: DatetimePicker,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Empty.name]: Empty,
    [TreeSelect.name]: TreeSelect
  },
  data() {
    return {
      activeTab: 0,
      leftNavItems: [],
      activeId: 1,
      activeIndex: 0,
      loading: true,
      finished: false,
      companyList: [],
      list: [],
      id: undefined,
      companyId: undefined,
      sportType: undefined
    }
  },
  created() {
    this.setTitle()
    this.parseQuery()
    this.getCompanyList()
  },
  methods: {
    getTabBackgroundColor() {
      if (this.$store.getters.themeType === 'default') {
        return '#1A1A1C'
      } else if (this.$store.getters.themeType === 'tint') {
        return '#ffffff'
      }
    },
    getTabTitleActiveColor() {
      if (this.$store.getters.themeType === 'default') {
        return '#ffffff'
      } else if (this.$store.getters.themeType === 'tint') {
        return '#404044'
      }
    },
    parseQuery() {
      this.id = this.getQueryVariable('id')
      this.activeTab = this.getQueryVariable('handicapType') - 1
      this.companyId = this.getQueryVariable('companyId')
      this.sportType = this.getQueryVariable('sportType')
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return false
    },
    setTitle() {
      document.title = '详细指数'
    },
    getCompanyList() {
      fetchCompanyList({ id: this.id, handicapType: this.activeTab + 1 }).then(response => {
        this.companyList = response.data
        this.leftNavItems = response.data.map(obj => {
          return {
            text: obj.companyName
          }
        })
        for (let i = 0; i < this.companyList.length; i++) {
          const company = this.companyList[i]
          if (company.companyId === this.companyId) {
            this.activeIndex = i
          }
        }
        this.getIndex()
      })
    },
    getIndex() {
      this.loading = true
      gameIndex({ id: this.id, handicapType: this.activeTab + 1, companyId: this.companyId }).then(response => {
        this.finished = true
        this.loading = false
        this.list = response.data
      })
    },
    handleTabChange(name, title) {
      this.list = []
      fetchCompanyList({ id: this.id, handicapType: this.activeTab + 1 }).then(response => {
        this.companyList = response.data
        this.companyId = this.companyList.length > 0 ? this.companyList[0].companyId : undefined
        this.activeIndex = 0
        this.leftNavItems = response.data.map(obj => {
          return {
            text: obj.companyName
          }
        })
        this.getIndex()
      })
    },
    clickNav(index) {
      this.list = []
      const company = this.companyList[index]
      this.companyId = company.companyId
      this.getIndex()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.main-container {
  position: relative;
  height: 100%;
  padding: 0px 0px 15px 0px;
  @include color_main_bg();
}
.pl-14 {
  padding-left: 14px;
}

.pr-20 {
  padding-right: 20px;
}

/deep/.van-sidebar-item__text {
  position: relative;
  display: block;
}
/deep/.van-tree-select__nav {
  flex: 5;
  @include color_main_bg();
}
/deep/.van-tree-select__content {
  @include color_index_bg();
  flex: 19;
}
/deep/.van-sidebar-item {
  @include color_main_bg();
  color: #9B9B9B;
}
/deep/.van-sidebar-item--select {
  @include color_index_bg();
}
/deep/.van-sidebar-item--select::before {
  @include color_index_bg();
}
/deep/.van-sidebar-item--select:active {
  @include color_index_bg();
}
/deep/.empty-image .van-empty__image {
  width: 100%;
  height: 59px;
}
</style>
