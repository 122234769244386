var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "main-content" },
      [
        _c("van-row", { attrs: { type: "flex", align: "center" } }, [
          _c(
            "span",
            { staticStyle: { "font-size": "14px", color: "#6C6C6C" } },
            [_vm._v(_vm._s(_vm.$t("dangqianzhibopingdao")))]
          ),
          _c(
            "span",
            {
              staticClass: "main-theme-font-color",
              staticStyle: { "margin-left": "10px", "font-size": "16px" },
            },
            [
              _vm._v(_vm._s(_vm.currentChannelName)),
              _vm.$store.getters.liveConfig.subChannelName
                ? _c("span", [
                    _vm._v(
                      "-" + _vm._s(_vm.$store.getters.liveConfig.subChannelName)
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-top": "29px" } }, [
          _c("span", { staticClass: "main-theme-font-color" }, [
            _vm._v(_vm._s(_vm.$t("xuanzezhibo"))),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "van-radio-group",
              {
                attrs: { direction: "horizontal" },
                on: { change: _vm.channelChange },
                model: {
                  value: _vm.channel,
                  callback: function ($$v) {
                    _vm.channel = $$v
                  },
                  expression: "channel",
                },
              },
              _vm._l(_vm.channelList, function (item, index) {
                return _c("van-radio", {
                  key: index,
                  staticStyle: { height: "60px" },
                  attrs: { name: item },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function (props) {
                          return [
                            _c(
                              "van-button",
                              {
                                class: props.checked
                                  ? "channe-item-active"
                                  : "channe-item-normal",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("omitString")(item.channelName, 4)
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.subChannelList.length > 0,
                expression: "subChannelList.length > 0",
              },
            ],
          },
          [
            _c("div", { staticStyle: { "margin-top": "29px" } }, [
              _c("span", { staticClass: "main-theme-font-color" }, [
                _vm._v(_vm._s(_vm.$t("erjipingdao"))),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "van-radio-group",
                  { attrs: { direction: "horizontal" } },
                  _vm._l(_vm.subChannelList, function (item, index) {
                    return _c("van-radio", {
                      key: index,
                      staticStyle: { height: "60px" },
                      attrs: { name: item },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c(
                                  "van-button",
                                  {
                                    class:
                                      _vm.subChannel === item
                                        ? "channe-item-active"
                                        : "channe-item-normal",
                                    on: {
                                      click: function ($event) {
                                        return _vm.subChannelChange(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm._f("omitString")(item.name, 4))
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "bottom" }, [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "van-button",
            {
              staticClass: "confirmation-button",
              attrs: { disabled: !_vm.channel },
              on: {
                click: function ($event) {
                  return _vm.confirm()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("querenbtn")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }