var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c(
            "van-row",
            {
              staticClass: "main-theme-font-color",
              staticStyle: { "margin-bottom": "20px" },
              attrs: {
                type: "flex",
                justify: "space-between",
                align: "center",
              },
            },
            [
              _c("div", [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.selectedGame.homeName) +
                      "VS" +
                      _vm._s(_vm.selectedGame.awayName)
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticStyle: { "margin-top": "8px", color: "#9B9B9B" } },
                  [
                    _vm._v(
                      _vm._s(_vm.selectedGame.eventName) +
                        " | " +
                        _vm._s(
                          _vm.selectedGame.matchTime &&
                            _vm.selectedGame.matchTime.substr(5, 2)
                        ) +
                        "/" +
                        _vm._s(
                          _vm.selectedGame.matchTime &&
                            _vm.selectedGame.matchTime.substr(8, 2)
                        ) +
                        " " +
                        _vm._s(
                          _vm.selectedGame.matchTime &&
                            _vm.selectedGame.matchTime.substr(10, 6)
                        )
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "van-button",
                {
                  staticClass: "choose-botton",
                  attrs: { plain: "", type: "info" },
                  on: {
                    click: function ($event) {
                      return _vm.goBack()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("chongxinxuanze")))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "separation-line" }),
          _vm._v(" "),
          _vm.selectedGame.gameType === 0
            ? _c(
                "div",
                [
                  _c(
                    "van-row",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        "margin-bottom": "10px",
                      },
                      attrs: { type: "flex", align: "center" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "main-theme-font-color",
                          staticStyle: { "font-size": "16px" },
                        },
                        [_vm._v(_vm._s(_vm.$t("recommendgoods")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "#9B9B9B",
                            "margin-left": "5px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("qingwf")))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "van-row",
                    {
                      attrs: {
                        type: "flex",
                        justify: "space-between",
                        align: "center",
                      },
                    },
                    [
                      _c("van-col", { attrs: { span: "6" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "card",
                            staticStyle: {
                              "text-align": "center",
                              color: "#9B9B9B",
                              "font-size": "12px",
                            },
                          },
                          [
                            _c(
                              "p",
                              { staticStyle: { "margin-bottom": "10px" } },
                              [_vm._v(_vm._s(_vm.$t("rq")))]
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.selectedGame
                                    ? _vm.selectedGame.spf.addBall
                                    : "-"
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "van-col",
                        { attrs: { span: "17", offset: "1" } },
                        [
                          _c(
                            "van-row",
                            [
                              _c(
                                "van-col",
                                {
                                  attrs: { span: "8" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.optionChange(1)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rectangle left-radius right-border",
                                      class:
                                        _vm.selectedOption === 1
                                          ? "option-active"
                                          : "option",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class:
                                            _vm.selectedOption === 1
                                              ? "option-title-active"
                                              : "option-title",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "10px",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.$t("zhusheng")))]
                                          ),
                                          _vm._v(" "),
                                          !_vm.selectedGame
                                            ? _c("span", [_vm._v("-")])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedGame
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.selectedGame.spf
                                                      .realOdds[0]
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "van-col",
                                {
                                  attrs: { span: "8" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.optionChange(2)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "rectangle right-border",
                                      class:
                                        _vm.selectedOption === 2
                                          ? "option-active"
                                          : "option",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class:
                                            _vm.selectedOption === 2
                                              ? "option-title-active"
                                              : "option-title",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "10px",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.$t("pingju")))]
                                          ),
                                          _vm._v(" "),
                                          !_vm.selectedGame
                                            ? _c("span", [_vm._v("-")])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedGame
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.selectedGame.spf
                                                      .realOdds[1]
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "van-col",
                                {
                                  attrs: { span: "8" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.optionChange(3)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "rectangle right-radius",
                                      class:
                                        _vm.selectedOption === 3
                                          ? "option-active"
                                          : "option",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class:
                                            _vm.selectedOption === 3
                                              ? "option-title-active"
                                              : "option-title",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "10px",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.$t("kesheng")))]
                                          ),
                                          _vm._v(" "),
                                          !_vm.selectedGame
                                            ? _c("span", [_vm._v("-")])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedGame
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.selectedGame.spf
                                                      .realOdds[2]
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "van-row",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        "margin-bottom": "10px",
                      },
                    },
                    [
                      _c("van-col", { attrs: { span: "6" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "card",
                            staticStyle: {
                              "text-align": "center",
                              color: "#9B9B9B",
                              "font-size": "12px",
                            },
                          },
                          [
                            _c(
                              "p",
                              { staticStyle: { "margin-bottom": "10px" } },
                              [_vm._v(_vm._s(_vm.$t("rq")))]
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.selectedGame
                                    ? _vm.selectedGame.rqspf.addBall
                                    : "-"
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "van-col",
                        { attrs: { span: "17", offset: "1" } },
                        [
                          _c(
                            "van-row",
                            [
                              _c(
                                "van-col",
                                {
                                  attrs: { span: "8" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.optionChange(4)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rectangle left-radius right-border",
                                      class:
                                        _vm.selectedOption === 4
                                          ? "option-active"
                                          : "option",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class:
                                            _vm.selectedOption === 4
                                              ? "option-title-active"
                                              : "option-title",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "10px",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.$t("zhusheng")))]
                                          ),
                                          _vm._v(" "),
                                          !_vm.selectedGame
                                            ? _c("span", [_vm._v("-")])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedGame
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.selectedGame.rqspf
                                                      .realOdds[0]
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "van-col",
                                {
                                  attrs: { span: "8" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.optionChange(5)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "rectangle right-border",
                                      class:
                                        _vm.selectedOption === 5
                                          ? "option-active"
                                          : "option",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class:
                                            _vm.selectedOption === 5
                                              ? "option-title-active"
                                              : "option-title",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "10px",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.$t("pingju")))]
                                          ),
                                          _vm._v(" "),
                                          !_vm.selectedGame
                                            ? _c("span", [_vm._v("-")])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedGame
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.selectedGame.rqspf
                                                      .realOdds[1]
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "van-col",
                                {
                                  attrs: { span: "8" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.optionChange(6)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "rectangle right-radius",
                                      class:
                                        _vm.selectedOption === 6
                                          ? "option-active"
                                          : "option",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class:
                                            _vm.selectedOption === 6
                                              ? "option-title-active"
                                              : "option-title",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "10px",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.$t("kesheng")))]
                                          ),
                                          _vm._v(" "),
                                          !_vm.selectedGame
                                            ? _c("span", [_vm._v("-")])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedGame
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.selectedGame.rqspf
                                                      .realOdds[2]
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedGame.gameType === 1
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _c(
                    "van-row",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        "margin-bottom": "10px",
                      },
                      attrs: {
                        type: "flex",
                        align: "center",
                        justify: "space-between",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "main-theme-font-color",
                          staticStyle: { "font-size": "16px" },
                        },
                        [_vm._v(_vm._s(_vm.$t("fangfaleixing")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.chooseCompany()
                            },
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "#4A90E2" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.companyData ? _vm.companyData.label : "-"
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: {
                              width: "12px",
                              height: "12px",
                              "margin-left": "4px",
                            },
                            attrs: {
                              src: require("@/assets/images/live/arrow-down.png"),
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("method-segmentation", {
                    on: { change: _vm.methodChange },
                  }),
                  _vm._v(" "),
                  _c(
                    "van-row",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        "margin-bottom": "10px",
                      },
                      attrs: { type: "flex", align: "center" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "main-theme-font-color",
                          staticStyle: { "font-size": "16px" },
                        },
                        [_vm._v(_vm._s(_vm.$t("recommendgoods")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "#9B9B9B",
                            "margin-left": "5px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("qingwf")))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "van-popup",
                    {
                      style: { maxHeight: "80%", minHeight: "30%" },
                      attrs: {
                        "get-container": "#app",
                        round: true,
                        position: "bottom",
                      },
                      model: {
                        value: _vm.showCompanyList,
                        callback: function ($$v) {
                          _vm.showCompanyList = $$v
                        },
                        expression: "showCompanyList",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { position: "relative" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "main-bg",
                              staticStyle: {
                                position: "sticky",
                                top: "0px",
                                width: "100%",
                                "z-index": "2",
                              },
                            },
                            [
                              _c(
                                "van-row",
                                {
                                  staticClass: "main-theme-font-color",
                                  staticStyle: { padding: "20px" },
                                  attrs: {
                                    type: "flex",
                                    justify: "space-between",
                                    align: "center",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.showCompanyList = false
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "16px",
                                        "font-weight": "500",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("xuanzegongshi")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "popup-confirmation",
                                      staticStyle: { color: "#ffffff" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCompanyChange()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("confirm")))]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "van-radio-group",
                            {
                              model: {
                                value: _vm.selectedCompany,
                                callback: function ($$v) {
                                  _vm.selectedCompany = $$v
                                },
                                expression: "selectedCompany",
                              },
                            },
                            [
                              _c(
                                "van-cell-group",
                                _vm._l(_vm.companyList, function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c("van-cell", {
                                        attrs: { clickable: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.selectedCompany = item
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "main-theme-font-color",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.label)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "right-icon",
                                              fn: function () {
                                                return [
                                                  _c("van-radio", {
                                                    attrs: { name: item },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("recommend-segmentation", {
                    attrs: {
                      type: _vm.methodType,
                      gameType: _vm.selectedGame.gameType,
                      data: _vm.companyData ? _vm.recommendData : undefined,
                    },
                    on: { change: _vm.recommendTypeChange },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedGame.gameType === 2
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _c(
                    "van-row",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        "margin-bottom": "10px",
                      },
                      attrs: {
                        type: "flex",
                        align: "center",
                        justify: "space-between",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "main-theme-font-color",
                          staticStyle: { "font-size": "16px" },
                        },
                        [_vm._v(_vm._s(_vm.$t("fangfaleixing")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.chooseCompany()
                            },
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "#4A90E2" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.companyData ? _vm.companyData.label : "-"
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: {
                              width: "12px",
                              height: "12px",
                              "margin-left": "4px",
                            },
                            attrs: {
                              src: require("@/assets/images/live/arrow-down.png"),
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("method-segmentation", {
                    on: { change: _vm.methodChange },
                  }),
                  _vm._v(" "),
                  _c(
                    "van-row",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        "margin-bottom": "10px",
                      },
                      attrs: { type: "flex", align: "center" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "main-theme-font-color",
                          staticStyle: { "font-size": "16px" },
                        },
                        [_vm._v(_vm._s(_vm.$t("recommendgoods")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "#9B9B9B",
                            "margin-left": "5px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("qingwf")))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "van-popup",
                    {
                      style: { maxHeight: "80%", minHeight: "30%" },
                      attrs: {
                        "get-container": "#app",
                        round: true,
                        position: "bottom",
                      },
                      model: {
                        value: _vm.showCompanyList,
                        callback: function ($$v) {
                          _vm.showCompanyList = $$v
                        },
                        expression: "showCompanyList",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { position: "relative" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "main-bg",
                              staticStyle: {
                                position: "sticky",
                                top: "0px",
                                width: "100%",
                                "z-index": "2",
                              },
                            },
                            [
                              _c(
                                "van-row",
                                {
                                  staticClass: "main-theme-font-color",
                                  staticStyle: { padding: "20px" },
                                  attrs: {
                                    type: "flex",
                                    justify: "space-between",
                                    align: "center",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.showCompanyList = false
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "16px",
                                        "font-weight": "500",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("xuanzegongshi")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "popup-confirmation",
                                      staticStyle: { color: "#ffffff" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCompanyChange()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("confirm")))]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "van-radio-group",
                            {
                              model: {
                                value: _vm.selectedCompany,
                                callback: function ($$v) {
                                  _vm.selectedCompany = $$v
                                },
                                expression: "selectedCompany",
                              },
                            },
                            [
                              _c(
                                "van-cell-group",
                                _vm._l(_vm.companyList, function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c("van-cell", {
                                        attrs: { clickable: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.selectedCompany = item
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "main-theme-font-color",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.label)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "right-icon",
                                              fn: function () {
                                                return [
                                                  _c("van-radio", {
                                                    attrs: { name: item },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("recommend-segmentation", {
                    attrs: {
                      type: _vm.methodType,
                      gameType: _vm.selectedGame.gameType,
                      data: _vm.companyData ? _vm.recommendData : undefined,
                    },
                    on: { change: _vm.recommendTypeChange },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "main-theme-font-color",
              staticStyle: { "font-size": "16px" },
            },
            [_vm._v(_vm._s(_vm.$t("jinnangbiaoti")))]
          ),
          _vm._v(" "),
          _c("van-field", {
            staticClass: "title-field",
            staticStyle: { "margin-top": "10px", "margin-bottom": "10px" },
            attrs: {
              rows: "2",
              autosize: "",
              type: "textarea",
              maxlength: "40",
              placeholder: _vm.$t("jinnangbiaoti"),
              "show-word-limit": "",
            },
            on: { input: _vm.titleInput },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "main-theme-font-color",
              staticStyle: { "font-size": "16px" },
            },
            [_vm._v(_vm._s(_vm.$t("reason")))]
          ),
          _vm._v(" "),
          _c("van-field", {
            staticClass: "title-field",
            staticStyle: { "margin-top": "10px", "margin-bottom": "10px" },
            attrs: {
              rows: "10",
              type: "textarea",
              maxlength: "150",
              placeholder: _vm.$t("jinnangjiexibia"),
              "show-word-limit": "",
            },
            on: { input: _vm.contentInput },
            model: {
              value: _vm.reason,
              callback: function ($$v) {
                _vm.reason = $$v
              },
              expression: "reason",
            },
          }),
          _vm._v(" "),
          _c(
            "van-row",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "flex", align: "center" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "main-theme-font-color",
                  staticStyle: { "font-size": "16px", "margin-right": "10px" },
                },
                [_vm._v(_vm._s(_vm.$t("jiage")))]
              ),
              _vm._v(" "),
              _c("cube-checker", {
                staticStyle: { "font-size": "16px" },
                attrs: { options: _vm.options, type: "radio" },
                model: {
                  value: _vm.price,
                  callback: function ($$v) {
                    _vm.price = $$v
                  },
                  expression: "price",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bottom" }, [
        _c(
          "div",
          { staticClass: "row", staticStyle: { "padding-top": "10px" } },
          [
            _c(
              "van-button",
              {
                staticClass: "button-primary",
                staticStyle: { width: "80%", "border-radius": "6px" },
                attrs: {
                  disabled: _vm.publishingDisable,
                  loading: _vm.loading,
                },
                on: { click: _vm.commit },
              },
              [_vm._v(_vm._s(_vm.$t("fabu")))]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("alert", {
        attrs: {
          visible: _vm.showAlert,
          message: _vm.$t("tuichutips"),
          "cancel-button-text": _vm.$t("cancel"),
          "confirm-button-text": _vm.$t("confirm"),
        },
        on: {
          "update:visible": function ($event) {
            _vm.showAlert = $event
          },
          confirm: _vm.confirm,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }