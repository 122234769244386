var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-row",
    [
      _c(
        "van-col",
        {
          attrs: { span: "8" },
          on: {
            click: function ($event) {
              return _vm.optionChange(0)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "rectangle left-radius right-border",
              class: _vm.selectedOption === 0 ? "option-active" : "option",
            },
            [
              _c(
                "span",
                {
                  class:
                    _vm.selectedOption === 0
                      ? "option-title-active"
                      : "option-title",
                },
                [_vm._v(_vm._s(_vm.$t("hk")))]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "van-col",
        {
          attrs: { span: "8" },
          on: {
            click: function ($event) {
              return _vm.optionChange(1)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "rectangle right-border",
              class: _vm.selectedOption === 1 ? "option-active" : "option",
            },
            [
              _c(
                "span",
                {
                  class:
                    _vm.selectedOption === 1
                      ? "option-title-active"
                      : "option-title",
                },
                [_vm._v(_vm._s(_vm.$t("bm")))]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "van-col",
        {
          attrs: { span: "8" },
          on: {
            click: function ($event) {
              return _vm.optionChange(2)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "rectangle right-radius",
              class: _vm.selectedOption === 2 ? "option-active" : "option",
            },
            [
              _c(
                "span",
                {
                  class:
                    _vm.selectedOption === 2
                      ? "option-title-active"
                      : "option-title",
                },
                [_vm._v(_vm._s(_vm.$t("eur")))]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }