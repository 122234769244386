var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text": _vm.finishedText,
              },
              on: { load: _vm.getList },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticStyle: {
                    position: "relative",
                    "margin-top": "10px",
                    width: "100%",
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: "/kit-detail",
                          query: { id: item.plan.id },
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(" ")),
                      ]),
                      _vm._v(" "),
                      _c("van-cell", {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { border: false, "is-link": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c("span", { staticClass: "plan-big-type" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.planBigTypeFilter(
                                          item.plan.planBigType
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        item.match.matchTime.substr(5, 2)
                                      ) +
                                        "/" +
                                        _vm._s(
                                          item.match.matchTime.substr(8, 2)
                                        )
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-left": "4px",
                                        "margin-right": "4px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.match.matchTime.substr(11, 5)
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("span", [
                                    _vm._v(_vm._s(item.match.eventName)),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-left": "4px",
                                        "margin-right": "4px",
                                      },
                                    },
                                    [_vm._v("|")]
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(item.match.homeName) +
                                        "VS" +
                                        _vm._s(item.match.awayName)
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      item.plan.price > 0
                        ? _c(
                            "div",
                            [
                              _c(
                                "van-row",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: {
                                    type: "flex",
                                    justify: "space-between",
                                    align: "center",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "main-theme-font-color" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("goumairenshu")) +
                                          "：" +
                                          _vm._s(item.plan.countSell)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          color: "#9B9B9B",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "（ " +
                                            _vm._s(_vm.$t("fencheng")) +
                                            " ）"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    (item.plan.settlementFinish === 1 &&
                                      item.plan.guessHit === 0) ||
                                    (item.isRefund && item.isRefund === 1)
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              color: "#9B9B9B",
                                              "margin-left": "5px",
                                              "text-decoration": "line-through",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("zongji")) +
                                                "：" +
                                                _vm._s(item.plan.profitTotal) +
                                                _vm._s(
                                                  _vm.commonConfig
                                                    .currencyMoneyName
                                                )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              color: "#F1CB30",
                                              "margin-left": "5px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("zongji")) +
                                                "：" +
                                                _vm._s(item.plan.profitTotal) +
                                                _vm._s(
                                                  _vm.commonConfig
                                                    .currencyMoneyName
                                                )
                                            ),
                                          ]
                                        ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "van-row",
                                {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: {
                                    type: "flex",
                                    justify: "space-between",
                                    align: "center",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          color: "#9B9B9B",
                                        },
                                      },
                                      [
                                        _vm._v(_vm._s(item.plan.createTime)),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "4px",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("fabu")))]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  (item.plan.settlementFinish === 1 &&
                                    item.plan.guessHit === 0) ||
                                  (item.isRefund && item.isRefund === 1)
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            color: "#F1CB30",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("yituihuan")))]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "van-row",
                            {
                              staticStyle: { "margin-top": "10px" },
                              attrs: {
                                type: "flex",
                                justify: "space-between",
                                align: "center",
                              },
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      color: "#9B9B9B",
                                    },
                                  },
                                  [
                                    _vm._v(_vm._s(item.plan.createTime)),
                                    _c(
                                      "span",
                                      { staticStyle: { "margin-left": "4px" } },
                                      [_vm._v("{{}}")]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    color: "#F1CB30",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("mianfei")))]
                              ),
                            ]
                          ),
                      _vm._v(" "),
                      _c("van-divider", {
                        style: {
                          borderColor: _vm.$theme.getDividerBorderColor(),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.lang === "zh"
                    ? [
                        item.plan.guessHit !== null
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  top: "0px",
                                  right: "0px",
                                },
                              },
                              [
                                item.plan.guessHit === 1
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/kit/right.png"),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                (item.plan.planBigType === 1 ||
                                  item.plan.planBigType === 2) &&
                                _vm.parseLotteryResult(item.match.spf) === 1
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/kit/draw.png"),
                                      },
                                    })
                                  : item.plan.guessHit !== null &&
                                    item.plan.guessHit === 0
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/kit/wrong.png"),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : [
                        item.plan.guessHit !== null
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  top: "0px",
                                  right: "0px",
                                },
                              },
                              [
                                item.plan.guessHit === 1
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        src: require("@/assets/plan/" +
                                          _vm.lang +
                                          "/icon_silk_correct.png"),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                (item.plan.planBigType === 1 ||
                                  item.plan.planBigType === 2) &&
                                _vm.parseLotteryResult(item.match.spf) === 1
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        src: require("@/assets/plan/" +
                                          _vm.lang +
                                          "/icon_silk_miss.png"),
                                      },
                                    })
                                  : item.plan.guessHit !== null &&
                                    item.plan.guessHit === 0
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        src: require("@/assets/plan/" +
                                          _vm.lang +
                                          "/icon_silk_uncorrect.png"),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ],
                ],
                2
              )
            }),
            0
          ),
          _vm._v(" "),
          !_vm.loading && _vm.list.length === 0
            ? _c(
                "van-empty",
                {
                  staticClass: "empty-image",
                  staticStyle: { "margin-top": "40%" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { width: "100%", "text-align": "center" },
                      attrs: { slot: "image" },
                      slot: "image",
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "257px", height: "84px" },
                        attrs: { src: require("@/assets/images/empty.png") },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { slot: "description" },
                      slot: "description",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("fabuplantips")) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bottom" }, [
        _c(
          "div",
          { staticClass: "row", staticStyle: { "padding-top": "10px" } },
          [
            _c(
              "router-link",
              { attrs: { to: { path: "/game-selection" } } },
              [
                _c(
                  "van-button",
                  {
                    staticClass: "button-primary",
                    staticStyle: { width: "260px", "border-radius": "6px" },
                  },
                  [_vm._v(_vm._s(_vm.$t("fabu") + _vm.$t("planlist")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }