var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-container" }, [
      _c("p", [
        _c("span", { staticStyle: { color: "#ffffff" } }, [
          _vm._v("亲爱的主播们："),
        ]),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("span", { staticStyle: { color: "#ffffff" } }, [
          _vm._v(
            "感谢大家选择我们直播平台，也感谢各位主播的信任与支持，为了规范平台运营，出台《封面规范说明》，请主播认真阅读封面规范说明，以求达到更好的直播效果"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("span", { staticStyle: { color: "#ffffff" } }, [
          _c("strong", [_vm._v("具体封面规范如下：")]),
        ]),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("span", { staticStyle: { color: "#ffffff" } }, [
          _vm._v("一、主播人物封面图要求"),
        ]),
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v("封面必须使用本人图片；"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v("需要背景干净、光线明亮、人像高清、半身或全身的艺术照；"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v("图片不能太暗或者曝光过度、不要用自拍像、不要用大头照；"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v("禁止使用任何直播截图；"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v("禁止使用风景、动物、卡通人物、公众人物等图片；  "),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("span", { staticStyle: { color: "#ffffff" } }, [
          _vm._v("二、封面图尺寸要求"),
        ]),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("span", { staticStyle: { color: "#ffffff" } }, [
          _vm._v("348 *1 96的等比例长方形图片；"),
        ]),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("span", { staticStyle: { color: "#ffffff" } }, [
          _vm._v("三、封面图具体原则"),
        ]),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("span", { staticStyle: { color: "#ffffff" } }, [
          _vm._v("（一）主体构图"),
        ]),
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v("图片主体突出，清晰完整；"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v("不可出现无关的文字、水印、logo等；"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v("不可拼接；"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("span", { staticStyle: { color: "#ffffff" } }, [
          _vm._v("（二）其他"),
        ]),
      ]),
      _vm._v(" "),
      _c("ol", [
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v("封面内不得出现其他主播，有版权的除外；"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v(
              "侧躺、平躺、露臀线、露胸、抽烟等封面视为低俗违规，请勿上传；"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v("封面模糊、扭曲、边框等美观度差；"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v(
              "人像不居中、侧脸、低头、只有背影、远景导致看不到全五官特征的图片；"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v(
              "使用风景、动物、卡通人物、明星、影视角色等非本人照片的图片；"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("span", { staticStyle: { color: "#ffffff" } }, [
            _vm._v("戴口罩、大面积遮挡面部的图片；"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }