export default {
  toAPPlogin(platform) {
    if (platform === 'ios') {
      try {
        window.webkit.messageHandlers.appLogin.postMessage('done')
      } catch (error) {
        console.log(error)
      }
    } else if (platform === 'android') {
      window.android.appLogin()
    }
  },
  toAppBack(platform) {
    if (platform === 'ios') {
      try {
        window.webkit.messageHandlers.appBack.postMessage('done')
      } catch (error) {
        console.log(error)
      }
    } else if (platform === 'android') {
      window.android.appBack()
    }
  }
}

