<template>
  <div class="main-content" :data-theme="$store.getters.themeType">
    <van-tabs swipeable type="card" color="#63676B" style="margin-top: 5px;">
      <van-tab :title="$t('fb')">
        <game-selection-list :type="1" style="margin-top: 5px;" />
      </van-tab>
      <!-- <van-tab title="竞足推荐">
        <game-selection-list :type="0" style="margin-top: 5px;" />
      </van-tab> -->
      <van-tab :title="$t('bk')">
        <game-selection-list :type="2" style="margin-top: 5px;" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { CellGroup, Cell, Row, Col, Divider, List, Popup, Tab, Tabs, Empty } from 'vant'
import { getSwitch } from '@/api/answer-kit/answerKit'
import GameSelectionList from './components/GameSelectionList'

export default {
  name: 'GameSelection',
  components: {
    GameSelectionList,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [List.name]: List,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Empty.name]: Empty
  },
  data() {
    return {
      showTab: false,
      showList: false,
      dataType: -1
    }
  },
  created() {
    this.getSwitch()
  },
  methods: {
    getSwitch() {
      getSwitch({}).then(response => {
        if (response.data && response.data.length === 2) {
          this.showTab = true
        } else if (response.data && response.data.length === 1) {
          this.showTab = false
          this.showList = true
          this.dataType = response.data[0]
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.main-content {
  padding-top: 10px;
  height: 100%;
  @include color_main_bg_2();
}
.van-list {
  @include color_main_bg_2();
}
.search-input {
  border-radius: 6px!important;
  @include color_search_input();
}
/deep/input::-webkit-input-placeholder{
  color:#C0C0C0;
}
/deep/input::-moz-placeholder{   /* Mozilla Firefox 19+ */
  color:#C0C0C0;
}
/deep/input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
  color:#C0C0C0;
}
/deep/input:-ms-input-placeholder{  /* Internet Explorer 10-11 */
  color:#C0C0C0;
}
/deep/.cube-input-field {
  @include color_main_font();
  border-radius: 6px!important;
}
/deep/.cube-input::after {
  border: 0px solid #ebebeb;
}
/deep/.van-tabs__nav--card {
  @include van_tabs_card();
  margin: 0 10px;
  border-radius: 6px;
  padding: 1px;
}
/deep/.van-tab {
  @include van_tab();
  border-radius: 6px;
}
/deep/.van-tab--active {
  @include van_tab_active();
}
/deep/.empty-image .van-empty__image {
  width: 257px;
  height: 84px;
}
</style>
