<template>
  <div class="main-container">
    <img style="width: 132px; height: 20px; margin-top: 20px; margin-left: 20px;" src="@/assets/images/guessing/contribution_icon.png">
    <p class="text-content" style="margin-top: 20px;">
      预言主题由主播发起，用户可在主播直播间点击预言入口按钮即可查看预言内容。
      每个预言主题分别有2个可参与选项，可任意投注你认为会赢的选项参与，每个选项参与金额参与上限为5000；
      可同时选择多个进行参与，最终将根据主播公布的结果统一结算。
    </p>
    <div class="row" style="width: 100%; margin-top: 20px;">
      <img style="width: 355px; height: 181px;" src="@/assets/images/guessing/contribution_img_1.png">
    </div>
    <div class="title-wrapper" style="margin-top: 27px;">
      <img style="width: 150px; height: 28px;" src="@/assets/images/guessing/title_bg_1.png">
      <div class="title-content">
        <span class="title">倍数及派奖规则</span>
      </div>
    </div>
    <p class="text-content" style="margin-top: 20px;">
      <span>1、倍数根据双方参与的数额实时计算；</span><br>
      <span>2、选择你预测获胜的选项，点击选项，在弹出的参与面板中选择合适的数额参与；</span><br>
      <span>3、如果获胜，最终返还数额=参与数额*倍数；</span><br>
      <span>4、如果参与的主题只有其中1个结果有用户参与，主播公布结果后，此局不计输赢，将返还参与金额。</span>
    </p>
    <div class="row" style="width: 100%; margin-top: 20px;">
      <img style="width: 355px; height: 271px;" src="@/assets/images/guessing/contribution_img_2.png">
    </div>
    <div class="title-wrapper" style="margin-top: 27px;">
      <img style="width: 108px; height: 28px;" src="@/assets/images/guessing/title_bg_2.png">
      <div class="title-content">
        <span class="title">举例说明</span>
      </div>
    </div>
    <p class="text-content" style="margin-top: 20px;">
      <span>参与【选项A】数额100，参与时的倍数为1.5，停止时倍数为2.0；</span><br>
      <span>如预言结果正确，将返还你195（包含本金，且扣除抽成）</span><br>
      <span> 如预言结果错误，将扣除你100本金；</span><br>
      <br>
      <span>主播全部结束预言后，将在直播间内弹窗显示预言结果，以及你的收益或亏损；</span><br>
      <span>收益将会充入你的账户中，请耐心等待5-10分钟；如未到账，请及时联系客服；</span><br>
      <span> 您也可以进入预言记录页面查看详细参与记录。</span><br>
    </p>
    <div class="row" style="width: 100%; margin-top: 20px;">
      <img style="width: 355px; height: 256px;" src="@/assets/images/guessing/contribution_img_3.png">
    </div>
    <div class="title-wrapper" style="margin-top: 27px;">
      <img style="width: 178px; height: 28px;" src="@/assets/images/guessing/title_bg_3.png">
      <div class="title-content">
        <span class="title">异常问题及申诉反馈</span>
      </div>
    </div>
    <div style="position: relative; margin-top: 20px;">
      <div style="position:absolute; width: 4px; height: 16px; background-color: #4A90E2; top: 0px; left: 9px;" />
      <span class="text" style="padding-left: 20px;">1.主播结果公布错误怎么办？</span><br>
    </div>
    <p class="text-content">
      <span> 预言结果由主播个人提交，官方已提醒主播谨慎提交，若主播仍提交错误，请及时联系主播协商处理，若主播不受理，请联系客服，提供主播房间号及协商截图，官方核实后，将视情节对主播进行处罚；</span><br>
    </p>
    <div style="position: relative; margin-top: 20px;">
      <div style="position:absolute; width: 4px; height: 16px; background-color: #4A90E2; top: 0px; left: 9px;" />
      <span class="text" style="padding-left: 20px;"> 2.奖励未到账怎么办？ </span><br>
    </div>
    <p class="text-content">
      <span>预言结束后，打开我的预言记录页面，查看参与记录，确认是否赢得了预言。余额如果未及时更新，你可以等待5-10分钟，或重新进入记录页面查看；如果确实未到账，请联系客服人员处理问题，核实后会返还你的奖励；</span><br>
    </p>
    <div style="position: relative; margin-top: 20px;">
      <div style="position:absolute; width: 4px; height: 16px; background-color: #4A90E2; top: 0px; left: 9px;" />
      <span class="text" style="padding-left: 20px;">3.什么情况下回流局处理？ </span><br>
    </div>
    <p class="text-content" style="padding-bottom: 20px;">
      <span>主播在直播中，遇到特殊情况，如掉线、停电、忘记结算等无法公正功的公布预言结果时，将按照流局处理本局，流局后参与的金额将会返还给你。</span><br>
    </p>
  </div>
</template>

<script>

import { Row, Button, Divider } from 'vant'

export default {
  name: 'GuessingContribution',
  components: { [Button.name]: Button,
    [Row.name]: Row,
    [Divider.name]: Divider
  },
  data() {
    return {
    }
  },
  created() {
    this.setTitle()
  },
  methods: {
    setTitle() {
      document.title = '预言玩法介绍'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.main-container {
  width: 100%;
  font-size: 14px;
  @include color_main_bg();
}
.text {
  font-size:12px;
  font-family:PingFangSC-Regular,PingFang SC;
  font-weight:400;
  line-height:16px;
  @include color_main_font();
}
.text-content {
  width:100%;
  padding: 0px 20px;
  font-size:12px;
  font-family:PingFangSC-Regular,PingFang SC;
  font-weight:400;
  line-height:22px;
  @include color_main_font();
}
.title-wrapper {
  position: relative;
  height: 28px;
  .title-content {
    position: absolute;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 20px;
    .title {
      line-height: 28px;
      color: #ffffff;
    }
  }
}
</style>
