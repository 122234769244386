<template>
  <div class="app-container" :data-theme="$store.getters.themeType">
    <div class="main-content">
      <span class="main-theme-font-color">{{ $t('title_options') }}</span>
      <van-row style="margin-top: 30px">
        <van-field
          v-model="topic"
          :style="{ backgroundColor: $theme.getMainBackgroundColor() }"
          class="textarea"
          rows="1"
          autosize
          type="textarea"
          maxlength="15"
          :placeholder="$t('hint11')"
        />
      </van-row>
      <van-row
        type="flex"
        justify="space-between"
        align="center"
        style="height: 100px; margin-top: 10px"
      >
        <van-col span="10">
          <van-field
            v-model="optionA"
            :style="{ backgroundColor: $theme.getMainBackgroundColor() }"
            class="textarea"
            rows="1"
            autosize
            type="textarea"
            maxlength="5"
            :placeholder="$t('hint12')"
          />
        </van-col>
        <van-col span="4" style="text-align: center">
          <img
            style="
              width: 22px;
              height: 25px;
              margin-left: 10px;
              margin-right: 10px;
            "
            :src="$theme.getVsImage()"
          />
        </van-col>
        <van-col span="10">
          <van-field
            v-model="optionB"
            :style="{ backgroundColor: $theme.getMainBackgroundColor() }"
            class="textarea"
            rows="1"
            autosize
            type="textarea"
            maxlength="5"
            :placeholder="$t('hint13')"
          />
        </van-col>
      </van-row>
      <van-row style="margin-top: 49px">
        <span class="main-theme-font-color">{{ $t('stop_time') }}</span>
        <span style="color: rgba(155, 155, 155, 1)">
          {{ $t('hint14') }}
        </span>
      </van-row>
      <cube-checker
        v-model="duration"
        :options="options"
        type="radio"
        style="font-size: 16px; margin-top: 30px"
      />
    </div>
    <div class="bottom">
      <div class="content">
        <van-button
          :disabled="
            topic === '' || optionA === '' || optionB === '' || !duration
          "
          class="save-button"
          @click="isEdit ? edit() : add()"
        >
          {{ $t('save1') }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Row, Col, Button, Divider, Toast, Field } from 'vant'
import { addGuessing, topicDetail, editTopic } from '@/api/guessing/index'

export default {
  name: 'GuessingDetail',
  components: {
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [Field.name]: Field,
    [Toast.name]: Toast
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: undefined,
      topic: '',
      duration: undefined,
      optionA: '',
      optionB: '',
      options: [{ value: 5, text: this.$t('5minute') }, { value: 10, text: this.$t('10minute') }, { value: 30, text: this.$t('30minute') }, { value: 40, text: this.$t('40minute') }, { value: 110, text: this.$t('110minute') }]
    }
  },
  created() {
    if (this.isEdit) {
      this.setTitle(this.$t('editing_subject'))
      this.id = this.$route.query.id
      this.getTopicDetail(this.id)
    } else {
      this.setTitle(this.$t('add_subject'))
      this.duration = 5
    }
  },
  methods: {
    setTitle(title) {
      document.title = title
    },
    add() {
      const params = {
        topic: this.topic,
        duration: this.duration,
        option: [this.optionA, this.optionB]
      }
      addGuessing(params).then(response => {
        this.$emit('change', true)
        this.$router.go(-1)
      })
    },
    edit() {
      const params = {
        id: this.id,
        topic: this.topic,
        duration: this.duration,
        option: [this.optionA, this.optionB]
      }
      editTopic(params).then(response => {
        this.$emit('change', true)
        this.$router.go(-1)
      })
    },
    getTopicDetail(id) {
      topicDetail({ id }).then(response => {
        this.topic = response.data.topic
        this.duration = response.data.duration
        this.optionA = response.data.forwardName
        this.optionB = response.data.backName
      })
    },
    checkInput() {
      if (this.topic !== '' || this.optionA !== '' || this.optionB !== '') {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/color.scss';
.main-content {
  width: 100%;
  max-width: 100%;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  color: #fff;
  .textarea {
    background-color: #29292d;
    color: #fff;
    border-bottom: 1px solid #25292e;
  }
}
.bottom {
  position: fixed;
  width: 100%;
  height: 82px;
  color: #fff;
  bottom: 35px;
  @include color_main_bg();
  .content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .save-button {
      width: 283px;
      height: 40px;
      background-color: #4a8fe2 !important;
      border-radius: 6px;
      border: 1px solid #4a8fe2 !important;
      color: #fff !important;
      font-size: 12px;
    }
  }
}
/deep/ .cube-checker-item {
  @include color_main_bg();
  @include color_main_font();
  margin-bottom: 30px;
  width: 100px;
}
/deep/.cube-checker-item::after {
  border-width: 2px;
  border-color: #373941;
}
/deep/.cube-checker-item_active::after {
  border-width: 2px;
  border-color: #1989fa !important;
}
/deep/.van-field__control {
  @include color_main_font();
}
/deep/.van-cell {
  padding: 0px 0px 10px 0px;
}
</style>
