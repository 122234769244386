export default {
  loading: "กำลังโหลด...",
  searchplaceholder: "ผู้ประกาศช่องเกม",
  dowload: "ดาวน์โหลด",
  home: "บ้าน",
  live: "สด",
  match: "จับคู่",
  plan: "เคล็ดลับ",
  mine: "ของฉัน",
  hotgame: "การแข่งขันยอดนิยม",
  news: "ข้อมูล",
  mall: "ห้างสรรพสินค้า",
  video: "วิดีโอสั้น ๆ",
  activity: "กิจกรรม",
  hotlive: "ถ่ายทอดสดสุดฮอต",
  hotanchor: "ถ่ายทอดสดยอดนิยม",
  highlights: "ไฮไลท์",
  more: "มากกว่า",
  noliving: "ขณะนี้ไม่มีสมออยู่บนอากาศ",
  nolivetips: "สมอยังอุ่นเครื่องอยู่ กรุณารอสักครู่...",
  chat: "แชท",
  speak: "ป้อนเนื้อหาแชท",
  nospeak: "คุณสามารถพูดได้หลังจากเข้าสู่ระบบเท่านั้น~",
  gologin: "เข้าสู่ระบบ",
  seenewnews: "ข่าวใหม่",
  unlockvip: "ปลดล็อค VIP ฟรี",
  announcement: "ประกาศ:",
  welcome: "ยินดีต้อนรับ",
  enterroom: "เข้าสู่ห้องถ่ายทอดสด",
  followed: "ติดตามแล้ว",
  newsinfo: "ข้อมูล",
  experts: "ผู้เชี่ยวชาญที่โดดเด่น",
  hit: "ตี",
  return: "กลับ",
  wins: "ชนะ",
  fb: "ฟุตบอล",
  bk: "บาสเกตบอล",
  notstarted: "ไม่ได้เริ่ม",
  checkmore: "ดูรายละเอียด",
  videolist: "ไฮไลท์/วิดีโอ",
  qianzan: "ซึ่งไปข้างหน้า",
  jinhua: "แก่นแท้",
  yulei: "ความบันเทิง",
  recommendplan: "แผนแนะนำ",
  buyedplan: 'แผนการซื้อ',
  winrate: "อัตราการเข้าชม",
  zuigaolianghong: "สีแดงสูงสุดติดต่อกัน",
  lianghong: "เหลียนหง",
  returnrate: "อัตราผลตอบแทน",
  buzhong: "คืนเงินถ้าคุณไม่ชนะ",
  xieyi: "ข้อตกลงที่เกี่ยวข้อง",
  personal: "ข้อตกลงความเป็นส่วนตัว",
  userxieyi: "ข้อตกลงการใช้",
  livexieyi: "ข้อตกลงการถ่ายทอดสด",
  chargexieyi: "ข้อตกลงการเติมเงิน",
  anchorhelp: "ความช่วยเหลือจากแองเคอร์",
  anchornew: "สมอใหม่อยู่บนท้องถนน",
  followus: "ตามเรามา",
  helpcenter: "ศูนย์ช่วยเหลือ",
  scancodedowload: "สแกนรหัสเพื่อดาวน์โหลดแอป Bobcat",
  smzb: "แมวสด",
  noduty:
    "ข้อจำกัดความรับผิดชอบ: การถ่ายทอดสดฟุตบอลและสัญญาณถ่ายทอดสด NBA ของ Bobcat Live ทั้งหมดถูกรวบรวมโดยผู้ใช้หรือได้รับจากเครื่องมือค้นหา เนื้อหาทั้งหมดมาจากอินเทอร์เน็ต เราไม่ได้ให้สัญญาณถ่ายทอดสดและเนื้อหาวิดีโอด้วยตนเอง กรุณาแจ้งให้เราทราบหากมีการละเมิดสิทธิของคุณ เราจะจัดการกับมันโดยเร็วที่สุด",
  roomsearch: "ป้อนคำหลัก",
  myfollow: "การติดตามของฉัน",
  clearcache: "ล้างแคช",
  kinds: "การจัดหมวดหมู่",
  all: "ทั้งหมด",
  others: "อื่น",
  yingchao: "พรีเมียร์ลีก",
  hot: "ร้อน",
  esports: "อีสปอร์ต",
  exit: "ออก",
  downloadApp: "ดาวน์โหลดแอพ",
  want: "ไปถ่ายทอดสด",
  roomnum: "หมายเลขห้อง",
  wang: "วาน",
  feedback: "ข้อเสนอแนะ",
  funs: "แฟน ๆ",
  follows: "ติดตาม",
  numberonlive: "ผู้ประกาศข่าวกำลังถ่ายทอดกิจกรรมนี้อยู่",
  anchorchat: "สมอแชท",
  contributerank: "รายการบริจาค",
  othermatch: "นัดอื่นๆ",
  chatinput: "พูดคุยและลุ้นไปด้วยกัน",
  send: "ส่ง",
  livefail: "ไม่สามารถรับสตรีมสดได้",
  tryagain: "คลิกเพื่อลองอีกครั้ง",
  pushlist: "รายการสั่งซื้อแบบพุช",
  reward: "คำอธิบายรางวัล",
  nopush: "ยังไม่มีการกดสั่งเลย.",
  anchorpush: "แองเคอร์ดันออกคำสั่ง",
  maobipokect: "ซองแดงเหรียญแมว",
  sendsuccess:
    "การส่งที่ประสบความสำเร็จสามารถกระตุ้นการออกอากาศหน้าจอแบบลอยบนทุกแพลตฟอร์ม",
  manexchange: "ประชาชนสามารถรับได้",
  threeminexhang: "สามารถเก็บได้ภายใน 3 นาที",
  exchangenow: "สามารถสะสมได้ทันที",
  sendpokect: "ส่งซองแดง",
  pokecttips: "ฉันส่งซองแดงไปในห้องถ่ายทอดสดแล้ว~ คุณสามารถรับได้ทันที!",
  pokecttips1:
    "ฉันส่งซองจดหมายสีแดงไปที่ห้องถ่ายทอดสดแล้ว~ คุณสามารถรับได้ใน 3 นาที!",
  task: "งาน",
  litterhelper: "ผู้ช่วยตัวน้อย",
  litterhelper1: "ผู้ช่วยส่วนลด",
  enter: "คลิกเพื่อเข้า",
  gift: "ของขวัญ",
  high: "เอชดี",
  charge: "เติมเงิน",
  balance: "สมดุล",
  words: "ปัง",
  sendonepokect: "ส่งซองแดงแล้ว",
  exchange: "รับ",
  ofpokect: "ซองจดหมายสีแดง",
  nopokect: "ช้าไป แจกอั่งเปาแล้ว",
  maobi: "เหรียญแมว",
  savemyaccount: "ได้ถูกฝากเข้าบัญชีของฉันแล้ว",
  checkexchange: "ดูรายละเอียดการเรียกร้อง",
  exchangelist: "บันทึกการรวบรวมซองจดหมายสีแดง",
  pokecttips2: "ฉันคว้าเหรียญแมว x เหรียญในซองจดหมายสีแดงเหรียญแมวของฉัน!",
  contribute: "การบริจาครายวัน",
  contribute1: "การบริจาครายสัปดาห์",
  contribute2: "รายการบริจาค",
  contributevalue: "มูลค่าการบริจาค",
  living: "ออกอากาศสด",
  matchstatus: "สถานะการแข่งขัน",
  zhishu: "ดัชนี",
  livematchstatus: "สถานะการแข่งขันแบบเรียลไทม์",
  jinqiu: "เป้าหมาย",
  zugong: "ช่วยเหลือ",
  shemeng: "ยิง",
  dianqiu: "การเตะลูกโทษ",
  wulongqiu: "เป้าหมายของเรา",
  huangpai: "ใบเหลือง",
  nodianqiu: "เตะจุดโทษไม่ได้ประตู",
  huanxia: "แทนที่",
  nomatchevent: "ยังไม่มีการแข่งขัน",
  onlyjinqiu: "ดูแต่เป้าหมายเท่านั้น",
  analysis: "การวิเคราะห์ทางเทคนิค",
  contrrate: "อัตราการครอบครอง",
  shezheng: "ยิงเข้าเป้า",
  jingong: "จู่โจม",
  dendrgjingong: "การโจมตีที่เป็นอันตราย",
  renyiqiu: "ฟรีคิก",
  jiaoqiu: "เตะมุม",
  hongpai: "ใบแดง",
  login: "เข้าสู่ระบบ",
  roommannage: "แต่งตั้งผู้จัดการห้อง",
  roomtips: "คุณแน่ใจหรือว่าจะแต่งตั้งฟิฟทีนเป็นผู้จัดการห้อง?",
  roomtips1: "คุณแน่ใจหรือว่า Fifteen จะถูกไล่ออกจากตำแหน่งผู้จัดการห้อง?",
  cancel: "ยกเลิก",
  confirm: "ตกลง",
  logout: "ออกจากระบบ",
  uplevel: "การอัพเกรดระยะทาง: มูลค่าประสบการณ์ {msg}",
  wallt: "กระเป๋าสตางค์",
  wantlive: "ฉันต้องการถ่ายทอดสด",
  usercenter: "ศูนย์ส่วนบุคคล",
  vipright: "แนะนำสิทธิพิเศษ VIP",
  pwdlogin: "รหัสผ่านเข้าสู่ระบบ",
  mobilelogin: "เข้าสู่ระบบมือถือ",
  doregister1: "ไม่มีบัญชี",
  doregister2: "สมัครตอนนี้",
  forgetpwd: "ลืมรหัสผ่านหรือไม่?",
  logintips: "เข้าสู่ระบบหมายความว่าคุณเห็นด้วย",
  inputmobile: "ป้อนหมายเลขโทรศัพท์มือถือ",
  inputcode: "ป้อนรหัสยืนยัน",
  mobiletips: "ป้อนรหัสผ่าน 6-20 หลักหรือรหัสผ่านตัวอักษร",
  resetpwd: "รีเซ็ตรหัสผ่าน",
  register: "ลงทะเบียน",
  sendcode: "รับรหัส",
  registernow: "สมัครตอนนี้",
  appdownload: "ดาวน์โหลดแอพ",
  advice: "ข้อเสนอแนะ",
  backtop: "กลับด้านบน",
  intime: "ทันที",
  anchor: "สมอ",
  matchschude: "กำหนดการ",
  matchresult: "ผลการแข่งขัน",
  checkdata: "ดูข้อมูล",
  nomatch: "ยังไม่มีการแข่งขัน",
  winlist: "รายการยอดฮิต",
  returnlist: "รายการส่งคืน",
  lianghongrank: "รายการแดงอย่างต่อเนื่อง",
  hotrace: "การแข่งขันที่ร้อนแรง",
  allrace: "ทุกเชื้อชาติ",
  onrace: "ในการแข่งขัน",
  onsale: "แผนการขาย",
  recommendmaster: "ผู้เชี่ยวชาญที่แนะนำ",
  mime: "ฉัน",
  boughtplan: "แผนการซื้อ",
  sorttype: "การเรียงลำดับที่ครอบคลุม",
  returnfirst: "กลับมาก่อน",
  winfirst: "อัตราการชนะก่อน",
  lianghongfirst: "เหลียนหงก่อน",
  nodata: "ยังไม่มีข้อมูล",
  detail: "รายละเอียด",
  newslocal: "ตำแหน่งปัจจุบัน: หน้าแรก/ข่าว",
  comments: "ความคิดเห็น",
  nocomments: "ยังไม่มีความคิดเห้น",
  docomments: "ความคิดเห็น",
  typecomments: "พูดอะไรสักอย่าง",
  goods: "สินค้า",
  searchgoods: "ค้นหาผลิตภัณฑ์ที่คุณชอบ",
  mycarts: "ตะกร้าสินค้าของฉัน",
  myorders: "คำสั่งของฉัน",
  demens: "เพชร",
  goodsinfo: "ข้อมูลสินค้า",
  kucun: "รายการสิ่งของ",
  limit: "จำกัดการแลกเปลี่ยน",
  value: "ราคาต่อหน่วย",
  num: "ปริมาณ",
  total: "ผลรวมย่อย",
  choice: "เลือกแล้ว",
  jiesuan: "การตั้งถิ่นฐาน",
  chooseall: "เลือกทั้งหมด",
  limitnum: "วงเงินการซื้อสะสม",
  limitday: "จำกัดการซื้อรายวัน",
  limitweek: "จำกัดการซื้อรายสัปดาห์",
  totallimt: "การไถ่ถอนทั้งหมด:",
  totallimt1: "การไถ่ถอนสะสมรายวัน:",
  totallimt2: "การแลกรางวัลรายสัปดาห์ทั้งหมด:",
  jiage: "ราคา",
  goodsdetail: "รายละเอียดสินค้า",
  buynow: "แลกเลย",
  addcart: "เพิ่มลงในรถเข็น",
  recommendgoods: "แนะนำสำหรับคุณ",
  confirmbuy: "ยืนยันการไถ่ถอน",
  mobile: "เบอร์โทรศัพท์",
  recipename: "ชื่อผู้รับสินค้า",
  address: "ที่อยู่สำหรับการจัดส่ง",
  remark: "ข้อสังเกต",
  inputreciptename: "ป้อนชื่อของผู้รับตราส่ง",
  inputaddress: "กรอกที่อยู่ในการจัดส่ง",
  orders: "หมายเลขคำสั่งซื้อ",
  time: "เวลา",
  recipe: "ผู้รับตราส่ง",
  telphone: "โทรศัพท์",
  address1: "ที่อยู่",
  recovideo: "วิดีโอแนะนำ",
  onplay: "เล่นได้ปกติ...",
  ongoing: "กำลังดำเนินการ",
  result: "สิ้นสุดแล้ว",
  reserve: "การจอง",
  reserved: "ที่สงวนไว้",
  myinfo: "ข้อมูลของฉัน",
  anchorlevel: "ระดับจุดยึด",
  userlevel: "ระดับผู้ใช้",
  changeavter: "เปลี่ยนอวตาร",
  changeinfo: "ปรับเปลี่ยนข้อมูล",
  usertips: "(ขนาดภาพต้องไม่เกิน 1M รองรับรูปแบบ PNG, JPG)",
  uploadimg: "อัปโหลดรูปภาพท้องถิ่น",
  myaccount: "บัญชีของฉัน",
  anchorinfo: "เกี่ยวข้องกับสมอ",
  tasks: "ศูนย์งาน",
  signin: "เข้าสู่ระบบด้วยความสุภาพ",
  mywords: "คำทำนายของฉัน",
  downloadreword: "ดาวน์โหลดรางวัลคำเชิญ",
  nickname: "ชื่อเล่น",
  inputnickname: "ใส่ชื่อเล่นของคุณ",
  infotips:
    "คำอธิบาย:\n 1. ชื่อเล่นใหม่จะต้องเป็นไปตามข้อกำหนดการลงทะเบียนและสามารถใช้ตัวเลข ตัวอักษร ตัวอักษรจีน และสัญลักษณ์ได้ ความยาวสูงสุดไม่ควรเกิน 24 ตัวอักษร (2 ตัวอักษรสำหรับภาษาจีนและ 1 ตัวอักษรสำหรับภาษาอังกฤษ)\n 2. หากการแก้ไขชื่อเล่นล้มเหลวเนื่องจากการอัพเดตเซิร์ฟเวอร์หรือปัญหาอื่น ๆ โปรดติดต่อฝ่ายบริการลูกค้า",
  introduce: "รายละเอียดส่วนบุคคล",
  region: "ภูมิภาค",
  selectprovince: "กรุณาเลือกจังหวัด",
  selectregion: "กรุณาเลือกเมือง",
  sex: "เพศ",
  savesecrt: "เป็นความลับ",
  man: "ชาย",
  woman: "หญิง",
  bedate: "วันเกิด",
  selectdate: "กรุณาเลือกปี",
  selectmonth: "กรุณาเลือกเดือน",
  select: "โปรดเลือก",
  year: "ปี",
  month: "เดือน",
  day: "วัน",
  save: "บันทึก",
  accountvalue: "ยอดเงินในบัญชี",
  billlist: "บันทึกการเรียกเก็บเงิน",
  cast: "การบริโภค",
  earnings: "รายได้",
  casttype: "ประเภทการบริโภค",
  sendgift: "ของขวัญ",
  prophecy: "คำทำนาย",
  redpokect: "ซองจดหมายสีแดง",
  exchangegoods: "แลกของขวัญ",
  luckydraw: "จ่ายงวด",
  querytime: "เวลาสอบถาม",
  starttime: "วันที่เริ่มต้น",
  endtime: "วันที่สิ้นสุด",
  query: "แบบสอบถาม",
  reset: "รีเซ็ต",
  castmaobi: "ปริมาณการใช้ (เหรียญแมว)",
  allprophecy: "คำทำนายทั้งหมด",
  prophecylist: "ห้องพยากรณ์",
  planlist: "เคล็ดลับในการแก้ปัญหา",
  yuyuematch: "นัดจองครับ",
  earnlist: "บันทึกรายได้",
  liveset: "การตั้งค่าการถ่ายทอดสด",
  roompush: "คำสั่งกดห้องถ่ายทอดสด",
  roomset: "การตั้งค่าจดหมายห้องถ่ายทอดสด",
  edit: "แก้ไข",
  beAnchor: "มาเป็นสมอเรือ",
  inviterUser: "เชิญเพื่อน ๆ",
  setting: "การตั้งค่า",
  logining: "กำลังเข้าสู่ระบบ...",
  loginerr: "การเข้าสู่ระบบล้มเหลว โปรดลองอีกครั้งในภายหลัง...",
  loginsecucced: "เข้าสู่ระบบสำเร็จ",
  codesend: "ส่งรหัสยืนยันเรียบร้อยแล้ว",
  inputrightphone: "กรอกหมายเลขโทรศัพท์มือถือที่ถูกต้อง",
  inputagain: "ป้อนรหัสผ่านของคุณอีกครั้ง",
  pwdtips: "รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน",
  pwdtips1:
    "รูปแบบรหัสผ่านไม่ถูกต้อง โปรดป้อนรหัสผ่าน 6-20 หลักหรือรหัสผ่านตัวอักษร",
  registed: "การลงทะเบียนสำเร็จ",
  registerfail: "การลงทะเบียนล้มเหลว โปรดลองอีกครั้งในภายหลัง...",
  registing: "กำลังลงทะเบียน...",
  findpwd: "ดึงรหัสผ่าน",
  submit: "ส่ง",
  settingPwd: "ตั้งรหัสผ่านเรียบร้อยแล้ว",
  submitting: "กำลังส่ง...",
  "nomore": 'ไม่มีอีกแล้ว',
  "pullrefresh": 'ดึงลงเพื่อรีเฟรช',
  "notstart": 'เกมยังไม่เริ่ม คุณสามารถดูเกมอื่นได้:',
  newsDe: 'รายละเอียดข้อมูล',
  shouqi: 'เอาออกไป',
  fabu: 'ปล่อย',
  'eur': 'ดิสก์ยุโรป',
  "hk": 'ฮ่องกงดิสก์',
  "bm": 'สูง/ต่ำ',
  'rq': 'แฮนดิแคป',
  'eur': 'ดิสก์ยุโรป',
  "hk": 'ฮ่องกงดิสก์',
  "bm": 'สูง/ต่ำ',
  'rq': 'แฮนดิแคป',
  'mzl': 'อัตราการเข้าชม',
  'mago': 'นาทีที่แล้ว',
  'sago': 'วินาทีที่แล้ว',
  'hago': 'ชั่วโมงที่ผ่านมา',
  'mianfei': 'ฟรี',
  'tacticde': 'รายละเอียดแผน',
  'zhusheng': 'ชนะ',
  "pingju": 'วาด',
  "kesheng": 'สูญเสีย',
  'pay': 'จ่ายตอนนี้',
  'stop': 'ขายแล้วหยุด',
  'reson': 'เหตุผลที่แนะนำ',
  'afterpay': 'ดูแผนและการวิเคราะห์หลังการชำระเงิน',
  'stoptime': 'กำหนดเวลาการขาย:',
  'tacticdetips': 'หมายเหตุ: ความคิดเห็นมีไว้เพื่อการอ้างอิงเท่านั้น และเราไม่ได้ให้บริการธุรกิจลอตเตอรี โปรดใช้ความระมัดระวังด้วยความระมัดระวัง! ',
  'dago': 'วันก่อน',
  'wago': 'สัปดาห์ที่แล้ว',
  'mouthago': 'เดือนที่แล้ว',
  'ganggang': 'เมื่อกี้นี้',
  'bigqiu': 'สูง{msg}balls',
  'smallqiu': 'ต่ำ{msg}balls',
  'buyplan': 'ซื้อแผนผู้เชี่ยวชาญ',
  'buyplantips': 'การซื้อแผนนี้ต้องใช้เหรียญแมว {num} เหรียญ คุณแน่ใจหรือว่าเพียงพอแล้ว? ',
  'yesbuy': 'ยืนยันการซื้อ',
  'cancelfollow': 'ยกเลิกการติดตามเรียบร้อยแล้ว',
  'followsucceed': 'ติดตามความสำเร็จ',
  'buysucceed': 'การซื้อสำเร็จ',
  'buyfialed': 'การซื้อล้มเหลว',
  'liangsai': 'ลีก',
  'team': 'เจ้าบ้าน vs ทีมเยือน',
  'playtype': 'เล่น',
  'rate': 'อัตราต่อรอง',
  'chongzhijilu': 'บันทึกการเติมเงิน',
  'xiaofeijilu': 'บันทึกการบริโภค',
  'huodongshouyi': 'รายได้จากกิจกรรม',
  'myinvitecode': 'รหัสเชิญของฉัน',
  'inputcodeinapp': 'กรุณาระบุเมื่อเข้าสู่ระบบแอป',
  'dianjifuzhi': 'คลิกเพื่อคัดลอกรหัสเชิญ',
  'jietu': 'ส่งภาพหน้าจอให้เพื่อนของคุณ',
  'shokuang': 'วิธีการชำระเงิน',
  'alipay': 'อาลีเพย์',
  'wechat': 'วีแชท',
  'shokuangzhanghao': 'หมายเลขบัญชีการชำระเงิน',
  'invitegonglue': 'กลยุทธ์การเชิญ',
  'shareqrcode': 'แชร์ดาวน์โหลดโค้ด QR และโค้ดเชิญพิเศษ',
  'getjiangli': 'คุณจะได้รับรางวัลหากเพื่อนของคุณเข้าสู่ระบบสำเร็จเป็นครั้งแรก',
  'invitemingxi': 'รายละเอียดคำเชิญ',
  'zhanwuhaoyou': 'ยังไม่มีการเชิญเพื่อน ไปเชิญเพื่อนได้เลย! ',
  'nicheng': 'ชื่อเล่น',
  'zucengshijian': 'เวลาลงทะเบียน',
  'jiangli': 'รางวัล',
  'islogout': 'คุณแน่ใจหรือไม่ว่าต้องการออกจากระบบ? ',
  'dijilun': 'รอบที่ {num}',
  'guangyuus': 'เกี่ยวกับเรา',
  'zhuanjiajieshao': 'การแนะนำโดยผู้เชี่ยวชาญ',
  'recent10': 'อัตราการกลับมาใน 10 เกมหลังสุด',
  'zuijinzhuantai': 'สถานะล่าสุด',
  'zhengzaituijian': 'แนะนำ',
  'historyrem': 'คำแนะนำเกี่ยวกับประวัติศาสตร์',
  'bisaishijian': 'กิจกรรมการแข่งขัน',
  'qiuduitongji': 'สถิติทีม',
  'qiudui': 'ทีม',
  'zongfen': 'คะแนนรวม',
  'bisaikaishi': 'เกมเริ่มต้นขึ้น',
  'fangui': 'เหม็น',
  'erfen': 'สองจุด',
  'faqiu': 'โยนโทษ',
  'sanfen': 'สามแต้ม',
  'paizhao': 'ถ่ายรูป',
  'xuanzezhaopian': 'เลือกจากอัลบั้ม',
  'jianyanzhi': 'คุณค่าของประสบการณ์',
  'yiwangcheng': 'เสร็จสมบูรณ์',
  'dangqian': 'ปัจจุบัน',
  'shengjidao': 'อัปเกรดเป็น',
  'xuyao': 'ต้องการ',
  'cehngzhang': 'สิทธิพิเศษในการเติบโต',
  'dengji': 'ไอคอนระดับ',
  'kaiqi': 'เปิด',
  'qidai': 'คอยติดตาม',
  'mashanglai': 'สิทธิพิเศษเพิ่มเติมกำลังจะมาเร็วๆ นี้',
  'qiandao': 'เช็คอินทุกวัน',
  'qiandaochenggong': 'ลงชื่อเข้าใช้เรียบร้อยแล้ว',
  'qiandaotips': 'คำเตือนที่อบอุ่น: โปรดติดต่อผู้ช่วยเพื่อสมัครโบนัสและรางวัลทางกายภาพ',
  'chakanqiandao': 'ดูรางวัล',
  'goxiaozhushou': 'ติดต่อผู้ช่วย',
  'dijitian': '{msg}วัน',
  'zhubodasan': 'รายการรางวัลสมอเรือ',
  'riban': 'รายการรายวัน',
  'yueban': 'รายการรายเดือน',
  'zongban': 'รายการทั่วไป',
  'gongxianzhi': 'มูลค่าการบริจาค',
  'fananjieshu': 'การขายแผนสิ้นสุดลงแล้ว',
  'bisaijieshu': 'รายละเอียดการวิเคราะห์จะประกาศหลังการแข่งขัน',
  'yuebuzu': 'ยอดคงเหลือไม่เพียงพอ',
  'qitajinnang': 'เคล็ดลับอื่นๆ ของผู้เชี่ยวชาญ',
  'qitatuijian': 'คำแนะนำอื่นๆ',
  'dengluhou': 'คุณสามารถดูเฉพาะบันทึกที่ซื้อได้ในภายหลังเท่านั้น',
  'zhengzaibofng': 'กำลังเล่นอยู่',
  'jijiangbofang': 'กำลังมาเล่น',
  'touxiangshangchuan': 'อัปโหลดอวตาร',
  'shouyeleixing': 'ประเภทรายได้',
  'lingquhongbao': 'รับอั่งเปา',
  'hongbaotuikuang': 'การส่งคืนซองจดหมายสีแดง',
  'jinnantutikuang': 'การคืนเงินทิป',
  'yuyanshouyi': 'คำทำนายกำไร',
  'yuyantuikuang': 'การคืนเงินคำทำนาย',
  'hongbaoyu': 'hongbaoyu',
  'jincaidati': 'เดาและตอบ',
  'zhuanpan': 'ลอตเตอรีรูเล็ต',
  'jindan': 'ทุบไข่ทองคำ',
  'zenfa': 'การออกระบบเพิ่มเติม',
  'dengluhuodong': 'กิจกรรมการเข้าสู่ระบบ',
  'guankan': 'ชมการถ่ายทอดสด',
  'danmu': 'ส่งเขื่อนกั้นน้ำ',
  'denglujianli': 'รางวัลการเข้าสู่ระบบแอป',
  'tuidan': 'รางวัลการกดสั่งซื้อ',
  'zhuanpanchoujian': 'ลอตเตอรีแบบหมุนใหม่',
  'zhuti': 'ธีม',
  'xuanxian': 'ตัวเลือก',
  'kejitouzhu': 'การเดิมพันสะสม',
  'xuanxianbeishu': 'หลายตัวเลือก',
  'touzhurenshu': 'จำนวนผู้เดิมพัน',
  'jieguo': 'ผลลัพธ์',
  'zhuantai': 'สถานะ',
  'daojishi': 'นับถอยหลัง',
  'fabuplantips': 'รับเงินจำนวนมหาศาลหลังจากเผยแพร่เคล็ดลับการแก้ลูกบอล~',
  'goumairenshu': 'จำนวนผู้ซื้อ',
  'zongji': 'ทั้งหมด',
  'fencheng': 'ก่อนที่จะแบ่งออกเป็น',
  'liveshouyi': 'ถ่ายทอดสดรายได้ที่สามารถชำระได้',
  'jinnanshouyi': 'เคล็ดลับสามารถชำระเป็นรายได้',
  'jiesuanrules': 'กฎการตั้งถิ่นฐาน',
  'tequan': 'สิทธิ์ระดับ',
  'kuaisushengji': 'วิธีอัปเกรดอย่างรวดเร็ว',
  'wodedengji': 'ระดับของฉัน',
  'dengjishuoming': 'คำอธิบายระดับ',
  'zhubotequan': 'สิทธิ์ยึดเหนี่ยว',
  'shejizhong': 'กำลังออกแบบสิทธิพิเศษของ Anchor',
  'moretequan': 'สิทธิพิเศษเพิ่มเติมกำลังจะมาเร็วๆ นี้',
  'huodeliwu': 'รับของขวัญ',
  'qingfenzhibo': 'ถ่ายทอดสดอย่างขยันขันแข็ง',
  'zensongliwu': 'ให้ของขวัญ',
  'yonghutequan': 'สิทธิ์ของผู้ใช้',
  'tequansheji': 'สิทธิ์ของผู้ใช้อยู่ระหว่างการออกแบบ',
  'fengzhong': 'นาที',
  'jiyang': 'ประสบการณ์',
  'quedingquxiao': 'คุณแน่ใจหรือไม่ว่าต้องการเลิกติดตาม',
  'zhengquetianxie': 'กรุณากรอกข้อมูลให้ถูกต้อง ไม่เช่นนั้นคุณจะไม่ได้รับรางวัลใด ๆ',
  'qingshuruzhanghao': 'กรุณากรอกหมายเลขบัญชีของคุณ',
  'yaoqingshuoming': 'คำอธิบายคำเชิญ',
  'zaiciquanren': 'กรุณายืนยันบัญชี {msg} อีกครั้ง: {msg1}',
  'qingshuruzhanghao': 'กรุณากรอก {msg} หมายเลขบัญชี',
  'daishenhe': 'ได้รับการตรวจสอบ',
  'yipaijiang': 'มีการแจกรางวัลแล้ว',
  'weitongguo': 'ล้มเหลว',
  'leijizhong': 'กำลังสะสม',
  'tishi': 'คำใบ้',
  'tijiaochengong': 'ส่งสำเร็จ! ',
  'zonghe': 'ครอบคลุม',
  'yituichi': 'เลื่อนออกไป',
  'yiquxiao': 'ยกเลิก',
  'jitian': 'วันนี้',
  'mintian': 'พรุ่งนี้',
  'houtian': 'มะรืนนี้',
  'zuotian': 'เมื่อวาน',
  'xiazaitixing': 'การนัดหมายสำเร็จแล้ว ดาวน์โหลด APP เพื่อรับการแจ้งเตือนการนัดหมาย',
  'quxiaoyuyue': 'การจองถูกยกเลิกเรียบร้อยแล้ว',
  'zhoutian': 'วันอาทิตย์',
  'zhouyi': 'วันจันทร์',
  'zhouer': 'วันอังคาร',
  'zhousan': 'วันพุธ',
  'zhousi': 'วันพฤหัสบดี',
  'zhouwu': 'วันศุกร์',
  'zhouliu': 'วันเสาร์',
  'jianshu': 'ชิ้น',
  'meiri': 'รายวัน',
  'meizhou': 'รายสัปดาห์',
  'duihuanchenggong': 'แลกสำเร็จ',
  'zuiduoduihuan': 'การแลกสูงสุด {msg} ชิ้น',
  'caozuo': 'การดำเนินงาน',
  'zongjia': 'ราคารวม',
  'shanchu': 'ลบ',
  'now': 'เพียง',
  'yituikuan': 'คืนเงินแล้ว',
  'goumai': 'ซื้อ',
  'xuanzezhibo': 'เลือกช่องถ่ายทอดสด',
  'erjipingdao': 'ช่องระดับที่สอง',
  'zhibobiaoti': 'ชื่อการถ่ายทอดสด',
  'shezhibiaoti': 'กรุณาตั้งชื่อการถ่ายทอดสด',
  'guanliansaishi': 'เหตุการณ์ที่เกี่ยวข้อง',
  'xuanzhiguanlian': 'กรุณาเชื่อมโยงเหตุการณ์',
  'xuanze': 'เลือก',
  'yichu': 'ลบ',
  'zhibofenmian': 'หน้าปกถ่ายทอดสด',
  'yishenhe': 'ตรวจสอบแล้ว',
  'shenhezhong': 'อยู่ระหว่างการพิจารณา',
  'yijujue': 'ถูกปฏิเสธ',
  'zhibijieshu': 'สิ้นสุดการถ่ายทอดสด',
  'kaishizibo': 'เริ่มถ่ายทอดสด',
  'tuiliudizhi': 'กดที่อยู่ (ที่อยู่เซิร์ฟเวอร์)',
  'fuzhi': 'สำเนา',
  'tuiliumiyao': 'รหัสกด (คีย์สตรีมมิ่ง)',
  'laliudizhi': 'ลลิวดิจือ',
  'zhiboshuoming': 'คำอธิบายการถ่ายทอดสด',
  'zhibotips1': `
   1. ตั้งค่าข้อมูลพื้นฐาน: เลือกช่อง ตั้งชื่อเรื่อง กิจกรรมที่เกี่ยวข้อง ปกถ่ายทอดสด ตั้งค่าตามต้องการ<br />
   2. หลังจากคลิกปุ่ม "เริ่มถ่ายทอดสด" ให้คัดลอกที่อยู่พุชและโค้ดพุช<br />
   3. วางที่อยู่พุชและรหัสพุชไปที่ "เซิร์ฟเวอร์และคีย์สตรีมมิ่ง" ที่สอดคล้องกับซอฟต์แวร์ OBS และเริ่มการถ่ายทอดสด <br />`,
  'zhibotips2': `
   1. หลังจากคลิกเพื่อเริ่มการถ่ายทอดสดกรุณาคัดลอกและวางที่อยู่พุชและพุชโค้ดไปที่ OBS ภายใน 1 นาทีแล้วพุชสตรีม ไม่เช่นนั้นห้องถ่ายทอดสดอาจถูกดาวน์เกรดเป็นระบบที่ผิดปกติ<br />
   2. หลังจากสิ้นสุดการถ่ายทอดสดและปิด OBS คุณต้องคลิกปุ่มสิ้นสุดการถ่ายทอดสดด้านบนเพื่อดาวน์โหลดห้องถ่ายทอดสด <br />`,
  'yiyuyue': 'จองกิจกรรมแล้ว',
  'biotitips': 'ชื่อไม่สามารถเว้นว่างได้! ',
  'yuliang': 'ดูตัวอย่าง',
  'zhuyishixian': 'หมายเหตุ',
  'xinjian': 'คำสั่งพุชใหม่',
  'xuanzesaishi': 'เลือกกิจกรรม',
  'xuanzesaiduan': 'เลือกด่าน',
  'xuanzewf': 'เลือกวิธีการเล่น',
  'jieguotips': 'กรุณากรอกผลลัพธ์ไม่เกิน 25 คำ',
  'tuidanlishi': 'คำสั่งผลักดันทางประวัติศาสตร์',
  'renmai': '{msg}人 follow orders',
  'renfanmai': '{msg}ผู้คนซื้อต่อต้าน',
  'qingxuanzesaishi': 'โปรดเลือกกิจกรรม',
  'qingsaiduan': 'กรุณาเลือกเวที',
  'qingwf': 'โปรดเลือกวิธีการเล่น',
  'qingjieguo': 'กรุณากรอกผลลัพธ์',
  'tuidangtips': 'ไม่สามารถเปลี่ยนแปลงคำสั่งซื้อโปรโมชั่นนี้ได้หลังจากส่งแล้ว คุณยืนยันการส่งหรือไม่? ',
  'fabutuidan': 'เผยแพร่คำสั่งพุช',
  'chuangjian': 'สร้างสำเร็จแล้ว',
  'yijianfabu': 'การเผยแพร่ด้วยคลิกเดียว',
  'zimushezhi': 'การตั้งค่าคำบรรยายสด',
  'zhimutips': 'ไม่จำเป็น คุณสามารถแก้ไขต่อได้หลังจากการออกอากาศเริ่มต้นแล้ว',
  'dingbu': 'จอแสดงผลด้านบน',
  'shangshi': 'แสดงคำบรรยาย',
  'zimutips': 'โปรดป้อนคำบรรยายสำหรับอินเทอร์เฟซการถ่ายทอดสด ไม่เกิน 50 ตัวอักษร',
  'xuanzepeise': 'เลือกสี:',
  'dibu': 'จอแสดงผลด้านล่าง',
  'shanchutips': "เคล็ดลับ: หากคุณต้องการลบ โปรดคลิกรีเซ็ต",
  'yuliangzhuangshi': 'แสดงตัวอย่าง',
  'shoujiduan': 'ตัวอย่างเวอร์ชันมือถือ',
  'diannaoduan': 'ตัวอย่างพีซี',
  'resettips': 'คุณแน่ใจหรือไม่ว่าต้องการรีเซ็ต? คำบรรยายที่มีผลในส่วนหน้าจะถูกลบโดยอัตโนมัติหลังจากการรีเซ็ต ',
  'baountips': 'คุณแน่ใจหรือไม่ว่าต้องการบันทึก? หลังจากบันทึก คำบรรยายส่วนหน้าจะได้รับการอัปเดตและแสดง ',
  'caozuochenggongg': 'การดำเนินการสำเร็จ',
  'dangqianjy': 'มูลค่าประสบการณ์ปัจจุบัน',
  'jinrihuode': 'รับเลยวันนี้',
  'guizeshuo': 'คำอธิบายกฎ',
  'dangqian': ' LV ปัจจุบัน {msg}',
  'shenjilv': 'การอัปเกรดเป็น LV.{msg} ต้องใช้คะแนนประสบการณ์ {msg1}',
  'dachenglv': 'ต้องมีคะแนนประสบการณ์ {msg1}ทั้งหมดจึงจะบรรลุ V{msg}',
  'shenjidaolv': ' การอัปเกรดเป็น V{msg} ต้องใช้คะแนนประสบการณ์ {msg1}',
  'xinshourenwu': 'งานมือใหม่',
  'meirirenwu': 'งานรายวัน',
  'jieji': 'คลาส',
  'dengjit': 'ระดับ',
  'tubiao': 'ไอคอน',
  'gongxihuode': 'ยินดีด้วยที่ได้รับ',
  'weiwangcheng': 'ยังไม่เสร็จ',
  'jianlilist': 'รายละเอียดรางวัล',
  'renwuleixin': 'ประเภทงาน',
  'renwujianli': 'รางวัลงาน',
  'paifazhuangt': 'สถานะการจัดจำหน่าย',
  'huodeshijian': 'ขอเวลา',
  'weipaifa': 'ไม่ได้แจกจ่าย',
  'yipaifa': 'แจกจ่าย',
  'yuyanzhuti': 'หัวข้อคำทำนาย',
  'cangyuxiang': 'รายการการมีส่วนร่วม',
  'beishu': 'หลายรายการ',
  'touru': 'การลงทุน',
  'yingkui': 'กำไรและขาดทุน',
  'yuyanshijian': 'เวลาทำนาย',
  'zhubogonggao': 'ประกาศสมอเรือ',
  'zuiduiyuyue': 'สามารถจองได้สูงสุด 20 กิจกรรมในเวลาเดียวกัน',
  'yituihuan': 'กลับมาแล้ว',
  'qiuduimingcheng': 'กรุณากรอกชื่อทีม',
  'sousuoqiudui': 'ค้นหา',
  'chongxinxuanze': 'เลือกใหม่',
  'fangfaleixing': 'ประเภทวิธีการ',
  xuanzegongshi: 'เลือกบริษัท',
  jinnangbaoti: 'ชื่อโครงการ',
  jinnangbaoti: 'กรุณากรอกชื่อเรื่อง (มากกว่า 4 คำ)',
  jinnangjiexibia: 'กรุณาระบุการวิเคราะห์ (ไม่น้อยกว่า 20 คำ~)',
  tuichutips: 'ไม่สามารถบันทึกเนื้อหาได้หลังจากออก คุณแน่ใจหรือไม่ว่าต้องการออก? ',
  biaotizuishao: 'ชื่อเรื่องต้องมีอย่างน้อย 4 คำ',
  jiexizuishao: 'แยกวิเคราะห์อย่างน้อย 20 ตัวอักษร',
  tianjiachenggong: 'เพิ่มเรียบร้อยแล้ว',
  // 新增
  "flow_bureau": '(สตรีม)',
  "participation": 'การมีส่วนร่วมของโสม',
  "money": 'จำนวนเงิน',
  "hint1": 'คำพยากรณ์ยังไม่ได้เริ่มต้น',
  "hint2": 'เปิดตัวคำทำนายนำความสุขให้กับผู้ใช้มากขึ้น',
  "issuing_prophecy": 'ประกาศคำทำนาย',
  "add_theme": 'เพิ่มธีม',
  "prophetic_record": 'บันทึกคำทำนาย',
  // 新增
  "hint3": 'คุณสามารถเปิดตัวได้ถึง 5 ธีมพร้อมกัน',
  "selected": 'เลือก',
  "Entries": 'หนึ่ง',
  "above_to_add": 'กรุณาคลิกที่ "เพิ่มหัวข้อ" ด้านบนเพื่อเพิ่ม',
  "hint5": 'หัวข้อคำทำนายยังไม่ได้เพิ่ม',
  "hint4": 'แน่ใจว่าจะลบหัวข้อคำทำนายนี้?',
  "hint6": 'ไลบรารีธีมถึงขีด จำกัด สูงสุดโปรดลบส่วนก่อน',
  "select_theme": 'เลือกหัวข้อ',
  "have_deleted": 'ลบ',
  "hint7": 'คุณยังไม่ได้ออกอากาศโปรดเปิดสดก่อน',
  "End_of_batch": 'สิ้นสุดแบทช์',
  "submit_the_results": 'ส่งผลการค้นหา',
  "hint8": 'คำแนะนำ: โปรดส่งผลลัพธ์ด้วยความระมัดระวังหากผู้ใช้ร้องเรียนหลายครั้งและตรวจสอบว่าข้อร้องเรียนเป็นความจริงสิทธิ์การพยากรณ์จะถูกเรียกคืนและทำการลงโทษที่สอดคล้องกัน ',
  "hint9": 'สถานการณ์ดังกล่าวเป็นสตรีมแล้วสำนักนี้ไม่ถูกต้องและจะคืนจำนวนการมีส่วนร่วมของผู้ใช้ทั้งหมด',
  "submit1": 'ส่ง',
  "confirming_the_prophecy": 'ยืนยันผลการพยากรณ์',
  // 新增
  "title_options": 'ชื่อและตัวเลือก',
  "hint11": 'หัวข้อคำทำนาย (จำกัด 15 คำ)',
  "hint12": 'ตัวเลือก A (จำกัด 5 คำ)',
  "hint13": 'ตัวเลือก B (จำกัด 5 คำ)',
  "stop_time": 'หยุดเวลา',
  "hint14": '(ใช้ได้เฉพาะสำหรับโหมดพยากรณ์สตรีมเมอร์)',
  "save1": 'บันทึก',
  "5minute": '5 นาที',
  "10minute": '10 นาที',
  "30minute": '30 นาที',
  "45minute": '45 นาที',
  "110 นาที": '110 นาที',
  "editing_subject": 'แก้ไขหัวข้อ',
  "add_subject": 'เพิ่มหัวข้อ',
  // 新增
  "surplus": 'ส่วนที่เหลือ',
  "Victory": "ชัยชนะ",
  "Defeated": 'แพ้',
  "Flow": 'กระแส',
  "Waiting_announcement": 'รอการประกาศ',
  "Doubling": 'คูณ',
  "Stop": 'หยุด',
  "End": 'สิ้นสุด',
  "stopped": 'หยุด',
  "hint15": 'หลังจากหยุดจะไม่สามารถมีส่วนร่วมและทำนายว่าจะหยุดพยากรณ์',
  'xuanzepingdao':'โปรดเลือกช่อง',
 'xuanzesaishi':'โปรดเลือกกิจกรรม',
 'jiamifanjianshezhi':'การตั้งค่าห้องเข้ารหัส',
 'paomadeng':'การตั้งค่ากระโจม',
 'paomadengkaiguan':'สวิตช์กระโจม',
 'qingshurupaomadengneirong':'กรุณาป้อนเนื้อหาปะรำ',
 'dangqianzhibopingdao':'ช่องถ่ายทอดสดปัจจุบัน',
 'querenbtn':'ยืนยัน',
 'dingdaobianhao':'หมายเลขคำสั่งซื้อ',
 'chongzhijine':'จำนวนเงินที่เติม',
 'qingxuanxuanzepingdao':'โปรดเลือกช่องก่อน',
 'kaisaiishijian':'เวลาเริ่มต้นต้องห่างกัน 1.5 ชั่วโมง',
 'fanjianmima':'รหัสผ่านห้อง',
 'jiamifanjianshuoming':'คำอธิบายห้องเข้ารหัส:',
 'jiami1':'1. โปรดตั้งรหัสผ่านสี่หลักสำหรับรหัสผ่านของคุณ',
 'jiami2':'2. เมื่อตั้งรหัสผ่านของห้องที่เข้ารหัสสำเร็จแล้ว จะมีผลหลังจากการถ่ายทอดสดครั้งถัดไปเริ่มต้นขึ้น และไม่สามารถยกเลิกได้หลังจากเริ่มต้นแล้ว',
 'jiami3':'3. ห้องเข้ารหัสจะมีผลกับการถ่ายทอดสดเพียงครั้งเดียวเท่านั้น และข้อจำกัดจะถูกยกเลิกโดยอัตโนมัติหลังจากดาวน์โหลดการออกอากาศแล้ว',
 'chakanguize':'ดูกฎการชำระบัญชี',
 'kefuhao':'รหัสบริการลูกค้า WeChat',
 'zhiboshouyi':'รายได้ถ่ายทอดสดในเดือนนี้',
 'jinnanshouyi':'รายได้จากเคล็ดลับเดือนนี้'
};
