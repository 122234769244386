var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _vm.kitPermissionStatus === 1
            ? _c(
                "van-row",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    "margin-right": "10px",
                  },
                  attrs: { type: "flex", align: "center" },
                },
                [
                  _c(
                    "van-col",
                    {
                      staticStyle: { "margin-right": "11px" },
                      attrs: { span: "12" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card" },
                        [
                          _c(
                            "van-row",
                            {
                              attrs: {
                                type: "flex",
                                align: "center",
                                justify: "center",
                              },
                            },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.incomeInfo
                                        .ios3X26X26CurrencyZhuboIconUrl,
                                    expression:
                                      "incomeInfo.ios3X26X26CurrencyZhuboIconUrl",
                                  },
                                ],
                                staticClass: "feather",
                                attrs: {
                                  src: _vm.incomeInfo
                                    .ios3X26X26CurrencyZhuboIconUrl,
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "amount" }, [
                                _vm._v(_vm._s(_vm.incomeInfo.settlement)),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "van-row",
                            {
                              staticClass: "main-theme-font-color",
                              staticStyle: {
                                "text-align": "center",
                                "margin-top": "12px",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      _vm.$t("zhiboshouyi") +
                                        _vm.incomeInfo.currencyZhuboName
                                    ) +
                                    ")"
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("van-col", { attrs: { span: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "card" },
                      [
                        _c(
                          "van-row",
                          {
                            attrs: {
                              type: "flex",
                              align: "center",
                              justify: "center",
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.incomeInfo
                                      .ios3X26X26CurrencySilkIconUrl,
                                  expression:
                                    "incomeInfo.ios3X26X26CurrencySilkIconUrl",
                                },
                              ],
                              staticClass: "feather",
                              attrs: {
                                src: _vm.incomeInfo
                                  .ios3X26X26CurrencySilkIconUrl,
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "amount" }, [
                              _vm._v(_vm._s(_vm.incomeInfo.tacticCoin)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "van-row",
                          {
                            staticClass: "main-theme-font-color",
                            staticStyle: {
                              "text-align": "center",
                              "margin-top": "12px",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm.$t("jinnanshouyi") +
                                      _vm.incomeInfo.currencySilkName
                                  ) +
                                  ")"
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _c(
                "van-row",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    "margin-right": "10px",
                  },
                  attrs: { type: "flex", align: "center" },
                },
                [
                  _c("van-col", { attrs: { span: "24" } }, [
                    _c(
                      "div",
                      { staticClass: "card" },
                      [
                        _c(
                          "van-row",
                          {
                            attrs: {
                              type: "flex",
                              align: "center",
                              justify: "center",
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.incomeInfo
                                      .ios3X26X26CurrencyZhuboIconUrl,
                                  expression:
                                    "incomeInfo.ios3X26X26CurrencyZhuboIconUrl",
                                },
                              ],
                              staticClass: "feather",
                              attrs: {
                                src: _vm.incomeInfo
                                  .ios3X26X26CurrencyZhuboIconUrl,
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "amount" }, [
                              _vm._v(_vm._s(_vm.incomeInfo.settlement)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "van-row",
                          {
                            staticClass: "main-theme-font-color",
                            staticStyle: {
                              "text-align": "center",
                              "margin-top": "12px",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm.$t("zhiboshouyi") +
                                      _vm.incomeInfo.currencyZhuboName
                                  ) +
                                  ")"
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
          _vm._v(" "),
          _vm.kitPermissionStatus === 1
            ? _c(
                "van-tabs",
                {
                  staticStyle: { "margin-top": "15px" },
                  attrs: {
                    swipeable: "",
                    type: "card",
                    color: "#63676B",
                    sticky: "",
                  },
                  on: { change: _vm.tabChange },
                },
                [
                  _c(
                    "van-tab",
                    { attrs: { title: _vm.$t("live") } },
                    [
                      _c("live-income-record", {
                        ref: "liveIncome",
                        attrs: { unit: _vm.incomeInfo.currencyZhuboName },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "van-tab",
                    { attrs: { title: _vm.$t("plan") } },
                    [
                      _c("kit-income-record", {
                        ref: "kitIncome",
                        attrs: { unit: _vm.incomeInfo.currencySilkName },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("live-income-record", {
                ref: "liveIncome",
                attrs: { unit: _vm.incomeInfo.currencyZhuboName },
              }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }