var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("guessing-detail", {
    attrs: { "is-edit": true },
    on: { change: _vm.change },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }