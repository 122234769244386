<template>
  <div class="main-container main-theme-font-color">
    <div class="header">
      <img style="width: 80px; max-width: 80px; height: 80px; max-height: 80px; object-fit: fill;" :src="info.ios3X80X80LogoImageUrl + '?x-oss-process=image/resize,w_80,h_80,m_mfit'">
    </div>
    <div class="main-content">
      <span style="text-indent:2em;" v-html="info.aboutAgreement" />
    </div>
    <div class="footer">
      <span>{{ info.brandName }}</span>
      <br>
      <span>联系方式：{{ info.csCode }}</span>
      <br>
      <span>Copyright© {{ info.brandName }} All rights. </span>
      <br>
      <span>{{ info.icp }}</span>
    </div>
  </div>
</template>
<script>
import { getAboutInfo } from '@/api/about'

export default {
  name: 'About',
  components: {},
  data() {
    return {
      info: {}
    }
  },
  computed: {},
  created() {
    this.setTitle()
    this.getAboutInfo()
  },
  methods: {
    setTitle() {
      document.title = '关于'
    },
    getAboutInfo() {
      getAboutInfo({}).then(res => {
        this.info = res.data
        this.setTitle()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.main-container {
  width: 100%;
  height: 100%;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #C0C0C0;
  letter-spacing: 0;
  text-align: justify;
  line-height: 22px;
  @include color_main_bg();
  .header {
    width: 100%;
    padding-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
  .main-content {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 39px;
    text-align: center;
    @include color_main_bg();
  }
}
</style>
