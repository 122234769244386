<template>
  <div>
    <van-overlay :show="show">
      <div class="main-container">
        <transition name="bounce">
          <div v-show="show" class="wrapper">
            <van-row type="flex" justify="center" align="center">{{ title }}</van-row>
            <van-row type="flex" justify="center" align="center">
              <span style="text-align: center; font-size: 14px; line-height: 20px;">{{ message }}</span>
            </van-row>
            <van-row type="flex" justify="center" align="center" style="margin-top: 40px;">
              <van-button size="normal" :color="cancelButtonColor()" class="cancel-btn" style="border-radius: 4px; font-size: 13px; width: 112px; height: 40px;" plain @click="close">{{ cancelButtonText }}</van-button>
              <van-button size="normal" color="#4A90E2" style="margin-left: 20px; border-radius: 4px; font-size: 13px; width: 112px; height: 40px;" @click="$emit('confirm')">{{ confirmButtonText }}</van-button>
            </van-row>
          </div>
        </transition>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Overlay, Button, Row } from 'vant'

export default {
  components: {
    [Overlay.name]: Overlay,
    [Button.name]: Button,
    [Row.name]: Row
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    cancelButtonText: {
      type: String,
      default: ''
    },
    confirmButtonText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false
    }
  },
  watch: {
    visible: function(val) {
      this.show = val
    }
  },
  methods: {
    cancelButtonColor() {
      const themeType = window.document.documentElement.getAttribute('data-theme')
      if (themeType === 'default') {
        return '#ffffff'
      } else if (themeType === 'tint') {
        return '#000000'
      }
    },
    close() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  .wrapper {
    width: 284px;
    padding: 60px 20px 20px 20px;
    border-radius: 10px;
    @include color_main_font();
    @include color_main_bg_2();
  }
}
.cancel-btn {
  @include color_main_button_border();
  @include color_main_bg_2();
}
.bounce-enter-active {
  animation: bounce-in .2s;
}
.bounce-levave-active {
  animation: bounce-in .2s reverse;
}
@keyframes bounce-in {
  10%{
      transform: scale(1.2);
  }
  100%{
      transform: scale(1);
  }
}
</style>
