var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "main-content" }, [
      _c(
        "div",
        { staticClass: "card", staticStyle: { "margin-top": "10px" } },
        [
          _c("van-cell", {
            attrs: {
              border: false,
              "title-class": "cell-title",
              title: _vm.$t("xuanzepingdao"),
              "is-link": "",
            },
            on: { click: _vm.chooseChannel },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    !_vm.liveConfig.channelName
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("xuanzepingdao")))])
                      : _c("span", { staticClass: "main-theme-font-color" }, [
                          _vm._v(_vm._s(_vm.liveConfig.channelName)),
                          _vm.liveConfig.subChannelName
                            ? _c("span", [
                                _vm._v(
                                  "-" + _vm._s(_vm.liveConfig.subChannelName)
                                ),
                              ])
                            : _vm._e(),
                        ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("van-divider", {
            style: {
              borderColor: _vm.$theme.getDividerBorderColor(),
              marginLeft: "16px",
              marginRight: "19px",
            },
          }),
          _vm._v(" "),
          _c("van-cell", {
            attrs: {
              border: false,
              "title-class": "cell-title",
              title: _vm.$t("xuanzesaishi"),
              "is-link": "",
            },
            on: { click: _vm.chooseGame },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    !_vm.liveConfig.homeTeam
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("xuanzesaishi")))])
                      : _c("span", { staticClass: "main-theme-font-color" }, [
                          _vm._v(_vm._s(_vm.liveConfig.homeTeam)),
                        ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.liveConfig.sportsType === 1,
                  expression: "liveConfig.sportsType === 1",
                },
              ],
            },
            [
              _c("van-divider", {
                style: {
                  borderColor: _vm.$theme.getDividerBorderColor(),
                  marginLeft: "16px",
                  marginRight: "19px",
                },
              }),
              _vm._v(" "),
              _c("van-cell", {
                attrs: {
                  border: false,
                  "title-class": "cell-title",
                  title: "PK",
                  "is-link": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "right-icon",
                    fn: function () {
                      return [
                        _c("van-switch", {
                          staticStyle: { "margin-right": "2px" },
                          attrs: {
                            disabled: !_vm.liveConfig.homeTeam,
                            size: "20px",
                            "active-color": "#4CD964",
                            "inactive-color": "#9A9B9C",
                          },
                          on: { change: _vm.switchChange },
                          model: {
                            value: _vm.liveConfig.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.liveConfig, "status", $$v)
                            },
                            expression: "liveConfig.status",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("van-divider", {
            style: {
              borderColor: _vm.$theme.getDividerBorderColor(),
              marginLeft: "16px",
              marginRight: "19px",
            },
          }),
          _vm._v(" "),
          _c("van-cell", {
            attrs: {
              border: false,
              "title-class": "cell-title",
              title: _vm.$t("jiamifanjianshezhi"),
              "is-link": "",
            },
            on: { click: _vm.configRoom },
          }),
          _vm._v(" "),
          _c("van-divider", {
            style: {
              borderColor: _vm.$theme.getDividerBorderColor(),
              marginLeft: "16px",
              marginRight: "19px",
            },
          }),
          _vm._v(" "),
          _c("van-cell", {
            attrs: {
              border: false,
              "title-class": "cell-title",
              title: _vm.$t("paomadeng"),
              "is-link": "",
            },
            on: { click: _vm.configMarquee },
          }),
          _vm._v(" "),
          _c("van-divider", {
            style: {
              borderColor: _vm.$theme.getDividerBorderColor(),
              marginLeft: "16px",
              marginRight: "19px",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }