export default {
  "loading": "loading...",
  "searchplaceholder": "Anchor, channel, game",
  "dowload": "download",
  "home": "Home",
  "live": "Live",
  "match": "Match",
  "plan": "Tips",
  "mine": "Mine",
  "hotgame": "Popular competitions",
  "news": "Information",
  "mall": "mall",
  "video": "short video",
  "activity": "activity",
  "hotlive": "Hot live broadcast",
  "hotanchor": "Popular live broadcast",
  "highlights": "Highlights",
  "more": "More",
  "noliving": "There is no anchor currently on the air",
  "nolivetips": "The anchor is still warming up, please wait a moment...",
  "chat": "Chat",
  "speak": "Enter chat content",
  "nospeak": "You can speak only after logging in~",
  "gologin": "Login",
  "seenewnews": "New News",
  "unlockvip": "Unlock VIP for free",
  "announcement": "Announcement: ",
  "welcome": "Welcome",
  "enterroom": "enter the live broadcast room",
  "followed": "Followed the anchor",
  "newsinfo": "information",
  "experts": "Featured Experts",
  "hit": "Hit",
  "return": "Return",
  "wins": "Wins",
  "fb": "Football",
  "bk": "Basketball",
  "notstarted": "not started",
  "checkmore": "View details",
  "videolist": "Highlights/Videos",
  "qianzan": "forward",
  "jinhua": "essence",
  "yulei": "entertainment",
  "recommendplan": "Recommended plan",
  buyedplan: 'own plan',
  "winrate": "hit rate",
  "zuigaolianghong": "the highest consecutive {msg} red",
  "lianghong": "lianhong",
  "returnrate": "return rate",
  "buzhong": "refund if you don't win",
  "xieyi": "agreement related",
  "personal": "Privacy Agreement",
  "userxieyi": "User Agreement",
  "livexieyi": "Live broadcast agreement",
  "chargexieyi": "Recharge Agreement",
  "anchorhelp": "Anchor Help",
  "anchornew": "The new anchor is on the road",
  "followus": "Follow us",
  "helpcenter": "Help Center",
  "scancodedowload": "Scan the code to download the Bobcat APP",
  "smzb": "Cat Live",
  "noduty": "Disclaimer: All football live broadcasts and NBA live broadcast signals of Bobcat Live are collected by users or obtained from search engines. All content comes from the Internet. We do not provide any live broadcast signals and video content ourselves. Please notify us if there is any infringement of your rights. , we will deal with it as soon as possible.",
  "roomsearch": "Enter keywords",
  "myfollow": "my follow",
  "clearcache": "clear cache",
  "kinds": "classification",
  "all": "All",
  "others": "other",
  "yingchao": "Premier League",
  "hot": "Hot",
  "esports": "esports",
  "exit": "exit",
  "downloadApp": "Download APP",
  "want": "Go to live broadcast",
  "roomnum": "room number",
  "wang": "wan",
  "feedback": "feedback",
  "funs": "fans",
  "follows": "Follow",
  "numberonlive": "An anchor is currently broadcasting this event",
  "anchorchat": "anchor chat",
  "contributerank": "contribution list",
  "othermatch": "other matches",
  "chatinput": "Chat and get high together",
  "send": "send",
  "livefail": "Failed to obtain live stream",
  "tryagain": "Click to try again",
  "pushlist": "Push order list",
  "reward": "reward description",
  "nopush": "No push order yet",
  "anchorpush": "Anchor pushes orders",
  "maobipokect": "Cat coin red envelope",
  "sendsuccess": "Successful sending can trigger floating screen broadcast on all platforms",
  "manexchange": "People can receive it",
  "threeminexhang": "Can be collected in 3 minutes",
  "exchangenow": "Can be collected immediately",
  "sendpokect": "Send red envelope",
  "pokecttips": "I sent a red envelope in the live broadcast room~ You can collect it immediately! ",
  "pokecttips1": "I sent a red envelope in the live broadcast room~ You can collect it in 3 minutes! ",
  "task": "task",
  "litterhelper": "little helper",
  "litterhelper1": "Discount Assistant",
  "enter": "Click to enter",
  "gift": "gift",
  "high": "HD",
  "charge": "recharge",
  "balance": "balance",
  "words": "bang",
  "sendonepokect": "Sent a red envelope",
  "exchange": "receive",
  "ofpokect": "red envelope",
  "nopokect": "I'm too slow, the red envelopes have been distributed",
  "maobi": "cat coin",
  "savemyaccount": "has been deposited into my account",
  "checkexchange": "View claim details",
  "exchangelist": "Red envelope collection record",
  "pokecttips2": "I grabbed x cat coins in my cat coin red envelope! ",
  "contribute": "daily contribution",
  "contribute1": "Weekly contribution",
  "contribute2": "Contribution list",
  "contributevalue": "contribution value",
  "living": "live",
  "matchstatus": "Match status",
  "zhishu": "index",
  "livematchstatus": "real-time match status",
  "jinqiu": "goal",
  "zugong": "assist",
  "shemeng": "shoot",
  "dianqiu": "penalty kick",
  "wulongqiu": "own goal",
  "huangpai": "yellow card",
  "nodianqiu": "The penalty kick was not scored",
  "huanxia": "replace",
  "nomatchevent": "No match event yet",
  "onlyjinqiu": "Only watch the goals",
  "analysis": "technical analysis",
  "contrrate": "possession rate",
  "shezheng": "shoot on target",
  "jingong": "attack",
  "dendrgjingong": "dangerous attack",
  "renyiqiu": "free kick",
  "jiaoqiu": "corner kick",
  "hongpai": "red card",
  "login": "Login",
  "followed": "Followed",
  "roommannage": "appoint room manager",
  "roomtips": "Are you sure to appoint Fifteen as the room manager?",
  "roomtips1": "Are you sure that Fifteen will be dismissed as room manager?",
  "cancel": "cancel",
  "confirm": "OK",
  "logout": "Log out",
  "uplevel": "Distance upgrade: {msg} experience value",
  "wallt": "wallet",
  "wantlive": "I want live broadcast",
  "usercenter": "personal center",
  "vipright": "VIP privilege introduction",
  "pwdlogin": "Password login",
  "mobilelogin": "Mobile login",
  "doregister1": "No account",
  "doregister2": "register",
  "forgetpwd": "Forgot password? ",
  "logintips": "Login means you agree",
  "inputmobile": "Enter the mobile number",
  "inputcode": "Enter the verification code",
  "mobiletips": "Enter a 6-20 digit password or letter password",
  "resetpwd": "Reset password",
  "register": "Register",
  "sendcode": "Get code",
  "registernow": "Register now",
  "appdownload": "APP download",
  "advice": "Feedback",
  "backtop": "Back Top",
  "intime": "Instant",
  "anchor": "anchor",
  "matchschude": "schedule",
  "matchresult": "match result",
  "checkdata": "View data",
  "nomatch": "No match yet",
  "winlist": "hit list",
  "returnlist": "return list",
  "lianghongrank": "Continuously Red List",
  "hotrace": "hot race",
  "allrace": "all races",
  "onrace": "in competition",
  "onsale": "Plan on sale",
  "recommendmaster": "recommended expert",
  "mime": "me",
  "boughtplan": "Purchased plan",
  "sorttype": "sorting",
  "returnfirst": "return first",
  "winfirst": "Win rate first",
  "lianghongfirst": "Lianhong first",
  "nodata": "No data yet",
  "detail": "details",
  "newslocal": "Current location: Home/News",
  "comments": "comments",
  "nocomments": "No comments yet",
  "docomments": "Comments",
  "typecomments": "Say something",
  "goods": "goods",
  "searchgoods": "Search for products you like",
  "mycarts": "My cart",
  "myorders": "My orders",
  "demens": "diamond",
  "goodsinfo": "goods information",
  "kucun": "inventory",
  "limit": "limit exchange",
  "value": "unit price",
  "num": "quantity",
  "total": "Subtotal",
  "choice": "selected",
  "jiesuan": "settlement",
  "chooseall": "select all",
  "limitnum": "accumulated purchase limit",
  "limitday": "daily purchase limit",
  "limitweek": "weekly purchase limit",
  "totallimt": "Total redemption: ",
  "totallimt1": "Daily cumulative redemption: ",
  "totallimt2": "Total weekly redemption: ",
  "jiage": "price",
  "goodsdetail": "product details",
  "buynow": "Redeem now",
  "addcart": "Add to cart",
  "recommendgoods": "recommended for you",
  "confirmbuy": "confirm redemption",
  "mobile": "Mobile phone number",
  "recipename": "Consignee's name",
  "address": "delivery address",
  "remark": "remark",
  "inputreciptename": "Enter the name of the consignee",
  "inputaddress": "Enter the delivery address",
  "orders": "order number",
  "time": "time",
  "recipe": "consignee",
  "telphone": "telephone",
  "address1": "address",
  "recovideo": "recommended video",
  "onplay": "Normal play...",
  "ongoing": "in progress",
  "result": "Ended",
  "reserve": "reservation",
  "reserved": "reserved",
  "myinfo": "my information",
  "anchorlevel": "Anchor level",
  "userlevel": "user level",
  "changeavter": "Change avatar",
  "changeinfo": "Modify information",
  "usertips": "(Picture size must not exceed 1M, support png, jpg format)",
  "uploadimg": "Upload local pictures",
  "myaccount": "My account",
  "anchorinfo": "Anchor related",
  "tasks": "Task Center",
  "signin": "Sign in with courtesy",
  "mywords": "My prophecy",
  "downloadreword": "Download reward",
  "nickname": "nickname",
  "inputnickname": "Enter your nickname",
  "infotips": "Description:\n 1. The new nickname must comply with registration specifications and can use numbers, letters, Chinese characters, and symbols. The maximum length should not exceed 24 characters (2 characters for Chinese and 1 character for English). \n 2. If the nickname modification fails due to server updates or other problems, please contact customer service. ",
  "introduce": "personal profile",
  "region": "region",
  "selectprovince": "Please select a province",
  "selectregion": "Please select a city",
  "sex": "gender",
  "savesecrt": "confidential",
  "man": "male",
  "woman": "female",
  "bedate": "birthday",
  "selectdate": "Please select the year",
  "selectmonth": "Please select a month",
  "select": "Please select",
  "year": "year",
  "month": "month",
  "day": "day",
  "save": "save",
  "accountvalue": "Balance",
  "billlist": "Bill record",
  "cast": "consumption",
  "earnings": "earnings",
  "casttype": "Consumption type",
  "sendgift": "gift",
  "prophecy": "prophecy",
  "redpokect": "red envelope",
  "exchangegoods": "Exchange gifts",
  "luckydraw": "paid draw",
  "querytime": "query time",
  "starttime": "start date",
  "endtime": "end date",
  "query": "query",
  "reset": "reset",
  "castmaobi": "Consumption amount (cat coins)",
  "allprophecy": "All prophecy",
  "prophecylist": "Prophecy Hall",
  "planlist": "Tips for solving the problem",
  "yuyuematch": "Reservation match",
  "earnlist": "earning record",
  "liveset": "Live settings",
  "roompush": "Live room push order",
  "roomset": "Live room letter setting",
  "edit": "edit",
  "beAnchor": "Become an anchor",
  "inviterUser": "Invite friends",
  "setting": "setting",
  "logining": "Logging in...",
  "loginerr": "Login failed, please try again later...",
  "loginsecucced": "Login successful",
  "codesend": "Verification code sent successfully",
  "inputrightphone": "Enter the correct mobile phone number",
  "inputagain": "Enter your password again",
  "pwdtips": "The passwords entered twice are inconsistent",
  "pwdtips1": "Password format is wrong, please enter 6-20 digit password or letter password",
  "registed": "Registration successful",
  "registerfail": "Registration failed, please try again later...",
  "registing": "Registering...",
  "findpwd": "Retrieve password",
  "submit": "submit",
  "settingPwd": "Password set successfully",
  "submitting": "Submitting...",
  "nomore": 'No more',
  "pullrefresh": 'Pull down to refresh',
  "notstart": 'The game has not started, you can watch other games:',
  newsDe: 'information details',
  shouqi: 'put away',
  fabu: 'release',
  'eur': 'European Disk',
  "hk": 'Hong Kong Disk',
  "bm": 'Over/Under',
  'rq': 'Handicap',
  'mzl': 'hit rate',
  'mago': 'minutes ago',
  'sago': 'seconds ago',
  'hago': 'hours ago',
  'mianfei': 'free',
  'tacticde': 'Plan details',
  'zhusheng': 'win',
  "pingju": 'draw',
  "kesheng": 'lose',
  'pay': 'Pay now',
  'stop': 'Sold has stopped',
  'reason': 'Recommendation reason',
  'afterpay': 'View the plan and analysis after payment',
  'stoptime': 'Sales deadline:',
  'tacticdetips': 'Note: The opinions are for reference only, and we do not provide lottery business, please pay attention with caution! ',
  'dago': 'day ago',
  'wago': 'week ago',
  'mouthago': 'months ago',
  'ganggang': 'just now',
  'bigqiu': 'Over{msg}balls',
  'smallqiu': 'Under{msg}balls',
  'buyplan': 'Buy expert plan',
  'buyplantips': 'Purchasing this plan requires {num} cat coins. Are you sure you have enough? ',
  'yesbuy': 'Confirm purchase',
  'cancelfollow': 'Cancel follow successfully',
  'followsucceed': 'Follow success',
  'buysucceed': 'Purchase successful',
  'buyfialed': 'Purchase failed',
  'liangsai': 'league',
  'team': 'Home vs away',
  'playtype': 'play',
  'rate': 'odds',
  'chongzhijilu': 'Recharge record',
  'xiaofeijilu': 'consumption record',
  'huodongshouyi': 'activity income',
  'myinvitecode': 'My invitation code',
  'inputcodeinapp': 'Please enter when logging in to the App',
  'dianjifuzhi': 'Click to copy the invitation code',
  'jietu': 'Send screenshots to your friends',
  'shoukuang': 'Payment method',
  'alipay': 'Alipay',
  'wechat': 'WeChat',
  'shoukuangzhanghao': 'payment account number',
  'invitegonglue': 'Invite strategy',
  'shareqrcode': 'Share download QR code and exclusive invitation code',
  'getjiangli': 'You will receive a reward if your friend logs in successfully for the first time',
  'invitemingxi': 'Invitation details',
  'zhanwuhaoyou': 'No friends have been invited yet. Go and invite friends! ',
  'nicheng': 'nickname',
  'zucengshijian': 'Registration time',
  'jiangli': 'reward',
  'islogout': 'Are you sure you want to log out? ',
  'dijilun': 'Round {num}',
  'guangyuus': 'about us',
  'zhuanjiajieshao': 'Expert introduction',
  'recent10': 'Return rate in the last 10 games',
  'zuijinzhuantai': 'Recent status',
  'zhengzaituijian': 'Recommending',
  'historyrem': 'History recommendation',
  'bisaishijian': 'match event',
  'qiuduitongji': 'Team statistics',
  'qiudui': 'Team',
  'zongfen': 'total score',
  'bisaikaishi': 'The game begins',
  'fangui': 'foul',
  'erfen': 'two points',
  'faqiu': 'free throw',
  'sanfen': 'three points',
  'paizhao': 'take pictures',
  'xuanzezhaopian': 'Select from album',
  'jianyanzhi': 'experience value',
  'yiwangcheng': 'Completed',
  'dangqian': 'current',
  'shengjidao': 'Upgrade to',
  'xuyao': 'need',
  'cehngzhang': 'Growth privilege',
  'dengji': 'level icon',
  'kaiqi': 'open',
  'qidai': 'Stay tuned',
  'mashanglai': 'More privileges are coming soon',
  'qiandao': 'Daily check-in',
  'qiandaochenggong': 'Sign in successfully',
  'qiandaotips': 'Warm reminder: Please contact the assistant to apply for bonuses and physical rewards. ',
  'chakanqiandao': 'View rewards',
  'goxiaozhushou': 'Contact assistant',
  'dijitian': '{msg}day',
  'zhubodasan': 'Anchor Reward List',
  'riban': 'daily',
  'yueban': 'monthly',
  'zongban': 'General',
  'gongxianzhi': 'contribution',
  'fananjieshu': 'The plan sale has ended',
  'bisaijieshu': 'The analysis details will be announced after the competition',
  'yuebuzu': 'Insufficient balance',
  'qitajinnang': "The expert's other tips",
  'qitatuijian': 'Other recommendations',
  'dengluhou': 'You can only see the purchased records later',
  'zhengzaibofng': 'Now playing',
  'jijiangbofang': 'Coming to play',
  'touxiangshangchuan': 'Avatar upload',
  'shouyeleixing': 'income type',
  'lingquhongbao': 'Receive red envelope',
  'hongbaotuikuang': 'red envelope return',
  'jinnantutikuang': 'Tips Refund',
  'yuyanshouyi': 'prophecy profit',
  'yuyantuikuang': 'prophecy refund',
  'hongbaoyu': 'hongbaoyu',
  'jincaidati': 'guess and answer',
  'zhuanpan': 'Roulette lottery',
  'jindan': 'smashing golden eggs',
  'zenfa': 'System additional issuance',
  'dengluhuodong': 'Login activity',
  'guankan': 'Watch live broadcast',
  'danmu': 'Send barrage',
  'denglujianli': 'APP login reward',
  'tuidan': 'Push order reward',
  'zhuanpanchoujian': 'New carousel lottery',
  'zhuti': 'theme',
  'xuanxian': 'option',
  'kejitouzhu': 'accumulated betting',
  'xuanxianbeishu': 'Option multiple',
  'touzhurenshu': 'number of bettors',
  'jieguo': 'result',
  'zhuantai': 'status',
  'daojishi': 'Countdown',
  'fabuplantips': 'Earn huge amounts of money after publishing the ball solving tips~',
  'goumairenshu': 'number of buyers',
  'zongji': 'Total',
  'fencheng': 'before divided into',
  'liveshouyi': 'Live broadcast settleable income',
  'jinnanshouyi': 'The tips can be settled as income',
  'jiesuanrules': 'Settlement rules',
  'tequan': 'level privilege',
  'kuaisushengji': 'How to upgrade quickly',
  'wodedengji': 'my level',
  'dengjishuoming': 'level description',
  'zhubotequan': 'Anchor privilege',
  'shejizhong': 'Anchor privileges are being designed',
  'moretequan': 'More privileges are coming soon',
  'huodeliwu': 'Get a gift',
  'qingfenzhibo': 'diligent live broadcast',
  'zensongliwu': 'give a gift',
  'yonghutequan': 'User privileges',
  'tequansheji': 'User privileges are under design',
  'fengzhong': 'minute',
  'jiyang': 'experience',
  'quedingquxiao': 'Are you sure you want to unfollow? ',
  'zhengquetianxie': 'Please fill in the correct information, otherwise you will not receive any rewards',
  'qingshuruzhanghao': 'Please enter your account number',
  'yaoqingshuoming': 'Invitation description',
  'zaiciquanren': 'Please confirm {msg} account again: {msg1}',
  'qingshuruzhanghao': 'Please enter {msg} account number',
  'daishenhe': 'to be reviewed',
  'yipaijiang': 'Award has been distributed',
  'weitongguo': 'failed',
  'leijizhong': 'accumulating',
  'tishi': 'hint',
  'tijiaochengong': 'Submission successful! ',
  'zonghe': 'comprehensive',
  'yituichi': 'postponed',
  'yiquxiao': 'Cancelled',
  'jitian': 'today',
  'mintian': 'tomorrow',
  'houtian': 'the day after tomorrow',
  'zuotian': 'yesterday',
  'xiazaitixing': 'The appointment is successful, download the APP to receive the appointment reminder',
  'quxiaoyuyue': 'Reservation canceled successfully',
  'zhoutian': 'Sunday',
  'zhouyi': 'Monday',
  'zhouer': 'Tuesday',
  'zhousan': 'Wednesday',
  'zhousi': 'Thursday',
  'zhouwu': 'Friday',
  'zhouliu': 'Saturday',
  'jianshu': 'piece',
  'meiri': 'daily',
  'meizhou': 'weekly',
  'duihuanchenggong': 'Redeem successful',
  'zuiduoduihuan': 'Maximum redemption of {msg} pieces',
  'caozuo': 'operation',
  'zongjia': 'total price',
  'shanchu': 'delete',
  'now': 'just',
  'yituikuan': 'refunded',
  'goumai': 'buy',
  'xuanzezhibo': 'Select live broadcast channel',
  'erjipingdao': 'Second level channel',
  'zhibobiaoti': 'Live broadcast title',
  'shezhibiaoti': 'Please set the live broadcast title',
  'guanliansaishi': 'related events',
  'xuanzhiguanlian': 'Please associate the event',
  'xuanze': 'select',
  'yichu': 'remove',
  'zhibofenmian': 'Live broadcast cover',
  'yishenhe': 'reviewed',
  'shenhezhong': 'under review',
  'yijujue': 'Rejected',
  'zhibijieshu': 'End live broadcast',
  'kaishizhibo': 'Start live broadcast',
  'tuiliudizhi': 'Push address (server address)',
  'fuzhi': 'copy',
  'tuiliumiyao': 'Push code (streaming key)',
  'laliudizhi': 'Laliudizhi',
  'zhiboshuoming': 'Live broadcast description',
  'zhibotips1': `
   1. Set basic information: select channel, set title, associate events, live broadcast cover, set as needed;<br />
   2. After clicking the "Start Live Broadcast" button; copy the push address and push code;<br />
   3. Paste the push address and push code to the "server and streaming key" corresponding to the OBS software, and start the live broadcast;<br />`,
  'zhibotips2': `
   1. After clicking to start the live broadcast, please copy and paste the push address and push code to OBS within 1 minute and push the stream. Otherwise, the live broadcast room may be downgraded as an abnormal system;<br />
   2. After ending the live broadcast and closing OBS, you need to click the end live broadcast button above to download the live broadcast room. <br />`,
  'yiyuyue': 'The event has been booked',
  'biaotitips': 'The title cannot be empty! ',
  'yuliang': 'preview',
  'zhuyishixian': 'Notes',
  'xinjian': 'New push order',
  'xuanzesaishi': 'Select event',
  'xuanzesaiduan': 'Select stage',
  'xuanzewf': 'Choose how to play',
  'jieguotips': 'Please enter the result, up to 25 words',
  'tuidanlishi': 'Historical push order',
  'renmai': '{msg}people follow orders',
  'renfanmai': '{msg}people buy against',
  'qingxuanzesaishi': 'Please select the event',
  'qingsaiduan': 'Please select the stage',
  'qingwf': 'Please choose how to play',
  'qingjieguo': 'Please enter the result',
  'tuidangtips': 'This promotion order cannot be changed after it is submitted. Do you confirm the submission? ',
  'fabutuidan': 'Publish a push order',
  'chuangjian': 'Created successfully',
  'yijianfabu': 'One-click publishing',
  'zimushezhi': 'Live subtitle setting',
  'zhimutips': 'Not required, you can continue editing after the broadcast starts',
  'dingbu': 'top display',
  'shangshi': 'Show subtitles',
  'zimutips': 'Please enter subtitles for the live broadcast interface, up to 50 characters',
  'xuanzepeise': 'Select color:',
  'dibu': 'bottom display',
  'shanchutips': 'Tip: If you need to delete, please click Reset',
  'yuliangzhuangshi': 'Preview display',
  'shoujiduan': 'Mobile version preview',
  'diannaoduan': 'PC preview',
  'resettips': 'Are you sure you want to reset? Subtitles that have taken effect on the front end will be automatically deleted after the reset. ',
  'baocuntips': 'Are you sure you want to save? After saving, the front-end subtitles will be updated and displayed. ',
  'caozuochenggongg': 'Operation successful',
  'dangqianjy': 'Current experience value',
  'jinrihuode': 'Get it today',
  'guizeshuo': 'Rule description',
  'dangqian': ' Current LV.{msg}',
  'shenjilv': 'Upgrading to LV.{msg} requires {msg1} experience points',
  'dachenglv': 'A total of {msg1}experience points are required to achieve V{msg}',
  'shenjidaolv': ' Upgrading to V{msg} requires {msg1} experience points',
  'xinshourenwu': 'Novice Task',
  'meirirenwu': 'daily task',
  'jieji': 'class',
  'dengjit': 'level',
  'tubiao': 'icon',
  'gongxihuode': 'Congratulations on getting',
  'weiwangcheng': 'Unfinished',
  'jianlilist': 'Reward details',
  'renwuleixin': 'task type',
  'renwujianli': 'Task reward',
  'paifazhuangt': 'Distribution status',
  'huodeshijian': 'Get time',
  'weipaifa': 'not distributed',
  'yipaifa': 'distributed',
  'yuyanzhuti': 'Prophecy theme',
  'cangyuxiang': 'participation item',
  'beishu': 'multiple',
  'touru': 'investment',
  'yingkui': 'profit and loss',
  'yuyanshijian': 'prophecy time',
  'zhubogonggao': 'Anchor Announcement',
  'zuiduiyuyue': 'Maximum reservations for 20 events at the same time',
  'yituihuan': 'Returned',
  'qiuduimingcheng': 'Please enter the team name',
  'sousuoqiudui': 'search',
  'chongxinxuanze': 'Reselect',
  'fangfaleixing': 'Method type',
  xuanzegongshi: 'Choose a company',
  jinnangbiaoti: 'Project title',
  jinnangbiaoti: 'Please enter a title (more than 4 words)',
  jinnangjiexibia: 'Please enter the analysis (not less than 20 words~)',
  tuichutips: 'The content cannot be saved after exiting. Are you sure you want to exit? ',
  biaotizuishao: 'The title must be at least 4 words',
  jiexizuishao: 'parse at least 20 characters',
  tianjiachenggong: 'Added successfully',
  // 新增
  flow_bureau: " (Flow Bureau) ",
  'participation': 'Participation in people',
  'money': 'Amount',
  hint1: " The prophecy has not been initiated yet",
  hint2: " Initiate prophecies to bring more happiness to users ",
  issuing_prophecy: " Publish prophecy ",
  'add_theme': 'Add theme',
  prophetic_record: " Prophecy record ",
  // 新增
  hint3: " You can initiate up to 5 topics simultaneously",
  'selected': ' already selected ',
  'Entries': ' pieces',
  "above_to_add": " Please click on Add Theme above to add ",
  hint5: " The prophecy theme has not been added yet",
  "hint4": "Are you sure to delete this prophecy topic",
  hint6: " The theme library has reached its limit, please delete some parts first",
  'select_theme ': ' Select theme ',
  'have_deleted': 'deleted',
  hint7: " You haven't started broadcasting yet, please start broadcasting first",
  End_of_batch: "Batch end",
  'submit_the_results': 'Submit results',
  hint8: " Explanation: Please submit the results carefully. If the user makes multiple complaints and verifies that the complaints are true, the prophecy permission will be revoked and corresponding penalties will be imposed",
  hint9: " If this game is a failed game, it will be invalid and all user participation amounts will be refunded",
  'submit1': ' Submit ',
  confirming_the_prophecy: " Confirm the prophecy result ",
  // 新增
  "title_options": " Title and options ",
  hint11: " Prophecy title (limited to 15 words) ",
  hint12: " Option A (limited to 5 words) ",
  hint13: " Option B (limited to 5 words) ",
  stop_time: " Stop time ",
  hint14: " (Effective only for anchor prediction mode) ",
  'save1 ': ' Save',
  "5minute": " 5 minutes ",
  "10minute": " 10 minutes ",
  "30minute": " 30 minutes ",
  "45minute": " 45 minutes ",
  "110minute": " 110 minutes ",
  "editing_subject": " Edit Topic ",
  'add_Subject': 'Add topic',
  // 新增
  'surplus': 'remaining',
  "Victory": "Victory",
  "Defeated": "Defeated",
  'Flow': 'Flow',
  "Waiting_announcement": "Waiting for announcement",
  "Doubling": "Doubling",
  "Stop": "Stop",
  'End': 'End',
  'stopped': 'stopped',
  "Hint15": " After stopping, you will not be able to participate in and predict.Are you sure you want to stop predicting",
  'xuanzepingdao':'Please select a channel',
'xuanzesaishi':'Please select a competition',
'jiamifanjianshezhi':'Encryption room settings',
'paomadeng':'Marquee settings',
'paomadengkaiguan':'Marquee switch',
'qingshurupaomadengneirong':'Please enter the content of the marquee',
'dangqianzhibopingdao':'Current live channel',
'querenbtn':'Confirm',
'dingdaobianhao':'Order number',
'chongzhijine':'Recharge amount',
'qingxuanxuanzepingdao':'Please select a channel first',
'kaisaishijian':'The start time needs to be 1.5 hours apart',
'fanjianmima':'Room password',
'jiamifanjianshuoming':'Encryption room instructions:',
'jiami1':'1. Please set a four-digit password',
'jiami2':'2. After the password of the encrypted room is successfully set, it will take effect after the next live broadcast starts and cannot be cancelled after the broadcast starts',
'jiami3':'3. The encrypted room is only effective for one live broadcast, and the restriction will be automatically lifted after the broadcast ends',
'chakanguize':'Check the settlement rules',
'kefuhao':'Customer service WeChat account',
'zhiboshouyi':'Live broadcast income this month',
'jinnanshouyi':'Jinan income this month'
}