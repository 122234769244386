<template>
  <div class="app-container" :data-theme="$store.getters.themeType">
    <div class="main-content">
      <van-row type="flex" justify="space-between" align="center" class="main-theme-font-color" style="margin-bottom: 20px;">
        <div>
          <p>{{ selectedGame.homeName }}VS{{ selectedGame.awayName }}</p>
          <p style="margin-top: 8px; color: #9B9B9B;">{{ selectedGame.eventName }} | {{ selectedGame.matchTime&&selectedGame.matchTime.substr(5,2) }}/{{ selectedGame.matchTime&&selectedGame.matchTime.substr(8,2) }} {{ selectedGame.matchTime&&selectedGame.matchTime.substr(10,6) }}</p>
        </div>
        <van-button plain type="info" class="choose-botton" @click="goBack()">{{ $t('chongxinxuanze') }}</van-button>
      </van-row>
      <div class="separation-line" />
      <div v-if="selectedGame.gameType === 0">
        <van-row type="flex" align="center" style="margin-top: 10px; margin-bottom:10px;">
          <span style="font-size: 16px;" class="main-theme-font-color">{{ $t('recommendgoods') }}</span>
          <span style="font-size: 12px; color: #9B9B9B; margin-left: 5px;">{{ $t('qingwf') }}</span>
        </van-row>
        <van-row type="flex" justify="space-between" align="center">
          <van-col span="6">
            <div class="card" style="text-align: center; color: #9B9B9B; font-size: 12px">
              <p style="margin-bottom: 10px;">{{ $t('rq') }}</p>
              <span>{{ selectedGame ? selectedGame.spf.addBall : '-' }}</span>
            </div>
          </van-col>
          <van-col span="17" offset="1">
            <van-row>
              <van-col span="8" @click="optionChange(1)">
                <div class="rectangle left-radius right-border" :class="selectedOption === 1 ? 'option-active' : 'option'">
                  <div :class="selectedOption === 1 ? 'option-title-active' : 'option-title'">
                    <p style="margin-bottom: 10px;">{{ $t('zhusheng') }}</p>
                    <span v-if="!selectedGame">-</span>
                    <span v-if="selectedGame">{{ selectedGame.spf.realOdds[0] }}</span>
                  </div>
                </div>
              </van-col>
              <van-col span="8" @click="optionChange(2)">
                <div class="rectangle right-border" :class="selectedOption === 2 ? 'option-active' : 'option'">
                  <div :class="selectedOption === 2 ? 'option-title-active' : 'option-title'">
                    <p style="margin-bottom: 10px;">{{ $t('pingju') }}</p>
                    <span v-if="!selectedGame">-</span>
                    <span v-if="selectedGame">{{ selectedGame.spf.realOdds[1] }}</span>
                  </div>
                </div>
              </van-col>
              <van-col span="8" @click="optionChange(3)">
                <div class="rectangle right-radius" :class="selectedOption === 3 ? 'option-active' : 'option'">
                  <div :class="selectedOption === 3 ? 'option-title-active' : 'option-title'">
                    <p style="margin-bottom: 10px;">{{ $t('kesheng') }}</p>
                    <span v-if="!selectedGame">-</span>
                    <span v-if="selectedGame">{{ selectedGame.spf.realOdds[2] }}</span>
                  </div>
                </div>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
        <van-row style="margin-top: 10px; margin-bottom: 10px;">
          <van-col span="6">
            <div class="card" style="text-align: center;  color: #9B9B9B; font-size: 12px;">
              <p style="margin-bottom: 10px;">{{ $t('rq') }}</p>
              <span>{{ selectedGame ? selectedGame.rqspf.addBall : '-' }}</span>
            </div>
          </van-col>
          <van-col span="17" offset="1">
            <van-row>
              <van-col span="8" @click="optionChange(4)">
                <div class="rectangle left-radius right-border" :class="selectedOption === 4 ? 'option-active' : 'option'">
                  <div :class="selectedOption === 4 ? 'option-title-active' : 'option-title'">
                    <p style="margin-bottom: 10px;">{{ $t('zhusheng') }}</p>
                    <span v-if="!selectedGame">-</span>
                    <span v-if="selectedGame">{{ selectedGame.rqspf.realOdds[0] }}</span>
                  </div>
                </div>
              </van-col>
              <van-col span="8" @click="optionChange(5)">
                <div class="rectangle right-border" :class="selectedOption === 5 ? 'option-active' : 'option'">
                  <div :class="selectedOption === 5 ? 'option-title-active' : 'option-title'">
                    <p style="margin-bottom: 10px;">{{ $t('pingju') }}</p>
                    <span v-if="!selectedGame">-</span>
                    <span v-if="selectedGame">{{ selectedGame.rqspf.realOdds[1] }}</span>
                  </div>
                </div>
              </van-col>
              <van-col span="8" @click="optionChange(6)">
                <div class="rectangle right-radius" :class="selectedOption === 6 ? 'option-active' : 'option'">
                  <div :class="selectedOption === 6 ? 'option-title-active' : 'option-title'">
                    <p style="margin-bottom: 10px;">{{ $t('kesheng') }}</p>
                    <span v-if="!selectedGame">-</span>
                    <span v-if="selectedGame">{{ selectedGame.rqspf.realOdds[2] }}</span>
                  </div>
                </div>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </div>
      <div v-if="selectedGame.gameType === 1" style="margin-top: 10px; margin-bottom: 10px;">
        <van-row type="flex" align="center" justify="space-between" style="margin-top: 10px; margin-bottom:10px;">
          <span style="font-size: 16px;" class="main-theme-font-color">{{ $t('fangfaleixing') }}</span>
          <div @click="chooseCompany()">
            <span style="color: #4A90E2">{{ companyData ? companyData.label : '-' }}</span>
            <img style="width: 12px; height: 12px; margin-left: 4px;" src="@/assets/images/live/arrow-down.png">
          </div>
        </van-row>
        <method-segmentation @change="methodChange" />
        <van-row type="flex" align="center" style="margin-top: 10px; margin-bottom:10px;">
          <span style="font-size: 16px;" class="main-theme-font-color">{{ $t('recommendgoods') }}</span>
          <span style="font-size: 12px; color: #9B9B9B; margin-left: 5px;">{{ $t('qingwf') }}</span>
        </van-row>
        <van-popup v-model="showCompanyList" get-container="#app" :round="true" position="bottom" :style="{ maxHeight: '80%',minHeight:'30%' }">
          <div style="position: relative;">
            <div style="position: sticky; top: 0px; width: 100%; z-index: 2;" class="main-bg">
              <van-row type="flex" justify="space-between" align="center" style="padding: 20px;" class="main-theme-font-color">
                <span @click="showCompanyList = false">{{ $t('cancel') }}</span>
                <span style="font-size: 16px; font-weight:500;">{{ $t('xuanzegongshi') }}</span>
                <div class="popup-confirmation" style="color: #ffffff" @click="handleCompanyChange()">{{ $t('confirm') }}</div>
              </van-row>
            </div>
            <van-radio-group v-model="selectedCompany">
              <van-cell-group>
                <div v-for="(item,index) in companyList" :key="index">
                  <van-cell clickable @click="selectedCompany = item">
                    <template #default>
                      <div class="main-theme-font-color">
                        <span>{{ item.label }}</span>
                      </div>
                    </template>
                    <template #right-icon>
                      <van-radio :name="item" />
                    </template>
                  </van-cell>
                </div>
              </van-cell-group>
            </van-radio-group>
          </div>
        </van-popup>
        <recommend-segmentation :type="methodType" :gameType="selectedGame.gameType" :data="companyData ? recommendData : undefined" @change="recommendTypeChange" />
      </div>
      <div v-if="selectedGame.gameType === 2" style="margin-top: 10px; margin-bottom: 10px;">
        <van-row type="flex" align="center" justify="space-between" style="margin-top: 10px; margin-bottom:10px;">
          <span style="font-size: 16px;" class="main-theme-font-color">{{ $t('fangfaleixing') }}</span>
          <div @click="chooseCompany()">
            <span style="color: #4A90E2">{{ companyData ? companyData.label : '-' }}</span>
            <img style="width: 12px; height: 12px; margin-left: 4px;" src="@/assets/images/live/arrow-down.png">
          </div>
        </van-row>
        <method-segmentation @change="methodChange" />
        <van-row type="flex" align="center" style="margin-top: 10px; margin-bottom:10px;">
          <span style="font-size: 16px;" class="main-theme-font-color">{{ $t('recommendgoods') }}</span>
          <span style="font-size: 12px; color: #9B9B9B; margin-left: 5px;">{{ $t('qingwf') }}</span>
        </van-row>
        <van-popup v-model="showCompanyList" get-container="#app" :round="true" position="bottom" :style="{ maxHeight: '80%',minHeight:'30%' }">
          <div style="position: relative;">
            <div style="position: sticky; top: 0px; width: 100%; z-index: 2;" class="main-bg">
              <van-row type="flex" justify="space-between" align="center" style="padding: 20px;" class="main-theme-font-color">
                <span @click="showCompanyList = false">{{ $t('cancel') }}</span>
                <span style="font-size: 16px; font-weight:500;">{{ $t('xuanzegongshi') }}</span>
                <div class="popup-confirmation" style="color: #ffffff" @click="handleCompanyChange()">{{ $t('confirm') }}</div>
              </van-row>
            </div>
            <van-radio-group v-model="selectedCompany">
              <van-cell-group>
                <div v-for="(item,index) in companyList" :key="index">
                  <van-cell clickable @click="selectedCompany = item">
                    <template #default>
                      <div class="main-theme-font-color">
                        <span>{{ item.label }}</span>
                      </div>
                    </template>
                    <template #right-icon>
                      <van-radio :name="item" />
                    </template>
                  </van-cell>
                </div>
              </van-cell-group>
            </van-radio-group>
          </div>
        </van-popup>
        <recommend-segmentation :type="methodType" :gameType="selectedGame.gameType" :data="companyData ? recommendData : undefined" @change="recommendTypeChange" />
      </div>
      <span style="font-size: 16px;" class="main-theme-font-color">{{ $t('jinnangbiaoti') }}</span>
      <van-field
        v-model="title"
        style="margin-top: 10px; margin-bottom: 10px;"
        class="title-field"
        rows="2"
        autosize
        type="textarea"
        maxlength="40"
        :placeholder="$t('jinnangbiaoti')"
        show-word-limit
        @input="titleInput"
      />
      <span style="font-size: 16px;" class="main-theme-font-color">{{ $t('reason') }}</span>
      <van-field
        v-model="reason"
        style="margin-top: 10px; margin-bottom: 10px;"
        class="title-field"
        rows="10"
        type="textarea"
        maxlength="150"
        :placeholder="$t('jinnangjiexibia')"
        show-word-limit
        @input="contentInput"
      />
      <van-row type="flex" align="center" style="margin-top: 20px;">
        <div style="font-size: 16px; margin-right: 10px;" class="main-theme-font-color">{{ $t('jiage') }}</div>
        <cube-checker v-model="price" :options="options" type="radio" style="font-size: 16px;" />
      </van-row>
    </div>
    <div class="bottom">
      <div class="row" style="padding-top: 10px;">
        <van-button class="button-primary" style="width: 80%; border-radius:6px;" :disabled="publishingDisable" :loading="loading" @click="commit">{{ $t('fabu') }}</van-button>
      </div>
    </div>
    <alert :visible.sync="showAlert" 
    :message="$t('tuichutips')" 
    :cancel-button-text="$t('cancel')" 
    :confirm-button-text="$t('confirm')" 
    @confirm="confirm" />
  </div>
</template>

<script>

import { Row, Col, Button, Divider, RadioGroup, Radio, Empty, Toast, Field, Cell, CellGroup, Tab, Tabs, Popup } from 'vant'
import { gameList, createAnswerKit, getMoneyList } from '@/api/answer-kit/answerKit'
import Alert from '@/components/Alert'
import MethodSegmentation from './components/MethodSegmentation'
import RecommendSegmentation from './components/RecommendSegmentation'
export default {
  name: 'GuessingSelection',
  components: {
    Alert,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Empty.name]: Empty,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Toast.name]: Toast,
    MethodSegmentation,
    RecommendSegmentation
  },
  data() {
    return {
      list: [],
      title: '',
      price: 0,
      reason: '',
      playType: 101, // 玩法类型 100让球 101不让球
      methodType: 1,
      recommend: undefined,
      recommendData: undefined,
      selectedGameItem: undefined,
      selectedGame: undefined,
      selectedOption: undefined,
      companyList: [],
      // 亚指、大小球 可选公司
      companyList1: [
        { value: 7, label: 'MX' },
        { value: 12, label: 'LD' },
        // { value: 1, label: '澳门' },
        // { value: 2, label: '皇冠' },
        { value: 32, label: 'STS' },
        { value: 73, label: 'TOTO' },
        { value: 183, label: 'ComeOn' },
        { value: 124, label: 'Sisal' },
        { value: 4, label: 'SNAI' },
        { value: 5, label: 'bet365' },
        { value: 57, label: 'Norway' },
        { value: 106, label: 'sb' },
      ],
      // 欧指 可选 公司
      companyList2: [
        { value: 7, label: 'MX' },
        { value: 12, label: 'LD' },
        // { value: 1, label: '澳门' },
        // { value: 2, label: '皇冠' },
        { value: 32, label: 'STS' },
        { value: 73, label: 'TOTO' },
        { value: 183, label: 'ComeOn' },
        { value: 124, label: 'Sisal' },
        { value: 4, label: 'SNAI' },
        { value: 5, label: 'bet365' },
        { value: 57, label: 'Norway' },
        { value: 106, label: 'sb' },
      ],
      options: [{ value: 0, text: this.$t('mianfei') }],
      playBigType: 0,
      showCompanyList: false,
      selectedCompany: undefined,
      companyData: undefined,
      showGameList: false,
      showAlert: false,
      isChecked: false,
      loading: false
    }
  },
  computed: {
    publishingDisable() {
      if (this.title !== '' && this.reason !== '' && this.recommend) {
        return false
      }
      return true
    },
    isEditing() {
      if (this.title !== '' || this.reason !== '' || this.recommend !== undefined) {
        return true
      }
      return false
    }
  },
  created() {
    this.selectedGame = this.$route.params
    // console.log(this.selectedGame,111)
    // if (this.selectedGame.gameType === 1) {
      
    // }
    this.initCompanyList()
    this.setTitle()
    this.getList()
    this.getMoneyList()
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isChecked && this.isEditing) {
      this.showAlert = true
      next(false)
      return
    }
    next()
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    setTitle() {
      document.title = '添加方案'
    },
    chooseCompany() {
      this.showCompanyList = true
    },
    getMoneyList(query) {
      getMoneyList({}).then(response => {
        if (response.data.moneyList) {
          response.data.moneyList.forEach(item => {
            this.options.push({
              value: item,
              text: item + ''
            })
          })
        }
      })
    },
    handleCompanyChange() {
      this.showCompanyList = false
      this.companyData = this.selectedCompany
      this.recommendData = this.selectedGame.oddMap[this.companyData.value]['1']
      this.recommend = undefined
      this.methodChange(this.methodType)
    },
    methodChange(index) {
      this.filterCompanyList(index)
      this.methodType = index
      this.playBigType = index + 1
      this.recommend = undefined
      if (!this.companyData) return
      if (index === 1) {
        this.recommendData = this.selectedGame.oddMap[this.companyData.value]['1']
      } else if (index === 2) {
        this.recommendData = this.selectedGame.oddMap[this.companyData.value]['5']
      } else if (index === 3) {
        this.recommendData = this.selectedGame.oddMap[this.companyData.value]['3']
      }
    },
    initCompanyList() {
      this.filterCompanyList(1)
      this.companyData = this.companyList.length > 0 ? this.companyList[0] : undefined
      if (!this.companyData) return
      this.recommendData = this.selectedGame.oddMap[this.companyData.value]['1']
    },
    filterCompanyList(methodType) {
      const companyList = methodType === 3 ? this.companyList2 : this.companyList1
      const list = []
      companyList.forEach(item => {
        if (this.selectedGame.oddMap&&this.selectedGame.oddMap.hasOwnProperty(item.value)) {
          list.push(item)
        }
      })
      this.companyList = list
    },
    recommendTypeChange(recommendType) {
      this.playType = 200 + this.methodType
      this.playBigType = this.methodType
      this.recommend = this.companyData.value + ',' + this.methodType + ',' + recommendType
    },
    getList() {
      gameList({ type: 0 }).then(response => {
        this.list = response.data
      })
    },
    titleInput(title) {
      if (title.length === 40) {
        Toast('已达最大输入字数')
      }
    },
    contentInput(content) {
      if (content.length === 2000) {
        Toast('已达最大输入字数')
      }
    },
    optionChange(index) {
      if (!this.selectedGame) {
        Toast('请先选择赛事')
        return
      }
      this.selectedOption = index
      this.playBigType = 0
      switch (index) {
        case 1:
          this.playType = 101
          this.recommend = this.selectedGame.spf.addBall + ',' + 0
          break
        case 2:
          this.playType = 101
          this.recommend = this.selectedGame.spf.addBall + ',' + 1
          break
        case 3:
          this.playType = 101
          this.recommend = this.selectedGame.spf.addBall + ',' + 2
          break
        case 4:
          this.playType = 100
          this.recommend = this.selectedGame.rqspf.addBall + ',' + 0
          break
        case 5:
          this.playType = 100
          this.recommend = this.selectedGame.rqspf.addBall + ',' + 1
          break
        case 6:
          this.playType = 100
          this.recommend = this.selectedGame.rqspf.addBall + ',' + 2
          break
        default:
          break
      }
    },
    commit() {
      if (this.title.length < 4) {
        Toast(this.$t('biaotizuishao'))
        return
      }
      if (this.reason.length < 20) {
        Toast(this.$t('jiexizuishao'))
        return
      }
      const params = {
        title: this.title,
        analysisText: this.reason,
        recommend: this.recommend,
        matchId: this.selectedGame.id,
        price: this.price,
        playType: this.playType,
        playBigType: this.playBigType
      }
      this.loading = true
      createAnswerKit(params).then(response => {
        Toast(this.$t('tianjiachenggong'))
        this.isChecked = true
        setTimeout(() => {
          this.$router.go(-2)
        }, 1000)
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
    },
    confirm() {
      this.isChecked = true
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.separation-line {
  width: 100%;
  height: 5px;
  @include color_separation_line()
}
.option-active {
  text-align: center;
  font-size: 12px;
  background-color: #4A90E2;
}
.option {
  text-align: center;
  font-size: 12px;
  @include color_main_card_bg_2();
}
.option-title {
  color: #9B9B9B;
  font-size: 12px;
}
.option-title-active {
  color: #fff;
  font-size: 12px;
}
.van-cell {
  color: #9B9B9B;
  @include color_main_bg();
}
.main-content {
  width: 100%;
  overflow: scroll;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 140px;
  @include color_main_bg_2();
  .title-field {
    border-radius: 6px;
    @include color_main_font();
    @include color_main_card_bg_2();
  }
}

.bottom {
  position: fixed;
  width: 100%;
  height: 80px;
  color: #fff;
  bottom: 35px;
  @include color_main_bg_2();
}
/deep/ .van-field__control {
  @include color_main_font();
}
/deep/ .van-cell::after {
  border-bottom: 0px solid #ebedf0;
}
.van-button--plain.van-button--info {
  color: #4A90E2;
  border: 1px solid #4A90E2;
}
.popup-confirmation {
  width: 48px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background-color: #4A90E2;
  border-radius: 6px;
}

.choose-botton {
  width: 88px;
  height: 25px;
  font-size: 12px;
  @include color_main_bg_2();
}

.card {
  padding: 10px 10px;
  border-radius: 6px;
  color: #fff;
  @include color_main_card_bg_2();
}
.rectangle {
  padding: 10px 10px;
  @include color_main_font();
  // @include color_main_card_bg_2();
}

.left-radius {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;;
}

.right-radius {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.right-border {
  @include color_main_right_border();
}
/deep/.cube-checker-item {
  @include color_main_bg_2();
  @include color_main_font();
}
/deep/.cube-checker-item::after {
  border-width: 2px;
  border-color: #373941;
}
/deep/.cube-checker-item_active::after {
  border-width: 2px;
  color: #ffffff!important;
  border-color: #1989fa!important;
  // background-color: #1989fa!important;
}
/deep/.van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
  border-width: 0px 0;
}
</style>
