var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "main-content" }, [
      _c(
        "div",
        { staticClass: "card", staticStyle: { "margin-top": "10px" } },
        [
          _vm.permissionStatus === 1
            ? _c(
                "router-link",
                { attrs: { to: { path: "/guessing" } } },
                [
                  _c("van-cell", {
                    attrs: {
                      border: false,
                      "title-class": "cell-title",
                      title: _vm.$t("allprophecy"),
                      "is-link": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("van-divider", {
                    style: {
                      borderColor: _vm.$theme.getDividerBorderColor(),
                      marginLeft: "16px",
                      marginRight: "19px",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.clientVersion !== "1.2.0"
            ? _c(
                "router-link",
                { attrs: { to: { path: "/kit-list" } } },
                [
                  _c("van-cell", {
                    attrs: {
                      border: false,
                      "title-class": "cell-title",
                      title: _vm.$t("planlist"),
                      "is-link": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("van-divider", {
                    style: {
                      borderColor: _vm.$theme.getDividerBorderColor(),
                      marginLeft: "16px",
                      marginRight: "19px",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "router-link",
            { attrs: { to: { path: "/income" } } },
            [
              _c("van-cell", {
                attrs: {
                  border: false,
                  "title-class": "cell-title",
                  title: _vm.$t("earnlist"),
                  "is-link": "",
                },
              }),
              _vm._v(" "),
              _c("van-divider", {
                style: {
                  borderColor: _vm.$theme.getDividerBorderColor(),
                  marginLeft: "16px",
                  marginRight: "19px",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("van-cell", {
            attrs: {
              border: false,
              "title-class": "cell-title",
              title: _vm.$t("yuyuematch"),
              "is-link": "",
            },
            on: { click: _vm.book },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _vm.bookCount === 0
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("qingxuanzesaishi")))])
                      : _c("span", { staticClass: "main-theme-font-color" }, [
                          _vm._v(
                            _vm._s(_vm.$t("reserved")) +
                              "(" +
                              _vm._s(_vm.bookCount) +
                              ")"
                          ),
                        ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("van-divider", {
            style: {
              borderColor: _vm.$theme.getDividerBorderColor(),
              marginLeft: "16px",
              marginRight: "19px",
            },
          }),
          _vm._v(" "),
          _c(
            "router-link",
            { attrs: { to: { path: "/live-setting" } } },
            [
              _c("van-cell", {
                attrs: {
                  border: false,
                  "title-class": "cell-title",
                  title: _vm.$t("liveset"),
                  "is-link": "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("van-divider", {
            style: {
              borderColor: _vm.$theme.getDividerBorderColor(),
              marginLeft: "16px",
              marginRight: "19px",
            },
          }),
          _vm._v(" "),
          _c(
            "router-link",
            { attrs: { to: { path: "/grade" } } },
            [
              _c("van-cell", {
                attrs: {
                  border: false,
                  "title-class": "cell-title",
                  title: _vm.$t("anchorlevel"),
                  "is-link": "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("van-divider", {
            style: {
              borderColor: _vm.$theme.getDividerBorderColor(),
              marginLeft: "16px",
              marginRight: "19px",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }