var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "main-content",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c(
        "van-tabs",
        {
          staticStyle: { "margin-top": "5px" },
          attrs: { swipeable: "", type: "card", color: "#63676B" },
        },
        [
          _c(
            "van-tab",
            { attrs: { title: _vm.$t("fb") } },
            [
              _c("game-selection-list", {
                staticStyle: { "margin-top": "5px" },
                attrs: { type: 1 },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "van-tab",
            { attrs: { title: _vm.$t("bk") } },
            [
              _c("game-selection-list", {
                staticStyle: { "margin-top": "5px" },
                attrs: { type: 2 },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }