import { login, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    platform: 'm-h5',
    appId: 'APP1608123719281',
    merchantId: '10021',
    clientVersion: '1.5.0',
    logining: false,
    name: '',
    avatar: '',
    user: {},
    headerInfo: {},
    liveConfig: {},
    themeType: 'default'
  },

  mutations: {
    SET_NATIVE_LOGINING: (state, logining) => {
      state.logining = logining
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_PLATFORM: (state, platform) => {
      state.platform = platform
    },
    SET_MERCHANT_ID: (state, merchantId) => {
      state.merchantId = merchantId
    },
    SET_APP_ID: (state, appId) => {
      state.appId = appId
    },
    SET_CLIENT_VERSION: (state, clientVersion) => {
      state.clientVersion = clientVersion
    },
    SET_HEADER_INFO: (state, headerInfo) => {
      state.headerInfo = headerInfo
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_LIVE_CONFIG: (state, config) => {
      state.liveConfig = config
    },
    SET_THEME_TYPE: (state, themeType) => {
      state.themeType = themeType
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          setToken(res.data.accessToken)
          commit('SET_TOKEN', res.data.accessToken)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          setUserInfo(res.data, commit)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    configureHeaders({ commit }, headerInfo) {
      return new Promise((resolve, reject) => {
        if (headerInfo.token) setToken(headerInfo.token)
        if (headerInfo.token) commit('SET_TOKEN', headerInfo.token)
        if (headerInfo.platform) commit('SET_PLATFORM', headerInfo.platform)
        if (headerInfo.merchantId) commit('SET_MERCHANT_ID', headerInfo.merchantId)
        if (headerInfo.appId) commit('SET_APP_ID', headerInfo.appId)
        if (headerInfo.clientVersion) commit('SET_CLIENT_VERSION', headerInfo.clientVersion)
        if (headerInfo) commit('SET_HEADER_INFO', headerInfo)
        resolve()
      })
    },

    updateToken({ commit }, token) {
      return new Promise((resolve, reject) => {
        if (token) setToken(token)
        if (token) commit('SET_TOKEN', token)
        commit('SET_NATIVE_LOGINING', false)
        resolve()
      })
    },

    updateLogining({ commit }, logining) {
      return new Promise((resolve, reject) => {
        commit('SET_NATIVE_LOGINING', logining)
        resolve()
      })
    },

    setLiveConfig({ commit }, liveConfig) {
      return new Promise((resolve, reject) => {
        commit('SET_LIVE_CONFIG', liveConfig)
        resolve()
      })
    },
    setThemeType({ commit }, themeType) {
      commit('SET_THEME_TYPE', themeType)
    }
  }
}

export const logOut = (commit) => {
  commit('SET_TOKEN', '')
  removeToken()
}

export const setUserInfo = (res, commit) => {
  commit('SET_USER', res)
}

export default user
