export default {
  loading: '正在刷新...',
  searchplaceholder: '主播、频道、游戏',
  dowload: '下载',
  home: '首页',
  live: '直播',
  match: '赛事',
  plan: '锦囊',
  mine: '我的',
  hotgame: '热门比赛',
  news: '资讯',
  mall: '商城',
  video: '短视频',
  activity: '活动',
  hotlive: '热门直播',
  hotanchor: '热门主播',
  highlights: '集锦',
  more: '更多',
  nolivetips: "相关主播还在热身，请您稍等片刻...",
  hit: "命中",
  return: "回报",
  wins: "连红",
  chat: '聊天',
  speak: '请输入聊天内容',
  nospeak: '登录后才可以发言哦~',
  gologin: '去登录',
  seenewnews: '看新消息',
  unlockvip: '免费解锁VIP',
  welcome: '欢迎',
  enterroom: '进入直播间',
  followed: '关注了主播',
  announcement: "公告：",
  newsinfo: '情报资讯',
  fb: '足球',
  bk: '篮球',
  notstarted: "未开始",
  checkmore: '查看详情',
  videolist: '集锦/录像',
  qianzan: '前瞻',
  jinhua: '精华',
  yulei: '娱乐',
  recommendplan: '锦囊推荐',
  winrate: '推荐准确率',
  zuigaolianghong: '最高{msg}连红',
  lianghong: '连红',
  returnrate: '回报率',
  buzhong: '不中返还',
  xieyi: '协议相关',
  personal: '隐私协议',
  userxieyi: '用户协议',
  livexieyi: '直播协议',
  chargexieyi: '充值协议',
  anchorhelp: '主播帮助',
  anchornew: '主播新手上路',
  experts: '精选专家',
  followus: '关注我们',
  helpcenter: '帮助中心',
  scancodedowload: '扫码即可下载山猫APP',
  smzb: '山猫直播',
  noduty: '免责声明：山猫直播所有足球直播和NBA直播信号均由用户收集或从搜索引擎搜索整理获得，所有内容均来自互联网，我们自身不提供任何直播信号和视频内容，如有侵犯您的权益请通知我们，我们会第一时间处理。',
  roomsearch: '请输入关键词',
  myfollow: '我的关注',
  clearcache: '清除缓存',
  kinds: '分类',
  all: '全部',
  others: '其他',
  yingchao: '英超',
  hot: '热门',
  esports: '电竞',
  exit: '退出',
  downloadApp: '下载APP',
  want: '去直播',
  roomnum: '房间号',
  wang: '万',
  feedback: '反馈',
  funs: '粉丝',
  follows: '+关注',
  numberonlive: '位主播正在直播本场赛事',
  anchorchat: '主播聊天',
  contributerank: '贡献榜',
  othermatch: '其他比赛',
  chatinput: '一起聊天一起high',
  send: '发送',
  livefail: '直播流获取失败',
  tryagain: '点击重试',
  pushlist: '推单列表',
  reward: '奖励说明 ',
  nopush: '暂无推单',
  anchorpush: '主播推单',
  maobipokect: '猫币红包',
  sendsuccess: '发送成功可触发全平台飘屏广播',
  manexchange: '人可领',
  threeminexchang: '3分钟后可领',
  exchangenow: '立即可领',
  sendpokect: '发红包',
  pokecttips: '我在直播间发了个红包哟～立即就可以领取啦！',
  pokecttips1: '我在直播间发了个红包哟～3分钟后就可以领取啦！',
  task: '任务',
  litterhelper: '小助手',
  litterhelper1: '优惠小助手',
  enter: '点击进入',
  gift: '赠送',
  high: '高清',
  charge: '充值',
  balance: '余额',
  words: '弹',
  sendonepokect: '发了一个红包',
  exchange: '领取',
  ofpokect: '的红包',
  nopokect: '手慢了，红包派完了',
  maobi: '猫币',
  savemyaccount: '已存入我的账户',
  checkexchange: '查看领取详情',
  exchangelist: '红包领取记录',
  pokecttips2: '的猫币红包里抢到了x个猫币！',
  contribute: '日贡献',
  contribute1: '周贡献',
  contribute2: '贡献总榜',
  contributevalue: '贡献值',
  living: '直播中',
  noliving: '暂无开播的主播',
  matchstatus: '赛况',
  zhishu: '指数',
  livematchstatus: '实时赛况',
  jinqiu: '进球',
  zugong: '助攻',
  shemeng: '射门',
  dianqiu: '点球',
  wulongqiu: '乌龙球',
  huangpai: '黄牌',
  nodianqiu: '点球未进',
  huanxia: '换下',
  nomatchevent: '暂无比赛事件',
  onlyjinqiu: '只看进球',
  analysis: '技术分析',
  contrrate: '控球率',
  shezheng: '射正',
  jingong: '进攻',
  dendrgjingong: '危险进攻',
  renyiqiu: '任意球',
  jiaoqiu: '角球',
  hongpai: '红牌',
  login: '登录',
  followed: '已关注',
  roommannage: '任命房管',
  roomtips: '是否确定将 十五 任命为房管?',
  roomtips1: '是否确定将 十五 解任房管?',
  cancel: '取消',
  confirm: '确定',
  logout: '退出登录',
  uplevel: '距离升级：{msg}经验值',
  wallt: '钱包',
  wantlive: '我要直播',
  usercenter: '个人中心',
  vipright: 'VIP特权介绍',
  pwdlogin: '密码登录',
  mobilelogin: '手机登录',
  doregister1: '没有账号',
  doregister2: '立即注册',
  forgetpwd: '忘记密码？',
  logintips: '登录即表示同意',
  inputmobile: '请输入手机号',
  inputcode: '请输入验证码',
  mobiletips: '请输入6-20位数字密码、字母密码',
  resetpwd: '重置密码',
  register: '注册',
  sendcode: '发送验证码',
  registernow: '立即注册',
  appdownload: 'APP下载',
  advice: '意见反馈',
  backtop: '返回顶部',
  intime: '即时',
  anchor: '主播',
  matchschude: '赛程',
  matchresult: '赛果',
  checkdata: '查看数据',
  nomatch: '暂无赛事',
  winlist: '命中榜',
  returnlist: '回报榜',
  lianghongrank: '连红榜',
  hotrace: '热门赛事',
  allrace: '全部赛事',
  onrace: '比赛中',
  onsale: '篇在售方案',
  recommendmaster: '推荐专家',
  mime: '本人',
  recommendplan: '推荐方案',
  buyedplan: '已购方案',
  sorttype: '综合排序',
  returnfirst: '回报优先',
  winfirst: '胜率优先',
  lianghongfirst: '连红优先',
  nodata: '暂无数据',
  detail: '详情',
  newslocal: '当前位置：首页/资讯',
  comments: '条评论',
  nocomments: '暂无评论',
  docomments: '评论',
  typecomments: '说点什么吧',
  goods: '商品',
  searchgoods: '搜索喜欢的商品',
  mycarts: '我的购物车',
  myorders: '我的订单',
  demens: '钻石',
  goodsinfo: '商品信息',
  kucun: '库存',
  limit: '限制兑换',
  value: '单价',
  num: '数量',
  total: '小计',
  choice: '已选择',
  jiesuan: '结算',
  chooseall: '全选',
  limitnum: '累计限购',
  limitday: '日限购',
  limitweek: '周限购',
  totallimt: '累计兑换:',
  totallimt1: '每日累计兑换:',
  totallimt2: '每周累计兑换:',
  jiage: '价格',
  goodsdetail: '商品详情',
  buynow: '立即兑换',
  addcart: '加入购物车',
  recommendgoods: '为你推荐',
  confirmbuy: '确认兑换',
  mobile: '手机号',
  reciptename: '收货人名字',
  address: '收货地址',
  remark: '备注',
  inputreciptename: '请输入收货人名字',
  inputaddress: '请输入收货地址',
  orders: '单号',
  time: '时间',
  recipte: '收货人',
  telphone: '电话',
  address1: '地址',
  recovideo: '推荐视频',
  onplay: '正常播放...',
  ongoing: '进行中',
  result: '已结束',
  reserve: '预约',
  reserved: '我的预约',
  myinfo: '我的资料',
  anchorlevel: '主播等级',
  userlevel: '用户等级',
  changeavter: '修改头像',
  changeinfo: '修改资料',
  usertips: '(图片大小不得超过1M，支持png，jpg格式)',
  uploadimg: '上传本地图片',
  myaccount: '我的账户',
  anchorinfo: '主播相关',
  tasks: '任务中心',
  signin: '签到有礼',
  mywords: '我的预言',
  downloadreword: '下载邀请奖励',
  nickname: '昵称',
  inputnickname: '请输入昵称',
  infotips: `说明：
  1. 新昵称需符合注册规范，可使用数字，字母，汉字，符号，最长长度不超过24个字符（中文占2个字符，英文占1个字符）。
  2. 如遇服务器更新或其它问题导致昵称修改失败，请联系客服。`,
  introduce: '个人简介',
  region: '地区',
  selectprovince: '请选择省',
  selectregion: '请选择市',
  sex: '性别',
  savesecrt: '保密',
  man: '男',
  woman: '女',
  bedate: '生日',
  selectdate: '请选择年份',
  selectmonth: '请选择月份',
  select: '请选择',
  year: '年',
  month: '月',
  day: '日',
  save: '保存',
  accountvalue: '账户余额',
  billlist: '账单记录',
  cast: '消费',
  earnings: '收益',
  casttype: '消费类型',
  sendgift: '送礼',
  prophecy: '预言',
  redpokect: '红包',
  exchangegoods: '兑换礼品',
  luckydraw: '付费抽奖',
  querytime: '查询时间',
  starttime: '开始日期',
  endtime: '结束日期',
  query: '查询',
  reset: '重置',
  castmaobi: '消费金额',
  allprophecy: '全民预言',
  prophecylist: '预言大厅',
  planlist: '解球锦囊',
  yuyuematch: '预约赛事',
  earnlist: '收益记录',
  liveset: '直播设置',
  roompush: '直播间推单',
  roomset: '直播间字母设置',
  // new
  edit: '编辑',
  beAnchor: '成为主播',
  inviterUser: '邀请好友',
  setting: '设置',
  logining: '登录中...',
  loginerr: '登录失败,请稍后再试...',
  loginsecucced: '登录成功',
  codesend: '验证码发送成功',
  inputrightphone: '请输入正确的手机号',
  inputagain: '请再次输入密码',
  pwdtips: '两次输入的密码不一致',
  pwdtips1: '密码格式错误，请输入6-20位数字密码、字母密码',
  registed: '注册成功',
  registerfail: "注册失败,请稍后再试...",
  registing: '注册中...',
  findpwd: '找回密码',
  submit: '提交',
  setingPwd: '密码设置成功',
  submitting: '提交中...',
  nomore: '没有更多了',
  pullrefresh: '下拉刷新',
  notstart: '该场未开始，您可观看其他比赛：',
  newsDe: '资讯详情',
  shouqi: '收起',
  fabu: '发布',
  'eur': '欧赔',
  "hk": '亚指',
  "bm": '大小球',
  'rq': '让球胜平负',
  'mzl': '命中率',
  'mago': '分钟前',
  'sago': '秒前',
  'hago': '小时前',
  'mianfei': '免费',
  'tacticde': '方案详情',
  'zhusheng': '主胜',
  "pingju": '平局',
  "kesheng": '客胜',
  'pay': '立即支付',
  'stop': '已停止售卖',
  'reason': '推荐理由',
  'afterpay': '支付后查看方案及解析',
  'stoptime': '距离售卖截止时间：',
  'tacticdetips': '注：观点仅供参考,且不提供购彩业务，请您谨慎关注！',
  'dago': '天前',
  'wago': '周前',
  'mouthago': '个月前',
  'ganggang': '刚刚',
  'bigqiu': '大于{msg}球',
  'smallqiu': '小于{msg}球',
  'buyplan': '购买专家方案',
  'buyplantips': '购买此方案需要消耗{num}猫币,确认够购买吗？',
  'yesbuy': '确定购买',
  'cancelfollow': '取消关注成功',
  'followsucceed': '关注成功',
  'buysucceed': '购买成功',
  'buyfialed': '购买失败',
  'liangsai': '联赛',
  'team': '主队vs客队',
  'playtype': '玩法',
  'rate': '推荐(赔率)',
  'chongzhijilu': '充值记录',
  'xiaofeijilu': '消费记录',
  'huodongshouyi': '活动收益',
  'myinvitecode': '我的邀请码',
  'inputcodeinapp': '请在App登入时输入',
  'dianjifuzhi': '点击复制邀请码',
  'jietu': '截图传给你的好友',
  'shoukuang': '收款方式',
  'alipay': '支付宝',
  'wechat': '微信',
  'shoukuangzhanghao': '收款账号',
  'invitegonglue': '邀请攻略',
  'shareqrcode': '分享下载二维码及专属邀请码',
  'getjiangli': '好友首次登入成功您将获得奖励',
  'invitemingxi': '邀请明细',
  'zhanwuhaoyou': '暂无邀请好友，快去邀请好友吧！',
  'nicheng': '昵称',
  'zucengshijian': '注册时间',
  'jiangli': '奖励',
  'islogout': '确定退出登录？',
  'dijilun': '第{num}轮',
  'guangyuus': '关于山猫直播',
  'zhuanjiajieshao': '专家介绍',
  'recent10': '近10场回报率',
  'zuijinzhuantai': '近期状态',
  'zhengzaituijian': '正在推荐',
  'history': '历史推荐',
  'bisaishijian': '比赛事件',
  'qiuduitongji': '球队统计',
  'qiudui': '球队',
  'zongfen': '总分',
  'bisaikaishi': '比赛开始',
  'fangui': '犯规',
  'erfen': '二分',
  'faqiu': '罚球',
  'sanfen': '三分',
  'paizhao': '拍照',
  'xuanzezhaopian': '从相册中选择',
  'jianyanzhi': '经验值',
  'yiwangcheng': '已完成',
  'dangqian': '当前',
  'shengjidao': '升级到',
  'xuyao': '需要',
  'cehngzhang': '成长特权',
  'dengji': '等级图标',
  'kaiqi': '开启',
  'qidai': '敬请期待',
  'mashanglai': '更多特权马上来袭',
  'qiandao': '每日签到',
  'qiandaochenggong': '签到成功',
  'qiandaotips': '温馨提示：彩金和实物奖励请联系小助手申请发放。',
  'chakanqiandao': '查看奖励',
  'goxiaozhushou': '联系小助手',
  'dijitian': '第{msg}天',
  'zhubodasan': '主播打赏榜',
  'riban': '日榜',
  'yueban': '月榜',
  'zongban': '总榜',
  'gongxianzhi': '贡献值',
  'fananjieshu': '方案售卖已结束',
  'bisaijieshu': '比赛结束后公布解析详情',
  'yuebuzu': '余额不足',
  'qitajinnang': '该专家其它锦囊',
  'qitatuijian': '其它推荐锦囊',
  'dengluhou': '后才可以看到已购记录哦',
  'zhengzaibofng': '正在播放',
  'jijiangbofang': '即将播放',
  'touxiangshangchuan': '头像上传',
  'shouyeleixing': '收益类型',
  'lingquhongbao': '领取红包',
  'hongbaotuikuang': '红包退还',
  'jinnantutikuang': '锦囊退款',
  'yuyanshouyi': '预言收益',
  'yuyantuikuang': '预言退款',
  'hongbaoyu': '红包雨',
  'jincaidati': '竞猜答题',
  'zhuanpan': '转盘抽奖',
  'jindan': '砸金蛋',
  'zenfa': '系统增发',
  'dengluhuodong': '登录活动',
  'guankan': '观看直播',
  'danmu': '发送弹幕',
  'denglujianli': 'APP登陆奖励',
  'tuidan': '推单奖励',
  'zhuanpanchoujian': '新转盘抽奖',
  'zhuti': '主题',
  'xuanxian': '选项',
  'kejitouzhu': '累计投注',
  'xuanxianbeishu': '选项倍数',
  'touzhurenshu': '投注人数',
  'jieguo': '结果',
  'zhuantai': '状态',
  'daojishi': '倒计时',
  'fabuplantips': '发布解球锦囊命中后，赚取海量收益哦～',
  'goumairenshu': '购买人数',
  'zongji': '总计',
  'fencheng': '分成前',
  'liveshouyi': '直播可结算收益',
  'jinnanshouyi': '锦囊可结算收益',
  'jiesuanrules': '结算规则',
  'tequan': '等级特权',
  'kuaisushengji': '如何快速升级',
  'wodedengji': '我的等级',
  'dengjishuoming': '等级说明',
  'zhubotequan': '主播特权',
  'shejizhong': '主播特权正在设计中',
  'moretequan': '更多特权马上来袭',
  'huodeliwu': '获得礼物',
  'qingfenzhibo': '勤奋直播',
  'zensongliwu': '赠送礼物',
  'yonghutequan': '用户特权',
  'tequansheji': '用户特权正在设计中',
  'fengzhong': '分钟',
  'jiyang': '经验',
  'quedingquxiao': '您确定要取消关注吗？',
  'zhengquetianxie': '请填写正确资讯，未填写将不会收到任何奖励',
  'qingshuruzhanghao': '请输入账号',
  'yaoqingshuoming': '邀请说明',
  'zaiciquanren': '请再次确认{msg}账号：{msg1}',
  'qingshuruzhanghao': '请输入{msg}账号',
  'daishenhe': '待审核',
  'yipaijiang': '已派奖',
  'weitongguo': '未通过',
  'leijizhong': '累计中',
  'tishi': '提示',
  'tijiaochengong': '提交成功！',
  'zonghe': '综合',
  'yituichi': '已推迟',
  'yiquxiao': '已取消',
  'jitian': '今天',
  'mintian': '明天',
  'houtian': '后天',
  'zuotian': '昨天',
  'xiazaitixing': '预约成功',
  'quxiaoyuyue': '取消预约成功',
  'zhoutian': '周日',
  'zhouyi': '周一',
  'zhouer': '周二',
  'zhousan': '周三',
  'zhousi': '周四',
  'zhouwu': '周五',
  'zhouliu': '周六',
  'jianshu': '件',
  'meiri': '每日',
  'meizhou': '每周',
  'duihuanchenggong': '兑换成功',
  'zuiduoduihuan': '最多兑换{msg}件',
  'caozuo': '操作',
  'zongjia': '总价',
  'shanchu': '删除',
  'now': '刚刚',
  'yituikuan': '已退款',
  'goumai': '购买',
  'xuanzezhibo': '选择直播频道',
  'erjipingdao': '二级频道',
  'zhibobiaoti': '直播标题',
  'shezhibiaoti': '请设置直播标题',
  'guanliansaishi': '关联赛事',
  'xuanzhiguanlian': '请关联赛事',
  'xuanze': '选择',
  'yichu': '移除',
  'zhibofenmian': '直播封面',
  'yishenhe': '已审核',
  'shenhezhong': '审核中',
  'yijujue': '已拒绝',
  'zhibijieshu': '结束直播',
  'kaishizhibo': '开始直播',
  'tuiliudizhi': '推流地址（服务器地址）',
  'fuzhi': '复制',
  'tuiliumiyao': '推流码（串流秘钥)',
  'laliudizhi': '拉流地址',
  'zhiboshuoming': '直播说明',
  'zhibotips1': ` 
  1.设置基础信息：选择频道、设置标题、关联赛事、直播封面，根据需要进行设定;<br />
  2.点击“开始直播”按钮后；复制推流地址及推流码；<br />
  3.粘贴推流地址、推流码至OBS软件对应的“服务器及串流秘钥”，并进行开始直播；<br />`,
  'zhibotips2': `          
  1.点击开始直播后，请在1分钟内复制推流地址及推流码粘贴至OBS并进行推流，否则可能将作为异常系统将直播间下播；<br />
  2.结束直播后，关闭OBS后，需点击上方结束直播按钮，将直播间下播。<br />`,
  'yiyuyue': '已预约赛事',
  'biaotitips': '标题不能为空！',
  'yuliang': '预览',
  'zhuyishixian': '注意事项',
  'xinjian': '新建推单',
  'xuanzesaishi': '选择赛事',
  'xuanzesaiduan': '选择赛段',
  'xuanzewf': '选择玩法',
  'jieguotips': '请输入结果,最多25字',
  'tuidanlishi': '历史推单',
  'renmai': '{msg}人 跟单',
  'renfanmai': '{msg}人 反买',
  'qingxuanzesaishi': '请选择赛事',
  'qingsaiduan': '请选择赛段',
  'qingwf': '请选择玩法',
  'qingjieguo': '请输入结果',
  'tuidangtips': '本场推单提交后不可更改，确认提交吗？',
  'fabutuidan': '发布推单',
  'chuangjian': '创建成功',
  'yijianfabu': '一键发布',
  'zimushezhi': '直播字幕设置',
  'zhimutips': '非必填，开播后还可继续编辑',
  'dingbu': '顶部展示',
  'shangshi': '展示字幕',
  'zimutips': '请输入直播界面字幕,最大50个字',
  'xuanzepeise': '选择配色：',
  'dibu': '底部展示',
  'shanchutips': '提示：如需要删除，请点击重置即可',
  'yuliangzhuangshi': '预览展示',
  'shoujiduan': '手机端预览',
  'diannaoduan': '电脑端预览',
  'resettips': '确定要重置吗？重置后前端已生效的字幕会自动删除。',
  'baocuntips': '确定要保存吗？保存后会更新前端字幕并展示。',
  'caozuochenggongg': '操作成功',
  'dangqianjy': '当前经验值',
  'jinrihuode': '今日获得',
  'guizeshuo': '规则说明',
  'dangqian': ' 当前LV.{msg}',
  'shenjilv': '升级到LV.{msg}还需{msg1}经验值',
  'dachenglv': ' 达成V{msg}共需{msg1}经验值',
  'shenjidaolv': ' 升级到V{msg}共需{msg1}经验值',
  'xinshourenwu': '新手任务',
  'meirirenwu': '每日任务',
  'jieji': '阶级',
  'dengjit': '等级',
  'tubiao': '图标',
  'gongxihuode': '恭喜获得',
  'weiwangcheng': '未完成',
  'jianlilist': '奖励明细',
  'renwuleixin': '任务类型',
  'renwujianli': '任务奖励',
  'paifazhuangt': '派发状态',
  'huodeshijian': '获得时间',
  'weipaifa': '未派发',
  'yipaifa': '已派发',
  'yuyanzhuti': '预言主题',
  'cangyuxiang': '参与项',
  'beishu': '倍数',
  'touru': '投入',
  'yingkui': '盈亏',
  'yuyanshijian': '预言时间',
  'zhubogonggao': '主播公告',
  'zuiduiyuyue': '最多同时预约20场赛事',
  'yituihuan': '已退还',
  'qiuduimingcheng': '请输入球队名称',
  'sousuoqiudui': '搜索',
  'chongxinxuanze': '重新选择',
  'fangfaleixing': '方法类型',
  xuanzegongshi: '公司',
  jinnangbiaoti: '方案标题',
  jinnangbiaoti: '请输入标题（4字以上）',
  jinnangjiexibia: '请输入解析（不得少于20字哦～）',
  tuichutips: '退出后内容无法保存，确定要退出吗？',
  biaotizuishao: '标题最少4个字',
  jiexizuishao: '解析最少20个字',
  tianjiachenggong: '添加成功',
  // 新增
  "flow_bureau": '（流局)',
  "participation": '人参与',
  "money": '金额',
  "hint1": '还没发起预言',
  "hint2": '发起预言给用户带来更多快乐',
  "issuing_prophecy": '发布预言',
  "add_theme": '添加主题',
  "prophetic_record": '预言记录',
  // 新增
  "hint3": '您最多可同时发起5个主题',
  "selected": '已选 ',
  "Entries": '个 ',
  "above_to_add": '请点击上方“添加主题”添加 ',
  "hint5": '还没添加预言主题 ',
  "hint4": '确定删除此条预言主题吗? ',
  "hint6": '主题库达到上限，请先删除部分 ',
  "select_theme": '选择主题 ',
  "have_deleted": '已删除 ',
  "hint7": '您还未开播，请先开启直播',
  "End_of_batch": '批量结束',
  "submit_the_results": '提交结果',
  "hint8": '说明：请谨慎提交结果，若用户多次投诉，核实投诉属实，将收回预言权限及作出对应惩罚。',
  "hint9": '如此局为流局，则此局无效，将返还全部用户参与金额。',
  "submit1": '提交',
  "confirming_the_prophecy": '确认预言结果',
  // 新增
  "title_options": '标题及选项',
  "hint11": '预言标题（限15个字）',
  "hint12": '选项A（限5个字）',
  "hint13": '选项B（限5个字）',
  "stop_time": '停止时间',
  "hint14": '（仅针对主播预言模式有效）',
  "save1": '保存',
  "5minute": '5分钟',
  "10minute": '10分钟',
  "30minute": '30分钟',
  "45minute": '45分钟',
  "110minute": '110分钟',
  "editing_subject": '编辑主题',
  "add_subject": '添加主题',
  // 新增
  "surplus": '剩余',
  "Victory": '胜',
  "Defeated": '败',
  "Flow": '流',
  "Waiting_announcement": '等待公布',
  "Doubling": '倍',
  "Stop": '停止',
  "End": '结束',
  "stopped": '已停止',
  "hint15": '停止后将无法参与和预言，是否确定停止预言',
  'xuanzepingdao':'请选择频道',
  'xuanzesaishi':'请选择赛事',
  'jiamifanjianshezhi':'加密房设置',
  'paomadeng':'跑马灯设置',
  'paomadengkaiguan':'跑马灯开关',
  'qingshurupaomadengneirong':'请输入跑马灯内容',
  'dangqianzhibopingdao':'当前直播频道',
  'querenbtn':'确认',
  'dingdaobianhao':'订单编号',
  'chongzhijine':'充值金额',
  'qingxuanxuanzepingdao':'请先选择频道',
  'kaisaishijian':'开赛时间需间隔1.5小时',
  'fanjianmima':'房间密码',
  'jiamifanjianshuoming':'加密房说明：',
  'jiami1':'1.密码请设置四位数字密码',
  'jiami2':'2.加密房的密码设置成功后，将会在下一场直播开播后生效，开播后不能取消',
  'jiami3':'3.加密房只对一场直播生效，下播后限制自动解除',
  'chakanguize':'查看结算规则',
  'kefuhao':'客服微信号',
  'zhiboshouyi':'直播本月收益',
  'jinnanshouyi':'锦囊本月收益'
}