var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "main-content",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c(
        "van-row",
        {
          staticStyle: { "margin-top": "0px" },
          attrs: { type: "flex", align: "center" },
        },
        [
          _c(
            "van-col",
            { staticStyle: { "padding-left": "10px" }, attrs: { span: "20" } },
            [
              _c("cube-input", {
                staticClass: "search-input",
                attrs: {
                  clearable: true,
                  placeholder: _vm.$t("qiuduimingcheng"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function () {
                      return [
                        _c("img", {
                          staticStyle: {
                            width: "14px",
                            height: "14px",
                            "margin-left": "10px",
                          },
                          attrs: {
                            src: require("@/assets/images/live-setting/search_icon.png"),
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.keywords,
                  callback: function ($$v) {
                    _vm.keywords = $$v
                  },
                  expression: "keywords",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "van-col",
            { staticStyle: { "text-align": "center" }, attrs: { span: "4" } },
            [
              _c(
                "span",
                {
                  staticClass: "main-theme-font-color",
                  on: { click: _vm.handleSearch },
                },
                [_vm._v(_vm._s(_vm.$t("sousuoqiudui")))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "_main-content-list",
          staticStyle: { "margin-top": "15px" },
        },
        [
          _vm.list.length > 0
            ? _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": _vm.finishedText,
                  },
                  on: { load: _vm.loadList },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    { key: index, attrs: { title: item } },
                    [
                      _c(
                        "van-row",
                        {
                          staticClass: "main-theme-font-color",
                          staticStyle: {
                            "margin-left": "20px",
                            "margin-right": "20px",
                          },
                          attrs: {
                            type: "flex",
                            align: "center",
                            justify: "space-between",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.rowClicked(item)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "line-height": "22px",
                                "font-size": "13px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(item.eventName) +
                                  " | " +
                                  _vm._s(item.matchTime.substr(5, 2)) +
                                  "/" +
                                  _vm._s(item.matchTime.substr(8, 2)) +
                                  " " +
                                  _vm._s(item.matchTime.substr(10, 6)) +
                                  " " +
                                  _vm._s(item.homeName) +
                                  "VS" +
                                  _vm._s(item.awayName)
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("van-divider", {
                        style: {
                          borderColor: _vm.$theme.getDividerBorderColor(),
                          marginLeft: "16px",
                          marginRight: "19px",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "van-empty",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading && _vm.list.length === 0,
                  expression: "!loading && list.length === 0",
                },
              ],
              staticClass: "empty-image",
              staticStyle: { "margin-top": "50%" },
              attrs: { description: _vm.$t("nodata") },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { width: "100%", "text-align": "center" },
                  attrs: { slot: "image" },
                  slot: "image",
                },
                [
                  _c("img", {
                    staticStyle: { width: "257px", height: "84px" },
                    attrs: { src: require("@/assets/images/empty.png") },
                  }),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }