<template>
  <div class="app-container">
    <div class="main-content">
      <div class="card" style="margin-top: 10px;">
        <router-link v-if="permissionStatus === 1" :to="{ path: '/guessing'}">
          <van-cell :border="false" title-class="cell-title" :title="$t('allprophecy')" is-link />
          <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
        </router-link>
        <router-link v-if=" clientVersion !== '1.2.0'" :to="{ path: '/kit-list'}">
          <van-cell :border="false" title-class="cell-title" :title="$t('planlist')" is-link />
          <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
        </router-link>
        <router-link :to="{ path: '/income'}">
          <van-cell :border="false" title-class="cell-title" :title="$t('earnlist')" is-link />
          <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
        </router-link>
        <van-cell :border="false" title-class="cell-title" :title="$t('yuyuematch')" is-link @click="book">
          <template #default>
            <span v-if="bookCount === 0">{{ $t('qingxuanzesaishi') }}</span>
            <span v-else class="main-theme-font-color">{{$t('reserved')}}({{ bookCount }})</span>
          </template>
        </van-cell>
        <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
        <router-link :to="{ path: '/live-setting'}">
          <van-cell :border="false" title-class="cell-title" :title="$t('liveset')" is-link />
        </router-link>
        <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
        <router-link :to="{ path: '/grade'}">
          <van-cell :border="false" title-class="cell-title" :title="$t('anchorlevel')" is-link />
        </router-link>
        <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
      </div>
    </div>
  </div>
</template>

<script>
import { CellGroup, Cell, Row, Col, Divider } from 'vant'
import { guessingPermissionStatus, kitPermissionStatus } from '@/api/anchor'
import { fetchRecord } from '@/api/live-setting/index'

export default {
  name: 'Anchor',
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider
  },
  data() {
    return {
      income: 0,
      permissionStatus: 0,
      kitPermissionStatus: 0,
      bookCount: 0,
      nativeData: undefined
    }
  },
  computed: {
    clientVersion() {
      return this.$store.getters.clientVersion
    }
  },
  created() {
    // console.log(this.$i18n.locale)
    this.setTitle()
    guessingPermissionStatus({}).then(response => {
      this.permissionStatus = response.data.status
    })
    kitPermissionStatus({}).then(response => {
      this.kitPermissionStatus = response.data.status
    })
    fetchRecord({ }).then(response => {
      this.bookCount = response.data.bookCount
    })
  },
  methods: {
    setTitle() {
      document.title = this.$t('anchorinfo')
    },
    book() {
      this.$router.push({
        path: '/appointment-game-list'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.van-cell--clickable:active {
  @include color_main_card_bg();
}
.van-cell {
  @include color_main_font();
  @include color_main_card_bg();
}
.cell-title {
    font-size: 14px;
}
.cell-value {
  font-size:14px;
  font-family:PingFangSC-Regular,PingFang SC;
  font-weight:400;
  color:rgba(74,144,226,1);
}
.weixin {
  font-size: 14px;
  color:rgba(249,249,249,1);
}
.main-content {
  padding-top: 16px;
  margin-left: 15px;
  margin-right: 15px;
}
.feather {
  width: 24px;
  height: 24px;
}
.amount {
  width:51px;
  height:18px;
  font-size:18px;
  font-family:PingFangSC-Semibold,PingFang SC;
  font-weight:600;
  color:rgba(240,207,44,1);
  line-height:18px;
  margin-left: 4px;
}

</style>
