<template>
  <div class="app-container" :data-theme="$store.getters.themeType">
    <van-sticky :offset-top="0">
      <div style="padding-top: 10px" class="sticky">
        <van-tabs
          v-model="activeIndex"
          swipeable
          type="card"
          color="#63676B"
          title-inactive-color="#9B9B9B"
          sticky
        >
          <van-tab :title="$t('fb')" />
          <van-tab :title="$t('bk')" />
          <!-- <van-tab title="网球" /> -->
          <van-tab :title="$t('reserved')" />
        </van-tabs>
        <van-row
          v-show="activeTabTitle !== $t('reserved')"
          type="flex"
          align="center"
          style="margin-top: 0px; margin-right: 10px; flex: none"
        >
          <img
            style="float: left; width: 16px; height: 16px; margin-left: 20px"
            src="@/assets/images/live-setting/search_icon.png"
            @click="search"
          />
          <van-tabs
            v-model="dateActiveIndex"
            :color="$theme.getTabsUnderlineColor()"
            title-inactive-color="#9B9B9B"
            title-active-color="#fff"
            line-width="22px"
            style="display: block"
          >
            <van-tab
              v-for="(item, index) in tabs"
              :key="index"
              :title="item.name"
            />
          </van-tabs>
        </van-row>
      </div>
    </van-sticky>
    <div v-show="activeTabTitle !== $t('reserved')">
      <div class="main-content">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="getList"
        >
          <van-checkbox-group v-model="checkedList">
            <van-cell-group>
              <div
                v-for="(item, index) in list"
                :key="index"
                style="margin-bottom: 10px"
              >
                <van-cell clickable @click="cellClicked(item, index)">
                  <div class="card">
                    <van-row type="flex" justify="space-between" align="center">
                      <div style="max-width: 80%" class="main-theme-font-color">
                        {{ item.eventName }}
                        <span v-show="item.roundId !== '0'">
                          {{ $t('dijilun', { num: item.roundId }) }}
                        </span>
                        {{ item.matchTime }}
                      </div>
                      <van-checkbox
                        v-show="item.isBook !== 1"
                        ref="checkboxes"
                        :name="item"
                      />
                      <van-icon
                        v-show="item.isBook === 1"
                        name="checked"
                        size="22px"
                        color="#3D679A"
                      />
                    </van-row>
                    <van-row
                      type="flex"
                      align="center"
                      style="margin-top: 20px"
                    >
                      <img
                        v-if="item.homeLogo && item.homeLogo !== ''"
                        style="
                          width: 26px;
                          height: 26px;
                          border-radius: 13px;
                          border: 1px solid rgba(255, 255, 255, 1);
                          margin-right: 10px;
                        "
                        :src="item.homeLogo"
                      />
                      <img
                        v-if="!item.homeLogo || item.homeLogo === ''"
                        style="
                          width: 26px;
                          height: 26px;
                          border-radius: 13px;
                          border: 1px solid rgba(255, 255, 255, 1);
                          margin-right: 10px;
                        "
                        src="@/assets/images/team_logo_default.png"
                      />
                      <span
                        style="font-size: 12px"
                        class="main-theme-font-color"
                      >
                        {{ item.homeName }}
                      </span>
                      <img
                        style="
                          width: 22px;
                          height: 25px;
                          margin-left: 20px;
                          margin-right: 20px;
                        "
                        :src="$theme.getVsImage()"
                      />
                      <span
                        style="font-size: 12px"
                        class="main-theme-font-color"
                      >
                        {{ item.awayName }}
                      </span>
                      <img
                        v-if="item.awayLogo && item.awayLogo !== ''"
                        style="
                          width: 26px;
                          height: 26px;
                          border-radius: 13px;
                          border: 1px solid rgba(255, 255, 255, 1);
                          margin-left: 10px;
                          margin-right: 10px;
                        "
                        :src="item.awayLogo"
                      />
                      <img
                        v-if="!item.awayLogo || item.awayLogo === ''"
                        style="
                          width: 26px;
                          height: 26px;
                          border-radius: 13px;
                          border: 1px solid rgba(255, 255, 255, 1);
                          margin-left: 10px;
                          margin-right: 10px;
                        "
                        src="@/assets/images/team_logo_default.png"
                      />
                    </van-row>
                  </div>
                </van-cell>
              </div>
            </van-cell-group>
          </van-checkbox-group>
        </van-list>
        <van-empty
          v-if="!loading && list.length === 0"
          class="empty-image"
          style="margin-top: 40%"
        >
          <div slot="image" style="width: 100%; text-align: center">
            <img
              style="width: 257px; height: 84px"
              src="@/assets/images/empty.png"
            />
          </div>
          <div slot="description" style="text-align: center">
            <div>{{ $t('nomatch') }}</div>
          </div>
        </van-empty>
      </div>
      <div class="bottom">
        <div class="row" style="padding-top: 10px">
          <span>
            {{ $t('zuiduiyuyue') }}（{{
              // $t('choice') + checkedList.length + bookCount
              $t('choice') + checkedList.length
            }})
          </span>
        </div>
        <div class="row" style="padding-top: 10px">
          <van-button
            class="button-primary"
            style="width: 80%; border-radius: 6px"
            :disabled="checkedList.length === 0"
            @click="commit"
          >
            {{ $t('confirm') }}
          </van-button>
        </div>
      </div>
    </div>
    <my-appointment-list
      v-show="activeTabTitle === $t('reserved')"
      ref="myAppointment"
    />
  </div>
</template>

<script>
import { Sticky, Row, Button, Col, Checkbox, CheckboxGroup, CellGroup, Divider, Tab, Tabs, List, Empty, Cell, Toast, Icon } from 'vant'
import MyAppointmentList from './components/MyAppointmentList'
import { fetchAppointmentList, book, fetchMyAppointmentList, checkBooking } from '@/api/live-setting'

export default {
  name: 'AppointmentGameList',
  components: {
    MyAppointmentList,
    [List.name]: List,
    [Sticky.name]: Sticky,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [Empty.name]: Empty,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [Icon.name]: Icon
  },
  data() {
    return {
      dataType: 1, // 1：足球 2：篮球 99：其他
      activeTabTitle: this.$t('fb'),
      selectedDate: undefined,
      list: [],
      checkedList: [],
      tabs: [],
      loading: false,
      finished: false,
      finishedText: this.$t('nomore'),
      pageNum: 1,
      defaultLogo: '@/assets/images/team_logo_default.png',
      bookCount: 0,
      myAppointmentList: [],
      checkedAndBookedList: [],
      activeIndex: 0,
      dateActiveIndex: 0
    }
  },
  computed: {
    maxBookCount() {
      return 20 - this.bookCount
    }
  },
  watch: {
    checkedList(val, oldVal) {
      this.checkedAndBookedList = this.myAppointmentList.concat(val)
    },
    activeIndex(val, oldVal) {
      if (val === 0) {
        this.activeTabTitle = this.$t('fb')
        this.dataType = 1
        this.pageNum = 1
        this.finished = false
        this.list = []
        this.checkedList = []
        this.getList()
      } else if (val === 1) {
        this.activeTabTitle = this.$t('bk')
        this.dataType = 2
        this.pageNum = 1
        this.finished = false
        this.list = []
        this.checkedList = []
        this.getList()
      } else if (val === 2) {
        // this.activeTabTitle = '网球'
        // this.dataType = 3
        // this.pageNum = 1
        // this.finished = false
        // this.list = []
        // this.checkedList = []
        // this.getList()
        this.activeTabTitle = this.$t('reserved')
        this.$refs.myAppointment.pageNum = 1
        this.$refs.myAppointment.finished = false
        this.$refs.myAppointment.list = []
        this.$refs.myAppointment.getList()
      } else if (val === 3) {
        this.activeTabTitle = this.$t('reserved')
        this.$refs.myAppointment.pageNum = 1
        this.$refs.myAppointment.finished = false
        this.$refs.myAppointment.list = []
        this.$refs.myAppointment.getList()
      }
    },
    dateActiveIndex(val, oldVal) {
      this.selectedDate = this.tabs[val].value
      this.pageNum = 1
      this.finished = false
      this.list = []
      this.checkedList = []
      this.getList()
    }
  },
  created() {
    this.setTitle()
    this.getMyAppointmentList()
    this.generateTabs()
    this.selectedDate = this.tabs[0].value
  },
  methods: {
    setTitle() {
      document.title = '预约赛事'
    },
    getMyAppointmentList() {
      fetchMyAppointmentList({}).then(response => {
        this.myAppointmentList = response.data.length > 0 ? response.data[0].list : []
      })
    },
    getList() {
      this.loading = true
      fetchAppointmentList({ pageNum: this.pageNum, sportsType: this.dataType, date: this.selectedDate }).then(response => {
        this.loading = false
        this.bookCount = response.data.bookCount
        this.list = this.list.concat(response.data.list[0].list)
        if (response.data.list[0].list.length < 20) {
          this.finished = true
          this.finishedText = this.$t('nomore')
        } else {
          this.pageNum += 1
        }
        if (this.list.length < 20) {
          this.finishedText = ''
        }
      })
    },
    generateTabs() {
      const list = []
      for (let i = 0; i < 7; i++) {
        const myDate = new Date()
        const milliseconds = myDate.getTime() + 1000 * 60 * 60 * 24 * i // 当i为0代表当前日期，为1时可以得到明天的日期，以此类推
        const newMyDate = new Date(milliseconds)
        const year = newMyDate.getFullYear()
        const month = newMyDate.getMonth() + 1 < 10 ? '0' + (newMyDate.getMonth() + 1) : newMyDate.getMonth() + 1
        const day = newMyDate.getDate() < 10 ? '0' + newMyDate.getDate() : newMyDate.getDate()
        if (i === 0) {
          list.push({
            name: this.$t('jitian'),
            value: year + '-' + month + '-' + day
          })
        } else {
          list.push({
            name: month + '-' + day,
            value: year + '-' + month + '-' + day
          })
        }
      }
      this.tabs = list
    },
    cellClicked(item, index) {
      if (item.isBook === 1) return
      if (this.checkedList.length >= this.maxBookCount && !this.checkedList.includes(item)) {
        this.$refs.checkboxes[index].toggle(false)
        Toast(this.$t('zuiduiyuyue'))
        return
      }
      const checked = this.$refs.checkboxes[index].checked
      if (checked) {
        this.$refs.checkboxes[index].toggle(false)
      } else {
        const idList = this.checkedList.map(obj => {
          return obj.id
        })
        idList.push(item.id)
        checkBooking({ source: 1, matchIds: idList, sportsType: item.sportsType }).then(response => {
          if (response.data) {
            this.$refs.checkboxes[index].toggle(true)
          } else {
            this.$refs.checkboxes[index].toggle(false)
            Toast('开赛时间需间隔1.5小时')
          }
        })
      }
    },
    tabChange(name, title) {
      this.activeTabTitle = title
      if (name === 0) {
        this.dataType = 1
        this.pageNum = 1
        this.finished = false
        this.list = []
        this.checkedList = []
        this.getList()
      } else if (name === 1) {
        this.dataType = 2
        this.pageNum = 1
        this.finished = false
        this.list = []
        this.checkedList = []
        this.getList()
      } else if (name === 2) {
        this.dataType = 99
        this.pageNum = 1
        this.finished = false
        this.list = []
        this.checkedList = []
        this.getList()
      } else if (name === 3) {
        this.$refs.myAppointment.pageNum = 1
        this.$refs.myAppointment.finished = false
        this.$refs.myAppointment.list = []
        this.$refs.myAppointment.getList()
      }
    },
    dateChange(index, title) {
      this.selectedDate = this.tabs[index].value
      this.pageNum = 1
      this.finished = false
      this.list = []
      this.checkedList = []
      this.getList()
    },
    search() {
      this.$router.push({
        path: '/appointment-game-search'
      })
    },
    commit() {
      const matchIds = this.checkedList.map(obj => {
        return obj.id
      })
      // status 1: 取消预约 2: 预约
      // source 1: 主播预约 2: 普通预约
      book({ source: 1, matchId: matchIds, sportsType: this.dataType, status: 2 }).then(response => {
        Toast(this.$t('xiazaitixing'))
        this.updateStatus(matchIds)
      })
    },
    updateStatus(matchIds) {
      for (let i = 0; i < matchIds.length; i++) {
        const id = matchIds[i]
        for (let j = 0; j < this.list.length; j++) {
          const item = this.list[j]
          if (id === item.id) item.isBook = 1
        }
      }
      this.checkedList = []
      this.bookCount += matchIds.length
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/color.scss';
/deep/.van-row--flex {
  flex-wrap: nowrap;
}
.main-content {
  @include color_main_bg();
  padding-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 100px;
}
.sticky {
  @include color_main_bg();
}
.bottom {
  position: fixed;
  width: 100%;
  height: 90px;
  bottom: 55px;
  // bottom: 0px;
  font-size: 10px;
  z-index: 10;

  @include color_main_bg();
  @include color_main_font();
}
/deep/.van-cell {
  padding: 0px 0px;
  @include color_main_bg();
}
/deep/.van-cell-group {
  padding: 0px 0px;
  @include color_main_bg();
}
/deep/.empty-image .van-empty__image {
  width: 257px;
  height: 84px;
}
/deep/.van-tab {
  @include van_tab();
  border-radius: 6px;
}
/deep/.van-tabs__nav--card {
  margin: 0 10px;
  border-radius: 6px;
  padding: 1px;
  @include van_tabs_card();
}
/deep/.van-tab {
  @include van_tab();
  border-radius: 6px;
}
/deep/.van-tabs__nav--card .van-tab.van-tab--active {
  @include van_tab_active();
}
/deep/.van-tab--active {
  @include color_main_font();
}
/deep/.van-tabs__nav--line {
  box-sizing: content-box;
  height: 100%;
  padding-bottom: 15px;
  @include color_main_bg();
}
/deep/.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 0px 0;
}
</style>
