var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      attrs: { "data-theme": _vm.$store.getters.themeType },
    },
    [
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c("span", { staticClass: "main-theme-font-color" }, [
            _vm._v(_vm._s(_vm.$t("title_options"))),
          ]),
          _vm._v(" "),
          _c(
            "van-row",
            { staticStyle: { "margin-top": "30px" } },
            [
              _c("van-field", {
                staticClass: "textarea",
                style: { backgroundColor: _vm.$theme.getMainBackgroundColor() },
                attrs: {
                  rows: "1",
                  autosize: "",
                  type: "textarea",
                  maxlength: "15",
                  placeholder: _vm.$t("hint11"),
                },
                model: {
                  value: _vm.topic,
                  callback: function ($$v) {
                    _vm.topic = $$v
                  },
                  expression: "topic",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "van-row",
            {
              staticStyle: { height: "100px", "margin-top": "10px" },
              attrs: {
                type: "flex",
                justify: "space-between",
                align: "center",
              },
            },
            [
              _c(
                "van-col",
                { attrs: { span: "10" } },
                [
                  _c("van-field", {
                    staticClass: "textarea",
                    style: {
                      backgroundColor: _vm.$theme.getMainBackgroundColor(),
                    },
                    attrs: {
                      rows: "1",
                      autosize: "",
                      type: "textarea",
                      maxlength: "5",
                      placeholder: _vm.$t("hint12"),
                    },
                    model: {
                      value: _vm.optionA,
                      callback: function ($$v) {
                        _vm.optionA = $$v
                      },
                      expression: "optionA",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "van-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: "4" },
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "22px",
                      height: "25px",
                      "margin-left": "10px",
                      "margin-right": "10px",
                    },
                    attrs: { src: _vm.$theme.getVsImage() },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "van-col",
                { attrs: { span: "10" } },
                [
                  _c("van-field", {
                    staticClass: "textarea",
                    style: {
                      backgroundColor: _vm.$theme.getMainBackgroundColor(),
                    },
                    attrs: {
                      rows: "1",
                      autosize: "",
                      type: "textarea",
                      maxlength: "5",
                      placeholder: _vm.$t("hint13"),
                    },
                    model: {
                      value: _vm.optionB,
                      callback: function ($$v) {
                        _vm.optionB = $$v
                      },
                      expression: "optionB",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("van-row", { staticStyle: { "margin-top": "49px" } }, [
            _c("span", { staticClass: "main-theme-font-color" }, [
              _vm._v(_vm._s(_vm.$t("stop_time"))),
            ]),
            _vm._v(" "),
            _c("span", { staticStyle: { color: "rgba(155, 155, 155, 1)" } }, [
              _vm._v("\n        " + _vm._s(_vm.$t("hint14")) + "\n      "),
            ]),
          ]),
          _vm._v(" "),
          _c("cube-checker", {
            staticStyle: { "font-size": "16px", "margin-top": "30px" },
            attrs: { options: _vm.options, type: "radio" },
            model: {
              value: _vm.duration,
              callback: function ($$v) {
                _vm.duration = $$v
              },
              expression: "duration",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bottom" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "van-button",
              {
                staticClass: "save-button",
                attrs: {
                  disabled:
                    _vm.topic === "" ||
                    _vm.optionA === "" ||
                    _vm.optionB === "" ||
                    !_vm.duration,
                },
                on: {
                  click: function ($event) {
                    _vm.isEdit ? _vm.edit() : _vm.add()
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("save1")) + "\n      ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }