import request from '@/api/httpRequest'

export function income(params) {
  return request({
    url: '/app/user/settlement',
    method: 'post',
    data: params
  })
}

export function incomeList(params) {
  return request({
    url: '/app/user/settlement/list',
    method: 'post',
    data: params
  })
}

export function kitIncomeList(params) {
  return request({
    url: '/app/user/tactic/list',
    method: 'post',
    data: params
  })
}
