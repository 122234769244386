<template>
  <div class="app-container">
    <div class="main-content">
      <div class="card" style="margin-top: 10px;">
        <van-cell :border="false" title-class="cell-title" :title="$t('xuanzepingdao')" is-link @click="chooseChannel">
          <template #default>
            <span v-if="!liveConfig.channelName">{{ $t('xuanzepingdao') }}</span>
            <span v-else class="main-theme-font-color">{{ liveConfig.channelName }}<span v-if="liveConfig.subChannelName">-{{ liveConfig.subChannelName }}</span></span>
          </template>
        </van-cell>
        <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
        <van-cell :border="false" title-class="cell-title" :title="$t('xuanzesaishi')" is-link @click="chooseGame">
          <template #default>
            <span v-if="!liveConfig.homeTeam">{{ $t('xuanzesaishi') }}</span>
            <span v-else class="main-theme-font-color">{{ liveConfig.homeTeam }}</span>
          </template>
        </van-cell>
        <div v-show="liveConfig.sportsType === 1">
          <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
          <van-cell :border="false" title-class="cell-title" title="PK" is-link>
            <template #right-icon>
              <van-switch v-model="liveConfig.status" :disabled="!liveConfig.homeTeam" size="20px" active-color="#4CD964" inactive-color="#9A9B9C" style="margin-right: 2px;" @change="switchChange" />
            </template>
          </van-cell>
        </div>
        <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
        <van-cell :border="false" title-class="cell-title" :title="$t('jiamifanjianshezhi')" is-link @click="configRoom" />
        <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
        <van-cell :border="false" title-class="cell-title" :title="$t('paomadeng')" is-link @click="configMarquee" />
        <van-divider :style="{borderColor: $theme.getDividerBorderColor(), marginLeft: '16px', marginRight: '19px'}" />
      </div>
    </div>
  </div>
</template>

<script>
import { CellGroup, Cell, Row, Col, Divider, Switch, Toast } from 'vant'
import { fetchRecord, setLiveConfig } from '@/api/live-setting/index'

export default {
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
    [Toast.name]: Toast,
    [Switch.name]: Switch
  },
  data() {
    return {
      isOn: false
    }
  },
  computed: {
    liveConfig() {
      return this.$store.getters.liveConfig
    }
  },
  mounted() {
    window.load = this.load
  },
  created() {
    this.setTitle()
    this.getRecord()
  },
  methods: {
    setTitle() {
      document.title = ''
    },
    chooseChannel() {
      this.homeTeam = undefined
      this.matchId = undefined
      this.$router.push({
        path: '/channel-selection',
        query: { channelName: this.liveConfig.channelName }
      })
    },
    configRoom() {
      this.$router.push({
        path: '/room-password-setting'
      })
    },
    configMarquee() {
      this.$router.push({
        path: '/marquee-setting'
      })
    },
    book() {
      this.$router.push({
        path: '/appointment-game-list'
      })
    },
    chooseGame() {
      if (!this.liveConfig.channelId) {
        Toast(this.$t('qingxuanxuanzepingdao'))
        return
      }
      this.$router.push({
        path: '/game-list',
        query: { channelId: this.liveConfig.channelId }
      })
    },
    getRecord() {
      fetchRecord({ }).then(response => {
        const config = response.data
        config.status = response.data.status === 1
        this.$store.dispatch('setLiveConfig', config).then(() => {})
      })
    },
    switchChange(value) {
      const status = value ? 1 : 0
      setLiveConfig({ channelId: this.liveConfig.channelId, matchId: this.liveConfig.matchId, status: status }).then(response => {
        this.getRecord()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/color.scss";
.van-cell--clickable:active {
  background-color: #29292D
}
.van-cell {
  @include color_main_font();
  @include color_main_card_bg();
}
.cell-title {
    font-size: 14px;
}
.cell-value {
  font-size:14px;
  font-family:PingFangSC-Regular,PingFang SC;
  font-weight:400;
  color:rgba(74,144,226,1);
}
.weixin {
  font-size: 14px;
  color:rgba(249,249,249,1);
}
.main-content {
  padding-top: 16px;
  margin-left: 15px;
  margin-right: 15px;
}

.feather {
  width: 24px;
  height: 24px;
}
.amount {
  width:51px;
  height:18px;
  font-size:18px;
  font-family:PingFangSC-Semibold,PingFang SC;
  font-weight:600;
  color:rgba(240,207,44,1);
  line-height:18px;
  margin-left: 4px;
}
</style>
