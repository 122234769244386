const getters = {
  language: state => state.app.language,
  size: state => state.app.size,
  device: state => state.app.device,
  token: state => state.user.token,
  logining: state => state.user.logining,
  merchantId: state => state.user.merchantId,
  appId: state => state.user.appId,
  clientVersion: state => state.user.clientVersion,
  platform: state => state.user.platform,
  introduction: state => state.user.introduction,
  errorLogs: state => state.errorLog.logs,
  liveConfig: state => state.user.liveConfig,
  themeType: state => state.user.themeType
}
export default getters

