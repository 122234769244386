// import parseTime, formatTime and set to filter
export { parseTime, formatTime, stringToTime } from '@/utils'

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

Date.prototype.format = function(format) {
  var o = {
    'M+': this.getMonth() + 1,
    'd+': this.getDate(),
    'h+': this.getHours(),
    'm+': this.getMinutes(),
    's+': this.getSeconds(),
    'q+': Math.floor((this.getMonth() + 3) / 3),
    'S': this.getMilliseconds()
  }
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return format
}

function getFormatDateByLong(l, pattern) {
  return getFormatDate(new Date(l), pattern)
}
function getFormatDate(date, pattern) {
  if (date === undefined) {
    date = new Date()
  }
  if (pattern === undefined || pattern === '') {
    pattern = 'yyyy-MM-dd hh:mm:ss'
  }
  return date.format(pattern)
}

/**
 * @param {string} timeStr
 */
export function diyDate(timeStr) {
  timeStr = timeStr.replace(/-/g, '/')
  const date = new Date(timeStr).getTime()
  const tDate = getFormatDate(new Date(), 'yyyy/MM/dd') // 当前凌晨时间(string)
  const tTime = new Date(tDate).getTime() // 当前凌晨时间(long)
  const eTime = 24 * 60 * 60 * 1000 // 一天时间(long)
  const yTime = tTime - eTime // 昨天凌晨时间(long)
  const byTime = tTime - 2 * eTime // 前天凌晨时间(long)
  const formatDate = getFormatDateByLong(date, 'hh:mm') // 转成时分格式
  let result
  if (date >= tTime) {
    // 今天
    result = formatDate
  } else if (date < tTime && date >= yTime) {
    // 昨天
    result = '昨天 ' + formatDate
  } else if (date < yTime && date >= byTime) {
    // 前天
    result = '前天 ' + formatDate
  } else {
    // 前天之前
    result = getFormatDateByLong(date, 'MM-dd hh:mm')
  }
  return result
}

/**
 * @param {string} timeStr
 */
export function diyDateFormat(timeStr) {
  timeStr = timeStr.replace(/-/g, '/')
  const date = new Date(timeStr)
  var time = date.getTime()
  var tDate = getFormatDate(new Date(), 'yyyy/MM/dd') // 当前凌晨时间(string)
  var tTime = new Date(tDate).getTime() // 当前凌晨时间(long)
  var eTime = 24 * 60 * 60 * 1000 // 一天时间(long)
  var yTime = tTime - eTime // 昨天凌晨时间(long)
  if (time >= tTime) {
    // 今天
    return '今天'
  } else if (time < tTime && time >= yTime) {
    // 昨天
    return '昨天'
  } else {
    return (
      date.getMonth() +
      1 +
      '月' +
      date.getDate() +
      '日'
    )
  }
}

/**
 * @param {string} timeStr
 */
export function parseRemainTime(timeStr) {
  timeStr = timeStr.replace(/-/g, '/')
  const date = new Date(timeStr)
  return date.getTime() - new Date().getTime()
}

/**
 * 获取字符串的指定长度，超出部分以...的替代
 * @param {String} string
 * @param {Number} length
 * @returns {String} newString
 * @example omitString('abcd', 2), 返回值是'ab...'
 */
export function omitString(string, length) {
  if (!string) return ''
  return string.length > length ? string.substr(0, length) + '...' : string
}
